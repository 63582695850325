<template>
    <v-row no-gutters>
        <v-col
            lg="6"
            offset-lg="3"
            md="12"
        >
            <v-progress-linear
                v-if="loading"
                indeterminate
                color="primary"
            />
            <v-skeleton-loader
                v-if="loading"
                class="mx-auto"
                type="card"
            />
            <v-skeleton-loader
                v-if="loading"
                class="mx-auto"
                type="card"
            />
            <v-skeleton-loader
                v-if="loading"
                class="mx-auto"
                type="card"
            />
            <div v-if="!loading">
                <v-alert
                    v-for="(error, errorKey) in generalFormErrors"
                    :key="errorKey"
                    type="error"
                    v-text="error"
                />
                <v-expand-transition>
                    <v-card
                        flat
                        class="mx-auto mb-6"
                        v-if="add"
                    >
                        <v-card-text>
                            <p
                                class="title text--primary mb-0"
                                v-text="$t('page.correspondence.new')"
                            />
                            <v-col 
                                cols="12"
                            >
                                <v-text-field
                                    type="text"
                                    v-model="data.subject"
                                    :label="$t('correspondence.subject')"
                                    :error-messages="formError(formErrors, 'subject')"
                                />
                                <ckeditor
                                    :config="editorConfig"
                                    :editor="editor"
                                    v-model="data.content"
                                />
                                <v-textarea
                                    v-if="false"
                                    type="text"
                                    outlined
                                    auto-grow
                                    v-model="data.content"
                                    :label="$t('correspondence.content')"
                                    :error-messages="formError(formErrors, 'content')"
                                />
                            </v-col>
                            <v-col 
                                cols="12"
                                v-cloak
                                @drop.prevent="addDropFile"
                                @dragover.prevent
                            >
                                <v-file-input
                                    v-model="file"
                                    color="primary"
                                    label="Anhang"
                                    :placeholder="$t('page.participant_upload.select')"
                                    prepend-icon=""
                                    prepend-inner-icon="mdi-paperclip"
                                    outlined
                                    :show-size="1000"
                                >
                                    <template v-slot:selection="{ text }">
                                        <v-chip
                                            color="primary"
                                            dark
                                            label
                                            small
                                        >
                                            {{ text }}
                                        </v-chip>
                                    </template>
                                </v-file-input>
                            </v-col>
                        </v-card-text>
                    </v-card>
                </v-expand-transition>

                <v-card
                    flat
                    class="mx-auto mb-6"
                    v-for="(correspondence, key) in objects"
                    :key="`correspondence-${key}`"
                >
                    <v-card-text>
                        <v-row
                            class="float-right mr-2"
                        >
                            <v-menu
                                v-if="!add"
                                offset-y
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        mdi-dots-horizontal
                                    </v-icon>
                                </template>
                                <v-list>
                                    <v-list-item @click="doEdit(correspondence)">
                                        <v-list-item-action>
                                            <v-icon>mdi-pencil</v-icon>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                            <v-list-item-title v-text="$t('general.edit')" />
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item @click="initiateDeletion(correspondence)">
                                        <v-list-item-action>
                                            <v-icon>mdi-delete</v-icon>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                            <v-list-item-title v-text="$t('general.delete')" />
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </v-row>
                        <p class="title text--primary mb-0">
                            {{ formatDate(correspondence.created) }} {{ correspondence.subject }}
                        </p>
                        <p class="text--secondary">
                            {{ $t('correspondence.last_update') }}: {{ formatDate(correspondence.updated) }}
                        </p>
                        <div
                            v-if="edit !== correspondence.id"
                            class="text--primary"
                            v-html="correspondence.content"
                        />
                        <p
                            v-if="correspondence.filename && edit !== correspondence.id"
                            class="text--secondary"
                        >
                            {{ $t('correspondence.attachment') }}: 
                            <a :href="`${apiRoot}assets/public/participant-correspondence/${correspondence.id}/1`">
                                {{ correspondence.filename }}
                            </a>
                        </p>

                        <v-col 
                            v-if="edit === correspondence.id"
                            cols="12"
                        >
                            <v-text-field
                                type="text"
                                v-model="data.subject"
                                :label="$t('correspondence.subject')"
                                :error-messages="formError(formErrors, 'subject')"
                            />
                            <ckeditor
                                :config="editorConfig"
                                :editor="editor"
                                v-model="data.content"
                            />
                            <v-textarea
                                v-if="false"
                                type="text"
                                outlined
                                auto-grow
                                v-model="data.content"
                                :label="$t('correspondence.content')"
                                :error-messages="formError(formErrors, 'content')"
                            />
                        </v-col>
                    </v-card-text>
                </v-card>
            </div>
        </v-col>
        <ConfirmationDialog
            :confirm="deleteObject"
            :cancel="() => (deleteDialog = false)"
            :loading="deleting"
            :dialog="deleteDialog"
        />
        <UnsavedForm
            :unsaved-form="edit !== false || add"
            :errors="formErrors"
            :on-save="saveFunction"
            :on-cancel="cancelObjectChanges"
        />
    </v-row>
</template>

<script>

import UnsavedForm from '@/components/common/UnsavedForm';
import ConfirmationDialog from '@/components/common/ConfirmationDialog';
import CorrespondenceProxy from '@/proxies/CorrespondenceProxy';
import DatesMixin from '@/mixins/dates';
import NoteMixin from '@/mixins/correspondence';
import { SuccessSnackbar, StandBy } from '@/app-events.js';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
    name: 'Correspondence',
    mixins: [
        DatesMixin,
        NoteMixin,
    ],
    components: {
        ConfirmationDialog,
        UnsavedForm,
    },
    props: {
        participant: {
            type: Object,
            default: () => {},
        },
        addProp: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            apiRoot: process.env.VUE_APP_API_ROOT,
            editor: ClassicEditor,
            editorConfig: {
                toolbar: {
                    items: [
                        'heading',
                        '|',
                        'bold',
                        'italic',
                        'link',
                        'bulletedList',
                        'numberedList',
                        'indent',
                        'outdent',
                        'undo',
                        'redo',
                    ],
                },
            },
            loading: false,
            edit: false,
            add: false,
            data: {},
            objects: [],
            deleteDialog: false,
            deleting: false,
            objectToDelete: null,
            file: null,
        }
    },
    created() {
        this.fetchData();
    },
    methods: {
        addDropFile(e) {
            // https://github.com/vuetifyjs/vuetify/issues/7836#issuecomment-582841222
            this.file = e.dataTransfer.files[ 0 ];
        },
        fetchData() {
            this.loading = true;
            new CorrespondenceProxy().all({
                participantId: this.participant.id,
            }).then(({ data }) => {
                this.objects = data.data;
                this.loading = false;
            }).catch(({ data }) => {
                this.displayError(data);
            });
        },
        doEdit(correspondence) {
            this.data = JSON.parse(JSON.stringify(correspondence));
            this.edit = correspondence.id;
        },
        saveFunction() {
            this.$eventBus.dispatch(new StandBy(true));
            if (this.data.id) {
                new CorrespondenceProxy().update(this.data.id, this.prepareFormData(this.data)).then(({ data }) => {
                    this.$eventBus.dispatch(new StandBy(false));
                    this.fetchData();
                    this.$eventBus.dispatch(new SuccessSnackbar(this.$t('correspondence.actions.saved')));
                    this.edit = false;
                    this.resetErrors();
                }).catch(({ data }) => {
                    this.processErrors(data);
                    this.$eventBus.dispatch(new StandBy(false));
                });
            } else {
                const preparedData = this.prepareFormData(this.data);
                let formData = new FormData();
                if (this.file !== null) {
                    formData.append(this.file.name, this.file, this.file.name);   
                }
                formData.append('participant_correspondence[participant]', preparedData.participant_correspondence.participant);
                formData.append('participant_correspondence[content]', preparedData.participant_correspondence.content);
                formData.append('participant_correspondence[subject]', preparedData.participant_correspondence.subject);

                
                new CorrespondenceProxy().new(formData).then(({ data }) => {
                    this.$eventBus.dispatch(new StandBy(false));
                    this.fetchData();
                    this.$eventBus.dispatch(new SuccessSnackbar(this.$t('correspondence.actions.saved')));
                    this.add = false;
                    this.file = null;
                    this.resetErrors();
                }).catch(({ data }) => {
                    this.processErrors(data);
                    this.$eventBus.dispatch(new StandBy(false));
                });
            }
        },
        cancelObjectChanges() {
            this.edit = false;
            this.add = false;
        },
        initiateDeletion(item) {
            this.objectToDelete = item;
            this.deleteDialog = true;
        },
        deleteObject() {
            this.deleting = true;
            new CorrespondenceProxy().delete(this.objectToDelete.id).then(({ data }) => {
                this.$eventBus.dispatch(new SuccessSnackbar(this.$t('correspondence.actions.deleted')));
                for (let i = 0; i < this.objects.length; i++) {
                    const correspondence = this.objects[ i ];
                    if (correspondence.id === this.objectToDelete.id) {
                        this.objects.splice(i, 1);
                        break;
                    }
                }
                this.cancelObjectChanges();
            }).catch(({ data }) => {
                this.displayError(data);
            }).finally(() => {
                this.deleteDialog = false;
                this.objectToDelete = null;
                this.deleting = false;
            });
        },
    },
    watch: {
        addProp(val) {
            if (this.edit !== false) {
                return;
            }
            this.add = true;
            this.data = {
                id: null,
                participant: this.participant,
                content: '',
                subject: '',
            };
        },
    },
};
</script>
