<template>
    <v-card flat>
        <v-card-text>
            <v-row>
                <v-col cols="12">
                    <v-text-field
                        type="text"
                        v-model="data.name"
                        :label="$t('amm.name')"
                        :error-messages="formError(errors, 'name')"
                        :hint="$t('general.required')"
                        persistent-hint
                    />
                </v-col>
                <v-col cols="12">
                    <v-textarea
                        type="text"
                        v-model="data.address"
                        :label="$t('amm.address')"
                        :error-messages="formError(errors, 'address')"
                    />
                </v-col>
                <v-col md="6">
                    <v-text-field
                        type="text"
                        v-model="data.zip"
                        :label="$t('amm.zip')"
                        :error-messages="formError(errors, 'zip')"
                    />
                </v-col>
                <v-col md="6">
                    <v-text-field
                        type="text"
                        v-model="data.city"
                        :label="$t('amm.city')"
                        :error-messages="formError(errors, 'city')"
                    />
                </v-col>
                <v-col cols="12">
                    <v-select
                        v-model="data.canton.code"
                        :items="computedCantons"
                        :label="$t('amm.canton')"
                        :error-messages="formError(errors, 'canton')"
                        :hint="$t('general.required')"
                        persistent-hint
                    />
                </v-col>
                <v-col cols="12">
                    <v-text-field
                        type="text"
                        v-model="data.phone"
                        :label="$t('amm.phone')"
                        :error-messages="formError(errors, 'phone')"
                    />
                </v-col>
                <v-col cols="12">
                    <v-text-field
                        type="text"
                        v-model="data.contact_name"
                        :label="$t('amm.contactName')"
                        :error-messages="formError(errors, 'contactName')"
                    />
                </v-col>
                <v-col cols="12">
                    <v-text-field
                        type="text"
                        v-model="data.contact_email"
                        :label="$t('amm.contactEmail')"
                        :error-messages="formError(errors, 'contactEmail')"
                    />
                </v-col>
                <v-col cols="12">
                    <v-text-field
                        type="text"
                        v-model="data.contact_phone"
                        :label="$t('amm.contactPhone')"
                        :error-messages="formError(errors, 'contactPhone')"
                    />
                </v-col>
                <v-col cols="12">
                    <v-text-field
                        type="text"
                        v-model="data.website"
                        :label="$t('amm.website')"
                        :error-messages="formError(errors, 'website')"
                    />
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>

import FormDataMixin from '@/mixins/form-data';
import AmmMixin from '@/mixins/amm';
import { mapGetters } from 'vuex';

export default {
    name: 'OfficeInformation',
    mixins: [
        FormDataMixin,
        AmmMixin,
    ],
    props: {
        data: {
            type: Object,
            default: () => ({
                canton: {},
            }),
        },
        errors: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        ...mapGetters({
            cantons: 'canton/cantons',
        }),
        computedCantons() {
            const allCantons = [];
            for (let i = 0; i < this.cantons.length; i++) {
                const canton = this.cantons[ i ];
                allCantons.push({
                    value: canton.code,
                    text: `${canton.code} ${canton.name}`,
                })
            }
            return allCantons;
        },
    },
};
</script>
