import Proxy from './Proxy';

class AuthProxy extends Proxy {
    /**
   * The constructor of the AuthProxy.
   *
   * @param {Object} parameters The parameters for the request.
   */
    constructor(parameters = {}) {
        super('/security', parameters);
    }

    requestPassword(formData) {
        return this.submit('post', '/password-reset', formData);
    }

    resetPassword(token, formData) {
        return this.submit('post', `/password-reset/${token}`, formData);
    }

    resetPasswordLoggedIn(formData) {
        return this.submit('post', `/password-reset-logged-in`, formData);
    }
}

export default AuthProxy;
