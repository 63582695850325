<template>
    <v-row no-gutters>
        <v-col
            lg="6"
            offset-lg="3"
            md="12"
        >
            <v-progress-linear
                v-if="loading"
                indeterminate
                color="primary"
            />
            <v-row>
                <v-col cols="12">
                    <v-skeleton-loader
                        v-if="loading"
                        class="mx-auto"
                        type="card"
                    />
                    <InsuranceFundInformation
                        v-if="!loading"
                        :data="data"
                        :errors="errors"
                    />
                </v-col>
            </v-row>
            <UnsavedForm
                :unsaved-form="unsavedForm"
                :errors="errors"
                :on-save="saveFunction"
                :on-cancel="cancelObjectChanges"
            />
        </v-col>
    </v-row>
</template>

<script>
import UnsavedForm from '@/components/common/UnsavedForm';
import InsuranceFundInformation from '@/components/insuranceFund/Form/InsuranceFundInformation';
import { DefaultSnackbar } from '@/app-events.js';

export default {
    name: 'InsuranceFundForm',
    components: {
        UnsavedForm,
        InsuranceFundInformation,
    },
    props: {
        rawData: {
            type: Object,
            default: () => ({}),
        },
        data: {
            type: Object,
            default: () => ({}),
        },
        errors: {
            type: Object,
            default: () => ({}),
        },
        loading: {
            type: Boolean,
            default: false,
        },
        saveFunction: {
            type: Function,
            default: () => ({}),
        },
        discardFunction: {
            type: Function,
            default: () => ({}),
        },
    },
    data() {
        return {
            unsavedForm: false,
        }
    },
    methods: {
        cancelObjectChanges() {
            this.$eventBus.dispatch(new DefaultSnackbar(this.$t('general.changes_discarded')));
            this.discardFunction();
        },
    },
    watch: {
        data: {
            handler(_, oldValue) {
                if (JSON.stringify(this.data) === JSON.stringify(this.rawData)) {
                    this.unsavedForm = false;
                } else {
                    this.unsavedForm = true;
                }
            },
            deep: true
        },
        rawData: {
            handler(_, oldValue) {
                if (JSON.stringify(this.data) === JSON.stringify(this.rawData)) {
                    this.unsavedForm = false;
                } else {
                    this.unsavedForm = true;
                }
            },
            deep: true
        },
    },
};
</script>
