import DefaultLayout from '@/layouts/Default';
import ParticipantIndex from '@/views/Participants/Index';
import ParticipantDetail from '@/views/Participants/Detail';
import ParticipantNew from '@/views/Participants/New';

export default {
    path: '/participants-:courseType',
    component: DefaultLayout,
    children: [
        {
            path: '',
            name: 'participants',
            component: ParticipantIndex,
            meta: {
                auth: true,
            },
        },
        {
            path: 'all',
            name: 'participants_all',
            component: ParticipantIndex,
            meta: {
                auth: true,
            },
        },
        {
            path: 'archive',
            name: 'participants_archive',
            component: ParticipantIndex,
            meta: {
                auth: true,
            },
        },
        {
            path: 'completed',
            name: 'participants_course_completed',
            component: ParticipantIndex,
            meta: {
                auth: true,
            },
        },
        {
            path: 'canceled',
            name: 'participants_course_canceled',
            component: ParticipantIndex,
            meta: {
                auth: true,
            },
        },
        {
            path: 'no-course',
            name: 'participants_course_no_course',
            component: ParticipantIndex,
            meta: {
                auth: true,
            },
        },
        {
            path: 'new',
            name: 'participant_new',
            component: ParticipantNew,
            meta: {
                auth: true,
            },
        },
        {
            path: ':id/:menu?',
            name: 'participant_detail',
            component: ParticipantDetail,
            meta: {
                auth: true,
            },
        },
    ],
};
