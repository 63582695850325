<template>
    <v-flex
        md12
        class="px-12 pb-12"
    >
        <PageHeader
            :breadcrumbs="breadcrumbs"
            :title="$t('page.rav_consultant.title')"
            button
            :button-text="$t('page.rav_consultant.new')"
            button-to="rav_consultant_new"
            search
            :search-label="$t('page.rav_consultant.search')"
            @search-input="searchInputListener"
        />
        <v-row>
            <v-col cols="12">
                <TabMenu
                    v-model="menu"
                    :menus="menus"
                />
            </v-col>
            <ActionBar
                new-button
                new-to="rav_consultant_new"
                :new-label="$t('page.rav_consultant.new')"
                fetch
                :fetch-function="fetchData"
            />
            <v-col cols="12">
                <v-progress-linear
                    v-if="loading"
                    indeterminate
                    color="primary"
                />
                <v-skeleton-loader
                    v-if="loading"
                    class="mx-auto"
                    type="table-tbody"
                />
                <RavConsultantList
                    v-if="!loading"
                    :rav-consultants="ravConsultants"
                    :search="textSearch"
                />
            </v-col>
        </v-row>
    </v-flex>
</template>

<script>

import ActionBar from '@/components/common/ActionBar';
import PageHeader from '@/components/common/PageHeader';
import TabMenu from '@/components/common/TabMenu';
import RavConsultantList from '@/components/ravConsultants/RavConsultantList';
import RavConsultantProxy from '@/proxies/RavConsultantProxy';

import { SuccessSnackbar, ObjectDeleted } from '@/app-events.js';

export default {
    name: 'RavIndex',
    components: {
        ActionBar,
        PageHeader,
        TabMenu,
        RavConsultantList,
    },
    data() {
        return {
            breadcrumbs: [
                {
                    text: this.$t('page.home.title'),
                    exact: true,
                    to: { name: 'home' },
                },
                {
                    text: this.$t('page.rav_consultant.title'),
                    disabled: true,
                },
            ],
            menu: 'rav_consultant',
            menus: [
                {
                    id: 'rav',
                    label: 'RAV-Stelle',
                    to: { name: 'rav' },
                },
                {
                    id: 'rav_consultant',
                    label: 'RAV-Berater',
                },
            ],
            textSearch: '',
            ravConsultants: [],
            loading: true,
        };
    },
    created () {
        this.fetchData();
        this.$eventBus.listen(ObjectDeleted, (event) => {
            this.$eventBus.dispatch(new SuccessSnackbar(this.$t('rav_consultant.actions.deleted')));
            for (let i = 0; i < this.ravConsultants.length; i++) {
                const rav = this.ravConsultants[ i ];
                if (rav.id === event.object.id) {
                    this.ravConsultants.splice(i, 1);
                    break;
                }
            }
        });
    },
    methods: {
        fetchData() {
            this.loading = true;
            new RavConsultantProxy().all().then(({ data }) => {
                this.ravConsultants = data.data;
                this.loading = false;
            }).catch(({ data }) => {
                this.displayError(data);
            });
        },
        searchInputListener(value) {
            this.textSearch = value;
        },
    },
};
</script>
