export default {
    methods: {
        resetObject() {
            this.data = JSON.parse(JSON.stringify(this.rawData));
            this.resetErrors();
        },
        prepareData(data) {
            if (data.week === null) {
                data.week = {};
            }

            return data;
        },
        prepareFormData(data) {
            return {
                course_calendar: {
                    course_name: data.course_name,
                    professor: data.professor,
                    start: data.start_date + ' ' + data.timestart,
                    end: data.start_date + ' ' + data.timeend,
                    course: data.course.id,
                    color: data.course.color,
                    details:'Professor: ' + data.professor+ ', Course Name: ' + data.course_name,
                    name:'Professor: ' + data.professor+ ', Course Name: ' + data.course_name,
                },
            };
        },
    },
};

