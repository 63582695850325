<template>
    <div>
        <v-card flat>
            <v-data-table
                class="pa-3"
                :headers="headers"
                :items="objects"
                :search="search"
                :custom-filter="filterParticipant"
                :show-select="selection"
                v-model="selectedRows"
                :footer-props="defaultFooterProps"
                :items-per-page="getPrefenceValue('user.itemsPerPage', 25)"
                @update:items-per-page="val => setPrefenceValue('user.itemsPerPage', val)"
                :sort-by="getPrefenceValue('participants.sortBy', ['fullname'])"
                @update:sort-by="val => setPrefenceValue('participants.sortBy', val)"
                :sort-desc="getPrefenceValue('participants.sortDesc', false)"
                @update:sort-desc="val => setPrefenceValue('participants.sortDesc', val)"
                :page="currentPage"
                @update:page="page => currentPage = page"
            >
                <template #item.fullname="{ item }">
                    <v-btn
                        link
                        text
                        :to="{ name: 'participant_detail', params: { id: item.id, courseType: courseType }, query: participantLinkQuery()}"
                    >
                        {{ item.fullname }}
                    </v-btn>
                </template>
                <template #item.mobile="{ item }">
                    <span v-if="item.mobile">
                        {{ item.mobile }}
                    </span>
                    <span v-else>
                        {{ item.phone }}
                    </span>
                </template>
                <template #item.course="{ item }">
                    <span v-if="item.course">
                        {{ item.course.name }} ({{ formatDate(item.course.start_date) }} - {{ formatDate(item.course.end_date) }})
                    </span>
                </template>
                <template #item.waiting_course="{ item }">
                    <span v-if="item.waiting_course">
                        {{ item.waiting_course.name }} ({{ formatDate(item.waiting_course.start_date) }} - {{ formatDate(item.waiting_course.end_date) }})
                    </span>
                </template>
                <template #item.status="{ item }">
                    <span v-if="item.status">
                        {{ $t(`participant.statuses.${item.status}`) }}
                    </span>
                </template>
                <template #item.first_interview="{ item }">
                    <span v-if="hadFirstInterview(item)">
                        <v-checkbox
                            :label="$t('first_interview.already_done')"
                            v-model="item.had_first_interview_via_phone"
                            disabled
                        />
                    </span>
                    <span v-else>
                        {{ item.registration_time_slot ? formatDateTime(item.registration_time_slot.start) : 'Bisher kein Termin' }}
                    </span>
                </template>
                <template v-if="!['FI'].includes(menu)" #item.group="{ item }">
                    <v-chip
                        v-if="item.group == 'B'"
                        small
                        color="blue"
                        text-color="white"
                    />
                    <v-chip
                        v-if="item.group == 'R'"
                        small
                        color="red"
                        text-color="white"
                    />
                </template>
                <template #item.available="{ item }">
                    <v-checkbox
                        label=""
                        v-model="item.available"
                        disabled
                    />
                </template>
                <template #item.actions="{ item }">
                    <v-tooltip
                        v-if="item.email"
                        top
                    >
                        <template #activator="{ on }">
                            <v-btn
                                icon
                                :href="`mailto:${item.email}`"
                                v-on="on"
                                class="mx-1"
                            >
                                <v-icon>
                                    mdi-email-outline
                                </v-icon>
                            </v-btn>
                        </template>
                        <span>E-Mail an Teilnehmer</span>
                    </v-tooltip>
                    <v-tooltip
                        v-if="item.rav_consultant || item.rav_consultant_email_freetext"
                        top
                    >
                        <template #activator="{ on }">
                            <v-btn
                                icon
                                :href="`mailto:${item.email}?cc=${item.rav_consultant ? item.rav_consultant.email : item.rav_consultant_email_freetext}`"
                                v-on="on"
                                class="mx-1"
                            >
                                <v-icon>
                                    mdi-email-multiple-outline
                                </v-icon>
                            </v-btn>
                        </template>
                        <span>E-Mail mit Kopie an RAV Berater</span>
                    </v-tooltip>
                </template>
                <template #item.misc="{ item }">
                    <v-tooltip left>
                        <template #activator="{ on }">
                            <v-btn
                                icon
                                v-on="on"
                                class="mx-1"
                                :to="{ name: 'participant_detail', params: { id: item.id }}"
                            >
                                <v-icon>
                                    mdi-pencil
                                </v-icon>
                            </v-btn>
                        </template>
                        <span v-text="$t('general.edit')" />
                    </v-tooltip>
                    <v-menu offset-y>
                        <template #activator="{ on, attrs }">
                            <v-icon
                                v-bind="attrs"
                                v-on="on"
                            >
                                mdi-dots-horizontal
                            </v-icon>
                        </template>
                        <v-list>
                            <v-list-item
                                v-if="['FI'].includes(menu) && item.rav_consultant && item.rav_consultant.id"
                                @click="doSend(item)"
                            >
                                <v-list-item-action>
                                    <v-icon>mdi-email</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title v-text="$t('learn_goal_control.actions.rav_send')" />
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item
                                v-if="['A', 'FI'].includes(menu)"
                                @click="initiateDownloadCertificate(item)"
                            >
                                <v-list-item-action>
                                    <v-icon>mdi-certificate</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title v-text="$t('participant.downloads.amm_certificate')" />
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item
                                v-if="menu === 'A' || menu === 'CB'"
                                @click="printFinalReport(item)"
                            >
                                <v-list-item-action>
                                    <v-icon>mdi-file-chart</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title v-text="$t('participant.downloads.final_report')" />
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item
                                v-if="menu === 'A' || menu === 'CB'"
                                @click="generateCourseIdentityDocument(item)"
                            >
                                <v-list-item-action>
                                    <v-icon>mdi-file-chart</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title v-text="$t('participant.downloads.course_identity')" />
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="initiateDeletion(item)">
                                <v-list-item-action>
                                    <v-icon>mdi-delete</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title v-text="$t('general.delete')" />
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </template>
            </v-data-table>
        </v-card>
        <v-dialog
            v-model="certificateDialog"
            persistent
            max-width="350px"
        >
            <v-card>
                <v-card-title>
                    {{ $t('participant.downloads.amm_certificate') }}
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col
                                cols="12"
                            >
                                <v-select
                                    :items="languages"
                                    v-model="language"
                                    class="text-none mb-2 mr-2"
                                />
                                <v-date-picker
                                    locale="de-ch"
                                    v-model="certificateMonth"
                                    type="month"
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                                cols="12"
                            >
                                <v-checkbox
                                    v-model="certificateCorrection"
                                    :label="$t('participant.downloads.amm_certificate_correction')"
                                />
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn
                        color="default"
                        text
                        @click="certificateDialog = false"
                    >
                        {{ $t('general.cancel') }}
                    </v-btn>
                    <v-btn
                        color="primary darken-1"
                        text
                        @click="downloadCertificate()"
                    >
                        {{ $t('general.export') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <ConfirmationDialog
            :confirm="deleteObject"
            :cancel="() => (deleteDialog = false)"
            :loading="deleting"
            :dialog="deleteDialog"
        />
        <EmailDialog
            attachments
            :confirm="emailObject"
            :cancel="() => (emailDialog = false)"
            :loading="sending"
            :dialog="emailDialog"
            :data="email"
            :title="$t('learn_goal_control.actions.rav_send')"
        />
    </div>
</template>

<script>

import ConfirmationDialog from '@/components/common/ConfirmationDialog';
import EmailDialog from '@/components/common/EmailDialog';
import ParticipantProxy from '@/proxies/ParticipantProxy';
import LearningContractProxy from '@/proxies/LearningContractProxy';
import FileDownloadMixin from '@/mixins/file-download';
import DatesMixin from '@/mixins/dates';
import { ObjectDeleted, StandBy, SuccessSnackbar } from '@/app-events.js';

export default {
    name: 'ParticipantsList',
    mixins: [ FileDownloadMixin, DatesMixin ],
    components: {
        ConfirmationDialog,
        EmailDialog,
    },
    props: {
        search: {
            type: String,
            default: '',
        },
        menu: {
            type: String,
            default: '',
        },
        courseType: {
            type: String,
            default: 'ol',
        },
        courseId: {
            type: String,
            default: '',
        },
        selection: {
            type: Boolean,
            default: false,
        },
        all: {
            type: Boolean,
            default: false,
        },
        showStatus: {
            type: Boolean,
            default: false,
        },
        objects: {
            type: Array,
            default: () => ([]),
        },
    },
    data() {
        return {
            deleteDialog: false,
            deleting: false,
            objectToDelete: null,
            emailDialog: false,
            sending: false,
            objectToEmail: null,
            email: {
                subject: '',
                content: '',
                attachments: [],
            },
            selectedRows: [],
            headers: [
                {
                    text: 'Profile',
                    value: 'fullname',
                    align: 'start',
                },
                { text: 'Telefonnummer', value: 'mobile' },
                {
                    value: 'course',
                    text: this.menu === 'RE' ? 'Wunschkurs' : 'Kurs',
                },
                {
                    text: 'Warteliste',
                    value: 'waiting_course',
                    align: this.menu === 'WL' || this.all ? '' : ' d-none',
                },
                { text: 'Coach', value: 'coach.fullname', align: this.menu === 'RE' ? '' : ' d-none', },
                {
                    text: 'Gruppe' ,
                    value: 'group',
                    align: this.menu !== 'FI' ? '' : ' d-none',
                },
                {
                    text: 'Erstgespräch',
                    value: 'first_interview',
                    align: this.menu === 'FI' ? '' : ' d-none',
                },
                { text: 'Verfügt', value: 'available' },
                {
                    text: this.$t('participant.status'),
                    value: 'status',
                    align: this.all || this.showStatus ? '' : ' d-none',
                },
                {
                    text: 'Aktionen',
                    value: 'actions',
                    sortable: false ,
                    align: 'right',
                },
                {
                    text: '',
                    value: 'misc',
                    sortable: false ,
                    align: 'end',
                },
            ],
            certificateDialog: false,
            certificateCorrection: false,
            certificateMonth: new Date().toISOString().substr(0, 7),
            language: 'de',
            languages: [
                {
                    value: 'de',
                    text: 'Deutsch',
                },
                {
                    value: 'en',
                    text: 'Englisch',
                },
            ]
        }
    },
    methods: {
        filterParticipant(value, search, item) {
            if (search === '') {
                return true;
            }
            if (!value) {
                return false;
            }
            if (typeof value.start_date !== 'undefined') {
                return this.formatDate(value.start_date).includes(search) || value.name.toString().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(search.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""));
            }
            return value.toString().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(search.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""));
        },
        initiateDeletion(item) {
            this.objectToDelete = item;
            this.deleteDialog = true;
        },
        initiateDownloadCertificate(item) {
            this.objectToDownload = item;
            this.certificateDialog = true;
        },
        hadFirstInterview(item) {
            const hadFirstInterview = item.had_first_interview_via_phone || false;
            return hadFirstInterview;
        },
        deleteObject() {
            this.deleting = true;
            new ParticipantProxy().delete(this.objectToDelete.id).then(({ data }) => {
                this.$eventBus.dispatch(new ObjectDeleted({
                    object: this.objectToDelete,
                }));
            }).catch(({ data }) => {
                this.displayError(data);
            }).finally(() => {
                this.deleteDialog = false;
                this.objectToDelete = null;
                this.deleting = false;
            });
        },
        participantLinkQuery() {
            if (!this.courseId) {
                return {};
            }
            return {
                course: this.courseId,
            };
        },
        downloadCertificate() {
            this.$eventBus.dispatch(new StandBy(true));
            new ParticipantProxy().downloadCertificate([ this.objectToDownload.id ], this.certificateMonth, this.certificateCorrection, this.language).then(({ data }) => {
                this.openPdf(data);
            }).catch(({ data }) => {
                this.displayError(data);
            }).finally(() => {
                this.$eventBus.dispatch(new StandBy(false));
                this.objectToDownload = null;
                this.certificateDialog = false;
            });
        },
        doSend(item) {
            this.objectToEmail = item;

            new ParticipantProxy().firstInterviewEmail(this.objectToEmail.id).then(({ data }) => {
                this.email = {
                    subject: `Erstgespräche ${item.firstname} ${item.lastname}`,
                    content: data.data.content,
                    attachments: [],
                };
                this.emailDialog = true;
            }).catch(({ data }) => {
                this.displayError(data);
            });
        },
        emailObject() {
            this.sending = true;
            let formData = new FormData();
            for (let file of this.email.attachments) {
                formData.append(file.name, file, file.name);
            }
            formData.append('subject', this.email.subject);
            formData.append('content', this.email.content);
            new ParticipantProxy().sendFirstInterviewEmail(this.objectToEmail.id, formData).then(({ data }) => {
                this.$eventBus.dispatch(new SuccessSnackbar(this.$t('learn_goal_control.actions.sent')));
                this.fetchData();
            }).catch(({ data }) => {
                this.displayError(data);
            }).finally(() => {
                this.emailDialog = false;
                this.objectToEmail = null;
                this.sending = false;
            });
        },
        printFinalReport(item) {
            this.$eventBus.dispatch(new StandBy(true));
            new LearningContractProxy().print(item.id, 'final_report').then(({ data }) => {
                // this.openPdf(data);
                this.$eventBus.dispatch(new SuccessSnackbar(this.$t('participant.downloads.generated.final_report')));
            }).catch(({ data }) => {
            }).finally(() => {
                this.$eventBus.dispatch(new StandBy(false));
            });
        },
        generateGoalDocument(item) {
            this.$eventBus.dispatch(new StandBy(true));
            new ParticipantProxy().generateGoalDocument(item.id).then(({ data }) => {
                this.$eventBus.dispatch(new SuccessSnackbar(this.$t('participant.downloads.generated.goal')));
            }).catch(({ data }) => {
                this.displayError(data);
            }).finally(() => {
                this.$eventBus.dispatch(new StandBy(false));
            });
        },
        generateFinalReportDocument(item) {
            this.$eventBus.dispatch(new StandBy(true));
            new ParticipantProxy().generateFinalReportDocument(item.id).then(({ data }) => {
                this.$eventBus.dispatch(new SuccessSnackbar(this.$t('participant.downloads.generated.final_report')));
            }).catch(({ data }) => {
                this.displayError(data);
            }).finally(() => {
                this.$eventBus.dispatch(new StandBy(false));
            });
        },
        generateCourseIdentityDocument(item) {
            this.$eventBus.dispatch(new StandBy(true));
            new ParticipantProxy().generateCourseIdentityDocument(item.id).then(({ data }) => {
                this.openPdf(data);
            }).catch(({ data }) => {
                this.displayError(data);
            }).finally(() => {
                this.$eventBus.dispatch(new StandBy(false));
            });
        }
    },
    watch: {
        selectedRows(val) {
            this.$emit('selection-changed', val);
        }
    },
};
</script>
