export default {
    methods: {
        resetObject() {
            this.data = JSON.parse(JSON.stringify(this.rawData));
            this.resetErrors();
        },
        prepareData(data) {
            return data;
        },
        prepareFormData(data) {
            return {
                week: {
                    name: data.name,
                    courseType: data.course_type,
                    weekNo: data.week_no,
                },
            };
        },
    },
};
