import Proxy from './Proxy';

class LearningContractProxy extends Proxy {
    constructor(parameters = {}) {
        super('/learning-contract', parameters);
    }

    get(participantId) {
        return this.submit('get', `/${participantId}`);
    }

    getQuestions() {
        return this.submit('get', '/questions');
    }

    print(id, docType, language) {
        return this.submit('get', `/print/${id}/${docType}?language=${language}`, null, null, {}, 'blob');
    }
    update(id, loggedIn, start, formData) {

        let query;
        if (loggedIn) {
            query = 'logged_in=yes';
        } else {
            query = 'logged_in=no';
        }
        if (start === 'kursstart') {
            query = query+'&start=1';
        } else
        {
            query = query+'&start=0';
        }
        return this.submit('put', `/${id}?${query}`, formData);
    }
}

export default LearningContractProxy;
