<template>
    <v-snackbar
        :centered="false"
        v-model="unsavedForm"
        :timeout="-1"
        :color="hasError() ? 'error' : 'warning'"
    >
        {{ $t('general.unsaved_changes', language) }}
        <v-btn
            color="default"
            @click="onSave"
            depressed
            v-text="$t('general.save', language)"
        />
        <v-btn
            color="default"
            outlined
            @click="onCancel"
            v-text="$t('general.cancel', language)"
        />
    </v-snackbar>
</template>

<script>

export default {
    name: 'UnsavedForm',
    props: {
        unsavedForm: {
            type: Boolean,
            default: false,
        },
        centered: {
            type: Boolean,
            default: true,
        },
        language: {
            type: String,
            default: 'de',
        },
        onSave: {
            type: Function,
            default: () => ({}),
        },
        onCancel: {
            type: Function,
            default: () => ({}),
        },
        errors: {
            type: Object,
            default: () => ({}),
        },
    },
    methods: {
        hasError() {
            return Object.keys(this.errors).length > 0;
        }
    },
};
</script>
