import DefaultLayout from '@/layouts/Default';
import SecurityChangePassword from '@/views/Security/ChangePassword';

export default {
    path: '/security/',
    component: DefaultLayout,
    children: [
        {
            path: 'change_password',
            name: 'change_password',
            component: SecurityChangePassword,
            meta: {
                auth: true,
            },
        },
    ],
};
