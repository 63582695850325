import DefaultLayout from '@/layouts/Default';
import CourseIndex from '@/views/Course/Index';
import CourseDetail from '@/views/Course/Detail';
import CourseNew from '@/views/Course/New';

export default {
    path: '/course',
    component: DefaultLayout,
    children: [
        {
            path: '',
            name: 'course',
            component: CourseIndex,
            meta: {
                auth: true,
            },
        },
        {
            path: 'new',
            name: 'course_new',
            component: CourseNew,
            meta: {
                auth: true,
            },
        },
        {
            path: ':id',
            name: 'course_detail',
            component: CourseDetail,
            meta: {
                auth: true,
            },
        },
        {
            path: ':id/course-calendar',
            name: 'course_calendar',
            component: CourseDetail,
            meta: {
                auth: true,
            },
        },
    ],
};
