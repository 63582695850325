<template>
    <v-row>
        <v-col cols="12">
            <v-select
                v-model="data.salutation"
                :items="salutations"
                :label="$t('first_interview.salutation')"
                :error-messages="formError(formErrorsProp, 'salutation')"
                :hint="$t('general.required')"
                persistent-hint
            />
        </v-col>
        <v-col cols="12">
            <v-text-field
                type="text"
                v-model="data.firstname"
                :label="$t('first_interview.firstname')"
                :error-messages="formError(formErrorsProp, 'firstname')"
                :hint="$t('general.required')"
                persistent-hint
            />
        </v-col>
        <v-col cols="12">
            <v-text-field
                type="text"
                v-model="data.lastname"
                :label="$t('first_interview.lastname')"
                :error-messages="formError(formErrorsProp, 'lastname')"
                :hint="$t('general.required')"
                persistent-hint
            />
        </v-col>
        <v-col cols="12">
            <v-textarea
                type="text"
                v-model="data.address"
                :label="$t('first_interview.address')"
                :error-messages="formError(formErrorsProp, 'address')"
            />
        </v-col>
        <v-col md="6">
            <v-text-field
                type="text"
                v-model="data.zip"
                :label="$t('first_interview.zip')"
                :error-messages="formError(formErrorsProp, 'zip')"
                :hint="$t('general.required')"
                persistent-hint
            />
        </v-col>
        <v-col md="6">
            <v-text-field
                type="text"
                v-model="data.city"
                :label="$t('first_interview.city')"
                :error-messages="formError(formErrorsProp, 'city')"
                :hint="$t('general.required')"
                persistent-hint
            />
        </v-col>
        <v-col cols="12">
            <v-select
                v-model="data.canton.code"
                :items="cantons"
                :label="$t('first_interview.canton')"
                :error-messages="formError(formErrorsProp, 'canton')"
                clearable
                @click:clear="$nextTick(() => data.canton = null)"
                :hint="$t('general.required')"
                persistent-hint
            />
        </v-col>
        <v-col cols="12">
            <v-text-field
                type="text"
                v-model="data.phone"
                :label="$t('first_interview.phone')"
                :error-messages="formError(formErrorsProp, 'phone')"
                :hint="$t('general.required')"
                persistent-hint
            />
        </v-col>
        <v-col cols="12">
            <v-text-field
                type="email"
                v-model="data.email"
                :label="$t('first_interview.email')"
                :error-messages="formError(formErrorsProp, 'email')"
                :hint="$t('general.required')"
                persistent-hint
            />
        </v-col>
        <v-col cols="12">
            <BirthdatePicker
                v-model="data.birthdate"
                :label="$t('first_interview.birthdate')"
                :errors="formErrorsProp"
                error-key="birthdate"
                required
            />
        </v-col>
        <v-col cols="12">
            <v-textarea
                type="text"
                v-model="data.note"
                :label="$t('first_interview.note')"
                :error-messages="formError(formErrorsProp, 'note')"
            />
        </v-col>
    </v-row>
</template>

<script>

import BirthdatePicker from '@/components/common/BirthdatePicker';
import FormDataMixin from '@/mixins/form-data';

export default {
    name: 'SimpleForm',
    mixins: [
        FormDataMixin,
    ],
    components: {
        BirthdatePicker,
    },
    props: {
        data: {
            type: Object,
            default: () => ({}),
        },
        formErrorsProp: {
            type: Object,
            default: () => ({}),
        },
        cantons: {
            type: Array,
            default: () => ([]),
        },
        ravLocations: {
            type: Array,
            default: () => ([]),
        },
        insuranceFunds: {
            type: Array,
            default: () => ([]),
        },
        amms: {
            type: Array,
            default: () => ([]),
        },
        registrationTimeSlots: {
            type: Array,
            default: () => ([]),
        },
    },
};
</script>
