import { ErrorSnackbar, Snackbar } from '@/app-events.js';

export default {
    methods: {
        genericErrorSnackbar() {
            const snackbar = {
                enabled: true,
                color: 'error',
                text: `Technical Error. Contact an administrator`,
            };
            this.$eventBus.dispatch(new Snackbar(snackbar));
        },
        expectError(data, expectedType, displayError = true) {
            if (typeof data.errors === 'undefined') {
                if (displayError === true) {
                    this.displayError(data);
                }
                return false;
            }
            if (data.errors.type !== expectedType) {
                if (displayError === true) {
                    this.displayError(data);
                }
                return false;
            }
            return true;
        },
        displayError(data) {
            console.log(data);
            if (typeof data.errors === 'undefined') {
                data = {
                    errors: {
                        title: 'Technical Error',
                        detail: 'Contact an administrator',
                    },
                };
            }
            if (typeof data.errors.type !== 'undefined' && typeof data.errors.title !== 'undefined') {
                if (data.errors.type === 'translation') {
                    this.$eventBus.dispatch(new ErrorSnackbar(this.$t(data.errors.title)));
                    return;
                }
                this.$eventBus.dispatch(new ErrorSnackbar(data.errors.title));
                return;
            }
            this.$eventBus.dispatch(new ErrorSnackbar(`${data.errors.title}. ${data.errors.detail}`));
        }
    },
};
