import Vue from 'vue';
import App from './App.vue';
import LocalesMixin from './mixins/locales';
import FormErrorsMixin from './mixins/form-errors';
import ErrorsMixin from './mixins/errors';
import PreferencesMixin from './mixins/preferences';
import CopyMixin from './mixins/copy';
import router from './plugins/router';
import store from './store';
import i18n from './plugins/i18n';
import './plugins/axios';
import './plugins/ckeditor';
import './plugins/clipboard2';
import './plugins/event-bus';
import './plugins/vue-auth';
import './plugins/vue-localstorage';
import './plugins/vue-sessionstorage';
import vuetify from './plugins/vuetify';

Vue.config.productionTip = false;

Vue.use(require('vue-moment'));

// Register Global Mixin
Vue.mixin(LocalesMixin);
Vue.mixin(FormErrorsMixin);
Vue.mixin(ErrorsMixin);
Vue.mixin(PreferencesMixin);
Vue.mixin(CopyMixin);

new Vue({
    router,
    store,
    i18n,
    vuetify,
    render: (h) => h(App),
}).$mount('#app');
