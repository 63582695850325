import Proxy from './Proxy';

class CantonProxy extends Proxy {
    constructor(parameters = {}) {
        super('/canton', parameters);
    }

    all() {
        return this.submit('get', '/');
    }
}

export default CantonProxy;
