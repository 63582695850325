import Proxy from './Proxy';

class RavConsultantProxy extends Proxy {
    constructor(parameters = {}) {
        super('/rav-consultant', parameters);
    }

    all({ active = false, ravId = null } = {}) {
        if (active && ravId) {
            return this.submit('get', `/?rav=${ravId}&active=1`);    
        }
        if (ravId) {
            return this.submit('get', `/?rav=${ravId}`);    
        }
        if (active) {
            return this.submit('get', '/?active=1');    
        }
        return this.submit('get', '/');
    }

    deactivate(id) {
        return this.submit('post', `/${id}/deactivate`);
    }

    activate(id) {
        return this.submit('post', `/${id}/activate`);
    }
}

export default RavConsultantProxy;
