import DefaultLayout from '@/layouts/Default';
import CalendarIndex from '@/views/Calendar/Index';
import CalendarDetail from '@/views/Calendar/Detail';
import CalendarNew from '@/views/Calendar/New';

export default {
    path: '/kalender-:courseType',
    component: DefaultLayout,
    children: [
        {
            path: '',
            name: 'calendar',
            component: CalendarIndex,
            meta: {
                auth: true,
            },
        },
        {
            path: 'new/:weekDay?',
            name: 'calendar_new',
            component: CalendarNew,
            meta: {
                auth: true,
            },
        },
        {
            path: ':id',
            name: 'calendar_detail',
            component: CalendarDetail,
            meta: {
                auth: true,
            },
        },
    ],
};
