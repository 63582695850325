import DefaultLayout from '@/layouts/Default';
import RegistrationIndex from '@/views/Registrations/Index';
import RegistrationDetail from '@/views/Registrations/Detail';

export default {
    path: '/registrations',
    component: DefaultLayout,
    children: [
        {
            path: '',
            name: 'registrations',
            component: RegistrationIndex,
            meta: {
                auth: true,
            },
        },
        {
            path: 'closed',
            name: 'registrations_closed',
            component: RegistrationIndex,
            meta: {
                auth: true,
            },
        },
        {
            path: ':id/',
            name: 'registration_detail',
            component: RegistrationDetail,
            meta: {
                auth: true,
            },
        },
    ],
};
