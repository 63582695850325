const LOGIN = 'LOGIN';
const LOGOUT = 'LOGOUT';
const INITIALIZE = 'INITIALIZE';
const RESET = 'RESET';
const RESET_STATE = 'RESET_STATE';
const LOGIN_ERROR = 'LOGIN_ERROR';

export {
    LOGIN,
    LOGOUT,
    INITIALIZE,
    RESET,
    RESET_STATE,
    LOGIN_ERROR,
};
