<template>
    <v-row no-gutters>
        <v-col
            lg="12"
            md="12"
        >
            <v-progress-linear
                v-if="loading"
                indeterminate
                color="primary"
            />
            <v-row v-if="!loading">
                <v-col cols="12">
                    <div class="headline mb-3 mt-6 font-weight-black">
                        Self-Assessment:
                        <span v-if="courseStart">
                            Course start
                        </span>
                        <span v-if="courseEnd">
                            Course end
                        </span>
                    </div>
                    <v-card
                        flat
                        v-if="thankYou"
                        class="pa-5 mb-5"
                    >
                        <p class="text-h6 mb-0">
                            Thank you for your participation
                        </p>
                    </v-card>
                    <v-card flat class="mb-16">
                        <v-card-text>
                            <v-alert
                                dense
                                outlined
                                v-if="selectError"
                                type="error"
                            >
                                Please answer at least 10 questions
                            </v-alert>
                            <v-col cols="12 pa-0">
                                <table style="width: 100%">
                                    <thead>
                                        <tr>
                                            <th
                                                class="text-left"
                                            >
                                                Self-assessment of your competences
                                            </th>
                                        </tr>
                                        <tr>
                                            <td>
                                                <v-list-item three-line>
                                                    <v-list-item-content>
                                                        <v-list-item-subtitle>
                                                            Please rate your competences in the following areas. Scale:
                                                        </v-list-item-subtitle>
                                                        <v-list-item-subtitle>
                                                            0 : No competence (I can't do it)
                                                        </v-list-item-subtitle>
                                                        <v-list-item-subtitle>
                                                            1 : Low competence (I can barely do it)
                                                        </v-list-item-subtitle>
                                                        <v-list-item-subtitle>
                                                            2 : Moderate competence (I can do it somewhat)
                                                        </v-list-item-subtitle>
                                                        <v-list-item-subtitle>
                                                            3 : High competence (I can do it well)
                                                        </v-list-item-subtitle>
                                                        <v-list-item-subtitle>
                                                            4 : Very high competence (I can do it excellently)
                                                        </v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <template v-for="question in categories[1].learning_contract_questions">
                                            <tr
                                                v-if="!question.question.endsWith('#__end__') && question.id in answers && answers[ question.id ] !== undefined && answers[ question.id ] && 'start' in answers[ question.id ]"
                                                :key="`answer-${question.id}`"
                                            >
                                                <td class="pt-2">
                                                    <v-subheader class="pa-0">
                                                        {{ question.question_en }}
                                                    </v-subheader>
                                                    <v-slider
                                                        v-if="courseStart"
                                                        v-model="answers[ question.id ].start.answer"
                                                        :tick-labels="['0', '1', '2', '3', '4']"
                                                        :max="4"
                                                        color="grey"
                                                        label="Course start"
                                                        step="1"
                                                        ticks="always"
                                                        tick-size="4"
                                                        :readonly="courseEnd"
                                                        @click="answerStartEndQuestion(answers[ question.id ].start, 'start')"
                                                    />
                                                    <v-slider
                                                        v-if="courseEnd"
                                                        v-model="answers[ question.id ].end.answer"
                                                        :tick-labels="['0', '1', '2', '3', '4']"
                                                        :max="4"
                                                        label="Course end"
                                                        step="1"
                                                        ticks="always"
                                                        tick-size="4"
                                                        :readonly="courseStart"
                                                        @click="answerStartEndQuestion(answers[ question.id ].end, 'end')"
                                                    />
                                                </td>
                                            </tr>
                                            <tr
                                                v-if="!question.question.endsWith('#__end__') && question.id in answers && answers[ question.id ] !== undefined && answers[ question.id ] && 'start' in answers[ question.id ]"
                                                :key="`divider-${question.id}`"
                                            >
                                                <td>
                                                    <v-divider />
                                                </td>
                                            </tr>
                                        </template>
                                    </tbody>
                                </table>
                            </v-col>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
        <UnsavedForm
            :unsaved-form="unsavedForm"
            :centered="false"
            :language="'en'"
            :errors="formErrors"
            :on-save="saveFunction"
            :on-cancel="cancelObjectChanges"
        />
    </v-row>
</template>
<script>

import UnsavedForm from '@/components/common/UnsavedForm';
import LearningContractProxy from '@/proxies/LearningContractProxy';
import LearningContractMixin from '@/mixins/learningContract';
import FileDownloadMixin from '@/mixins/file-download';
import ParticipantProxy from '@/proxies/ParticipantProxy';
import { SuccessSnackbar, StandBy } from '@/app-events.js';

export default {
    name: 'LearningContract',
    components: {
        UnsavedForm,
    },
    mixins: [
        LearningContractMixin,
        FileDownloadMixin,
    ],
    props: {
        participant: {
            type: Object,
            default: () => {},
        },
        addProp: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            loading: false,
            unsavedForm: false,
            data: {},
            categories: [],
            answers: {},
            remarks: {},
            participantId: '',
            courseStart: false,
            courseEnd: false,
            thankYou: false,
            selectError: false,

        }
    },
    created() {
        this.fetchData();
    },
    methods: {
        fetchData() {
            this.loading = true;
            this.participantId = this.$route.params.token;
            if (this.$route.params.status == 'kursstart') {
                this.courseStart = true;
            }

            if (this.$route.params.status == 'kursende') {
                this.courseEnd = true;
            }
            new LearningContractProxy().get(this.participantId).then(({ data }) => {
                this.data = JSON.parse(JSON.stringify(data.data));
                this.rawData = JSON.parse(JSON.stringify(data.data));
                new LearningContractProxy().getQuestions().then(({ data }) => {
                    this.categories = data.data.categories;
                    this.loading = false;
                    this.prepareAnswers();
                }).catch(({ data }) => {
                    this.displayError(data);
                });
            }).catch(({ data }) => {
                this.displayError(data);
            });
        },
        prepareAnswers()
        {
            for (let i = 0; i < this.categories.length; i++) {
                const category = this.categories[ i ];
                for (let j = 0; j < category.learning_contract_questions.length; j++) {
                    const question = category.learning_contract_questions[ j ];
                    const answer = this.getAnswerToQuestion(question.id);
                    const endAnswer = this.getEndAnswerToQuestion(question.question);
                    if (endAnswer) {
                        this.answers[ question.id ] = {
                            start: answer,
                            end: endAnswer,
                        };
                    } else {
                        this.answers[ question.id ] = answer.answer;
                    }
                }
            }
        },
        saveFunction() {
            this.selectError = false;
            this.$eventBus.dispatch(new StandBy(true));
            if (this.data.id) {
                new LearningContractProxy().update(this.participantId,false, this.$route.params.status, this.prepareFormData(this.data)).then(({ data }) => {
                    this.$eventBus.dispatch(new StandBy(false));
                    this.fetchData();
                    this.$eventBus.dispatch(new SuccessSnackbar(this.$t('note.actions.saved')));
                    this.unsavedForm = false;
                    this.resetErrors();
                    this.thankYou = true;
                    this.resetObject();
                }).catch(({ data }) => {
                    if (data.data.errors[ 0 ] === 'MINIMUM_AMOUNT_OF_ANSWERS_ABOVE_ZERO') {
                        this.selectError = true;
                    }
                    this.processErrors(data);
                    this.$eventBus.dispatch(new StandBy(false));
                });
            } else {
                new LearningContractProxy().new(this.prepareFormData(this.data)).then(({ data }) => {
                    this.$eventBus.dispatch(new StandBy(false));
                    this.fetchData();

                    this.$eventBus.dispatch(new SuccessSnackbar(this.$t('note.actions.saved')));
                    this.unsavedForm = false;
                    this.resetErrors();
                }).catch(({ data }) => {
                    if (data.data.errors[ 0 ] === 'MINIMUM_AMOUNT_OF_ANSWERS_ABOVE_ZERO') {
                        this.selectError = true;
                    }
                    this.processErrors(data);
                    this.$eventBus.dispatch(new StandBy(false));
                });
            }
        },
        cancelObjectChanges() {
            this.resetObject();
        },
        answerQuestion(questionId, answer) {
            this.answers[ questionId ] = answer.id;
            for (let i = 0; i < this.data.learning_contract_participant_answers.length; i++) {
                const participantAnswer = this.data.learning_contract_participant_answers[ i ];
                if (participantAnswer.learning_contract_question.id === questionId) {
                    participantAnswer.answer = answer.answer.toString();
                    break;
                }
            }
        },
        answerStartEndQuestion(answer, which) {
            const questionId = answer.learning_contract_question.id;
            for (let i = 0; i < this.data.learning_contract_participant_answers.length; i++) {
                const participantAnswer = this.data.learning_contract_participant_answers[ i ];
                if (participantAnswer.learning_contract_question.id === questionId) {
                    participantAnswer.answer = answer.answer.toString();
                    break;
                }
            }
        },
        getAnswerToQuestion(questionId) {
            for (let i = 0; i < this.data.learning_contract_participant_answers.length; i++) {
                const participantAnswer = this.data.learning_contract_participant_answers[ i ];
                if (participantAnswer.learning_contract_question.id === questionId) {
                    return this.data.learning_contract_participant_answers[ i ];
                }
            }
            return null;
        },
        getEndAnswerToQuestion(questionName) {
            const endQuestion = `${questionName}#__end__`;
            for (let i = 0; i < this.data.learning_contract_participant_answers.length; i++) {
                const participantAnswer = this.data.learning_contract_participant_answers[ i ];
                if (participantAnswer.learning_contract_question.question === endQuestion) {
                    return this.data.learning_contract_participant_answers[ i ];
                }
            }
            return null;
        },
        printPdf() {
            this.$eventBus.dispatch(new StandBy(true));
            new LearningContractProxy().print(this.participant.id).then(({ data }) => {
                this.openPdf(data);
            }).catch(({ data }) => {
            }).finally(() => {
                this.$eventBus.dispatch(new StandBy(false));
            });
        },
        remarkToQuestion(questionId) {
            for (let i = 0; i < this.data.learning_contract_participant_answers.length; i++) {
                const participantAnswer = this.data.learning_contract_participant_answers[ i ];
                if (participantAnswer.learning_contract_question.id === questionId) {
                    return this.data.learning_contract_participant_answers[ i ].remark;
                }
            }
            return null;
        },
        answerRemarkToQuestion(questionId, event) {
            for (let i = 0; i < this.data.learning_contract_participant_answers.length; i++) {
                const participantAnswer = this.data.learning_contract_participant_answers[ i ];
                if (participantAnswer.learning_contract_question.id === questionId) {
                    this.data.learning_contract_participant_answers[ i ].remark = event;
                    break;
                }
            }
        }
    },
    watch: {
        data: {
            handler(_, oldValue) {
                this.unsavedForm = false;
                const participantAnswerText = this.data;
                const originalAnswersText = this.rawData;
                if ((participantAnswerText.position !== originalAnswersText.position ) || (participantAnswerText.request !== originalAnswersText.request ) || (participantAnswerText.goal !== originalAnswersText.goal )) {
                    this.unsavedForm = true;
                    return;
                }
                for (let i = 0; i < this.data.learning_contract_participant_answers.length; i++) {
                    const participantAnswer = this.data.learning_contract_participant_answers[ i ];
                    const originalAnswer = this.rawData.learning_contract_participant_answers[ i ];
                    if (null === participantAnswer.answer) {
                        this.unsavedForm = false;
                    }else {
                        participantAnswer.answer = participantAnswer.answer.toString();
                        if ((participantAnswer.answer !== originalAnswer.answer) || (participantAnswer.remark !== originalAnswer.remark )) {
                            this.unsavedForm = true;
                            break;
                        } else {
                            this.unsavedForm = false;
                        }
                    }

                }
            },
            deep: true
        },
        rawData: {
            handler(_, oldValue) {
                this.unsavedForm = false;
                const participantAnswerText = this.data;
                const originalAnswersText = this.rawData;
                if ((participantAnswerText.position !== originalAnswersText.position ) || (participantAnswerText.request !== originalAnswersText.request ) || (participantAnswerText.goal !== originalAnswersText.goal )) {
                    this.unsavedForm = true;
                    return;
                }
                for (let i = 0; i < this.data.learning_contract_participant_answers.length; i++) {
                    const participantAnswer = this.data.learning_contract_participant_answers[ i ];
                    const originalAnswer = this.rawData.learning_contract_participant_answers[ i ];
                    participantAnswer.answer = participantAnswer.answer.toString();
                    if ((participantAnswer.answer !== originalAnswer.answer) || (participantAnswer.remark !== originalAnswer.remark )) {
                        this.unsavedForm = true;
                        break;
                    } else {
                        this.unsavedForm = false;
                    }
                }
            },
            deep: true
        },
        addProp(val) {
            this.printPdf();
        },
    }
};
</script>
