<template>
    <div>
        <v-breadcrumbs
            class="d-md-flex"
            v-if="breadcrumbs.length > 0"
            :items="breadcrumbs"
        >
            <template v-slot:divider>
                <v-icon>mdi-chevron-right</v-icon>
            </template>
        </v-breadcrumbs>

        <v-row>
            <v-col
                :md="search ? '5' : '9'"
                cols="12"
            >
                <div class="display-1 mb-md-4 mt-md-4">
                    <v-btn
                        v-if="back"
                        icon
                        @click="routerBack()"
                    >
                        <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                    {{ title }}
                </div>
            </v-col>
            <v-col
                v-if="search"
                md="4"
                cols="12"
                class="mt-md-4"
            >
                <SearchInput
                    v-model="searchText"
                    :label="searchLabel"
                    input-class="mb-n9"
                    event-trigger="keyup"
                />
            </v-col>
            <v-col
                md="3"
                cols="12"
                :class="`justify-end mt-4${hideButtonOnMobile ? ' d-none d-md-flex' : ''}`"
            >
                <v-btn
                    v-if="button"
                    color="secondary--dark"
                    dark
                    rounded
                    depressed
                    class="text-none"
                    :to="buttonPath()"
                    @click="callback()"
                >
                    <v-icon class="ml-lg-n2 mr-lg-2">
                        mdi-{{ buttonIcon }}
                    </v-icon>
                    <span class="d-md-none d-lg-flex">
                        {{ buttonText }}
                    </span>
                </v-btn>
            </v-col>
            <v-col
                cols="1"
                v-if="false"
            >
                <v-btn
                    @click="showAdvancedSearched = !showAdvancedSearched"
                    text
                >
                    <v-icon v-if="!showAdvancedSearched">
                        mdi-chevron-down
                    </v-icon>
                    <v-icon v-else>
                        mdi-chevron-up
                    </v-icon>
                    Advanced Search
                </v-btn>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import SearchInput from '@/components/form/SearchInput';

export default {
    name: 'PageHeader',
    components: {
        SearchInput,
    },
    props: {
        breadcrumbs: {
            type: Array,
            default: () => ([]),
        },
        title: {
            type: String,
            default: '',
        },
        gutters: {
            type: Boolean,
            default: true,
        },
        search: {
            type: Boolean,
            default: false,
        },
        hideButtonOnMobile: {
            type: Boolean,
            default: true,
        },
        searchLabel: {
            type: String,
            default: '',
        },
        button: {
            type: Boolean,
            default: false,
        },
        buttonText: {
            type: String,
            default: '',
        },
        buttonIcon: {
            type: String,
            default: 'plus',
        },
        buttonTo: {
            type: String,
            default: '',
        },
        buttonRoute: {
            type: Object,
            default: () => ({}),
        },
        buttonCallback: {
            type: Function,
            default: () => {}
        },
        back: {
            type: Boolean,
            default: false,
        },
        backTo: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            searchText: '',
        };
    },
    methods: {
        routerBack() {
            if (this.backTo !== '') {
                this.$router.push({ name: this.backTo });
            } else {
                this.$router.go(-1);
            }
        },
        buttonPath() {
            if (this.buttonTo !== '') {
                return { name: this.buttonTo }
            }

            if (Object.keys(this.buttonRoute).length === 0) {
                return {};
            }

            return this.buttonRoute;
        },
        callback() {
            this.buttonCallback();
        }
    },
    watch: {
        searchText(val) {
            this.$emit('search-input', val);
        },
    },
};
</script>
