<template>
    <v-card flat>
        <v-card-text>
            <v-row>
                <v-col cols="12">
                    <v-text-field
                        type="text"
                        v-model="user.email"
                        :label="$t('user.email')"
                        required
                        :error-messages="formError(errors, 'email')"
                        :hint="$t('general.required')"
                        persistent-hint
                    />
                </v-col>
                <v-col cols="12">
                    <v-select
                        :items="roles"
                        v-model="user.roles"
                        :label="$t('user.roles')"
                        required
                        multiple
                        chips
                        :error-messages="formError(errors, 'roles')"
                        :hint="$t('general.required')"
                        persistent-hint
                    />
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>

export default {
    name: 'UserInformation',
    props: {
        user: {
            type: Object,
            default: () => ({
                email: '',
                roles: [],
            }),
        },
        errors: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            roles: [
                {
                    value: 'ROLE_COACH',
                    text: this.$t('user.role.coach'),
                },
                {
                    value: 'ROLE_ADMIN',
                    text: this.$t('user.role.admin'),
                },
                // {
                //     value: 'ROLE_PARTICIPANT',
                //     text: this.$t('user.role.participant'),
                // },
            ]
        }
    },
};
</script>
