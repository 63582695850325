export default {
    methods: {
        resetObject() {
            this.data = JSON.parse(JSON.stringify(this.rawData));
            this.resetErrors();
        },
        prepareData(data) {
            return data;
        },
        prepareFormData(data) {
            const learningContractParticipantAnswers = [];

            for (let i = 0; i < data.learning_contract_participant_answers.length; i++) {
                const participantAnswer = data.learning_contract_participant_answers[ i ];
                learningContractParticipantAnswers.push({
                    answer: participantAnswer.answer,
                    remark: participantAnswer.remark,
                    learningContractQuestion: participantAnswer.learning_contract_question.id
                });
            }

            return {
                learning_contract: {
                    position: data.position,
                    request: data.request,
                    goal: data.goal,
                    finalTalk: data.final_talk,
                    learningContractParticipantAnswers,
                },
            };
        },
    },
};
