<template>
    <div>
        <v-row>
            <v-col cols="12">
                <v-progress-linear
                    v-if="loading"
                    indeterminate
                    color="primary"
                />
                <v-skeleton-loader
                    v-if="loading"
                    class="mx-auto"
                    type="table-tbody"
                />
                <RavConsultantList
                    v-if="!loading"
                    :rav-consultants="ravConsultants"
                    :rav-id="ravId"
                />
            </v-col>
        </v-row>
    </div>
</template>

<script>

import RavConsultantList from '@/components/ravConsultants/RavConsultantList';
import RavConsultantProxy from '@/proxies/RavConsultantProxy';
import { ObjectDeleted, SuccessSnackbar } from '@/app-events.js';

export default {
    name: 'RavConsultants',
    components: {
        RavConsultantList
    },

    props: {
        ravId: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            ravConsultants: [],
            loading: true,
        }
    },
    created() {
        this.fetchData();
        this.$eventBus.listen(ObjectDeleted, (event) => {
            this.$eventBus.dispatch(new SuccessSnackbar(this.$t('rav_consultant.actions.deleted')));
            for (let i = 0; i < this.ravConsultants.length; i++) {
                const rav = this.ravConsultants[ i ];
                if (rav.id === event.object.id) {
                    this.ravConsultants.splice(i, 1);
                    break;
                }
            }
        });
    },
    methods: {
        fetchData() {
            new RavConsultantProxy().all({
                ravId: this.ravId,
            }).then(({ data }) => {
                this.ravConsultants = data.data;
                this.loading = false;
            }).catch(({ data }) => {
                this.displayError(data);
            });
        },
    },
};
</script>
