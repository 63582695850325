<template>
    <div>
        <v-row>
            <v-col cols="12">
                <v-progress-linear
                    v-if="loading"
                    indeterminate
                    color="primary"
                />
                <v-skeleton-loader
                    v-if="loading"
                    class="mx-auto"
                    type="table-tbody"
                />
                <PresenceListList
                    v-if="!loading"
                    :objects="objects"
                    :participant-id="participant.id"
                />
            </v-col>
        </v-row>
    </div>
</template>

<script>

import PresenceListList from '@/components/presenceList/PresenceListList';
import PresenceListProxy from '@/proxies/PresenceListProxy';
import { SuccessSnackbar } from '@/app-events.js';

export default {
    name: 'PresenceList',
    components: {
        PresenceListList
    },

    props: {
        participant: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            objects: [],
            loading: true,
        }
    },
    created() {
        this.fetchData();
    },
    methods: {
        fetchData() {
            new PresenceListProxy().all({
                participantId: this.participant.id,
            }).then(({ data }) => {
                this.objects = data.data;
                this.loading = false;
            }).catch(({ data }) => {
                this.displayError(data);
            });
        },
    },
};
</script>
