var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._l((_vm.computedObjects),function(week,weekKey){return _c('v-row',{key:weekKey,staticClass:"mb-6",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"10","offset-lg":"1"}},[_vm._v(" "+_vm._s(week.default ? 'STANDARD WOCHE' : ("Woche von " + (_vm.formatDate(_vm.formatDateISO(week.monday))) + " - " + (_vm.formatDate(_vm.formatDateISO(week.friday)))))+" ")]),_vm._l((week.days),function(day,dayKey){return _c('v-col',{key:dayKey,staticClass:"d-flex",attrs:{"lg":"2","cols":"12","offset-lg":dayKey === '1' ? 1 : 0}},[_c('v-card',{staticClass:"mx-auto flex-grow-1 pb-3",attrs:{"outlined":""}},[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('v-row',{staticClass:"mx-0",attrs:{"align":"center","justify":"space-between"}},[_c('span',{staticClass:"text-body-1 font-weight-bold",domProps:{"textContent":_vm._s(("" + (_vm.$t(("general.day_of_week." + dayKey)))))}}),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"to":{ name: 'registration_time_slot_new', params: { date: ((week.default ? 1 : 0) + "." + (_vm.formatDateISO(week.monday)) + "." + dayKey) }},"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s(_vm.$t('page.registration_time_slot.new'))}})])],1),_c('hr',{staticClass:"my-3"}),_vm._l((day),function(entry,entryKey){return _c('div',{key:entryKey},[_c('v-list-item-subtitle',{staticClass:"mt-3"},[_vm._v(" "+_vm._s(entry.start.split(' ')[1])+" "),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"float-right ml-1",attrs:{"small":"","color":"grey"},on:{"click":function($event){return _vm.initiateDeletion(entry)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s(_vm.$t('general.delete'))}})]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('router-link',{staticClass:"registration-time-slot-link float-right",attrs:{"to":{ name: 'registration_time_slot_detail', params: { id: entry.id }}}},[_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","color":"grey"}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s(_vm.$t('general.edit'))}})])],1),_c('v-list-item-subtitle',{staticClass:"font-weight-black"},[_c('router-link',{staticClass:"registration-time-slot-link",attrs:{"to":{ name: 'registration_time_slot_detail', params: { id: entry.id }}}},[_c('p',{style:(entry.used ? 'text-decoration: line-through;' : '')},[_vm._v(" "+_vm._s(entry.course)+" ")])])],1),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(entry.professor)}})],1)})],2)],1)],1)],1)})],2)}),_c('ConfirmationDialog',{attrs:{"confirm":_vm.deleteObject,"cancel":function () { return (_vm.deleteDialog = false); },"loading":_vm.deleting,"dialog":_vm.deleteDialog}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }