import Vue from 'vue';

class EventBus {
    constructor() {
        this.$eventBus = new Vue();
    }
      
    listen (eventClass, handler) {
        this.$eventBus.$on(eventClass.id, handler);
    }

    listenOnce (eventClass, handler) {
        this.$eventBus.$once(eventClass.id, handler);
    }

    remove (eventClass, handler) {
        if (handler) {
            this.$eventBus.$off(eventClass.id, handler);
        } else {
            this.$eventBus.$off(eventClass.id);
        }
    }

    removeAll () {
        this.$eventBus.$off();
    }

    dispatch (event) {
        this.$eventBus.$emit(event.constructor.id, event);
    }
}

const eventBus = {
    install (Vue, options) {
        Vue.prototype.$eventBus = new EventBus();
    }
}

Vue.use(eventBus);