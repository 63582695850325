import { mapGetters } from 'vuex';
import { preferences } from '@/store/modules/preferences';

export default {
    data() {
        return {
            salutations: [
                {
                    value: 'MRS',
                    text: 'Frau',
                },
                {
                    value: 'MR',
                    text: 'Herr',
                },
                {
                    value: 'T',
                    text: 'Neutrale Anrede',
                },
            ],
            statuses: [
                {
                    value: 'RE',
                    text: 'Anmeldung',
                },
                {
                    value: 'FI',
                    text: 'Erstgespräch',
                },
                {
                    value: 'NO',
                    text: 'Kein Interesse',
                },
                {
                    value: 'IC',
                    text: 'In Abklärung',
                },
                {
                    value: 'CB',
                    text: 'Kurs gebucht',
                },
                {
                    value: 'ER',
                    text: 'Vom Kurs zurückgetreten',
                },
                {
                    value: 'A',
                    text: 'Aktuelle TN',
                },
                {
                    value: 'WL',
                    text: 'Warteliste',
                },
                {
                    value: 'CCP',
                    text: 'Kurs abgeschlossen',
                },
                {
                    value: 'CCC',
                    text: 'Kurs abgebrochen',
                },
                {
                    value: 'ARC',
                    text: 'Archiv',
                },
            ],
            presences: [
                {
                    value: 'X',
                    text: 'X Anwesend',
                },
                {
                    value: 'E',
                    text: 'E entschuldigte Absenz',
                },
                {
                    value: 'FT',
                    text: 'FT Feiertag',
                },
                {
                    value: 'HO',
                    text: 'HO Home Office',
                },
                {
                    value: 'G',
                    text: 'G Kontrollfreie Tage / Ferien',
                },
                {
                    value: 'A',
                    text: 'A Krankheit/Mutterschaft',
                },
                {
                    value: 'C',
                    text: 'C Militär/Zivilschutz',
                },
                {
                    value: 'H',
                    text: 'H Nach 9.00 Uhr erschienen',
                },
                {
                    value: 'T',
                    text: 'T Teilpensum',
                },
                {
                    value: 'uF',
                    text: 'uF unbezahlte Ferien',
                },
                {
                    value: 'F',
                    text: 'F unentschuldigte Absenz',
                },
                {
                    value: 'B',
                    text: 'B Unfall',
                },
                {
                    value: 'D',
                    text: 'D Zwischenverdienst',
                },
            ],
            exitReasons: [
                {
                    value: 'PF',
                    text: 'Projekt beendet',
                },
                {
                    value: 'PLE',
                    text: 'Projekt vorzeitig verlassen',
                },
            ],
            germanKnowledges: [
                {
                    value: 'L',
                    text: 'Geringe Deutschkenntnisse',
                },
                {
                    value: 'G',
                    text: 'Gute Deutschkenntnisse',
                },
                {
                    value: 'MT',
                    text: 'Deutsch ist Muttersprache',
                },
            ],
            exitStatuses: [
                {
                    value: 'O',
                    text: 'Andere Lösung',
                },
                {
                    value: 'SP',
                    text: 'Stellenantritt',
                },
                {
                    value: 'U',
                    text: 'Arbeitslos',
                },
                {
                    value: 'NF',
                    text: 'keine Rückmeldung',
                },
                {
                    value: 'SE',
                    text: 'Selbständigkeit',
                },
                {
                    value: 'FT',
                    text: 'Antritt einer Weiterbildung',
                },
            ],
            groups: [
                {
                    value: 'B',
                    text: 'Blau',
                },
                {
                    value: 'R',
                    text: 'Rot',
                },
            ],
            professionalAwards: [
                {
                    value: 'PC',
                    text: 'Fachausweis',
                },
                {
                    value: 'B',
                    text: 'Bachelor',
                },
                {
                    value: 'M',
                    text: 'Master',
                },
                {
                    value: 'P',
                    text: 'PhD',
                },
                {
                    value: 'MBA',
                    text: 'MBA',
                },
                {
                    value: 'DP',
                    text: 'Diplom/Zertifikat',
                },
                {
                    value: 'CAS',
                    text: 'CAS',
                },
                {
                    value: 'IS',
                    text: 'Branchenspezifische Auszeichnung',
                },
            ],
            jobFunctions: [
                {
                    value: 'E',
                    text: 'Mitarbeiter',
                },
                {
                    value: 'CD',
                    text: 'Kader (tief)',
                },
                {
                    value: 'CM',
                    text: 'Kader (mittel)',
                },
                {
                    value: 'CH',
                    text: 'Kader (hoch)',
                },
                {
                    value: 'D',
                    text: 'Geschäftsleiter',
                },
                {
                    value: 'C',
                    text: 'CEO/Inhaber',
                },
            ],
            jobCategoriesExtended: [
                {
                    value: 'M',
                    text: 'Marketing/Kommunikation/Werbung',
                },
                {
                    value: 'D',
                    text: 'IT, Grafik/Design',
                },
                {
                    value: 'TD',
                    text: 'Textildesign',
                },
                {
                    value: 'I',
                    text: 'Illustration',
                },
                {
                    value: 'W',
                    text: 'Webdesign',
                },
                {
                    value: 'SA',
                    text: 'Bildung und Sozialwesen',
                },
                {
                    value: 'B',
                    text: 'Bank, Finanz- und Versicherungswesen',
                },
                {
                    value: 'AI',
                    text: 'Autoindustrie',
                },
                {
                    value: 'T',
                    text: 'Technische Berufe',
                },
                {
                    value: 'AD',
                    text: 'Administration',
                },
                {
                    value: 'C',
                    text: 'Handwerk&Dienstleistungen',
                },
                {
                    value: 'AR',
                    text: 'Architektur, Vertrieb und Verkauf',
                },
                {
                    value: 'J',
                    text: 'Journalismus',
                },
                {
                    value: 'H',
                    text: 'Hotellerie und Gastronomie',
                },
                {
                    value: 'S',
                    text: 'Naturwissenschaft und Forschung',
                },
                {
                    value: 'O',
                    text: 'Andere',
                },
            ],
            jobCategories: [
                {
                    value: 'U',
                    text: 'Ohne Ausbildung',
                },
                {
                    value: 'T',
                    text: 'Anlehre',
                },
                {
                    value: 'CA',
                    text: 'Berufslehre',
                },
                {
                    value: 'AD',
                    text: 'Studium (FH)',
                },
                {
                    value: 'UN',
                    text: 'Studium (Uni)',
                },
                {
                    value: 'OE',
                    text: 'Sonstiges',
                },
            ],
            civilStatuses: [
                {
                    value: 'S',
                    text: 'Ledig',
                },
                {
                    value: 'M',
                    text: 'Verheiratet',
                },
                {
                    value: 'D',
                    text: 'Geschieden',
                },
                {
                    value: 'W',
                    text: 'Verwitwet',
                },
            ],
            nationalities: [
                {
                    value: 'AF',
                    text: 'Afghanistan',
                },
                {
                    value: 'EG',
                    text: 'Ägypten',
                },
                {
                    value: 'AX',
                    text: 'Aland Inseln',
                },
                {
                    value: 'AL',
                    text: 'Albanien',
                },
                {
                    value: 'DZ',
                    text: 'Algerien',
                },
                {
                    value: 'UM',
                    text: 'Amerikanisch-Ozeanien',
                },
                {
                    value: 'AS',
                    text: 'Amerikanisch-Samoa',
                },
                {
                    value: 'VI',
                    text: 'Amerikanische Jungferninseln',
                },
                {
                    value: 'AD',
                    text: 'Andorra',
                },
                {
                    value: 'AO',
                    text: 'Angola',
                },
                {
                    value: 'AI',
                    text: 'Anguilla',
                },
                {
                    value: 'AQ',
                    text: 'Antarktis',
                },
                {
                    value: 'AG',
                    text: 'Antigua und Barbuda',
                },
                {
                    value: 'GQ',
                    text: 'Äquatorialguinea',
                },
                {
                    value: 'AR',
                    text: 'Argentinien',
                },
                {
                    value: 'AM',
                    text: 'Armenien',
                },
                {
                    value: 'AW',
                    text: 'Aruba',
                },
                {
                    value: 'AZ',
                    text: 'Aserbaidschan',
                },
                {
                    value: 'ET',
                    text: 'Äthiopien',
                },
                {
                    value: 'AU',
                    text: 'Australien',
                },
                {
                    value: 'BS',
                    text: 'Bahamas',
                },
                {
                    value: 'BH',
                    text: 'Bahrain',
                },
                {
                    value: 'BD',
                    text: 'Bangladesch',
                },
                {
                    value: 'BB',
                    text: 'Barbados',
                },
                {
                    value: 'BY',
                    text: 'Belarus (Weißrussland)',
                },
                {
                    value: 'BE',
                    text: 'Belgien',
                },
                {
                    value: 'BZ',
                    text: 'Belize',
                },
                {
                    value: 'BJ',
                    text: 'Benin',
                },
                {
                    value: 'BM',
                    text: 'Bermuda',
                },
                {
                    value: 'BT',
                    text: 'Bhutan',
                },
                {
                    value: 'BO',
                    text: 'Bolivien',
                },
                {
                    value: 'BA',
                    text: 'Bosnien und Herzegowina',
                },
                {
                    value: 'BW',
                    text: 'Botswana',
                },
                {
                    value: 'BV',
                    text: 'Bouvetinsel',
                },
                {
                    value: 'BR',
                    text: 'Brasilien',
                },
                {
                    value: 'VG',
                    text: 'Britische Jungferninseln',
                },
                {
                    value: 'IO',
                    text: 'Britisches Territorium im Indischen Ozean',
                },
                {
                    value: 'BN',
                    text: 'Brunei Darussalam',
                },
                {
                    value: 'BG',
                    text: 'Bulgarien',
                },
                {
                    value: 'BF',
                    text: 'Burkina Faso',
                },
                {
                    value: 'BI',
                    text: 'Burundi',
                },
                {
                    value: 'CL',
                    text: 'Chile',
                },
                {
                    value: 'CN',
                    text: 'China, Volksrepublik',
                },
                {
                    value: 'CK',
                    text: 'Cookinseln',
                },
                {
                    value: 'CR',
                    text: 'Costa Rica',
                },
                {
                    value: 'DK',
                    text: 'Dänemark',
                },
                {
                    value: 'DE',
                    text: 'Deutschland',
                },
                {
                    value: 'DM',
                    text: 'Dominica',
                },
                {
                    value: 'DO',
                    text: 'Dominikanische Republik',
                },
                {
                    value: 'DJ',
                    text: 'Dschibuti',
                },
                {
                    value: 'EC',
                    text: 'Ecuador',
                },
                {
                    value: 'SV',
                    text: 'El Salvador',
                },
                {
                    value: 'CI',
                    text: 'Elfenbeinküste',
                },
                {
                    value: 'ER',
                    text: 'Eritrea',
                },
                {
                    value: 'EE',
                    text: 'Estland (Reval)',
                },
                {
                    value: 'FK',
                    text: 'Falklandinseln (Malwinen)',
                },
                {
                    value: 'FO',
                    text: 'Faröer Inseln',
                },
                {
                    value: 'FJ',
                    text: 'Fidschi',
                },
                {
                    value: 'FI',
                    text: 'Finnland',
                },
                {
                    value: 'FR',
                    text: 'Frankreich',
                },
                {
                    value: 'GF',
                    text: 'Französisch-Guayana',
                },
                {
                    value: 'PF',
                    text: 'Französisch-Polynesien',
                },
                {
                    value: 'TF',
                    text: 'Französische Süd- und Antarktisgebiete',
                },
                {
                    value: 'GA',
                    text: 'Gabun',
                },
                {
                    value: 'GM',
                    text: 'Gambia',
                },
                {
                    value: 'GE',
                    text: 'Georgien',
                },
                {
                    value: 'GH',
                    text: 'Ghana',
                },
                {
                    value: 'GI',
                    text: 'Gibraltar',
                },
                {
                    value: 'GD',
                    text: 'Grenada',
                },
                {
                    value: 'GR',
                    text: 'Griechenland',
                },
                {
                    value: 'GL',
                    text: 'Grönland',
                },
                {
                    value: 'GB',
                    text: 'Großbritannien und Nordirland',
                },
                {
                    value: 'GP',
                    text: 'Guadeloupe',
                },
                {
                    value: 'GU',
                    text: 'Guam',
                },
                {
                    value: 'GT',
                    text: 'Guatemala',
                },
                {
                    value: 'GG',
                    text: 'Guernsey (Kanalinsel)',
                },
                {
                    value: 'GN',
                    text: 'Guinea',
                },
                {
                    value: 'GW',
                    text: 'Guinea-Bissau',
                },
                {
                    value: 'GY',
                    text: 'Guyana',
                },
                {
                    value: 'HT',
                    text: 'Haiti',
                },
                {
                    value: 'HM',
                    text: 'Heard- und McDonald-Inseln',
                },
                {
                    value: 'HN',
                    text: 'Honduras',
                },
                {
                    value: 'HK',
                    text: 'Hongkong',
                },
                {
                    value: 'IN',
                    text: 'Indien',
                },
                {
                    value: 'ID',
                    text: 'Indonesien',
                },
                {
                    value: 'IM',
                    text: 'Insel Man',
                },
                {
                    value: 'IQ',
                    text: 'Irak',
                },
                {
                    value: 'IR',
                    text: 'Iran',
                },
                {
                    value: 'IE',
                    text: 'Irland',
                },
                {
                    value: 'IS',
                    text: 'Island',
                },
                {
                    value: 'IL',
                    text: 'Israel',
                },
                {
                    value: 'IT',
                    text: 'Italien',
                },
                {
                    value: 'JM',
                    text: 'Jamaika',
                },
                {
                    value: 'JP',
                    text: 'Japan',
                },
                {
                    value: 'YE',
                    text: 'Jemen',
                },
                {
                    value: 'JE',
                    text: 'Jersey (Kanalinsel)',
                },
                {
                    value: 'JO',
                    text: 'Jordanien',
                },
                {
                    value: 'KY',
                    text: 'Kaimaninseln',
                },
                {
                    value: 'KH',
                    text: 'Kambodscha',
                },
                {
                    value: 'CM',
                    text: 'Kamerun',
                },
                {
                    value: 'CA',
                    text: 'Kanada',
                },
                {
                    value: 'CV',
                    text: 'Kap Verde',
                },
                {
                    value: 'KZ',
                    text: 'Kasachstan',
                },
                {
                    value: 'QA',
                    text: 'Katar',
                },
                {
                    value: 'KE',
                    text: 'Kenia',
                },
                {
                    value: 'KG',
                    text: 'Kirgisistan',
                },
                {
                    value: 'KI',
                    text: 'Kiribati',
                },
                {
                    value: 'CC',
                    text: 'Kokosinseln (Keelinginseln)',
                },
                {
                    value: 'CO',
                    text: 'Kolumbien',
                },
                {
                    value: 'KM',
                    text: 'Komoren',
                },
                {
                    value: 'CD',
                    text: 'Kongo',
                },
                {
                    value: 'XZ',
                    text: 'Kosovo',
                },
                {
                    value: 'HR',
                    text: 'Kroatien',
                },
                {
                    value: 'CU',
                    text: 'Kuba',
                },
                {
                    value: 'KW',
                    text: 'Kuwait',
                },
                {
                    value: 'LA',
                    text: 'Laos',
                },
                {
                    value: 'LS',
                    text: 'Lesotho',
                },
                {
                    value: 'LV',
                    text: 'Lettland',
                },
                {
                    value: 'LB',
                    text: 'Libanon',
                },
                {
                    value: 'LR',
                    text: 'Liberia',
                },
                {
                    value: 'LY',
                    text: 'Libyen',
                },
                {
                    value: 'LI',
                    text: 'Liechtenstein',
                },
                {
                    value: 'LT',
                    text: 'Litauen',
                },
                {
                    value: 'LU',
                    text: 'Luxemburg',
                },
                {
                    value: 'MO',
                    text: 'Macao',
                },
                {
                    value: 'MG',
                    text: 'Madagaskar',
                },
                {
                    value: 'MW',
                    text: 'Malawi',
                },
                {
                    value: 'MY',
                    text: 'Malaysia',
                },
                {
                    value: 'MV',
                    text: 'Malediven',
                },
                {
                    value: 'ML',
                    text: 'Mali',
                },
                {
                    value: 'MT',
                    text: 'Malta',
                },
                {
                    value: 'MA',
                    text: 'Marokko',
                },
                {
                    value: 'MH',
                    text: 'Marshallinseln',
                },
                {
                    value: 'MQ',
                    text: 'Martinique',
                },
                {
                    value: 'MR',
                    text: 'Mauretanien',
                },
                {
                    value: 'MU',
                    text: 'Mauritius',
                },
                {
                    value: 'YT',
                    text: 'Mayotte',
                },
                {
                    value: 'MK',
                    text: 'Mazedonien',
                },
                {
                    value: 'MX',
                    text: 'Mexiko',
                },
                {
                    value: 'FM',
                    text: 'Mikronesien',
                },
                {
                    value: 'MD',
                    text: 'Moldawien',
                },
                {
                    value: 'MC',
                    text: 'Monaco',
                },
                {
                    value: 'MN',
                    text: 'Mongolei',
                },
                {
                    value: 'MS',
                    text: 'Montserrat',
                },
                {
                    value: 'MZ',
                    text: 'Mosambik',
                },
                {
                    value: 'MM',
                    text: 'Myanmar (Burma)',
                },
                {
                    value: 'NA',
                    text: 'Namibia',
                },
                {
                    value: 'NR',
                    text: 'Nauru',
                },
                {
                    value: 'NP',
                    text: 'Nepal',
                },
                {
                    value: 'NC',
                    text: 'Neukaledonien',
                },
                {
                    value: 'NZ',
                    text: 'Neuseeland',
                },
                {
                    value: 'NI',
                    text: 'Nicaragua',
                },
                {
                    value: 'NL',
                    text: 'Niederlande',
                },
                {
                    value: 'AN',
                    text: 'Niederländische Antillen',
                },
                {
                    value: 'NE',
                    text: 'Niger',
                },
                {
                    value: 'NG',
                    text: 'Nigeria',
                },
                {
                    value: 'NU',
                    text: 'Niue',
                },
                {
                    value: 'KP',
                    text: 'Nordkorea',
                },
                {
                    value: 'MP',
                    text: 'Nördliche Marianen',
                },
                {
                    value: 'NF',
                    text: 'Norfolkinsel',
                },
                {
                    value: 'NO',
                    text: 'Norwegen',
                },
                {
                    value: 'OM',
                    text: 'Oman',
                },
                {
                    value: 'AT',
                    text: 'Österreich',
                },
                {
                    value: 'PK',
                    text: 'Pakistan',
                },
                {
                    value: 'PS',
                    text: 'Palästinensische Autonomiegebiete',
                },
                {
                    value: 'PW',
                    text: 'Palau',
                },
                {
                    value: 'PA',
                    text: 'Panama',
                },
                {
                    value: 'PG',
                    text: 'Papua-Neuguinea',
                },
                {
                    value: 'PY',
                    text: 'Paraguay',
                },
                {
                    value: 'PE',
                    text: 'Peru',
                },
                {
                    value: 'PH',
                    text: 'Philippinen',
                },
                {
                    value: 'PN',
                    text: 'Pitcairninseln',
                },
                {
                    value: 'PL',
                    text: 'Polen',
                },
                {
                    value: 'PT',
                    text: 'Portugal',
                },
                {
                    value: 'PR',
                    text: 'Puerto Rico',
                },
                {
                    value: 'CG',
                    text: 'Republik Kongo',
                },
                {
                    value: 'RE',
                    text: 'Réunion',
                },
                {
                    value: 'RW',
                    text: 'Ruanda',
                },
                {
                    value: 'RO',
                    text: 'Rumänien',
                },
                {
                    value: 'RU',
                    text: 'Russische Föderation',
                },
                {
                    value: 'SB',
                    text: 'Salomonen',
                },
                {
                    value: 'ZM',
                    text: 'Sambia',
                },
                {
                    value: 'WS',
                    text: 'Samoa',
                },
                {
                    value: 'SM',
                    text: 'San Marino',
                },
                {
                    value: 'ST',
                    text: 'Sao Tome und Pri­ncipe',
                },
                {
                    value: 'SA',
                    text: 'Saudi-Arabien',
                },
                {
                    value: 'SE',
                    text: 'Schweden',
                },
                {
                    value: 'CH',
                    text: 'Schweiz',
                },
                {
                    value: 'SN',
                    text: 'Senegal',
                },
                {
                    value: 'CS',
                    text: 'Serbien und Montenegro',
                },
                {
                    value: 'SC',
                    text: 'Seychellen',
                },
                {
                    value: 'SL',
                    text: 'Sierra Leone',
                },
                {
                    value: 'ZW',
                    text: 'Simbabwe',
                },
                {
                    value: 'SG',
                    text: 'Singapur',
                },
                {
                    value: 'SK',
                    text: 'Slowakei',
                },
                {
                    value: 'SI',
                    text: 'Slowenien',
                },
                {
                    value: 'SO',
                    text: 'Somalia',
                },
                {
                    value: 'ES',
                    text: 'Spanien',
                },
                {
                    value: 'LK',
                    text: 'Sri Lanka',
                },
                {
                    value: 'SH',
                    text: 'St. Helena',
                },
                {
                    value: 'KN',
                    text: 'St. Kitts und Nevis',
                },
                {
                    value: 'LC',
                    text: 'St. Lucia',
                },
                {
                    value: 'PM',
                    text: 'St. Pierre und Miquelon',
                },
                {
                    value: 'VC',
                    text: 'St. Vincent und die Grenadinen',
                },
                {
                    value: 'ZA',
                    text: 'Südafrika',
                },
                {
                    value: 'SD',
                    text: 'Sudan',
                },
                {
                    value: 'GS',
                    text: 'Südgeorgien und die Südl. Sandwichinseln',
                },
                {
                    value: 'KR',
                    text: 'Südkorea',
                },
                {
                    value: 'SR',
                    text: 'Suriname',
                },
                {
                    value: 'SJ',
                    text: 'Svalbard und Jan Mayen',
                },
                {
                    value: 'SZ',
                    text: 'Swasiland',
                },
                {
                    value: 'SY',
                    text: 'Syrien',
                },
                {
                    value: 'TJ',
                    text: 'Tadschikistan',
                },
                {
                    value: 'TW',
                    text: 'Taiwan',
                },
                {
                    value: 'TZ',
                    text: 'Tansania',
                },
                {
                    value: 'TH',
                    text: 'Thailand',
                },
                {
                    value: 'TL',
                    text: 'Timor-Leste',
                },
                {
                    value: 'TG',
                    text: 'Togo',
                },
                {
                    value: 'TK',
                    text: 'Tokelau',
                },
                {
                    value: 'TO',
                    text: 'Tonga',
                },
                {
                    value: 'TT',
                    text: 'Trinidad und Tobago',
                },
                {
                    value: 'TD',
                    text: 'Tschad',
                },
                {
                    value: 'CZ',
                    text: 'Tschechische Republik',
                },
                {
                    value: 'TN',
                    text: 'Tunesien',
                },
                {
                    value: 'TR',
                    text: 'Türkei',
                },
                {
                    value: 'TM',
                    text: 'Turkmenistan',
                },
                {
                    value: 'TC',
                    text: 'Turks- und Caicosinseln',
                },
                {
                    value: 'TV',
                    text: 'Tuvalu',
                },
                {
                    value: 'UG',
                    text: 'Uganda',
                },
                {
                    value: 'UA',
                    text: 'Ukraine',
                },
                {
                    value: 'HU',
                    text: 'Ungarn',
                },
                {
                    value: 'UY',
                    text: 'Uruguay',
                },
                {
                    value: 'UZ',
                    text: 'Usbekistan',
                },
                {
                    value: 'VU',
                    text: 'Vanuatu',
                },
                {
                    value: 'VA',
                    text: 'Vatikanstadt',
                },
                {
                    value: 'VE',
                    text: 'Venezuela',
                },
                {
                    value: 'AE',
                    text: 'Vereinigte Arabische Emirate',
                },
                {
                    value: 'US',
                    text: 'Vereinigte Staaten von Amerika',
                },
                {
                    value: 'VN',
                    text: 'Vietnam',
                },
                {
                    value: 'WF',
                    text: 'Wallis und Futuna',
                },
                {
                    value: 'CX',
                    text: 'Weihnachtsinsel',
                },
                {
                    value: 'EH',
                    text: 'Westsahara',
                },
                {
                    value: 'CF',
                    text: 'Zentralafrikanische Republik',
                },
                {
                    value: 'CY',
                    text: 'Zypern</select>',
                },
            ]
        };
    },
};
