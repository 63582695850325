export default {
    methods: {
        resetObject() {
            this.data = JSON.parse(JSON.stringify(this.rawData));
            this.resetErrors();
        },
        prepareData(data) {
            return data;
        },
        prepareFormData(data) {
            return {
                amm: {
                    name: data.name,
                    address: data.address,
                    zip: data.zip,
                    city: data.city,
                    phone: data.phone,
                    contactName: data.contact_name,
                    contactEmail: data.contact_email,
                    contactPhone: data.contact_phone,
                    website: data.website,
                    canton: data.canton.code,
                },
            };
        },
    },
};
