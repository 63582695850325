<template>
    <v-flex
        md12
        class="px-12 pb-12"
    >
        <PageHeader
            :breadcrumbs="breadcrumbs"
            :title="pageTitle"
            back
            back-to="email_master_template"
        />
        <v-card
            flat
            style="width: 50%"
            class="pa-12"
        >
            <v-card-text>
                <v-alert
                    v-if="authError !== null"
                    :value="true"
                    type="error"
                >
                    {{ $t(authError) }}
                </v-alert>
                <v-form
                    @submit.prevent="submit"
                    v-if="!sent"
                >
                    <v-text-field
                        id="password"
                        v-model="password"
                        prepend-icon="mdi-lock"
                        name="password"
                        required
                        :label="$t('page.auth.password_reset.new_password')"
                        type="text"
                        :error-messages="formError(formErrors, 'password.children.first')"
                    />
                    <v-text-field
                        id="password_confirmation"
                        v-model="password_confirmation"
                        prepend-icon="mdi-lock"
                        name="password_confirmation"
                        required
                        :label="$t('page.auth.password_reset.new_password_confirmation')"
                        type="text"
                        :error-messages="formError(formErrors, 'password.children.second')"
                    />
                    <v-btn
                        style="display: none;"
                        type="submit"
                    >
                        Login
                    </v-btn>
                </v-form>
                <div
                    v-else
                    v-text="$t('page.auth.password_reset.success')"
                />
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn
                    v-if="sent"
                    text
                    small
                    :to="{ name: 'login' }"
                >
                    {{ $t('general.back') }}
                </v-btn>
                <v-btn
                    v-else
                    color="secondary--dark"
                    class="px-4"
                    dark
                    rounded
                    depressed
                    type=""
                    @click="submit"
                >
                    {{ $t('page.auth.password_reset.button') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-flex>
</template>

<script>

import PageHeader from '@/components/common/PageHeader';
import AuthProxy from '@/proxies/AuthProxy';

export default {
    name: 'ChangePassword',
    components: {
        PageHeader,
    },
    data() {
        return {
            password: '',
            password_confirmation: '',
            sent: false,
            authError: null,
        };
    },
    computed: {
        pageTitle() {
            return 'Password ändern';
        },
        breadcrumbs() {
            return [
                {
                    text: this.$t('page.home.title'),
                    exact: true,
                    to: { name: 'home' },
                },
                {
                    text: this.$t('security.change_password.title'),
                    exact: true,
                    to: { name: 'change_password' },
                },
            ];
        },
    },
    methods: {
        submit() {
            new AuthProxy().resetPasswordLoggedIn({
                password_reset: {
                    password: {
                        first: this.password,
                        second: this.password_confirmation,
                    }
                },
            }).then(({ data }) => {
                this.sent = true;
                this.resetErrors();
            }).catch(({ data }) => {
                this.processErrors(data);
            });
        },
    },
};
</script>
