export default {
    methods: {
        resetObject() {
            this.data = JSON.parse(JSON.stringify(this.rawData));
            this.resetErrors();
        },
        prepareData(data) {

            return data;
        },
        prepareFormData(data) {
            return {
                course: {
                    executionNumber: data.execution_number,
                    startDate: data.start_date,
                    endDate: data.end_date,
                    fullyBooked: data.fully_booked,
                    name: data.name,
                    type: data.type,
                    language: data.language,
                },
            };
        },
    },
};
