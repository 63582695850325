import DefaultLayout from '@/layouts/Default';
import EmailMasterTemplateIndex from '@/views/EmailMasterTemplate/Index';
import EmailMasterTemplateDetail from '@/views/EmailMasterTemplate/Detail';

export default {
    path: '/email_master_template',
    component: DefaultLayout,
    children: [
        {
            path: '',
            name: 'email_master_template',
            component: EmailMasterTemplateIndex,
            meta: {
                auth: true,
            },
        },
        {
            path: ':type/:language',
            name: 'email_master_template_detail',
            component: EmailMasterTemplateDetail,
            meta: {
                auth: true,
            },
        },
    ],
};
