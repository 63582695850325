<template>
    <v-flex
        md12
        class="px-12 pb-12"
    >
        <PageHeader
            :breadcrumbs="breadcrumbs"
            :title="pageTitle"
            back
            back-to="course"
        />
        <v-row>
            <v-col cols="12">
                <TabMenu
                    :menus="menus"
                    v-model="activeMenu"
                />
            </v-col>
            <v-col cols="12">
                <v-alert
                    v-for="(error, errorKey) in generalFormErrors"
                    :key="errorKey"
                    type="error"
                    v-text="error"
                />
                <CourseForm
                    v-if="activeMenu == 'profile'"
                    :data="data"
                    :raw-data="rawData"
                    :errors="formErrors"
                    :loading="loading"
                    :save-function="saveObject"
                    :discard-function="resetObject"
                />
                <Participants
                    :key="`participants-${keyParticipants}`"
                    v-if="activeMenu == 'active_participants'"
                    :course="data"
                    active
                />
                <Participants
                    :key="`waiting_participants-${keyParticipants}`"
                    v-if="activeMenu == 'waiting_participants'"
                    :course="data"
                    waiting
                />
                <Participants
                    :key="`participants-${keyParticipants}`"
                    v-if="activeMenu == 'participants'"
                    :course="data"
                    show-status
                />
                <CourseCalendar
                    :key="`participants-${keyParticipants}`"
                    v-if="activeMenu == 'course_calendar'"
                    :course="data"
                    :raw-data="rawData"
                    show-status
                />
            </v-col>
        </v-row>
    </v-flex>
</template>

<script>

import PageHeader from '@/components/common/PageHeader';
import CourseForm from '@/components/courses/Form/CourseForm';
import CourseCalendar from '@/components/courses/CourseCalendar';
import Participants from '@/components/courses/Participants';
import CourseMixin from '@/mixins/course';
import TabMenu from '@/components/common/TabMenu';
import CourseProxy from '@/proxies/CourseProxy';
import DatesMixin from '@/mixins/dates';
import { SuccessSnackbar, StandBy } from '@/app-events.js';

export default {
    name: 'CourseDetail',
    mixins: [ CourseMixin, DatesMixin ],
    components: {
        PageHeader,
        CourseForm,
        Participants,
        TabMenu,
        CourseCalendar,
    },
    created() {
        this.fetchData();
    },
    data() {
        return {
            activeMenu: 'profile',
            menus: [
                {
                    id: 'profile',
                    label: 'Profil',
                },
                {
                    id: 'active_participants',
                    label: this.$t('course.participants'),
                },
                {
                    id: 'participants',
                    label: this.$t('course.other_participants'),
                },
                {
                    id: 'waiting_participants',
                    label: this.$t('course.waiting_participants'),
                },
                {
                    id: 'course_calendar',
                    label: this.$t('page.calendar.title'),
                },
            ],
            loading: false,
            data: {},
            rawData: {},
            keyParticipants: 0,
        };
    },
    computed: {
        pageTitle() {
            let title = '';
            if (this.data.name) {
                title = `${this.data.name} | `;
            }
            if (this.data.start_date) {
                try {
                    return `${title}${this.formatDate(this.data.start_date)} - ${this.formatDate(this.data.end_date)}`;    
                } catch (error) {
                    return title;
                }
            } else {
                return title;
            }
        },
        breadcrumbs() {
            return [
                {
                    text: this.$t('page.home.title'),
                    exact: true,
                    to: { name: 'home' },
                },
                {
                    text: this.$t('page.course.title'),
                    exact: true,
                    to: { name: 'course' },
                },
                {
                    text: this.pageTitle,
                    disabled: true,
                },
            ];
        },
    },
    methods: {
        fetchData() {
            this.loading = true;
            new CourseProxy().find(this.$route.params.id).then(({ data }) => {
                this.data = this.prepareData(data.data);
                this.rawData = JSON.parse(JSON.stringify(this.data));
                this.loading = false;
            }).catch(({ data }) => {
                this.displayError(data);
            });
            
        },
        saveObject() {
            this.$eventBus.dispatch(new StandBy(true));
            new CourseProxy().update(this.$route.params.id, this.prepareFormData(this.data)).then(({ data }) => {
                this.$eventBus.dispatch(new StandBy(false));
                this.rawData = JSON.parse(JSON.stringify(this.data));
                this.$eventBus.dispatch(new SuccessSnackbar(this.$t('course.actions.saved')));
                this.resetErrors();
            }).catch(({ data }) => {
                this.processErrors(data);
                this.$eventBus.dispatch(new StandBy(false));
            });
        },
    },
    watch: {
        activeMenu(val) {
            this.keyParticipants++;
        }
    },
};
</script>
