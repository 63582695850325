
import Vue from 'vue';
import axios from 'axios';
import router from './router';

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

const config = {
    baseURL: process.env.VUE_APP_API_ROOT || '',
    // timeout: 60 * 1000, // Timeout
    // withCredentials: true, // Check cross-site Access-Control
};

const _axios = axios.create(config);

// 401 errors handling (Not authenticated/JWT Expired)
_axios.interceptors.response.use((response) => response, async (error) => {
    if (!error.response || error.response.status !== 401) {
        return Promise.reject(error);
    }
    console.log('401');

    if (error.config.url.indexOf('token/refresh') === -1) {
        if (Vue.prototype.$localStorage.get('refresh_token', false)) {
            try {
                const response = await Vue.axios.post('/token/refresh', {
                    refresh_token: Vue.prototype.$localStorage.get('refresh_token'),
                });
                await Vue.auth.token(Vue.auth.options.tokenDefaultName, response.data.token);
                await Vue.prototype.$localStorage.set('refresh_token', response.data.refresh_token);

                // eslint-disable-next-line no-param-reassign
                error.config.headers.Authorization = `Bearer ${response.data.token}`;
                return _axios.request(error.config);
            } catch (e) {
                // Refresh token expired
                Vue.prototype.$localStorage.remove('access_token');
                Vue.prototype.$localStorage.remove('auth_token_default');
                Vue.prototype.$localStorage.remove('refresh_token');
                router.push({ name: 'login' });

                return Promise.reject(error.response);
            }
        }
    }

    return Promise.reject(error.response);
});

// 4xx errors handling
_axios.interceptors.response.use((response) => response, async (error) => {
    if (
        !error.response
    || error.response.status === 401
    || error.response.status < 400 || error.response.status >= 500
    ) {
        return Promise.reject(error);
    }
    console.log('4xx');

    // handle client error
    return Promise.reject(error);
});

// 5xx errors handling
_axios.interceptors.response.use((response) => response, async (error) => {
    if (!error.response || error.response.status < 500) {
        return Promise.reject(error);
    }

    // handle server error
    return Promise.reject(error);
});

Plugin.install = function (Vue) {
    Vue.axios = _axios;
    window.axios = _axios;
    Object.defineProperties(Vue.prototype, {
        axios: {
            get() {
                return _axios;
            },
        },
        $axios: {
            get() {
                return _axios;
            },
        },
    });
};

Vue.use(Plugin);
Vue.axios = _axios;
Vue.$http = _axios;

export default Plugin;
