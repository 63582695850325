<template>
    <v-card flat>
        <v-card-text>
            <v-row>
                <v-col cols="12">
                    <v-textarea
                        readonly
                        type="text"
                        v-model="data.name"
                        :label="$t('general_setting.name')"
                        :error-messages="formError(errors, 'name')"
                    />
                    <v-textarea
                        type="text"
                        v-model="data.value"
                        :label="$t('general_setting.value')"
                        :error-messages="formError(errors, 'value')"
                    />
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>

import { mapGetters } from 'vuex';

export default {
    name: 'OfficeInformation',
    props: {
        data: {
            type: Object,
            default: () => ({
                name: '',
                roles: [],
            }),
        },
        errors: {
            type: Object,
            default: () => ({}),
        },
    },
};
</script>
