<template>
    <v-row>
        <v-col cols="2">
            <SearchSelect
                :items="ages"
                label="Alter"
                v-model="value.age"
            />
        </v-col>
        <v-col cols="2">
            <SearchSelect
                :items="genders"
                label="Geschlecht"
                v-model="value.gender"
            />
        </v-col>
        <v-col cols="2">
            <SearchSelect
                :items="jobCategories"
                label="Berufsgruppe"
                v-model="value.jobCategory"
            />
        </v-col>
        <v-col cols="2">
            <SearchSelect
                :items="groups"
                label="Gruppe"
                v-model="value.group"
            />
        </v-col>
    </v-row>
</template>

<script>
import SearchSelect from '@/components/participants/AdvancedSearch/Select';

export default {
    name: 'Search',
    components: {
        SearchSelect,
    },
    props: {
        value: {
            type: Object,
            default: () => ({
                age: '',
                gender: '',
                jobCategory: '',
                group: '',
            }),
        },
    },
    data() {
        return {
            ages: [],
            genders: [],
            jobCategories: [],
            groups: [],
        }
    },

    created() {
        this.initialize();
    },
    methods: {
        initialize () {
            this.ages = [
                '18',
                '28',
                '38',
            ];
            this.genders = [
                { text: 'Frau', value: 'woman' },
                { text: 'Herr', value: 'man' },
            ];
            this.jobCategories = [
                'A',
                'B',
            ];
            this.groups = [
                { text: 'Blau', value: 'blue' },
                { text: 'Rot', value: 'red' },
            ];
        },
        updateSearch(val) {
            this.activeMenu = val;
            this.$emit('input', val);
        },
    },
};
</script>
