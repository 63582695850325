export default {
    methods: {
        prepareFormData(data) {
            data = JSON.parse(JSON.stringify(data));
            if (data.insurance_fund.id === 'other') {
                data.insurance_fund = {};
            } else {
                data.insurance_fund_freetext = undefined;
            }
            if (data.amm.id === 'other') {
                data.amm = {};
            } else {
                data.amm_freetext = undefined;
            }
            if (data.rav_location.id !== 'other') {
                data.rav_location_freetext = undefined;
            } else {
                data.rav_location = {}
            }
            let formData = {
                canton: data.canton.code,
                firstname: data.firstname,
                lastname: data.lastname,
                salutation: data.salutation,
                address: data.address,
                zip: data.zip,
                city: data.city,
                ammFreetext: data.amm_freetext,
                phone: data.phone,
                mobile: data.mobile,
                email: data.email,
                birthdate: data.birthdate,
                civilStatus: data.civil_status,
                nationality: data.nationality,
                plainAhvNumber: data.plain_ahv_number,
                germanKnowledge: data.german_knowledge,
                xing: data.xing,
                linkedin: data.linkedin,
                website: data.website,
                dailyAllowances: data.daily_allowances,
                note: data.note,
                rav: data.rav,
                course: data.course.id,
                ravLocation: data.rav_location.id,
                ravLocationFreetext: data.rav_location_freetext,
                ravConsultantEmailFreetext: data.rav_consultant_email_freetext,
                insuranceFundFreetext: data.insurance_fund_freetext,
                insuranceFund: data.insurance_fund.id,
                amm: data.amm.id,
                hadFirstInterviewViaPhone: data.hadFirstInterviewViaPhone,
            };
            if (data.registration_time_slot !== '') {
                formData.registrationTimeSlot = data.registration_time_slot;
            }
            return formData;
        },
    },
};
