import Vue from 'vue';
import Vuex from 'vuex';
import createLogger from 'vuex/dist/logger';
import createPersistedState from 'vuex-persistedstate';
import auth from '@/store/modules/auth';
import canton from '@/store/modules/canton';
import preferences from '@/store/modules/preferences';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production' && process.env.DEBUG_MODE;
const persistedState = createPersistedState({
    paths: [
        'auth',
        'canton',
        'preferences',
    ],
});

export default new Vuex.Store({
    modules: {
        auth,
        canton,
        preferences,
    },
    actions: {
        clearAllState({ commit }) {
            commit('auth/RESET_STATE');
            commit('canton/RESET_STATE');
            commit('preferences/RESET_STATE');
        },
    },
    strict: debug,
    plugins: debug ? [ createLogger(), persistedState ] : [ persistedState ],
});
