var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"flat":""}},[_c('v-data-table',{staticClass:"pa-3",attrs:{"headers":_vm.headers,"items":_vm.objects,"search":_vm.search,"custom-filter":_vm.filterParticipant,"footer-props":_vm.defaultFooterProps},scopedSlots:_vm._u([{key:"item.fullname",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"link":"","text":"","to":{ name: 'registration_detail', params: { id: item.id }}}},[_vm._v(" "+_vm._s(item.fullname)+" ")])]}},{key:"item.course",fn:function(ref){
var item = ref.item;
return [(item.course)?_c('span',[_vm._v(" "+_vm._s(item.course.name)+" ("+_vm._s(_vm.formatDate(item.course.start_date))+" - "+_vm._s(_vm.formatDate(item.course.end_date))+") ")]):_vm._e()]}},{key:"item.registration_time_slot",fn:function(ref){
var item = ref.item;
return [(_vm.hasTimeSlot(item))?_c('span',[_c('v-btn',{staticClass:"mr-1",attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.downloadEvent(item)}}},[_c('v-icon',[_vm._v("mdi-calendar-export")])],1),_vm._v(" "+_vm._s(_vm.formatDateTime(item.registration_time_slot.start))+" ")],1):_vm._e(),(_vm.hadFirstInterview(item) && ! _vm.hasTimeSlot(item))?_c('span',[_c('v-checkbox',{attrs:{"label":_vm.$t('first_interview.already_done'),"disabled":""},model:{value:(item.had_first_interview_via_phone),callback:function ($$v) {_vm.$set(item, "had_first_interview_via_phone", $$v)},expression:"item.had_first_interview_via_phone"}})],1):_vm._e()]}},{key:"item.registration_status",fn:function(ref){
var item = ref.item;
return [(item.registration_status)?_c('span',[_vm._v(" "+_vm._s(_vm.$t(("participant.registrationStatuses." + (item.registration_status))))+" ")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.email)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"icon":"","href":("mailto:" + (item.email))}},on),[_c('v-icon',[_vm._v(" mdi-email-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v("E-Mail")])]):_vm._e()]}},{key:"item.misc",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"icon":"","to":{ name: 'registration_detail', params: { id: item.id }}}},on),[_c('v-icon',[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s(_vm.$t('general.edit'))}})]),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-dots-horizontal ")])]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.initiateDeletion(item)}}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-delete")])],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(_vm.$t('general.delete'))}})],1)],1)],1)],1)]}}])})],1),_c('ConfirmationDialog',{attrs:{"confirm":_vm.deleteObject,"cancel":function () { return (_vm.deleteDialog = false); },"loading":_vm.deleting,"dialog":_vm.deleteDialog}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }