export default {
    methods: {
        prepareFormData(data) {
            return {
                participant_upload: {
                    filename: data.filename,
                },
            };
        },
    },
};
