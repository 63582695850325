import {
    LOGIN,
    LOGOUT,
    INITIALIZE,
    RESET,
    RESET_STATE,
    LOGIN_ERROR,
} from './mutation-types';

export default {
    [ LOGIN ]: (state, payload) => {
        state.isLoggedIn = true;
        payload.localstorageManager.set('refresh_token', payload.refresh_token);
    },
    [ INITIALIZE ]: (state, payload) => {
        state.isLoggedIn = payload.authManager.token() !== null;
        state.loginError = null;
    },
    [ LOGOUT ]: (state, payload) => {
        state.isLoggedIn = false;
        state.loginError = null;
        payload.localstorageManager.remove('refresh_token');
    },
    [ RESET ]: (state, payload) => {
        state.isLoggedIn = false;
        payload.localstorageManager.remove('refresh_token');
    },
    [ LOGIN_ERROR ]: (state, payload) => {
        state.loginError = payload.error;
    },
    /**
   * Reset state of this module
   *
   * @param {Object} state Auth's state
   */
    [ RESET_STATE ]: (state) => {
        state.isLoggedIn = false;
    },
};
