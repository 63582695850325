export default {
    methods: {
        isSimpleForm(data) {
            if (!data.course || typeof data.course === 'undefined' || typeof data.course.id === 'undefined' || data.course.id === null) {
                return false;
            }
            if (data.course.type === 'OL') {
                return false;
            }
            return true;
        },
        prepareData(data) {
            if (data.canton === null) {
                data.canton = {};
            }
            if (data.course === null || typeof data.course.id === 'undefined') {
                data.course = {};
            }
            if (typeof data.waiting_course === 'undefined' || data.waiting_course === null || typeof data.waiting_course.id === 'undefined') {
                data.waiting_course = {};
            }
            if (data.coach === null) {
                data.coach = {};
            }
            if (data.default_presence === null) {
                data.default_presence = {};
            }
            if (data.insurance_fund === null) {
                data.insurance_fund = {};
            }
            if (data.registration_time_slot === null) {
                data.registration_time_slot = {};
            }
            if (data.amm === null) {
                data.amm = {};
            }
            if (data.rav_consultant === null) {
                data.rav_consultant = {};
                if (data.rav_location === null) {
                    data.rav_location = {};
                }
            } else if (data.rav_location === null) {
                data.rav_location = data.rav_consultant.rav_location;
            }
            if (data.rav_location_freetext) {
                data.rav_location = {
                    id: 'other',
                };
            }
            if (data.insurance_fund_freetext) {
                data.insurance_fund = {
                    id: 'other',
                };
            }
            if (data.amm_freetext) {
                data.amm = {
                    id: 'other',
                };
            }
            return data;
        },
        prepareFormData(data) {
            data = JSON.parse(JSON.stringify(data));
            if (data.insurance_fund.id === 'other') {
                data.insurance_fund = {};
            } else {
                data.insurance_fund_freetext = null;
            }
            if (data.amm.id === 'other') {
                data.amm = {};
            } else {
                data.amm_freetext = null;
            }
            if (data.rav_location.id !== 'other') {
                data.rav_location_freetext = null;
            } else {
                data.rav_location = {}
            }
            if (typeof data.waiting_course === 'undefined' || data.waiting_course === null || typeof data.waiting_course.id === 'undefined') {
                data.waiting_course = {};
            }
            return {
                participant: {
                    canton: data.canton.code,
                    course: data.course.id,
                    waitingCourse: data.waiting_course.id,
                    waitingList: data.waiting_list,
                    amm: data.amm.id,
                    ammFreetext: data.amm_freetext,
                    firstname: data.firstname,
                    lastname: data.lastname,
                    salutation: data.salutation,
                    address: data.address,
                    zip: data.zip,
                    city: data.city,
                    phone: data.phone,
                    mobile: data.mobile,
                    email: data.email,
                    birthdate: data.birthdate,
                    civilStatus: data.civil_status,
                    nationality: data.nationality,
                    plainAhvNumber: data.plain_ahv_number,
                    germanKnowledge: data.german_knowledge,

                    jobCategory: data.job_category,
                    jobCategoryOther: data.job_category_other,
                    jobCategoryExtended: data.job_category_extended,
                    jobCategoryExtendedOther: data.job_category_extended_other,
                    jobFunction: data.job_function,
                    professionalAwards: data.professional_awards,
                    jobTitle: data.job_title,
                    workload: data.workload,
                    xing: data.xing,
                    linkedin: data.linkedin,
                    website: data.website,
                    personNumber: data.person_number,
                    dailyAllowances: data.daily_allowances,
                    dailyAllowancesValue: data.daily_allowances_value,
                    insuranceFund: data.insurance_fund.id,
                    rav: data.rav,
                    note: data.note,
                    ravConsultant: data.rav_consultant.id,
                    ravLocation: data.rav_location.id,
                    ravLocationFreetext: data.rav_location_freetext,
                    ravConsultantEmailFreetext: data.rav_consultant_email_freetext,
                    insuranceFundFreetext: data.insurance_fund_freetext,

                    status: data.status,
                    group: data.group,
                    startDate: data.start_date,
                    endDate: data.end_date,
                    available: data.available,
                    finalReportAt: data.final_report_at,
                    exitReason: data.exit_reason,
                    exitStatus: data.exit_status,
                    coach: data.coach.id,
                    
                    registrationTimeSlot: data.registration_time_slot.id,
                    registrationStatus: data.registration_status,
                    
                    defaultPresence: {
                        mondayMorning: data.default_presence.monday_morning,
                        mondayAfternoon: data.default_presence.monday_afternoon,
                        tuesdayMorning: data.default_presence.tuesday_morning,
                        tuesdayAfternoon: data.default_presence.tuesday_afternoon,
                        wednesdayMorning: data.default_presence.wednesday_morning,
                        wednesdayAfternoon: data.default_presence.wednesday_afternoon,
                        thursdayMorning: data.default_presence.thursday_morning,
                        thursdayAfternoon: data.default_presence.thursday_afternoon,
                        fridayMorning: data.default_presence.friday_morning,
                        fridayAfternoon: data.default_presence.friday_afternoon,
                    },
                },
            };
        },
    },
};
