<template>
    <v-flex
        md12
        class="px-12 pb-12"
    >
        <PageHeader
            :breadcrumbs="breadcrumbs"
            :title="pageTitle"
            back
            back-to="general_setting"
        />
        <v-row>
            <v-col cols="12">
                <v-alert
                    v-for="(error, errorKey) in generalFormErrors"
                    :key="errorKey"
                    type="error"
                    v-text="error"
                />
                <GeneralSettingForm
                    :data="data"
                    :raw-data="rawData"
                    :errors="formErrors"
                    :loading="loading"
                    :save-function="saveObject"
                    :discard-function="resetObject"
                />
            </v-col>
        </v-row>
    </v-flex>
</template>

<script>

import PageHeader from '@/components/common/PageHeader';
import GeneralSettingForm from '@/components/generalSetting/Form/GeneralSettingForm';
import GeneralSettingProxy from '@/proxies/GeneralSettingProxy';
import GeneralSettingMixin from '@/mixins/generalSetting';
import { SuccessSnackbar, StandBy } from '@/app-events.js';

export default {
    name: 'GeneralSettingDetail',
    mixins: [ GeneralSettingMixin ],
    components: {
        PageHeader,
        GeneralSettingForm,
    },
    created() {
        this.fetchData();
    },
    data() {
        return {
            loading: false,
            data: {},
            rawData: {},
        };
    },
    computed: {
        pageTitle() {
            return this.rawData.name;
        },
        breadcrumbs() {
            return [
                {
                    text: this.$t('page.home.title'),
                    exact: true,
                    to: { name: 'home' },
                },
                {
                    text: this.$t('page.general_setting.title'),
                    exact: true,
                    to: { name: 'general_setting' },
                },
                {
                    text: this.pageTitle,
                    disabled: true,
                },
            ];
        },
    },
    methods: {
        fetchData() {
            this.loading = true;
            new GeneralSettingProxy().find(this.$route.params.id).then(({ data }) => {
                this.data = this.prepareData(data.data);
                this.rawData = JSON.parse(JSON.stringify(this.data));
                this.loading = false;
            }).catch(({ data }) => {
                this.displayError(data);
            });
            
        },
        saveObject() {
            this.$eventBus.dispatch(new StandBy(true));
            new GeneralSettingProxy().update(this.$route.params.id, this.prepareFormData(this.data)).then(({ data }) => {
                this.$eventBus.dispatch(new StandBy(false));
                this.rawData = JSON.parse(JSON.stringify(this.data));
                this.$eventBus.dispatch(new SuccessSnackbar(this.$t('general_setting.actions.saved')));
                this.resetErrors();
            }).catch(({ data }) => {
                this.processErrors(data);
                this.$eventBus.dispatch(new StandBy(false));
            });
        },
    },
};
</script>
