import Vue from 'vue';
import RouterDriver from '@websanova/vue-auth/drivers/router/vue-router.2.x';
import AxiosDriver from '@websanova/vue-auth/drivers/http/axios.1.x';
import VueAuth from '@websanova/vue-auth';

const vueAuthMethods = {
    auth: {
        request(req, token) {
            // eslint-disable-next-line no-underscore-dangle
            this.options.http.setHeaders.call(this, req, { Authorization: `Bearer ${token}` });
        },
        response(res) {
            if (res.data && res.data.token) {
                return res.data.token;
            }
            return null;
        },
    },
    http: AxiosDriver,
    router: RouterDriver,
    loginData: {
        url: '/login_check',
        redirect: { name: 'participants_all', params: { courseType: 'all' } },
        fetchUser: false,
    },
    logoutData: {
        makeRequest: false, // do BE call
        redirect: { name: 'login' },
    },
    authRedirect: {
        name: 'login',
    },
    tokenDefaultName: 'access_token',
    refreshData: {
        enabled: false,
    },
    fetchData: {
        url: '/user',
        method: 'GET',
        enabled: true,
    },
};

Vue.use(VueAuth, vueAuthMethods);
