<template>
    <div id="app">
        <div v-if="$auth.ready()">
            <router-view />
        </div>
        <div v-if="!$auth.ready()" />
    </div>
</template>

<style lang="scss">
// .v-breadcrumbs, li, .v-breadcrumbs__item:not(:last-child) {
//     color: pink !important;
// }
</style>
<style>
  .v-text-field input {
    font-size: 1.1em;
  }
  .v-text-field .v-select__selection {
    font-size: 1.1em;
  }
  .v-text-field textarea {
    font-size: 1.1em;
  }
  .v-text-field label {
    font-size: 1.1em;
  }
</style>

<script>

export default {
    name: 'App',
};
</script>
