import { mapGetters } from 'vuex';
import { preferences } from '@/store/modules/preferences';

export default {
    methods: {
        formatDate(date) {
            if (!date) return null;

            const [ year, month, day ] = date.split('-');
            return `${day}.${month}.${year}`;
        },
        formatDateTime(datetime) {
            if (!datetime) return null;

            const [ date, time ] = datetime.split(' ');

            const [ year, month, day ] = date.split('-');
            return `${day}.${month}.${year} ${time}`;
        },
        formatDateISO(date) {
            if (!date) return null;

            return `${date.getFullYear()}-${(date.getMonth()+1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
        },
        beginingOfWeek(date) {
            const first = date.getDate() - date.getDay() + 1;
            
            return new Date(date.setDate(first));
        },
        dayOfWeek(val, short) {
            let date;
            if (typeof val === 'undefined') {
                return '';
            } else if (val instanceof Date) {
                date = val;
            } else {
                date = new Date(val);    
            }
            const dayOfWeek = date.getDay();
            let version = '';
            if (short === true) {
                version = '.short';
            }
            return this.$t(`general.day_of_week${version}.${dayOfWeek}`);
        },
    },
};
