import Proxy from './Proxy';

class CourseCalendarProxy extends Proxy {
    constructor(parameters = {}) {
        super('/course-calendar', parameters);
    }

    all({ courseId = null } = {}) {
        if (courseId) {
            return this.submit('get', `/?courseID=${courseId}`);
        }
        return this.submit('get', '/?courseID=85d8d47a-0b2f-11ec-8ca2-0242ac120004');
    }
    getEventsForCourse(ids) {
        return this.submit('post', `/get-course-events/${ids.join(',')}`);
    }

}

export default CourseCalendarProxy;
