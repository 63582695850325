<template>
    <v-flex
        md12
        class="px-12 pb-12"
    >
        <PageHeader
            :breadcrumbs="breadcrumbs"
            :title="$t('page.presence_list.title')"
        />
        <v-row>
            <ActionBar
                fetch
                :fetch-function="fetchData"
            >
                <template v-slot:addons>
                    <v-tooltip
                        v-if="objects.length > 0"
                        bottom
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                v-on="on"
                                v-bind="attrs"
                                small
                                icon
                                class="ml-3"
                                @click="print()"
                            >
                                <v-icon>mdi-printer</v-icon>
                            </v-btn>
                        </template>
                        <span v-text="$t('general.print')" />
                    </v-tooltip>
                    <v-menu
                        v-model="datePicker"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-chip
                                color="secondary--dark"
                                class="float-right text-none"
                                dark
                            >
                                <v-avatar left>
                                    <v-icon
                                        @click="!loading ? previousDate() : false"
                                    >
                                        mdi-chevron-left
                                    </v-icon>
                                </v-avatar>
                                <v-btn
                                    text
                                    v-on="on"
                                    class="mx-6"
                                    min-width="190px"
                                >
                                    <v-icon class="mr-2">
                                        mdi-calendar
                                    </v-icon>
                                    {{ currentDateDisplay }} | {{ dayOfWeek(currentDate, true) }}
                                </v-btn>
                                <v-avatar right>
                                    <v-icon @click="!loading ? nextDate() : false">
                                        mdi-chevron-right
                                    </v-icon>
                                </v-avatar>
                            </v-chip>
                        </template>
                        <v-date-picker
                            locale="de-ch"
                            :first-day-of-week="1"
                            v-model="currentDatePicker"
                            @input="datePicker = false"
                            :allowed-dates="allowedDates"
                        />
                    </v-menu><v-menu
                        v-if="selectedRows.length > 0"
                        offset-y
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                class="ml-3"
                                small
                                rounded
                                depressed
                                v-bind="attrs"
                                v-on="on"
                            >
                                {{ selectedRows.length }} {{ $t('general.selected') }}
                                <v-icon>mdi-chevron-down</v-icon>
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item @click="bulkEdit()">
                                <v-list-item-action>
                                    <v-icon>mdi-pencil-box-multiple</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title v-text="$t('general.bulk_edit')" />
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </template>
            </ActionBar>
            <v-col cols="12">
                <v-progress-linear
                    v-if="loading"
                    indeterminate
                    color="primary"
                />
                <v-skeleton-loader
                    v-if="loading"
                    class="mx-auto"
                    type="table-tbody"
                />
                <PresenceListList
                    v-if="!loading"
                    :objects="objects"
                    selection
                    @selection-changed="selectionChangedListener"
                />
            </v-col>
        </v-row>
    </v-flex>
</template>

<script>

import ActionBar from '@/components/common/ActionBar';
import PageHeader from '@/components/common/PageHeader';
import PresenceListList from '@/components/presenceList/PresenceListList';
import PresenceListProxy from '@/proxies/PresenceListProxy';
import DatesMixin from '@/mixins/dates';
import FileDownloadMixin from '@/mixins/file-download';
import { StandBy, BulkEditPresence, RefreshList } from '@/app-events.js';

export default {
    name: 'PresenceList',
    mixins: [ 
        DatesMixin,
        FileDownloadMixin,
    ],
    components: {
        ActionBar,
        PageHeader,
        PresenceListList
    },
    data() {
        return {
            loading: false,
            datePicker: false,
            currentDate: '',
            breadcrumbs: [
                {
                    text: this.$t('page.home.title'),
                    exact: true,
                    to: { name: 'home' },
                },
                {
                    text: this.$t('page.presence_list.title'),
                    disabled: true,
                },
            ],
            objects: [],
            direction: null,
            selectedRows: [],
        };
    },
    created () {
        this.currentDate = new Date();
        this.fetchData();

        this.$eventBus.listen(RefreshList, () => {
            this.fetchData();
        });
    },
    computed: {
        currentDateDisplay() {
            return `${this.currentDate.getDate().toString().padStart(2, '0')}.${(this.currentDate.getMonth()+1).toString().padStart(2, '0')}.${this.currentDate.getFullYear()}`;
        },
        currentDatePicker: {
            get() {
                return `${this.currentDate.getFullYear()}-${(this.currentDate.getMonth()+1).toString().padStart(2, '0')}-${this.currentDate.getDate().toString().padStart(2, '0')}`;
            },
            set(newValue) {
                this.currentDate = new Date(newValue);
                this.fetchData();
            }
        },
    },
    methods: {
        previousDate() {
            this.direction = 'previous';
            let dayBefore = new Date(this.currentDate.getTime());
            dayBefore.setDate(dayBefore.getDate()-1);
            this.currentDatePicker = `${dayBefore.getFullYear()}-${(dayBefore.getMonth()+1).toString().padStart(2, '0')}-${dayBefore.getDate().toString().padStart(2, '0')}`;
        },
        nextDate() {
            this.direction = 'next';
            let dayAfter = new Date(this.currentDate.getTime());
            dayAfter.setDate(dayAfter.getDate()+1);
            this.currentDatePicker = `${dayAfter.getFullYear()}-${(dayAfter.getMonth()+1).toString().padStart(2, '0')}-${dayAfter.getDate().toString().padStart(2, '0')}`;
        },
        allowedDates(val) {
            const date = new Date(val);
            const dayOfWeek = date.getDay();
            if (dayOfWeek === 0 || dayOfWeek === 6) {
                return false;
            }

            return true;
        },
        fetchData() {
            this.loading = true;
            this.selectedRows = [];
            new PresenceListProxy().all({
                date: `${this.currentDate.getFullYear()}-${(this.currentDate.getMonth()+1).toString().padStart(2, '0')}-${this.currentDate.getDate().toString().padStart(2, '0')}`,
            }).then(({ data }) => {
                this.objects = data.data;
                this.loading = false;
            }).catch(({ data }) => {
                this.expectError(data, 'not_week_day');
            });
        },
        print() {
            this.$eventBus.dispatch(new StandBy(true));
            new PresenceListProxy().export(this.formatDateISO(this.currentDate)).then(({ data }) => {
                this.loading = false;
                this.exportDialog = false;
                this.openPdf(data);
            }).catch(({ data }) => {
                this.displayError(data);
            }).finally(() => {
                this.$eventBus.dispatch(new StandBy(false));
            });
        },
        selectionChangedListener(value) {
            this.selectedRows = value;
        },
        bulkEdit() {
            this.$eventBus.dispatch(new BulkEditPresence(this.selectedRows));
        }
    },
    watch: {
        currentDate(val) {
            const dayOfWeek = this.currentDate.getDay();
            if (dayOfWeek === 0 || dayOfWeek === 6) {
                if (this.direction === 'previous') {
                    this.previousDate();
                } else {
                    this.nextDate();
                }
            }
        }
    },
};
</script>
