<template>
    <v-card flat>
        <v-card-text>
            <v-row>
                <v-col cols="6">
                    <v-select
                        :items="statuses"
                        v-model="data.registration_status"
                        :label="$t('participant.registration_status')"
                        :error-messages="formError(errors, 'registration_status')"
                        :hint="$t('general.required')"
                        persistent-hint
                    />
                </v-col>
                <v-col cols="6">
                    <v-autocomplete
                        v-model="data.coach.id"
                        :items="computedCoaches"
                        :label="$t('participant.registration_coach')"
                        :error-messages="formError(errors, 'coach')"
                        clearable
                        @click:clear="$nextTick(() => data.coach = null)"
                    />
                </v-col>
                <v-col :cols="data.had_first_interview_via_phone ? 6 : 12" >
                    <v-select v-model="data.registration_time_slot.id"
                        :items="computedRegistrationTimeSlots"
                        :label="$t('first_interview.registration_time_slot')"
                        :error-messages="formError(errors, 'registrationTimeSlot')"
                    />
                </v-col>
                <v-col cols="6">
                    <v-checkbox
                        v-if="data.had_first_interview_via_phone"
                        :label="$t('first_interview.already_done')"
                        v-model="data.had_first_interview_via_phone"
                        disabled
                    />
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>

import FirstInterviewProxy from '@/proxies/FirstInterviewProxy';
import FormDataMixin from '@/mixins/form-data';
import DatesMixin from '@/mixins/dates';
import CoachProxy from '@/proxies/CoachProxy';

export default {
    name: 'RegistrationInformation',
    mixins: [ FormDataMixin, DatesMixin ],
    props: {
        data: {
            type: Object,
            default: () => ({
                registration_time_slot: {},
            }),
        },
        errors: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            registrationTimeSlots: [],
            currentRegistrationTimeSlots: [],
            statuses: [
                {
                    value: 'new',
                    text: 'Neu',
                },
                {
                    value: 'in_progress',
                    text: 'In Berarbeitung',
                },
                {
                    value: 'duplicate',
                    text: 'Duplikat',
                },
                {
                    value: 'dummy',
                    text: 'Fehlerhafte Anmeldung',
                },
            ],
            coaches: [],
        }
    },
    created() {
        if (typeof this.data.registration_time_slot.id !== 'undefined') {
            this.currentRegistrationTimeSlots = JSON.parse(JSON.stringify(this.data.registration_time_slot));
        }
        this.fetchTimeSlots();
        new CoachProxy().all({
            active: true,
        }).then(({ data }) => {
            this.coaches = data.data;
        }).catch(({ data }) => {
            this.displayError(data);
        });
    },
    computed: {
        computedCoaches() {
            const allCoaches = [];
            for (let i = 0; i < this.coaches.length; i++) {
                const coach = this.coaches[ i ];
                allCoaches.push({
                    value: coach.id,
                    text: `${coach.firstname} ${coach.lastname}`,
                })
            }
            return allCoaches;
        },
        computedRegistrationTimeSlots() {
            const allRegistrationTimeSlots = [];
            if (typeof this.currentRegistrationTimeSlots.id !== 'undefined') {
                allRegistrationTimeSlots.push({
                    value: this.currentRegistrationTimeSlots.id,
                    text: this.formatDateTime(this.currentRegistrationTimeSlots.start),
                });
            }
            for (let i = 0; i < this.registrationTimeSlots.length; i++) {
                const registrationTimeSlot = this.registrationTimeSlots[ i ];
                const startParts = registrationTimeSlot.start.split(' ');
                allRegistrationTimeSlots.push({
                    value: registrationTimeSlot.id,
                    text: `${this.formatDate(startParts[ 0 ])} - ${startParts[ 1 ]}`,
                })
            }
            return allRegistrationTimeSlots;
        },
    },
    methods: {
        fetchTimeSlots() {
            new FirstInterviewProxy().registrationTimeSlots().then(({ data }) => {
                this.registrationTimeSlots = data.data;
            }).catch(({ data }) => {
                this.displayError(data);
            });
        },
    },
};
</script>
