import DefaultLayout from '@/layouts/Default';
import RavConsultantIndex from '@/views/RavConsultant/Index';
import RavConsultantDetail from '@/views/RavConsultant/Detail';
import RavConsultantNew from '@/views/RavConsultant/New';

export default {
    path: '/rav-berater',
    component: DefaultLayout,
    children: [
        {
            path: '',
            name: 'rav_consultant',
            component: RavConsultantIndex,
            meta: {
                auth: true,
            },
        },
        {
            path: 'new/:ravId?',
            name: 'rav_consultant_new',
            component: RavConsultantNew,
            meta: {
                auth: true,
            },
        },
        {
            path: ':id',
            name: 'rav_consultant_detail',
            component: RavConsultantDetail,
            meta: {
                auth: true,
            },
        },
    ],
};
