<template>
    <v-flex
        md12
        class="px-12 pb-12"
    >
        <PageHeader
            :breadcrumbs="breadcrumbs"
            :title="pageTitle"
            back
            back-to="mailsent"
        />
        <v-row>
            <v-col cols="12">
                <v-alert
                    v-for="(error, errorKey) in generalFormErrors"
                    :key="errorKey"
                    type="error"
                    v-text="error"
                />
                <MailSentForm
                    :data="data"
                    :raw-data="rawData"
                    :errors="formErrors"
                    :loading="loading"
                    :save-function="saveObject"
                    :discard-function="resetObject"
                />
            </v-col>
        </v-row>
    </v-flex>
</template>

<script>

import PageHeader from '@/components/common/PageHeader';
import MailSentForm from '@/components/mailsent/Form/MailSentForm';
import MailSentProxy from '@/proxies/MailSentProxy';
import MailSentMixin from '@/mixins/mailsent';
import { SuccessSnackbar, StandBy } from '@/app-events.js';

export default {
    name: 'MailSentDetail',
    mixins: [ MailSentMixin ],
    components: {
        PageHeader,
        MailSentForm,
    },
    created() {
        this.fetchData();
    },
    data() {
        return {
            loading: false,
            data: {},
            rawData: {},
        };
    },
    computed: {
        pageTitle() {
            let title = this.$t('mailsent.types.'+this.data.email_type);
            return title;
        },
        breadcrumbs() {
            return [
                {
                    text: this.$t('page.home.title'),
                    exact: true,
                    to: { name: 'home' },
                },
                {
                    text: 'Email',
                    exact: true,
                    to: { name: 'mailsent' },
                },
                {
                    text: this.data.emailType,
                    disabled: true,
                },
            ];
        },
    },
    methods: {
        fetchData() {
            this.loading = true;
            new MailSentProxy().find(this.$route.params.id).then(({ data }) => {
                this.data = this.prepareData(data.data);
                this.rawData = JSON.parse(JSON.stringify(this.data));
                this.loading = false;
            }).catch(({ data }) => {
                this.displayError(data);
            });

        },
        saveObject() {
            this.$eventBus.dispatch(new StandBy(true));
            new MailSentProxy().update(this.$route.params.id, this.prepareFormData(this.data)).then(({ data }) => {
                this.$eventBus.dispatch(new StandBy(false));
                this.rawData = JSON.parse(JSON.stringify(this.data));
                this.$eventBus.dispatch(new SuccessSnackbar(this.$t('weekname.actions.saved')));
                this.resetErrors();
            }).catch(({ data }) => {
                this.processErrors(data);
                this.$eventBus.dispatch(new StandBy(false));
            });
        },
    },
};
</script>
