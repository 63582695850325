<template>
    <div>
        <v-card flat>
            <v-data-table
                    class="pa-3"
                    :headers="headers"
                    :items="objects"
                    :search="search"
                    :custom-filter="filterParticipant"
                    :footer-props="defaultFooterProps"
            >
                <template v-slot:item.fullname="{ item }">
                    <v-btn
                            link
                            text
                            :to="{ name: 'registration_detail', params: { id: item.id }}"
                    >
                        {{ item.fullname }}
                    </v-btn>
                </template>
                <template v-slot:item.course="{ item }">
                    <span v-if="item.course">
                        {{ item.course.name }} ({{
                        formatDate(item.course.start_date)
                        }} - {{ formatDate(item.course.end_date) }})
                    </span>
                </template>
                <template v-slot:item.registration_time_slot="{ item }">
                    <span v-if="hasTimeSlot(item)">
                        <v-btn
                                small
                                icon
                                class="mr-1"
                                @click="downloadEvent(item)"
                        >
                            <v-icon>mdi-calendar-export</v-icon>
                        </v-btn>
                        {{ formatDateTime(item.registration_time_slot.start) }}
                    </span>
                    <span v-if="hadFirstInterview(item) && ! hasTimeSlot(item)">
                        <v-checkbox
                                :label="$t('first_interview.already_done')"
                                v-model="item.had_first_interview_via_phone"
                                disabled
                        />
                    </span>
                </template>
                <template v-slot:item.registration_status="{ item }">
                    <span v-if="item.registration_status">
                        {{ $t(`participant.registrationStatuses.${item.registration_status}`) }}
                    </span>
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-tooltip
                            v-if="item.email"
                            top
                    >
                        <template v-slot:activator="{ on }">
                            <v-btn
                                    icon
                                    :href="`mailto:${item.email}`"
                                    v-on="on"
                                    class="mx-1"
                            >
                                <v-icon>
                                    mdi-email-outline
                                </v-icon>
                            </v-btn>
                        </template>
                        <span>E-Mail</span>
                    </v-tooltip>
                </template>
                <template v-slot:item.misc="{ item }">
                    <v-tooltip left>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                    icon
                                    v-on="on"
                                    class="mx-1"
                                    :to="{ name: 'registration_detail', params: { id: item.id }}"
                            >
                                <v-icon>
                                    mdi-pencil
                                </v-icon>
                            </v-btn>
                        </template>
                        <span v-text="$t('general.edit')"/>
                    </v-tooltip>
                    <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                    v-bind="attrs"
                                    v-on="on"
                            >
                                mdi-dots-horizontal
                            </v-icon>
                        </template>
                        <v-list>
                            <v-list-item @click="initiateDeletion(item)">
                                <v-list-item-action>
                                    <v-icon>mdi-delete</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title v-text="$t('general.delete')"/>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </template>
            </v-data-table>
        </v-card>

        <ConfirmationDialog
                :confirm="deleteObject"
                :cancel="() => (deleteDialog = false)"
                :loading="deleting"
                :dialog="deleteDialog"
        />
    </div>
</template>

<script>

import ConfirmationDialog from '@/components/common/ConfirmationDialog';
import ParticipantProxy from '@/proxies/ParticipantProxy';
import FileDownloadMixin from '@/mixins/file-download';
import DatesMixin from '@/mixins/dates';
import {ObjectDeleted, StandBy, SuccessSnackbar} from '@/app-events.js';

export default {
    name: 'ParticipantsList',
    mixins: [FileDownloadMixin, DatesMixin],
    components: {
        ConfirmationDialog,
    },
    props: {
        search: {
            type: String,
            default: '',
        },
        objects: {
            type: Array,
            default: () => ([]),
        },
    },
    data() {
        return {
            deleteDialog: false,
            deleting: false,
            objectToDelete: null,
            email: {
                subject: '',
                content: '',
                attachments: [],
            },
            headers: [
                {
                    text: this.$t('participant.status'),
                    value: 'registration_status',
                },
                {
                    text: 'Interessent',
                    value: 'fullname',
                    align: 'start',
                },
                {
                    text: 'Wunschkurs', value: 'course',
                },
                {text: 'Coach', value: 'coach.fullname'},
                {text: 'Datum Termin mit Coach', value: 'registration_time_slot'},
                {
                    text: 'Aktionen',
                    value: 'actions',
                    sortable: false,
                    align: 'right',
                },
                {
                    text: '',
                    value: 'misc',
                    sortable: false,
                    align: 'end',
                },
            ],
        }
    },
    methods: {
        downloadEvent(participant) {
            console.log(participant);

            const [date, time] = participant.registration_time_slot.start.split(' ');
            const [year, month, day] = date.split('-');
            let [hour, min] = time.split(':');
            hour = parseInt(hour) - 1; // TZ
            let endHour = parseInt(hour);
            let endMin = parseInt(min) + 30;
            if (endMin >= 60) {
                endMin -= 60;
                endHour++;
            }
            let output = `BEGIN:VCALENDAR
VERSION:2.0
PRODID:olb-amm-db
BEGIN:VEVENT
UID:${participant.id}
DTSTART:${year}${month}${day}T${hour.toString().padStart(2, '0')}${min.toString().padStart(2, '0')}00Z
SEQUENCE:0
TRANSP:OPAQUE
DTEND:${year}${month}${day}T${endHour.toString().padStart(2, '0')}${endMin.toString().padStart(2, '0')}00Z
SUMMARY:Erstgespräch ${participant.fullname}
CLASS:PUBLIC
DTSTAMP:${year}${month}${day}T${hour.toString().padStart(2, '0')}${min.toString().padStart(2, '0')}00Z
END:VEVENT
END:VCALENDAR`;

            let element = document.createElement('a');
            element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(output));
            element.setAttribute('download', 'Calendar.ics');

            element.style.display = 'none';
            document.body.appendChild(element);

            element.click();

            document.body.removeChild(element);


        },
        filterParticipant(value, search, item) {
            if (search === '') {
                return true;
            }
            if (!value) {
                return false;
            }
            if (typeof value.start_date !== 'undefined') {
                return this.formatDate(value.start_date).includes(search) || value.name.toString().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(search.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""));
            }
            return value.toString().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(search.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""));
        },
        initiateDeletion(item) {
            this.objectToDelete = item;
            this.deleteDialog = true;
        },
        hadFirstInterview(item) {
            const hadFirstInterview = item.had_first_interview_via_phone || false;
            return hadFirstInterview;
        },
        hasTimeSlot(item) {
            if (typeof item.registration_time_slot !== 'undefined' && item.registration_time_slot !== null) {
                return true;
            }
            return false;
        },
        deleteObject() {
            this.deleting = true;
            new ParticipantProxy().delete(this.objectToDelete.id).then(({data}) => {
                this.$eventBus.dispatch(new ObjectDeleted({
                    object: this.objectToDelete,
                }));
            }).catch(({data}) => {
                this.displayError(data);
            }).finally(() => {
                this.deleteDialog = false;
                this.objectToDelete = null;
                this.deleting = false;
            });
        },
    },
};
</script>
