import Proxy from './Proxy';

class CalendarProxy extends Proxy {
    constructor(parameters = {}) {
        super('/calendar-static-entry', parameters);
    }

    all({ courseType = '', status = '' } = {}) {
        let courseTypeQuery = 'courseType=all';
        if (courseType !== '') {
            courseType = courseType.toUpperCase();
            courseTypeQuery = `&courseType=${courseType}`;
        }
        if (courseType !== '') {
            return this.submit('get', `/?courseType=${courseType}`);
        }
        return this.submit('get', `/?${courseTypeQuery}`);
    }

    export(date,courseType) {
        courseType = courseType.toUpperCase();
        return this.submit('post', `/export/${courseType}/${date}`, null, null, {}, 'blob');
    }
}

export default CalendarProxy;
