<template>
    <v-flex
        md12
        class="px-12 pb-12"
    >
        <PageHeader
            :breadcrumbs="breadcrumbs"
            :title="$t('page.course.title')"
            button
            :button-text="$t('page.course.new')"
            button-to="course_new"
            search
            :search-label="$t('page.course.search')"
            @search-input="searchInputListener"
        />
        <v-row>
            <ActionBar
                new-button
                new-to="course_new"
                :new-label="$t('page.course.new')"
                fetch
                :fetch-function="fetchData"
            >
                <template #extra>
                    <span>
                        <CourseFilter
                            :filters="filters"
                            :course-types="courseTypes"
                            :course-languages="courseLanguages"
                            @filter-change="filters = $event"
                        />
                        <v-row class="text-xl-body-1 mt-3">
                            <v-col cols="12">
                                {{ $t('course.list.all') }}
                            </v-col>
                        </v-row>
                    </span>
                </template>
            </ActionBar>
            <v-col cols="12">
                <v-progress-linear
                    v-if="loading"
                    indeterminate
                    color="primary"
                />
                <v-skeleton-loader
                    v-if="loading"
                    class="mx-auto"
                    type="table-tbody"
                />
                <CourseList
                    v-if="!loading"
                    :objects="objects"
                    :search="textSearch"
                />
            </v-col>
        </v-row>
    </v-flex>
</template>

<script>

import ActionBar from '@/components/common/ActionBar';
import PageHeader from '@/components/common/PageHeader';
import CourseList from '@/components/courses/CourseList';
import CourseProxy from '@/proxies/CourseProxy';

import { SuccessSnackbar, ObjectDeleted } from '@/app-events.js';
import CourseFilter from "./CourseFilter.vue";

export default {
    name: 'CourseIndex',
    components: {
        CourseFilter,
        ActionBar,
        PageHeader,
        CourseList,
    },
    data() {
        return {
            breadcrumbs: [
                {
                    text: this.$t('page.home.title'),
                    exact: true,
                    to: { name: 'home' },
                },
                {
                    text: this.$t('page.course.title'),
                    disabled: true,
                },
            ],
            courseTypes: [
                {
                    value: 'OL',
                    text: `Kursart ${this.$t('course.types.OL')}`,
                },
                {
                    value: 'PS_SOCIAL',
                    text: `Kursart ${this.$t('course.types.PS_SOCIAL')}`,
                },
                {
                    value: 'PS_STORY',
                    text: `Kursart ${this.$t('course.types.PS_STORY')}`,
                },
                {
                    value: 'PS_VIDEO',
                    text: `Kursart ${this.$t('course.types.PS_VIDEO')}`,
                },
                {
                    value: 'APPLICATION_AGENCY',
                    text: `Kursart ${this.$t('course.types.APPLICATION_AGENCY')}`,
                },
                {
                    value: 'EASY',
                    text: `Kursart ${this.$t('course.types.EASY')}`,
                },
            ],
            courseLanguages: [
                {
                    value: 'de',
                    text: 'Deutsch',
                },
                {
                    value: 'en',
                    text: 'Englisch',
                },
            ],
            textSearch: '',
            objects: [],
            loading: true,
            filters: {
                startDate: `${new Date().getFullYear()}-01-01`,
                endDate: null,
                courseType: null,
                language: null,
                fullyBooked: null,
            },
        };
    },
    created() {
        this.fetchData();
        this.$eventBus.listen(ObjectDeleted, (event) => {
            this.$eventBus.dispatch(new SuccessSnackbar(this.$t('course.actions.deleted')));
            for (let i = 0; i < this.objects.length; i++) {
                const course = this.objects[ i ];
                if (course.id === event.object.id) {
                    this.objects.splice(i, 1);
                    break;
                }
            }
        });
    },

    watch: {
        filters: {
            handler() {
                this.fetchData();
            },
            deep: true
        }
    },
    methods: {
        fetchData() {
            this.loading = true;
            const courseProxy = new CourseProxy();

            if (Object.keys(this.filters).length === 0) {
                this.filters.startDate = this.startDate;
            }

            let params = new URLSearchParams();
            for (let key in this.filters) {
                params.append(key, this.filters[ key ]);
            }

            for (let key in this.filters) {
                if (this.filters.hasOwnProperty(key)) {
                    params[ key ] = this.filters[ key ];
                }
            }

            if (Object.keys(params).length > 0) {
                courseProxy.setParameters(params);
            }

            courseProxy.all().then(({ data }) => {
                this.objects = data.data;
                this.loading = false;
            }).catch(({ data }) => {
                this.displayError(data);
            });
        },
        searchInputListener(value) {
            this.textSearch = value;
        },
    },
};
</script>
