export default {
    data: () => ({
        applicationLocales: [ 'de' ],
    }),
    methods: {
        getLocales() {
            return this.applicationLocales;
        },
    },
};
