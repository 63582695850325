<template>
    <div>
        <v-card flat>
            <v-data-table
                class="pa-3"
                :headers="headers"
                :items="objects"
                :search="search"
                disable-pagination
                :hide-default-footer="true"
            >
                <template v-slot:item.subjects="{ item }">
                    <div v-for="subject in item.subjects">
                        {{ 'de' === subject.language ? String.fromCodePoint(0x1F1E9, 0x1F1EA) : ('en' === subject.language ? String.fromCodePoint(0x1F1EC, 0x1F1E7) : subject.language) }}: {{ subject.subject }}
                        <v-tooltip right>
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    icon
                                    v-on="on"
                                    class="mx-1"
                                    :to="{ name: 'email_master_template_detail', params: { type: item.type, language: subject.language }}"
                                >
                                    <v-icon>mdi-pencil</v-icon>
                                </v-btn>
                            </template>
                            <span v-text="$t('general.edit')" />
                        </v-tooltip>
                    </div>
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>

<script>

export default {
    name: 'EmailMasterTemplate',
    props: {
        search: {
            type: String,
            default: '',
        },
        objects: {
            type: Array,
            default: () => ([]),
        },
    },
    data() {
        return {
            deleting: false,
            objectToDelete: null,
            headers: [
                {
                    text: this.$t('email_master_template.name'),
                    value: 'name',
                },
                {
                    text: this.$t('email_master_template.subject'),
                    value: 'subjects',
                    align: 'start',
                },
            ],
        }
    },
};
</script>
