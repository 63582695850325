<template>
    <v-text-field
        flat
        solo
        rounded
        dense
        clearable
        background-color="white"
        prepend-inner-icon="mdi-magnify"
        :label="label"
        :class="`${inputClass} d-print-none`"
        :value="value"
        @change="$emit('input', $event)"
        @keyup="emitKeyup($event.target.value)"
    />
</template>

<script>

export default {
    name: 'SearchInput',
    props: {
        label: {
            type: String,
            default: '',
        },
        inputClass: {
            type: String,
            default: '',
        },
        value: {
            type: String,
            default: '',
        },
        eventTrigger: {
            type: String,
            default: '',
        },
    },
    methods: {
        emitKeyup(val) {
            if (this.eventTrigger != 'keyup') {
                return;
            }
            this.$emit('input', val);
        },
    },
};
</script>
