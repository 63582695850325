export default {
    methods: {
        prepareFormData(data) {
            return {
                presence: {
                    morningPresence: data.morning_presence,
                    morningRemark: data.morning_remark,
                    afternoonPresence: data.afternoon_presence,
                    afternoonRemark: data.afternoon_remark,
                },
            };
        },
    },
};
