import Proxy from './Proxy';

class WeekProxy extends Proxy {
    constructor(parameters = {}) {
        super('/week', parameters);
    }

    all({ courseType = '' } = {}) {
        if (courseType === '') {
            return this.submit('get', `/?courseType=ALL`);
        }
        let courseTypeQuery = 'courseType=ol';
        if (courseType !== '') {
            courseType = courseType.toUpperCase();
            courseTypeQuery = `&courseType=${courseType}`;
        }
        return this.submit('get', `/?courseType=${courseType}`);
    }
}

export default WeekProxy;
