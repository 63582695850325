export default {
    methods: {
        resetObject() {
            this.data = JSON.parse(JSON.stringify(this.rawData));
            this.resetErrors();
        },
        prepareData(data) {
            return data;
        },
        prepareFormData(data) {
            return {
                feedback: {
                    participant: data.participant.id,
                    content: data.content,
                },
            };
        },
    },
};
