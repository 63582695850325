<template>
    <div>
        <v-card flat>
            <v-data-table
                class="pa-3"
                :headers="headers"
                :items="objects"
                :search="search"
                :show-select="selection"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                v-model="selectedRows"
                :footer-props="defaultFooterProps"
                :items-per-page="getPrefenceValue('course.itemsPerPage', 25)"
                @update:items-per-page="val => setPrefenceValue('course.itemsPerPage', val)"
                :page="currentPage"
                @update:page="page => currentPage = page"
            >
                <template #item.name="{ item }">
                    <v-btn
                        link
                        text
                        :to="{ name: 'course_detail', params: { id: item.id }}"
                    >
                        {{ item.name }}
                    </v-btn>
                </template>
                <template #item.type="{ item }">
                    {{ item.type ? $t(`course.types.${item.type}`) : '' }}
                </template>
                <template #item.language="{ item }">
                    {{ 'en' === item.language ? 'Englisch' : 'Deutsch' }}
                </template>
                <template #item.fully_booked="{ item }">
                    {{ item.fully_booked ? 'Ja' : 'Nein' }}
                </template>
                <template #item.start_date="{ item }">
                    {{ formatDate(item.start_date) }}
                </template>
                <template #item.end_date="{ item }">
                    {{ formatDate(item.end_date) }}
                </template>
                <template #item.actions="{ item }">
                    <v-tooltip left>
                        <template #activator="{ on }">
                            <v-btn
                                icon
                                v-on="on"
                                class="mx-1"
                                :to="{ name: 'course_detail', params: { id: item.id }}"
                            >
                                <v-icon>
                                    mdi-pencil
                                </v-icon>
                            </v-btn>
                        </template>
                        <span v-text="$t('general.edit')" />
                    </v-tooltip>
                    <v-menu offset-y>
                        <template #activator="{ on, attrs }">
                            <v-icon
                                v-bind="attrs"
                                v-on="on"
                            >
                                mdi-dots-horizontal
                            </v-icon>
                        </template>
                        <v-list>
                            <v-list-item @click="initiateDeletion(item)">
                                <v-list-item-action>
                                    <v-icon>mdi-delete</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title v-text="$t('general.delete')" />
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </template>
            </v-data-table>
        </v-card>
        <ConfirmationDialog
            :confirm="deleteObject"
            :cancel="() => (deleteDialog = false)"
            :loading="deleting"
            :dialog="deleteDialog"
            title="Möchtest du wirklich den Kurs löschen?"
        />
    </div>
</template>

<script>

import ConfirmationDialog from '@/components/common/ConfirmationDialog';
import CourseProxy from '@/proxies/CourseProxy';
import DatesMixin from '@/mixins/dates';
import { ObjectDeleted, StandBy } from '@/app-events.js';

export default {
    name: 'CourseList',
    mixins: [ DatesMixin ],
    components: {
        ConfirmationDialog,
    },
    props: {
        search: {
            type: String,
            default: '',
        },
        selection: {
            type: Boolean,
            default: false,
        },
        objects: {
            type: Array,
            default: () => ([]),
        },
    },
    data() {
        return {
            deleteDialog: false,
            deleting: false,
            objectToDelete: null,
            selectedRows: [],
            headers: [
                {
                    text: this.$t('course.name'),
                    value: 'name',
                    align: 'start',
                },
                {
                    text: this.$t('course.language'),
                    value: 'language',
                },
                {
                    text: this.$t('course.type'),
                    value: 'type',
                },
                {
                    text: this.$t('course.start_date'),
                    value: 'start_date',
                },
                {
                    text: this.$t('course.end_date'),
                    value: 'end_date',
                },
                {
                    text: this.$t('course.fully_booked'),
                    value: 'fully_booked',
                },
                {
                    text: this.$t('course.execution_number'),
                    value: 'execution_number',
                },
                {
                    text: 'Aktionen',
                    value: 'actions',
                    sortable: false ,
                    align: 'right',
                },
            ],
            sortBy: 'start_date',
            sortDesc: false,
        }
    },
    methods: {
        initiateDeletion(item) {
            this.objectToDelete = item;
            this.deleteDialog = true;
        },
        deleteObject() {
            this.deleting = true;
            new CourseProxy().delete(this.objectToDelete.id).then(({ data }) => {
                this.$eventBus.dispatch(new ObjectDeleted({
                    object: this.objectToDelete,
                }));
            }).catch(({ data }) => {
                this.displayError(data);
            }).finally(() => {
                this.deleteDialog = false;
                this.objectToDelete = null;
                this.deleting = false;
            });
        },
    },
    watch: {
        selectedRows(val) {
            this.$emit('selection-changed', val);
        },
    },
};
</script>
