<template>
    <v-row no-gutters>
        <v-col
            lg="6"
            offset-lg="3"
            md="12"
        >
            <v-progress-linear
                v-if="loading"
                indeterminate
                color="primary"
            />
            <v-skeleton-loader
                v-if="loading"
                class="mx-auto"
                type="card"
            />
            <v-skeleton-loader
                v-if="loading"
                class="mx-auto"
                type="card"
            />
            <v-skeleton-loader
                v-if="loading"
                class="mx-auto"
                type="card"
            />
            <div
                v-if="!loading"
                v-cloak
                @drop.prevent="addDropFile"
                @dragover.prevent
            >
                <v-alert
                    v-for="(error, errorKey) in generalFormErrors"
                    :key="errorKey"
                    type="error"
                    v-text="error"
                />
                <v-file-input
                    v-model="files"
                    color="primary"
                    counter
                    :label="$t('page.participant_upload.upload')"
                    multiple
                    :placeholder="$t('page.participant_upload.select')"
                    prepend-inner-icon="mdi-paperclip"
                    prepend-icon=""
                    outlined
                    :show-size="1000"
                >
                    <template #selection="{ index, text }">
                        <v-chip
                            v-if="index < 2"
                            color="primary"
                            dark
                            label
                            small
                        >
                            {{ text }}
                        </v-chip>

                        <span
                            v-else-if="index === 2"
                            class="overline grey--text text--darken-3 mx-2"
                        >
                            +{{ files.length - 2 }} {{ $t('general.files') }}
                        </span>
                    </template>
                </v-file-input>
                <v-text-field
                    type="text"
                    v-model="link"
                    placeholder="https://"
                    :label="$t('participant_upload.link')"
                />
                <v-col
                    v-if="files.length > 0 || link"
                    class="text-right"
                >
                    <v-btn
                        color="primary"
                        @click="uploadFiles"
                    >
                        {{ $t('page.participant_upload.button') }}
                    </v-btn>
                </v-col>

                <v-card
                    flat
                    class="mx-auto mb-6"
                >
                    <v-list
                        two-line
                        subheader
                    >
                        <v-subheader
                            v-if="objects.length > 0"
                            inset
                        >
                            {{ $t('general.files') }}
                        </v-subheader>
                        <v-subheader
                            v-else
                            inset
                        >
                            {{ $t('participant_upload.no_files') }}
                        </v-subheader>
                        <v-list-item
                            v-for="file in objects"
                            :key="file.id"
                        >
                            <v-list-item-avatar>
                                <v-icon
                                    class="primary white--text"
                                    v-text="mimeTypeIcon(file)"
                                />
                            </v-list-item-avatar>

                            <v-list-item-content>
                                <v-list-item-title
                                    v-if="file.filename"
                                    v-text="file.filename"
                                />
                                <v-list-item-subtitle
                                    v-if="file.link && file.link !== 'null'"
                                    v-text="file.link"
                                />
                                <v-list-item-subtitle
                                    v-if="file.filename"
                                    v-text="formatDate(file.created)"
                                />
                            </v-list-item-content>

                            <v-list-item-action v-if="file.email_sent">
                                <v-tooltip top>
                                    <template #activator="{ on }">
                                        <v-icon
                                            class="mr-2"
                                            color="green"
                                            v-on="on"
                                        >
                                            mdi-email-send
                                        </v-icon>
                                    </template>
                                    <span>E-Mail gesendet</span>
                                </v-tooltip>
                            </v-list-item-action>
                            <v-list-item-action v-if="isViewable(file)">
                                <v-tooltip top>
                                    <template #activator="{ on, attrs }">
                                        <v-btn
                                            icon
                                            v-on="on"
                                            v-bind="attrs"
                                            :href="`${apiRoot}assets/public/participant-upload/${file.id}/0`"
                                            target="blank"
                                        >
                                            <v-icon>
                                                mdi-eye
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    <span v-text="$t('general.open')" />
                                </v-tooltip>
                            </v-list-item-action>
                            <v-list-item-action v-if="file.filename">
                                <v-tooltip top>
                                    <template #activator="{ on, attrs }">
                                        <v-btn
                                            icon
                                            v-on="on"
                                            v-bind="attrs"
                                            :href="`${apiRoot}assets/public/participant-upload/${file.id}/1`"
                                        >
                                            <v-icon>
                                                mdi-download
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    <span v-text="$t('general.download')" />
                                </v-tooltip>
                            </v-list-item-action>
                            <v-list-item-action v-if="file.link && file.link !== 'null'">
                                <v-tooltip top>
                                    <template #activator="{ on, attrs }">
                                        <v-btn
                                            icon
                                            v-on="on"
                                            v-bind="attrs"
                                            target="_blank"
                                            :href="fileLink(file.link)"
                                        >
                                            <v-icon>
                                                mdi-open-in-new
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    <span v-text="$t('general.open')" />
                                </v-tooltip>
                            </v-list-item-action>
                            <v-list-item-action>
                                <v-menu offset-y>
                                    <template #activator="{ on, attrs }">
                                        <v-icon
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            mdi-dots-horizontal
                                        </v-icon>
                                    </template>
                                    <v-list>
                                        <v-list-item
                                            v-if="file.filename && (participant.rav_consultant.id || participant.rav_consultant_email_freetext)"
                                            @click="doSend(file)"
                                        >
                                            <v-list-item-action>
                                                <v-icon>mdi-email</v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title v-text="$t('learn_goal_control.actions.rav_send')" />
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item
                                            v-if="file.filename && file.filename.includes('Lernkontrakt')"
                                            @click="emailLearnContract(file)"
                                        >
                                            <v-list-item-action>
                                                <v-icon>mdi-email</v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title v-text="$t('participant.downloads.amm_learn_contract_send')" />
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item
                                            v-if="file.filename && file.filename.includes('Bescheinigungen')"
                                            @click="emailBescheinigung(file)"
                                        >
                                            <v-list-item-action>
                                                <v-icon>mdi-email</v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title v-text="$t('participant.downloads.amm_bescheinigungen_send')" />
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item
                                            v-if="file.filename && (file.filename.includes('Kursausweis') || file.filename.includes('Course certificate'))"
                                            @click="emailCourseCertificate(file)"
                                        >
                                            <v-list-item-action>
                                                <v-icon>mdi-email</v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title v-text="$t('participant.downloads.amm_course_identity_send')" />
                                            </v-list-item-content>
                                        </v-list-item>
                                        <!--<v-list-item
                                            v-if="file.filename && file.filename.includes('Schlussbericht')"
                                            @click="emailFinalReport(file)"
                                        >
                                            <v-list-item-action>
                                                <v-icon>mdi-email</v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title v-text="$t('participant.downloads.amm_final_report_send')" />
                                            </v-list-item-content>
                                        </v-list-item>-->
                                        <v-list-item @click="initiateDeletion(file)">
                                            <v-list-item-action>
                                                <v-icon>mdi-delete</v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title v-text="$t('general.delete')" />
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list>
                </v-card>
            </div>
        </v-col>
        <ConfirmationDialog
            :confirm="deleteObject"
            :cancel="() => (deleteDialog = false)"
            :loading="deleting"
            :dialog="deleteDialog"
        />
        <UnsavedForm
            :unsaved-form="edit !== false"
            :errors="formErrors"
            :on-save="saveFunction"
            :on-cancel="cancelObjectChanges"
        />
        <EmailDialog
            attachments
            :confirm="emailObject"
            :cancel="() => (emailDialog = false)"
            :loading="sending"
            :dialog="emailDialog"
            :data="email"
            :title="$t('learn_goal_control.actions.rav_send')"
        />
    </v-row>
</template>

<script>

import UnsavedForm from '@/components/common/UnsavedForm';
import ConfirmationDialog from '@/components/common/ConfirmationDialog';
import EmailDialog from '@/components/common/EmailDialog';
import ParticipantProxy from '@/proxies/ParticipantProxy';
import ParticipantUploadProxy from '@/proxies/ParticipantUploadProxy';
import DatesMixin from '@/mixins/dates';
import ParticipantUploadMixin from '@/mixins/participantUpload';
import { SuccessSnackbar, StandBy } from '@/app-events.js';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
    name: 'ParticipantUploads',
    mixins: [
        DatesMixin,
        ParticipantUploadMixin,
    ],
    components: {
        ConfirmationDialog,
        UnsavedForm,
        EmailDialog,
    },
    props: {
        type: {
            type: String,
            default: '',
        },
        participant: {
            type: Object,
            default: () => {
            },
        },
    },
    data() {
        return {
            editor: ClassicEditor,
            loading: false,
            data: {},
            files: [],
            objects: [],
            deleteDialog: false,
            deleting: false,
            objectToDelete: null,
            link: null,
            apiRoot: process.env.VUE_APP_API_ROOT,
            edit: false,
            emailDialog: false,
            sending: false,
            objectToEmail: null,
            email: {
                subject: '',
                content: '',
                attachments: [],
            }
        }
    },
    created() {
        this.fetchData();
    },
    methods: {
        addDropFile(e) {
            // https://github.com/vuetifyjs/vuetify/issues/7836#issuecomment-582841222
            this.files = Array.from(e.dataTransfer.files);
        },
        fetchData() {
            this.loading = true;
            new ParticipantUploadProxy().all({
                participantId: this.participant.id,
                type: this.type,
            }).then(({ data }) => {
                this.objects = data.data;
                this.loading = false;
            }).catch(({ data }) => {
                this.displayError(data);
            });
        },
        mimeTypeIcon(file) {
            if (!file.filename && file.link) {
                return 'mdi-link';
            }
            if (!file.filename) {
                return 'mdi-image-broken-variant';
            }
            if (file.mime_type.includes('image')) {
                return 'mdi-image';
            }
            if (file.mime_type.includes('pdf')) {
                return 'mdi-pdf-box';
            }
            if (file.mime_type.includes('word')) {
                return 'mdi-file-word-box';
            }
            if (file.mime_type.includes('excel') || file.mime_type.includes('sheet')) {
                return 'mdi-file-excel-box';
            }
            if (file.mime_type.includes('zip') || file.mime_type.includes('rar')) {
                return 'mdi-zip-box';
            }
            return 'mdi-text-box';
        },
        isViewable(file) {
            if (!file.filename) {
                return false;
            }
            if (file.mime_type.includes('image')) {
                return true;
            }
            if (file.mime_type.includes('pdf')) {
                return true;
            }
            if (file.mime_type.includes('text/plain')) {
                return true;
            }
            return false;
        },
        uploadFiles() {
            let formData = new FormData();
            for (let file of this.files) {
                formData.append(file.name, file, file.name);
            }
            formData.append('type', this.type);
            formData.append('link', this.link);
            new ParticipantProxy().upload(this.participant.id, formData).then(({ data }) => {
                this.files = [];
                this.fetchData();
                this.$eventBus.dispatch(new SuccessSnackbar(this.$t('participant_upload.actions.created')));
                this.link = null;
            }).catch(({ data }) => {
                this.displayError(data);
            });
        },
        doEdit(item) {
            this.data = JSON.parse(JSON.stringify(item));
            this.edit = item.id;
        },
        fileLink(link) {
            if (link.includes('http://') || link.includes('https://')) {
                return link;
            }

            return `https://${link}`;
        },
        saveFunction() {
            this.$eventBus.dispatch(new StandBy(true));
            new ParticipantUploadProxy().update(this.data.id, this.prepareFormData(this.data)).then(({ data }) => {
                this.$eventBus.dispatch(new StandBy(false));
                this.fetchData();
                this.$eventBus.dispatch(new SuccessSnackbar(this.$t('participant_upload.actions.saved')));
                this.edit = false;
                this.resetErrors();
            }).catch(({ data }) => {
                this.processErrors(data);
                this.$eventBus.dispatch(new StandBy(false));
            });
        },
        cancelObjectChanges() {
            this.edit = false;
        },
        initiateDeletion(item) {
            this.objectToDelete = item;
            this.deleteDialog = true;
        },
        deleteObject() {
            this.deleting = true;
            new ParticipantUploadProxy().delete(this.objectToDelete.id).then(({ data }) => {
                this.$eventBus.dispatch(new SuccessSnackbar(this.$t('participant_upload.actions.deleted')));
                for (let i = 0; i < this.objects.length; i++) {
                    const file = this.objects[ i ];
                    if (file.id === this.objectToDelete.id) {
                        this.objects.splice(i, 1);
                        break;
                    }
                }
            }).catch(({ data }) => {
                this.displayError(data);
            }).finally(() => {
                this.deleteDialog = false;
                this.objectToDelete = null;
                this.deleting = false;
            });
        },
        doSend(item) {
            this.objectToEmail = item;

            new ParticipantUploadProxy().email(this.objectToEmail.id).then(({ data }) => {
                this.email = {
                    subject: `Schlussbericht ${this.participant.firstname} ${this.participant.lastname}`,
                    content: data.data.content,
                    attachments: [],
                };
                this.emailDialog = true;
            }).catch(({ data }) => {
                this.displayError(data);
            });
        },
        emailObject() {
            this.sending = true;
            let formData = new FormData();
            /*if (typeof this.file !== 'undefined') {
          formData.append('email[attachment]', this.file, this.file.name);
      }*/
            for (let file of this.email.attachments) {
                formData.append(file.name, file, file.name);
            }
            formData.append('subject', this.email.subject);
            formData.append('content', this.email.content);
            new ParticipantUploadProxy().send(this.objectToEmail.id, formData).then(({ data }) => {
                this.$eventBus.dispatch(new SuccessSnackbar(this.$t('learn_goal_control.actions.sent')));
                this.fetchData();
            }).catch(({ data }) => {
                this.displayError(data);
            }).finally(() => {
                this.emailDialog = false;
                this.objectToEmail = null;
                this.sending = false;
            });
        },
        emailLearnContract(item) {
            this.sending = true;
            new ParticipantUploadProxy().sendLearnContract(item.id, item).then(({ data }) => {
                this.$eventBus.dispatch(new SuccessSnackbar(this.$t('learn_goal_control.actions.sent')));
                this.fetchData();
            }).catch(({ data }) => {
                this.displayError(data);
            }).finally(() => {
                this.emailDialog = false;
                this.objectToEmail = null;
                this.sending = false;
            });
        },
        emailBescheinigung(item) {
            this.sending = true;
            new ParticipantUploadProxy().sendBescheinigung(item.id, item).then(({ data }) => {
                this.$eventBus.dispatch(new SuccessSnackbar(this.$t('learn_goal_control.actions.sent')));
                this.fetchData();
            }).catch(({ data }) => {
                this.displayError(data);
            }).finally(() => {
                this.emailDialog = false;
                this.objectToEmail = null;
                this.sending = false;
            });
        },
        emailCourseCertificate(item) {
            this.sending = true;
            new ParticipantUploadProxy().sendCourseCertificate(item.id, item).then(({ data }) => {
                this.$eventBus.dispatch(new SuccessSnackbar(this.$t('general.email_sent')));
                this.fetchData();
            }).catch(({ data }) => {
                this.displayError(data);
            }).finally(() => {
                this.emailDialog = false;
                this.objectToEmail = null;
                this.sending = false;
            });
        },
        emailFinalReport(item) {
            this.sending = true;
            new ParticipantUploadProxy().sendFinalReport(item.id, item).then(({ data }) => {
                this.$eventBus.dispatch(new SuccessSnackbar(this.$t('learn_goal_control.actions.sent')));
                this.fetchData();
            }).catch(({ data }) => {
                this.displayError(data);
            }).finally(() => {
                this.emailDialog = false;
                this.objectToEmail = null;
                this.sending = false;
            });
        },
    },
};
</script>
