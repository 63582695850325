<template>
    <div>
        <v-card flat>
            <v-data-table
                class="pa-3"
                :headers="headers"
                :items="objects"
                :search="search"
                :show-select="selection"
                v-model="selectedRows"
                :footer-props="defaultFooterProps"
                :items-per-page="getPrefenceValue('general_setting.itemsPerPage', 25)"
                @update:items-per-page="val => setPrefenceValue('general_setting.itemsPerPage', val)"
                :page="currentPage"
                @update:page="page => currentPage = page"
            >
                <template v-slot:item.name="{ item }">
                    <v-btn
                        link
                        text
                        :to="{ name: 'general_setting_detail', params: { id: item.id }}"
                    >
                        {{ item.name }}
                    </v-btn>
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-tooltip left>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                icon
                                v-on="on"
                                class="mx-1"
                                :to="{ name: 'general_setting_detail', params: { id: item.id }}"
                            >
                                <v-icon>
                                    mdi-pencil
                                </v-icon>
                            </v-btn>
                        </template>
                        <span v-text="$t('general.edit')" />
                    </v-tooltip>
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>

<script>

import GeneralSettingProxy from '@/proxies/GeneralSettingProxy';
import { StandBy } from '@/app-events.js';

export default {
    name: 'GeneralSettingList',
    props: {
        search: {
            type: String,
            default: '',
        },
        selection: {
            type: Boolean,
            default: false,
        },
        objects: {
            type: Array,
            default: () => ([]),
        },
    },
    data() {
        return {
            selectedRows: [],
            headers: [
                { 
                    text: this.$t('general_setting.name'),
                    value: 'name',
                    align: 'start',
                },
                { 
                    text: this.$t('general_setting.value'),
                    value: 'value',
                },
                { 
                    text: 'Aktionen',
                    value: 'actions',
                    sortable: false ,
                    align: 'right',
                },
            ],
        }
    },
    watch: {
        selectedRows(val) {
            this.$emit('selection-changed', val);
        }
    },
};
</script>
