<template>
    <div>
        <v-card flat>
            <v-data-table
                class="pa-3"
                :headers="headers"
                :items="ravConsultants"
                :search="search"
                :show-select="selection"
                v-model="selectedRows"
                :footer-props="defaultFooterProps"
                :items-per-page="getPrefenceValue('rav_consultant.itemsPerPage', 25)"
                @update:items-per-page="val => setPrefenceValue('rav_consultant.itemsPerPage', val)"
                :page="currentPage"
                @update:page="page => currentPage = page"
            >
                <template v-slot:item.fullname="{ item }">
                    <v-btn
                        link
                        text
                        :to="{ name: 'rav_consultant_detail', params: { id: item.id }}"
                    >
                        {{ item.fullname }}
                    </v-btn>
                </template>
                <template v-slot:item.email="{ item }">
                    <v-tooltip
                        v-if="item.email"
                        right
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                icon
                                v-on="on"
                                v-bind="attrs"
                                class="mx-1"
                                @click="copyText(item.email)"
                            >
                                <v-icon>
                                    mdi-content-copy
                                </v-icon>
                            </v-btn>
                        </template>
                        <span v-text="$t('general.copy')" />
                    </v-tooltip>
                    {{ item.email }}
                </template>
                <template v-slot:item.active="{ item }">
                    <v-tooltip
                        v-if="item.active"
                        right
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                color="green"
                                v-bind="attrs"
                                v-on="on"
                            >
                                mdi-account-check
                            </v-icon>
                        </template>
                        <span v-text="$t('rav_consultant.active')" />
                    </v-tooltip>
                    <v-tooltip
                        v-else
                        right
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                color="red"
                                v-bind="attrs"
                                v-on="on"
                            >
                                mdi-account-cancel
                            </v-icon>
                        </template>
                        <span v-text="$t('rav_consultant.disabled')" />
                    </v-tooltip>
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-tooltip left>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                :disabled="!item.email"
                                icon
                                :href="`mailto:${item.email}`"
                                v-on="on"
                            >
                                <v-icon>
                                    mdi-email-outline
                                </v-icon>
                            </v-btn>
                        </template>
                        <span v-text="$t('page.rav_consultant.send_email')" />
                    </v-tooltip>
                    <v-tooltip left>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                icon
                                v-on="on"
                                class="mx-1"
                                :to="{ name: 'rav_consultant_detail', params: { id: item.id }}"
                            >
                                <v-icon>
                                    mdi-pencil
                                </v-icon>
                            </v-btn>
                        </template>
                        <span v-text="$t('general.edit')" />
                    </v-tooltip>
                    <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                v-bind="attrs"
                                v-on="on"
                            >
                                mdi-dots-horizontal
                            </v-icon>
                        </template>
                        <v-list>
                            <v-list-item @click="initiateDeletion(item)">
                                <v-list-item-action>
                                    <v-icon>mdi-delete</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title v-text="$t('general.delete')" />
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item
                                v-if="item.active"
                                @click="deactivateRavConsultant(item)"
                            >
                                <v-list-item-action>
                                    <v-icon>mdi-account-cancel</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title v-text="$t('rav_consultant.actions.deactivate')" />
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item
                                v-if="!item.active"
                                @click="activateRavConsultant(item)"
                            >
                                <v-list-item-action>
                                    <v-icon>mdi-account-check</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title v-text="$t('rav_consultant.actions.activate')" />
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </template>
            </v-data-table>
        </v-card>
        <ConfirmationDialog
            :confirm="deleteObject"
            :cancel="() => (deleteDialog = false)"
            :loading="deleting"
            :dialog="deleteDialog"
        />
    </div>
</template>

<script>

import ConfirmationDialog from '@/components/common/ConfirmationDialog';
import RavConsultantProxy from '@/proxies/RavConsultantProxy';
import { ObjectDeleted, StandBy, SuccessSnackbar } from '@/app-events.js';

export default {
    name: 'RavConsultantList',
    components: {
        ConfirmationDialog,
    },
    props: {
        search: {
            type: String,
            default: '',
        },
        selection: {
            type: Boolean,
            default: false,
        },
        ravId: {
            type: String,
            default: '',
        },
        ravConsultants: {
            type: Array,
            default: () => ([]),
        },
    },
    data() {
        return {
            deleteDialog: false,
            deleting: false,
            objectToDelete: null,
            selectedRows: [],
            headers: [
                { 
                    text: this.$t('rav_consultant.fullname'),
                    value: 'fullname',
                    align: 'start',
                },
                {
                    text: this.$t('rav.name'),
                    value: 'rav_location.name',
                    align: this.ravId ? ' d-none' : '',
                },
                {
                    text: this.$t('rav_consultant.phone'),
                    value: 'phone',
                },
                {
                    text: this.$t('rav_consultant.email'),
                    value: 'email',
                },
                {
                    text: this.$t('rav_consultant.memo'),
                    value: 'memo',
                    align: this.ravId ? '' : ' d-none',
                },
                {
                    text: this.$t('rav_consultant.active'),
                    value: 'active',
                },
                { 
                    text: 'Aktionen',
                    value: 'actions',
                    sortable: false ,
                    align: 'right',
                },
            ],
        }
    },
    methods: {
        initiateDeletion(item) {
            this.objectToDelete = item;
            this.deleteDialog = true;
        },
        deleteObject() {
            this.deleting = true;
            new RavConsultantProxy().delete(this.objectToDelete.id).then(({ data }) => {
                this.$eventBus.dispatch(new ObjectDeleted({
                    object: this.objectToDelete,
                }));
            }).catch(({ data }) => {
                this.displayError(data);
            }).finally(() => {
                this.deleteDialog = false;
                this.objectToDelete = null;
                this.deleting = false;
            });
        },
        deactivateRavConsultant(ravConsultant) {
            this.$eventBus.dispatch(new StandBy(true));
            new RavConsultantProxy().deactivate(ravConsultant.id).then(({ data }) => {
                ravConsultant.active = false;
                this.$eventBus.dispatch(new SuccessSnackbar(this.$t('rav_consultant.deactivated')));
            }).catch(({ data }) => {
                this.genericErrorSnackbar();
            }).finally(() => {
                this.$eventBus.dispatch(new StandBy(false));
            });
        },
        activateRavConsultant(ravConsultant) {
            this.$eventBus.dispatch(new StandBy(true));
            new RavConsultantProxy().activate(ravConsultant.id).then(({ data }) => {
                ravConsultant.active = true;
                this.$eventBus.dispatch(new SuccessSnackbar(this.$t('rav_consultant.activated')));
            }).catch(({ data }) => {
                this.genericErrorSnackbar();
            }).finally(() => {
                this.$eventBus.dispatch(new StandBy(false));
            });
        }
    },
    watch: {
        selectedRows(val) {
            this.$emit('selection-changed', val);
        },
    },
};
</script>
