import Proxy from './Proxy';

class PresenceListProxy extends Proxy {
    constructor(parameters = {}) {
        super('/presence', parameters);
    }

    all({ date = null, participantId = null } = {}) {
        if (date) {
            return this.submit('get', `/?date=${date}`);    
        }
        if (participantId) {
            return this.submit('get', `/participant/${participantId}`);    
        }
        return this.submit('get', '/');
    }

    export(date) {
        return this.submit('post', `/export/${date}`, null, null, {}, 'blob');
    }

    massUpdate(ids, formData) {
        return this.submit('post', `/${ids}/mass-edit`, formData);
    }
}

export default PresenceListProxy;
