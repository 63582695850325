<template>
    <v-flex
        md12
        class="px-12 pb-12"
    >
        <PageHeader
            :breadcrumbs="breadcrumbs"
            :title="pageTitle"
            back
            back-to="registrations"
        />
        <v-row>
            <v-col cols="12">
                <TabMenu
                    :menus="menus"
                    v-model="activeMenu"
                />
            </v-col>
            <v-col cols="12">
                <v-alert
                    v-for="(error, errorKey) in generalFormErrors"
                    :key="errorKey"
                    type="error"
                    v-text="error"
                />
                <ParticipantForm
                    v-if="activeMenu == 'profile'"
                    :data="data"
                    :raw-data="rawData"
                    :errors="formErrors"
                    :loading="loading"
                    :save-function="saveObject"
                    :discard-function="resetObject"
                />
                <ParticipantUploads
                    v-if="activeMenu == 'uploads'"
                    key="uploads"
                    type="R"
                    :participant="data"
                />
            </v-col>
        </v-row>
    </v-flex>
</template>

<script>

import PageHeader from '@/components/common/PageHeader';
import ParticipantForm from '@/components/registrations/Form/ParticipantForm';
import ParticipantProxy from '@/proxies/ParticipantProxy';
import ParticipantMixin from '@/mixins/participant';
import TabMenu from '@/components/common/TabMenu';
import ParticipantUploads from '@/components/participants/ParticipantUploads';
import { SuccessSnackbar, StandBy } from '@/app-events.js';

export default {
    name: 'ParticipantDetail',
    mixins: [ ParticipantMixin ],
    components: {
        ParticipantForm,
        PageHeader,
        TabMenu,
        ParticipantUploads,
    },
    data() {
        return {
            loading: false,
            data: {
                firstname: '',
                lastname: '',
            },
            rawData: {
                firstname: '',
                lastname: '',
            },
            addLearnGoalControl: 0,
            addNote: 0,
            addCorrespondence: 0,
            addFeedback: 0,
            activeMenu: 'profile',
            menus: [
                {
                    id: 'profile',
                    label: this.$t('page.participant_new.menu.profile'),
                },
                {
                    id: 'uploads',
                    label: this.$t('page.participant_new.menu.uploads'),
                },
            ],
        };
    },
    computed: {
        pageTitle() {
            try {
                return `${this.rawData.firstname} ${this.rawData.lastname}`;
            } catch (error) {
                return '';
            }
        },
        breadcrumbs() {
            return [
                {
                    text: this.$t('page.home.title'),
                    exact: true,
                    to: { name: 'home' },
                },
                {
                    text: this.$t('page.registrations.title'),
                    exact: true,
                    to: { name: 'registrations' },
                },
                {
                    text: this.pageTitle,
                    disabled: true,
                },
            ];
        },
    },
    created() {
        this.fetchData();
    },
    methods: {
        fetchData() {
            this.loading = true;
            new ParticipantProxy().find(this.$route.params.id).then(({ data }) => {
                this.data = this.prepareData(data.data);
                this.rawData = JSON.parse(JSON.stringify(this.data));
                this.loading = false;
            }).catch(({ data }) => {
                this.displayError(data);
            });
            
        },
        resetObject() {
            this.data = JSON.parse(JSON.stringify(this.rawData));
            this.resetErrors();
        },
        saveObject() {
            this.$eventBus.dispatch(new StandBy(true));
            new ParticipantProxy().update(this.$route.params.id, this.prepareFormData(this.data)).then(({ data }) => {
                this.$eventBus.dispatch(new StandBy(false));
                this.rawData = JSON.parse(JSON.stringify(this.data));
                this.$eventBus.dispatch(new SuccessSnackbar(this.$t('participant.actions.saved')));
                this.resetErrors();
            }).catch(({ data }) => {
                this.processErrors(data);
                this.$eventBus.dispatch(new StandBy(false));
            });
        },
    },
};
</script>
