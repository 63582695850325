import Vue from 'vue';

class BaseProxy {
    /**
   * The constructor of the BaseProxy.
   *
   * @param {string} endpoint   The endpoint being used.
   * @param {Object} parameters The parameters for the request.
   */
    constructor(endpoint = '', parameters = {}) {
        this.endpoint = endpoint;
        this.parameters = parameters;
    }

    /**
   * Method used to set the query parameters.
   *
   * @param {Object} parameters The given parameters.
   *
   * @returns {BaseProxy} The instance of the proxy.
   */
    setParameters(parameters) {
        Object.keys(parameters).forEach((key) => {
            this.parameters[ key ] = parameters[ key ];
        });

        return this;
    }

    /**
   * Method used to set a single parameter.
   *
   * @param {string} parameter The given parameter.
   * @param {*} value The value to be set.
   *
   * @returns {BaseProxy} The instance of the proxy.
   */
    setParameter(parameter, value) {
        this.parameters[ parameter ] = value;

        return this;
    }

    /**
   * Method used to remove all the parameters.
   *
   * @param {Array} parameters The given parameters.
   *
   * @returns {BaseProxy} The instance of the proxy.
   */
    removeParameters(parameters) {
        parameters.forEach((parameter) => {
            delete this.parameters[ parameter ];
        });

        return this;
    }

    /**
   * Method used to remove a single parameter.
   *
   * @param {string} parameter The given parameter.
   *
   * @returns {BaseProxy} The instance of the proxy.
   */
    removeParameter(parameter) {
        delete this.parameters[ parameter ];

        return this;
    }

    /**
   * The method used to perform an AJAX-request.
   *
   * @param {string}      requestType The request type.
   * @param {string}      url         The URL for the request.
   * @param {Object|null} data        The data to be send with the request.
   * @param {function}    validStatus Function which returns valid status
   *
   * @returns {Promise} The result in a promise.
   */
    submit(requestType, url, data = null, validStatus = null, headers = {}, responseType = 'json') {
        let validStatusCallback = (status) => (status < 300);
        if (validStatus !== null) {
            validStatusCallback = validStatus;
        }
        return new Promise((resolve, reject) => {
            Vue.$http.request({
                method: requestType,
                url: this.endpoint + url + this.getParameterString(),
                validateStatus: validStatusCallback,
                data,
                withCredentials: true,
                headers,
                responseType
            })
                .then((response) => {
                    resolve(response);
                })
                .catch(({ response }) => {
                    /*if (typeof response.data.errors === 'undefined') {
                        response.data = {
                            errors: {},
                        };
                    }
                    if (typeof response.data.errors.title === 'undefined') {
                        response.data.errors.title = 'Technical Error';
                    }
                    if (typeof response.data.errors.detail === 'undefined') {
                        response.data.errors.detail = 'Contact an administrator';
                    }*/
                    if (response) {
                        reject(response);
                    } else {
                        reject();
                    }
                });
        });
    }

    /**
   * Method used to fetch all items from the API.
   *
   * @returns {Promise} The result in a promise.
   */
    all() {
        return this.submit('get', '/');
    }

    /**
   * Method used to fetch a single item from the API.
   *
   * @param {int} id The given identifier.
   *
   * @returns {Promise} The result in a promise.
   */
    find(id) {
        return this.submit('get', `/${id}`);
    }

    /**
   * Method used to create an item.
   *
   * @param {Object} formData The given data.
   *
   * @returns {Promise} The result in a promise.
   */
    new(formData, type) {
        return this.submit('post', '/new?type=', formData);
    }

    /**
   * Method used to update an item.
   *
   * @param {int}    id   The given identifier.
   * @param {Object} formData The given data.
   *
   * @returns {Promise} The result in a promise.
   */
    update(id, formData) {
        return this.submit('put', `/${id}`, formData);
    }

    /**
   * Method used to delete an item.
   *
   * @param {int} id The given identifier.
   *
   * @returns {Promise} The result in a promise.
   */
    delete(id) {
        return this.submit('delete', `/${id}`);
    }

    /**
   * Method used to transform a parameters object to a parameters string.
   *
   * @returns {string} The parameter string.
   */
    getParameterString() {
        const keys = Object.keys(this.parameters);

        const parameterStrings = keys
            .filter((key) => !!this.parameters[ key ])
            .map((key) => `${key}=${this.parameters[ key ]}`);

        return parameterStrings.length === 0 ? '' : `?${parameterStrings.join('&')}`;
    }
}

export default BaseProxy;
