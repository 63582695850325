<template>
    <v-menu
        ref="menu"
        v-model="datePicker"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="290px"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="birthdateFormatted"
                :label="label"
                :error-messages="formError(errors, errorKey)"
                prepend-inner-icon="mdi-calendar"
                persistent-hint
                :hint="required ? requiredHint + ' (TT.MM.JJJJ)' : 'TT.MM.JJJJ'"
                :readonly="false"
                v-on="on"
                v-bind="attrs"
                clearable
                @click:clear="$nextTick(() => handleInput = null)"
                :autocomplete="new Date().getTime().toString()"
            />
        </template>
        <v-date-picker
            ref="picker"
            locale="de-ch"
            :first-day-of-week="1"
            v-model="handleInput"
            :max="new Date().toISOString().substr(0, 10)"
            min="1920-01-01"
            @change="saveBirthdate"
        />
    </v-menu>
</template>

<script>

import DatesMixin from '@/mixins/dates';

export default {
    name: 'BirthdatePicker',
    mixins: [ DatesMixin ],
    props: {
        value: {
            type: String,
            default: '',
        },
        clearable: {
            type: Boolean,
            default: true,
        },
        required: {
            type: Boolean,
            default: false,
        },
        requiredHint: {
            type: String,
            default: 'Pflichtfeld',
        },
        label: {
            type: String,
            default: '',
        },
        errorKey: {
            type: String,
            default: '',
        },
        errors: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            datePicker: false,
            dateValue: this.value,
        }
    },
    computed: {
        birthdateFormatted: {
            get() {
                return this.formatDate(this.dateValue);
            },
            set(newValue) {
                if (!/^\d{1,2}.\d{1,2}.\d{4}$/.test(newValue)) {
                    return;
                }
                const dateParts = newValue.split('.');
                let newDate;
                try {
                    newDate = new Date(`${dateParts[ 2 ]}-${dateParts[ 1 ]}-${dateParts[ 0 ]}`);
                } catch (e) {
                    return;
                }
                if (newDate instanceof Date && !isNaN(newDate)) {
                    this.handleInput = this.formatDateISO(newDate);
                }
            }
        },
        handleInput: {
            get() {
                return this.dateValue;
            },
            set(newValue) {
                this.dateValue = newValue;
                this.$emit('input', this.dateValue)
            }
        },
    },
    methods: {
        saveBirthdate(date) {
            this.$refs.menu.save(date);
        },
    },
    watch: {
        value(val) {
            this.dateValue = val;
        },
        datePicker (val) {
            val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
        },
    },
};
</script>
