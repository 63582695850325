<template>
    <div>
        <v-row>
            <v-col cols="12">
                <v-progress-linear
                    v-if="loading"
                    indeterminate
                    color="primary"
                />
                <v-skeleton-loader
                    v-if="loading"
                    class="mx-auto"
                    type="table-tbody"
                />
                <ParticipantsList
                    v-if="!loading"
                    :objects="participants"
                />
            </v-col>
        </v-row>
    </div>
</template>

<script>

import ParticipantsList from '@/components/participants/ParticipantsList';
import ParticipantProxy from '@/proxies/ParticipantProxy';
import { ObjectDeleted, SuccessSnackbar } from '@/app-events.js';

export default {
    name: 'Participants',
    components: {
        ParticipantsList,
    },

    props: {
        ravConsultant: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            participants: [],
            loading: true,
        }
    },
    created() {
        this.fetchData();
        this.$eventBus.listen(ObjectDeleted, (event) => {
            this.$eventBus.dispatch(new SuccessSnackbar(this.$t('participant.actions.deleted')));
            for (let i = 0; i < this.participants.length; i++) {
                const participant = this.participants[ i ];
                if (participant.id === event.object.id) {
                    this.participants.splice(i, 1);
                    break;
                }
            }
        });
    },
    methods: {
        fetchData() {
            new ParticipantProxy().all({
                ravConsultantId: this.ravConsultant.id,
            }).then(({ data }) => {
                this.participants = data.data;
                this.loading = false;
            }).catch(({ data }) => {
                this.displayError(data);
            });
        },
    },
};
</script>
