<template>
    <v-dialog
        v-model="dialog"
        persistent
        max-width="290"
    >
        <v-card>
            <v-card-title
                class="headline"
                v-text="titleDisplay"
            />
            <v-card-actions>
                <v-spacer />
                <v-btn
                    v-if="!loading"
                    text
                    @click="cancel()"
                >
                    {{ cancelTextDisplay }}
                </v-btn>
                <v-btn
                    color="red darken-1"
                    :loading="loading"
                    text
                    @click="confirm()"
                >
                    {{ confirmTextDisplay }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

export default {
    name: 'ConfirmationDialog',
    props: {
        dialog: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: '',
        },
        cancelText: {
            type: String,
            default: '',
        },
        confirmText: {
            type: String,
            default: '',
        },
        loading: {
            type: Boolean,
            default: false,
        },
        confirm: {
            type: Function,
            default: () => ({}),
        },
        cancel: {
            type: Function,
            default: () => ({}),
        },
    },
    data() {
        return {
            titleDisplay: '',
            cancelTextDisplay: '',
            confirmTextDisplay: '',
        }
    },
    created() {
        this.titleDisplay = this.title;
        this.cancelTextDisplay = this.cancelText;
        this.confirmTextDisplay = this.confirmText;
        if (this.titleDisplay === '') {
            this.titleDisplay = this.$t('general.are_you_sure');
        }
        if (this.cancelTextDisplay === '') {
            this.cancelTextDisplay = this.$t('general.cancel');
        }
        if (this.confirmTextDisplay === '') {
            this.confirmTextDisplay = this.$t('general.confirm');
        }
    },
};
</script>
