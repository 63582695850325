<template>
    <div>
        <div class="display-2">
            {{ $t('general.title') }}  Login
        </div>
        <div class="subtitle-1 my-4">
            Für die Coaches der bewerbeagentur
        </div>
        <v-card
            flat
            class="pa-12"
        >
            <v-card-text>
                <v-alert
                    v-if="authError !== null"
                    :value="true"
                    type="error"
                >
                    {{ $t(authError) }}
                </v-alert>
                <v-form @submit.prevent="submit">
                    <v-text-field
                        id="username"
                        v-model="credentials.username"
                        prepend-icon="mdi-account"
                        name="username"
                        :required="true"
                        :label="$t('page.auth.login.email')"
                        type="email"
                    />
                    <v-text-field
                        id="password"
                        v-model="credentials.password"
                        prepend-icon="mdi-lock"
                        name="password"
                        :required="true"
                        :label="$t('page.auth.login.password')"
                        type="password"
                    />
                    <v-btn
                        style="display: none;"
                        type="submit"
                    >
                        Login
                    </v-btn>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn
                    text
                    small
                    :to="{ name: 'password_request' }"
                >
                    {{ $t('page.auth.login.forgotten_password') }}
                </v-btn>
                <v-btn
                    color="secondary--dark"
                    class="px-4"
                    dark
                    rounded
                    depressed
                    type=""
                    @click="submit"
                >
                    {{ $t('page.auth.login.button') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'AuthLogin',
    data() {
        return {
            credentials: {
                username: '',
                password: '',
            },
        };
    },
    computed: {
        ...mapGetters({
            authError: 'auth/loginError',
        }),
    },
    created() {
        this.$store.dispatch('auth/initialize', {
            authManager: this.$auth,
        });
    },
    methods: {
        submit() {
            this.$store.dispatch('auth/login', {
                credentials: this.credentials,
                authManager: this.$auth,
                localstorageManager: this.$localStorage,
            });
        },
    },
};
</script>
