<template>
    <v-row no-gutters>
        <v-col
            lg="6"
            offset-lg="3"
            md="12"
        >
            <v-progress-linear
                v-if="loading"
                indeterminate
                color="primary"
            />
            <v-skeleton-loader
                v-if="loading"
                class="mx-auto"
                type="card"
            />
            <v-skeleton-loader
                v-if="loading"
                class="mx-auto"
                type="card"
            />
            <v-skeleton-loader
                v-if="loading"
                class="mx-auto"
                type="card"
            />
            <div v-if="!loading">
                <v-alert
                    v-for="(error, errorKey) in generalFormErrors"
                    :key="errorKey"
                    type="error"
                    v-text="error"
                />
                <v-expand-transition>
                    <v-card
                        flat
                        class="mx-auto mb-6"
                        v-if="add"
                    >
                        <v-card-text>
                            <p
                                class="title text--primary mb-0"
                                v-text="$t('page.feedback.new')"
                            />
                            <v-col 
                                cols="12"
                            >
                                <ckeditor
                                    :editor="editor"
                                    :config="editorConfig"
                                    v-model="data.content"
                                />
                                <v-textarea
                                    v-if="false"
                                    type="text"
                                    outlined
                                    auto-grow
                                    v-model="data.content"
                                    :label="$t('feedback.content')"
                                    :error-messages="formError(formErrors, 'content')"
                                />
                            </v-col>
                        </v-card-text>
                    </v-card>
                </v-expand-transition>

                <v-card
                    flat
                    class="mx-auto mb-6"
                    v-for="(goal, key) in objects"
                    :key="`goal-${key}`"
                >
                    <v-card-text>
                        <v-row
                            class="float-right mr-2"
                        >
                            <v-tooltip
                                v-if="goal.email_sent"
                                top
                            >
                                <template v-slot:activator="{ on }">
                                    <v-icon
                                        class="mr-2"
                                        color="green"
                                        v-on="on"
                                    >
                                        mdi-email-send
                                    </v-icon>
                                </template>
                                <span>E-Mail gesendet</span>
                            </v-tooltip>
                            <v-menu
                                v-if="!add"
                                offset-y
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        mdi-dots-horizontal
                                    </v-icon>
                                </template>
                                <v-list>
                                    <v-list-item @click="doEdit(goal)">
                                        <v-list-item-action>
                                            <v-icon>mdi-pencil</v-icon>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                            <v-list-item-title v-text="$t('general.edit')" />
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item
                                        v-if="participant.rav_consultant.id"
                                        @click="doSend(goal)"
                                    >
                                        <v-list-item-action>
                                            <v-icon>mdi-email</v-icon>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                            <v-list-item-title v-text="$t('learn_goal_control.actions.rav_send')" />
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item @click="initiateDeletion(goal)">
                                        <v-list-item-action>
                                            <v-icon>mdi-delete</v-icon>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                            <v-list-item-title v-text="$t('general.delete')" />
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </v-row>
                        <p class="title text--primary mb-0">
                            {{ formatDate(goal.created) }} {{ $t('feedback.content') }}
                        </p>
                        <p class="text--secondary">
                            {{ $t('feedback.author') }}: {{ goal.coach.firstname }} {{ goal.coach.lastname }}, {{ $t('feedback.last_update') }}: {{ formatDate(goal.updated) }}
                        </p>
                        <div
                            v-if="edit !== goal.id"
                            class="text--primary"
                            v-html="goal.content"
                        />

                        <v-col 
                            v-else
                            cols="12"
                        >
                            <ckeditor
                                :editor="editor"
                                :config="editorConfig"
                                v-model="data.content"
                            />
                            <v-textarea
                                v-if="false"
                                type="text"
                                outlined
                                auto-grow
                                v-model="data.content"
                                :label="$t('feedback.content')"
                                :error-messages="formError(formErrors, 'content')"
                            />
                        </v-col>
                    </v-card-text>
                </v-card>
            </div>
        </v-col>
        <ConfirmationDialog
            :confirm="deleteObject"
            :cancel="() => (deleteDialog = false)"
            :loading="deleting"
            :dialog="deleteDialog"
        />
        <UnsavedForm
            :unsaved-form="edit !== false || add"
            :errors="formErrors"
            :on-save="saveFunction"
            :on-cancel="cancelObjectChanges"
        />
        <EmailDialog
            attachments
            :confirm="emailObject"
            :cancel="() => (emailDialog = false)"
            :loading="sending"
            :dialog="emailDialog"
            :data="email"
            :title="$t('learn_goal_control.actions.rav_send')"
        />
    </v-row>
</template>

<script>

import UnsavedForm from '@/components/common/UnsavedForm';
import ConfirmationDialog from '@/components/common/ConfirmationDialog';
import EmailDialog from '@/components/common/EmailDialog';
import FeedbackProxy from '@/proxies/FeedbackProxy';
import DatesMixin from '@/mixins/dates';
import FeedbackMixin from '@/mixins/feedback';
import { SuccessSnackbar, StandBy } from '@/app-events.js';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
    name: 'Feedback',
    mixins: [
        DatesMixin,
        FeedbackMixin,
    ],
    components: {
        ConfirmationDialog,
        UnsavedForm,
        EmailDialog,
    },
    props: {
        participant: {
            type: Object,
            default: () => {},
        },
        addProp: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            editor: ClassicEditor,
            editorConfig: {
                toolbar: {
                    items: [
                        'heading',
                        '|',
                        'bold',
                        'italic',
                        'link',
                        'bulletedList',
                        'numberedList',
                        'indent',
                        'outdent',
                        'undo',
                        'redo',
                    ],
                },
            },
            loading: false,
            edit: false,
            add: false,
            data: {},
            objects: [],
            deleteDialog: false,
            deleting: false,
            objectToDelete: null,
            emailDialog: false,
            sending: false,
            objectToEmail: null,
            email: {
                subject: '',
                content: '',
                attachments: [],
            }
        }
    },
    created() {
        this.fetchData();
    },
    methods: {
        fetchData() {
            this.loading = true;
            new FeedbackProxy().all({
                participantId: this.participant.id,
            }).then(({ data }) => {
                this.objects = data.data;
                this.loading = false;
            }).catch(({ data }) => {
                this.displayError(data);
            });
        },
        doEdit(goal) {
            this.data = JSON.parse(JSON.stringify(goal));
            this.edit = goal.id;
        },
        saveFunction() {
            this.$eventBus.dispatch(new StandBy(true));
            if (this.data.id) {
                new FeedbackProxy().update(this.data.id, this.prepareFormData(this.data)).then(({ data }) => {
                    this.$eventBus.dispatch(new StandBy(false));
                    this.fetchData();
                    this.$eventBus.dispatch(new SuccessSnackbar(this.$t('feedback.actions.saved')));
                    this.edit = false;
                    this.resetErrors();
                }).catch(({ data }) => {
                    this.processErrors(data);
                    this.$eventBus.dispatch(new StandBy(false));
                });
            } else {
                new FeedbackProxy().new(this.prepareFormData(this.data)).then(({ data }) => {
                    this.$eventBus.dispatch(new StandBy(false));
                    this.fetchData();
                    this.$eventBus.dispatch(new SuccessSnackbar(this.$t('feedback.actions.saved')));
                    this.add = false;
                    this.resetErrors();
                }).catch(({ data }) => {
                    this.processErrors(data);
                    this.$eventBus.dispatch(new StandBy(false));
                });
            }
        },
        cancelObjectChanges() {
            this.edit = false;
            this.add = false;
        },
        initiateDeletion(item) {
            this.objectToDelete = item;
            this.deleteDialog = true;
        },
        deleteObject() {
            this.deleting = true;
            new FeedbackProxy().delete(this.objectToDelete.id).then(({ data }) => {
                this.$eventBus.dispatch(new SuccessSnackbar(this.$t('feedback.actions.deleted')));
                for (let i = 0; i < this.objects.length; i++) {
                    const goal = this.objects[ i ];
                    if (goal.id === this.objectToDelete.id) {
                        this.objects.splice(i, 1);
                        break;
                    }
                }
                this.cancelObjectChanges();
            }).catch(({ data }) => {
                this.displayError(data);
            }).finally(() => {
                this.deleteDialog = false;
                this.objectToDelete = null;
                this.deleting = false;
            });
        },
        doSend(item) {
            this.objectToEmail = item;

            new FeedbackProxy().email(this.objectToEmail.id).then(({ data }) => {
                this.email = {
                    subject: `Feedback ${this.participant.firstname} ${this.participant.lastname}`,
                    content: data.data.content,
                    attachments: [],
                };
                this.emailDialog = true;
            }).catch(({ data }) => {
                this.displayError(data);
            });
        },
        emailObject() {
            this.sending = true;
            let formData = new FormData();
            for (let file of this.email.attachments) {
                formData.append(file.name, file, file.name);
            }
            formData.append('subject', this.email.subject);
            formData.append('content', this.email.content);
            new FeedbackProxy().send(this.objectToEmail.id, formData).then(({ data }) => {
                this.$eventBus.dispatch(new SuccessSnackbar(this.$t('learn_goal_control.actions.sent')));
                this.fetchData();
            }).catch(({ data }) => {
                this.displayError(data);
            }).finally(() => {
                this.emailDialog = false;
                this.objectToEmail = null;
                this.sending = false;
            });
        },
    },
    watch: {
        addProp(val) {
            if (this.edit !== false) {
                return;
            }
            this.add = true;
            this.data = {
                id: null,
                participant: this.participant,
                content: '',
            };
        },
    },
};
</script>
