<template>
    <v-card flat>
        <v-card-text>
            <v-row>
                <v-col md="6">
                    <v-select
                        :items="presences"
                        v-model="data.default_presence.monday_morning"
                        :label="$t('participant.default_presence.monday_morning')"
                        :error-messages="formError(errors, 'defaultPresence.mondayMorning')"
                        clearable
                        @click:clear="$nextTick(() => data.default_presence.monday_morning = null)"
                    />
                </v-col>
                <v-col md="6">
                    <v-select
                        :items="presences"
                        v-model="data.default_presence.monday_afternoon"
                        :label="$t('participant.default_presence.monday_afternoon')"
                        :error-messages="formError(errors, 'defaultPresence.mondayAfternoon')"
                        clearable
                        @click:clear="$nextTick(() => data.default_presence.monday_afternoon = null)"
                    />
                </v-col>
                <v-col md="6">
                    <v-select
                        :items="presences"
                        v-model="data.default_presence.tuesday_morning"
                        :label="$t('participant.default_presence.tuesday_morning')"
                        :error-messages="formError(errors, 'defaultPresence.tuesdayMorning')"
                        clearable
                        @click:clear="$nextTick(() => data.default_presence.tuesday_morning = null)"
                    />
                </v-col>
                <v-col md="6">
                    <v-select
                        :items="presences"
                        v-model="data.default_presence.tuesday_afternoon"
                        :label="$t('participant.default_presence.tuesday_afternoon')"
                        :error-messages="formError(errors, 'defaultPresence.tuesdayAfternoon')"
                        clearable
                        @click:clear="$nextTick(() => data.default_presence.tuesday_afternoon = null)"
                    />
                </v-col>
                <v-col md="6">
                    <v-select
                        :items="presences"
                        v-model="data.default_presence.wednesday_morning"
                        :label="$t('participant.default_presence.wednesday_morning')"
                        :error-messages="formError(errors, 'defaultPresence.wednesdayMorning')"
                        clearable
                        @click:clear="$nextTick(() => data.default_presence.wednesday_morning = null)"
                    />
                </v-col>
                <v-col md="6">
                    <v-select
                        :items="presences"
                        v-model="data.default_presence.wednesday_afternoon"
                        :label="$t('participant.default_presence.wednesday_afternoon')"
                        :error-messages="formError(errors, 'defaultPresence.wednesdayAfternoon')"
                        clearable
                        @click:clear="$nextTick(() => data.default_presence.wednesday_afternoon = null)"
                    />
                </v-col>
                <v-col md="6">
                    <v-select
                        :items="presences"
                        v-model="data.default_presence.thursday_morning"
                        :label="$t('participant.default_presence.thursday_morning')"
                        :error-messages="formError(errors, 'defaultPresence.thursdayMorning')"
                        clearable
                        @click:clear="$nextTick(() => data.default_presence.thursday_morning = null)"
                    />
                </v-col>
                <v-col md="6">
                    <v-select
                        :items="presences"
                        v-model="data.default_presence.thursday_afternoon"
                        :label="$t('participant.default_presence.thursday_afternoon')"
                        :error-messages="formError(errors, 'defaultPresence.thursdayAfternoon')"
                        clearable
                        @click:clear="$nextTick(() => data.default_presence.thursday_afternoon = null)"
                    />
                </v-col>
                <v-col md="6">
                    <v-select
                        :items="presences"
                        v-model="data.default_presence.friday_morning"
                        :label="$t('participant.default_presence.friday_morning')"
                        :error-messages="formError(errors, 'defaultPresence.fridayMorning')"
                        clearable
                        @click:clear="$nextTick(() => data.default_presence.friday_morning = null)"
                    />
                </v-col>
                <v-col md="6">
                    <v-select
                        :items="presences"
                        v-model="data.default_presence.friday_afternoon"
                        :label="$t('participant.default_presence.friday_afternoon')"
                        :error-messages="formError(errors, 'defaultPresence.fridayAfternoon')"
                        clearable
                        @click:clear="$nextTick(() => data.default_presence.friday_afternoon = null)"
                    />
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>

import FormDataMixin from '@/mixins/form-data';

export default {
    name: 'PresenceInformation',
    mixins: [ FormDataMixin ],
    props: {
        data: {
            type: Object,
            default: () => ({
                default_presence: {},
            }),
        },
        errors: {
            type: Object,
            default: () => ({}),
        },
    },
};
</script>
