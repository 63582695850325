import DefaultLayout from '@/layouts/Default';
import GeneralSettingIndex from '@/views/GeneralSetting/Index';
import GeneralSettingDetail from '@/views/GeneralSetting/Detail';

export default {
    path: '/admin/settings',
    component: DefaultLayout,
    children: [
        {
            path: '',
            name: 'general_setting',
            component: GeneralSettingIndex,
            meta: {
                auth: true,
            },
        },
        {
            path: ':id',
            name: 'general_setting_detail',
            component: GeneralSettingDetail,
            meta: {
                auth: true,
            },
        },
    ],
};
