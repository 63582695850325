<template>
    <v-card flat>
        <v-card-text>
            <v-row>
                <v-col cols="6">
                    <v-select
                        :items="statuses"
                        v-model="data.status"
                        :label="$t('participant.status')"
                        :error-messages="formError(errors, 'status')"
                        :hint="!data.course || typeof data.course.id === 'undefined' ? $t('participant.course_required') : $t('general.required')"
                        persistent-hint
                        :disabled="!data.course || typeof data.course.id === 'undefined'"
                    />
                </v-col>
                <v-col
                    cols="6"
                    sm="6"
                >
                    <v-checkbox
                        v-model="data.waiting_list"
                        :label="$t('participant.waiting_list')"
                    />
                </v-col>
                <v-col cols="6">
                    <v-select
                        :items="groups"
                        v-model="data.group"
                        :label="$t('participant.group')"
                        :error-messages="formError(errors, 'group')"
                        clearable
                        @click:clear="$nextTick(() => data.group = null)"
                    />
                </v-col>
                <v-col cols="6">
                    <v-autocomplete
                        v-model="data.coach.id"
                        :items="computedCoaches"
                        :label="$t('participant.coach')"
                        :error-messages="formError(errors, 'coach')"
                        clearable
                        @click:clear="$nextTick(() => data.coach = null)"
                    />
                </v-col>
                <v-col
                    cols="12"
                    v-if="data.waiting_list"
                >
                    <v-select
                        v-model="data.waiting_course"
                        :items="courses"
                        return-object
                        :label="$t('participant.waiting_course')"
                        :error-messages="formError(errors, 'waitingCourse')"
                        :clearable="data.status === 'RE'"
                        @click:clear="$nextTick(() => data.waiting_course = {})"
                        :hint="$t('general.required')"
                        persistent-hint
                    />
                </v-col>
                <v-col cols="12">
                    <v-select
                        v-model="data.course"
                        :items="courses"
                        item-value="id"
                        return-object
                        :label="$t('participant.course')"
                        :error-messages="formError(errors, 'course')"
                        :clearable="data.status === 'RE'"
                        @click:clear="$nextTick(() => data.course = {})"
                        @change="courseChange"
                    />
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                >
                    <DatePicker
                        v-model="data.start_date"
                        :label="$t('participant.start_date')"
                        :errors="errors"
                        error-key="startDate"
                    />
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                >
                    <DatePicker
                        v-model="data.end_date"
                        :label="$t('participant.end_date')"
                        :errors="errors"
                        error-key="endDate"
                    />
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                >
                    <v-checkbox
                        v-model="data.available"
                        :label="$t('participant.available')"
                    />
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                >
                    <DatePicker
                        v-model="data.final_report_at"
                        :label="$t('participant.final_report_at')"
                        :errors="errors"
                        error-key="finalReportAt"
                    />
                </v-col>
                <v-col cols="12">
                    <v-select
                        :items="exitReasons"
                        v-model="data.exit_reason"
                        :label="$t('participant.exit_reason')"
                        :error-messages="formError(errors, 'exitReason')"
                        clearable
                        @click:clear="$nextTick(() => data.exit_reason = null)"
                    />
                </v-col>
                <v-col cols="12">
                    <v-select
                        :items="exitStatuses"
                        v-model="data.exit_status"
                        :label="$t('participant.exit_status')"
                        :error-messages="formError(errors, 'exitStatus')"
                        clearable
                        @click:clear="$nextTick(() => data.exit_status = null)"
                    />
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>

import DatePicker from '@/components/common/DatePicker';
import CoachProxy from '@/proxies/CoachProxy';
import CourseProxy from '@/proxies/CourseProxy';
import FormDataMixin from '@/mixins/form-data';
import DatesMixin from '@/mixins/dates';

export default {
    name: 'CourseInformation',
    mixins: [
        FormDataMixin,
        DatesMixin,
    ],
    components: {
        DatePicker
    },
    props: {
        data: {
            type: Object,
            default: () => ({
                coach: {},
                course: {},
            }),
        },
        errors: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            startDatePicker: false,
            endDatePicker: false,
            coaches: [],
            courses: [],
        }
    },
    created() {
        if (typeof this.data.course.id !== 'undefined') {
            this.data.course.text = this.formatCourseText(this.data.course);
        }
        if (typeof this.data.waiting_course !== 'undefined' && typeof this.data.waiting_course.id !== 'undefined') {
            this.data.waiting_course.text = this.formatCourseText(this.data.waiting_course);
        }
        new CoachProxy().all({
            active: true,
        }).then(({ data }) => {
            this.coaches = data.data;
        }).catch(({ data }) => {
            this.displayError(data);
        });
        new CourseProxy().all().then(({ data }) => {
            this.courses = [];
            for (let i = 0; i < data.data.length; i++) {
                const course = data.data[ i ];
                course.text = this.formatCourseText(course);
                /*allCourses.push({
                    value: course.id,
                    text: `${this.formatDate(course.start_date)} - ${this.formatDate(course.end_date)}`,
                });*/
                this.courses.push(course);
            }
            console.log(this.courses);
        }).catch(({ data }) => {
            this.displayError(data);
        });
    },
    computed: {
        startDateFormatted() {
            return this.formatDate(this.data.start_date);
        },
        endDateFormatted() {
            return this.formatDate(this.data.end_date);
        },
        computedCoaches() {
            const allCoaches = [];
            for (let i = 0; i < this.coaches.length; i++) {
                const coach = this.coaches[ i ];
                allCoaches.push({
                    value: coach.id,
                    text: `${coach.firstname} ${coach.lastname}`,
                })
            }
            return allCoaches;
        },
    },
    methods: {
        courseChange() {
            if (typeof this.data.course === 'undefined') {
                return;
            }
            if (this.data.start_date === null && this.data.end_date === null) {
                this.data.start_date = this.data.course.start_date;
                this.data.end_date = this.data.course.end_date;
            }
        },
        formatCourseText(course) {
            if (course === null) {
                return '';
            }
            return `${course.name} (${this.formatDate(course.start_date)} - ${this.formatDate(course.end_date)})`;
        },
    },
};
</script>
