<template>
    <v-card flat>
        <v-card-text>
            <v-row>
                <v-col cols="12">
                    <v-text-field
                        type="text"
                        v-model="data.name"
                        :label="$t('insurance_fund.name')"
                        :error-messages="formError(errors, 'name')"
                    />
                </v-col>
                <v-col cols="12">
                    <v-text-field
                        type="text"
                        v-model="data.number"
                        :label="$t('insurance_fund.number')"
                        :error-messages="formError(errors, 'number')"
                    />
                </v-col>
                <v-col md="6">
                    <v-text-field
                        type="text"
                        v-model="data.zip"
                        :label="$t('insurance_fund.zip')"
                        :error-messages="formError(errors, 'zip')"
                    />
                </v-col>
                <v-col md="6">
                    <v-text-field
                        type="text"
                        v-model="data.city"
                        :label="$t('insurance_fund.city')"
                        :error-messages="formError(errors, 'city')"
                    />
                </v-col>
                <v-col cols="12">
                    <v-textarea
                        type="text"
                        v-model="data.address"
                        :label="$t('insurance_fund.address')"
                        :error-messages="formError(errors, 'address')"
                    />
                </v-col>
                <v-col cols="12">
                    <v-text-field
                        type="text"
                        v-model="data.phone"
                        :label="$t('insurance_fund.phone')"
                        :error-messages="formError(errors, 'phone')"
                    />
                </v-col>
                <v-col cols="12">
                    <v-text-field
                        type="text"
                        v-model="data.fax"
                        :label="$t('insurance_fund.fax')"
                        :error-messages="formError(errors, 'fax')"
                    />
                </v-col>
                <v-col cols="12">
                    <v-text-field
                        type="text"
                        v-model="data.email"
                        :label="$t('insurance_fund.email')"
                        :error-messages="formError(errors, 'email')"
                    />
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>

export default {
    name: 'OfficeInformation',
    props: {
        data: {
            type: Object,
            default: () => ({
                name: '',
                roles: [],
            }),
        },
        errors: {
            type: Object,
            default: () => ({}),
        },
    },
};
</script>
