<template>
    <div>
        <div class="display-2">
            {{ $t('page.auth.password_request.title') }}
        </div>
        <div class="subtitle-1 my-4">
            Für die Coaches der bewerbeagentur
        </div>
        <v-card
            flat
            class="pa-12"
        >
            <v-card-text>
                <v-form
                    @submit.prevent="submit"
                    v-if="!sent"
                >
                    <v-text-field
                        id="email"
                        v-model="email"
                        prepend-icon="mdi-account"
                        name="email"
                        required
                        :label="$t('page.auth.password_request.email')"
                        type="email"
                    />
                    <v-btn
                        style="display: none;"
                        type="submit"
                    >
                        Login
                    </v-btn>
                </v-form>
                <div
                    v-else
                    v-html="$t('page.auth.password_request.check_email')"
                />
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn
                    v-if="sent"
                    text
                    small
                    :to="{ name: 'login' }"
                >
                    {{ $t('general.back') }}
                </v-btn>
                <v-btn
                    v-else
                    color="secondary--dark"
                    class="px-4"
                    :dark="email != ''"
                    :disabled="email == ''"
                    rounded
                    depressed
                    type=""
                    @click="submit"
                >
                    {{ $t('page.auth.password_request.button') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
import AuthProxy from '@/proxies/AuthProxy';

export default {
    name: 'AuthPasswordRequest',
    data() {
        return {
            email: '',
            sent: false,
        };
    },
    methods: {
        submit() {
            new AuthProxy().requestPassword({
                email: this.email,
            }).finally(() => {
                this.sent = true;
            });
        },
    },
};
</script>
