<template>
    <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                v-on="on"
                v-bind="attrs"
                small
                icon
                class="d-md-none mr-3"
                :to="{ name: to }"
            >
                <v-icon>mdi-plus</v-icon>
            </v-btn>
        </template>
        <span v-text="label" />
    </v-tooltip>
</template>

<script>

export default {
    name: 'MobileNewButton',
    props: {
        to: {
            type: String,
            default: '',
        },
        label: {
            type: String,
            default: '',
        },
    },
};
</script>
