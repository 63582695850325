<template>
    <v-flex
        md12
        class="px-12 pb-12"
    >
        <PageHeader
            :breadcrumbs="breadcrumbs"
            :title="$t('page.weekname.title')"
            button
            :button-text="$t('page.weekname.new')"
            button-to="week_new"
            search
            :search-label="$t('page.weekname.search')"
            @search-input="searchInputListener"
        />
        <v-row>
            <ActionBar
                new-button
                new-to="week_new"
                :new-label="$t('page.weekname.new')"
                fetch
                :fetch-function="fetchData"
            />
            <v-col cols="12">
                <v-progress-linear
                    v-if="loading"
                    indeterminate
                    color="primary"
                />
                <v-skeleton-loader
                    v-if="loading"
                    class="mx-auto"
                    type="table-tbody"
                />
                <WeekList
                    v-if="!loading"
                    :objects="objects"
                    :search="textSearch"
                />
            </v-col>
        </v-row>
    </v-flex>
</template>

<script>

import ActionBar from '@/components/common/ActionBar';
import PageHeader from '@/components/common/PageHeader';
import WeekList from '@/components/week/WeekList';
import { SuccessSnackbar, ObjectDeleted } from '@/app-events.js';
import WeekProxy from "@/proxies/WeekProxy";

export default {
    name: 'WeekIndex',
    components: {
        ActionBar,
        PageHeader,
        WeekList,
    },
    data() {
        return {
            breadcrumbs: [
                {
                    text: this.$t('page.home.title'),
                    exact: true,
                    to: { name: 'home' },
                },
                {
                    text: this.$t('page.weekname.title'),
                    disabled: true,
                },
            ],
            textSearch: '',
            objects: [],
            loading: true,
        };
    },
    created () {
        this.fetchData();
        this.$eventBus.listen(ObjectDeleted, (event) => {
            this.$eventBus.dispatch(new SuccessSnackbar(this.$t('week.actions.deleted')));
            for (let i = 0; i < this.objects.length; i++) {
                const insuranceFund = this.objects[ i ];
                if (insuranceFund.id === event.object.id) {
                    this.objects.splice(i, 1);
                    break;
                }
            }
        });
    },
    methods: {
        fetchData() {
            this.loading = true;
            new WeekProxy().all().then(({ data }) => {
                this.objects = data.data;
                this.loading = false;
            }).catch(({ data }) => {
                this.displayError(data);
            });
        },
        searchInputListener(value) {
            this.textSearch = value;
        },
    },
};
</script>
