import LoginLayout from '@/layouts/Login';
import Index from '@/views/Home/Index';

export default {
    path: '/',
    component: LoginLayout,
    children: [
        {
            path: '',
            name: 'home',
            component: Index,
        },
    ],
};
