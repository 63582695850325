<template>
    <v-flex
        md12
        class="px-12 pb-12"
    >
        <PageHeader
            :breadcrumbs="breadcrumbs"
            :title="$t('page.rav_new.title')"
            back
        />
        <v-row>
            <v-col cols="12">
                <RavForm
                    :rav="rav"
                    :raw-data="rawData"
                    :errors="formErrors"
                    :save-function="saveObject"
                    :discard-function="resetObject"
                />
            </v-col>
        </v-row>
    </v-flex>
</template>

<script>

import PageHeader from '@/components/common/PageHeader';
import RavForm from '@/components/rav/Form/RavForm';
import RavProxy from '@/proxies/RavProxy';
import { StandBy, SuccessSnackbar } from '@/app-events.js';

export default {
    name: 'RavNew',
    components: {
        PageHeader,
        RavForm,
    },
    data() {
        return {
            breadcrumbs: [
                {
                    text: this.$t('page.home.title'),
                    exact: true,
                    to: { name: 'home' },
                },
                {
                    text: this.$t('page.rav.title'),
                    exact: true,
                    to: { name: 'rav' },
                },
                {
                    text: this.$t('general.new'),
                    disabled: true,
                },
            ],
            rav: {
                canton: {},
            },
            rawData: {
                canton: {},
            },
        };
    },
    methods: {
        resetObject() {
            this.rav = JSON.parse(JSON.stringify(this.rawData));
            this.resetErrors();
        },
        saveObject() {
            if (JSON.stringify(this.rav) === JSON.stringify(this.rawData)) {
                return;
            }
            this.$eventBus.dispatch(new StandBy(true));
            const formData = {
                rav_location: {
                    name: this.rav.name,
                    address: this.rav.address,
                    city: this.rav.city,
                    zip: this.rav.zip,
                    canton: this.rav.canton.code,
                    phone: this.rav.phone,
                    fax: this.rav.fax,
                    email: this.rav.email,
                    website: this.rav.website,
                },
            };
            new RavProxy().new(formData).then(({ data }) => {
                this.$eventBus.dispatch(new StandBy(false));
                this.$eventBus.dispatch(new SuccessSnackbar(this.$t('rav.actions.created')));
                this.$router.push({ name: 'rav_detail', params: { id: data.data.id } });
            }).catch(({ data }) => {
                this.processErrors(data);
                this.$eventBus.dispatch(new StandBy(false));
            });
        }
    },
};
</script>
