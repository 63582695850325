import DefaultLayout from '@/layouts/Default';
import RegistrationTimeSlotIndex from '@/views/RegistrationTimeSlot/Index';
import RegistrationTimeSlotDetail from '@/views/RegistrationTimeSlot/Detail';
import RegistrationTimeSlotNew from '@/views/RegistrationTimeSlot/New';

export default {
    path: '/zeitfenster',
    component: DefaultLayout,
    children: [
        {
            path: '',
            name: 'registration_time_slot',
            component: RegistrationTimeSlotIndex,
            meta: {
                auth: true,
            },
        },
        {
            path: 'new/:date?',
            name: 'registration_time_slot_new',
            component: RegistrationTimeSlotNew,
            meta: {
                auth: true,
            },
        },
        {
            path: ':id',
            name: 'registration_time_slot_detail',
            component: RegistrationTimeSlotDetail,
            meta: {
                auth: true,
            },
        },
    ],
};
