import LoginLayout from '@/layouts/Login';
import AuthLogin from '@/views/Auth/Login';
import AuthLogout from '@/views/Auth/Logout';
import AuthPasswordRequest from '@/views/Auth/PasswordRequest';
import AuthPasswordReset from '@/views/Auth/PasswordReset';

export default {
    path: '/auth',
    component: LoginLayout,
    children: [
        {
            path: 'login',
            name: 'login',
            component: AuthLogin,
        },
        {
            path: 'logout',
            name: 'logout',
            component: AuthLogout,
        },
        {
            path: 'password-request',
            name: 'password_request',
            component: AuthPasswordRequest,
        },
        {
            path: 'password-reset/:token',
            name: 'password_reset',
            component: AuthPasswordReset,
        },
    ],
};
