import LoginLayout from '@/layouts/Login';
import CourseSurveyEn from '@/views/CourseSurveyEn/Index';

export default {
    path: '/course-survey-en',
    component: LoginLayout,
    children: [
        {
            path: ':token/:status',
            name: 'first_interview',
            component: CourseSurveyEn,
            meta: {
                auth: false,
            },
        },
    ],
};
