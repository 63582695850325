import {
    LOGIN, LOGOUT, INITIALIZE, RESET, LOGIN_ERROR,
} from './mutation-types';

const login = async ({ commit }, payload) => {
    try {
        commit(LOGIN_ERROR, {
            error: null,
        });
        await payload.authManager.login({
            data: payload.credentials,
            staySignedIn: true,
        })
            .then((res) => {
                commit(LOGIN, {
                    refresh_token: res.data.refresh_token,
                    localstorageManager: payload.localstorageManager,
                });
                payload.authManager.fetch();
            }, (err) => {
                commit(LOGIN_ERROR, {
                    error: 'page.auth.login.error',
                });
            });
    } catch (e) {
        commit(RESET, {
            localstorageManager: payload.localstorageManager,
        });
    // @igorweigel: overrule websanova/auth routerBeforeEach
    // window.location.replace('/auth/login-check/error');
    }
};

const logout = async ({ commit, dispatch }, payload) => {
    try {
        await payload.authManager.logout({})
            .then(() => {
                dispatch('clearAllState', {}, { root: true });
                commit(LOGOUT, {
                    localstorageManager: payload.localstorageManager,
                });
            }, () => {
            // error
            });
    } catch (e) {
        throw new Error(e);
    }
};

const initialize = async ({ commit }, payload) => {
    try {
        commit(INITIALIZE, {
            authManager: payload.authManager,
        });
    } catch (e) {
        throw new Error(e);
    }
};

export default {
    login,
    logout,
    initialize,
};
