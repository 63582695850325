<template>
    <v-flex
        md12
        class="px-12 pb-12"
    >
        <PageHeader
            :breadcrumbs="breadcrumbs"
            :title="$t('page.amm_new.title')"
            back
        />
        <v-row>
            <v-col cols="12">
                <AmmForm
                    :data="data"
                    :raw-data="rawData"
                    :errors="formErrors"
                    :save-function="saveObject"
                    :discard-function="resetObject"
                />
            </v-col>
        </v-row>
    </v-flex>
</template>

<script>

import PageHeader from '@/components/common/PageHeader';
import AmmForm from '@/components/amm/Form/AmmForm';
import AmmProxy from '@/proxies/AmmProxy';
import AmmMixin from '@/mixins/amm';
import { StandBy, SuccessSnackbar } from '@/app-events.js';

export default {
    name: 'AmmNew',
    mixins: [ AmmMixin ],
    components: {
        PageHeader,
        AmmForm,
    },
    data() {
        return {
            breadcrumbs: [
                {
                    text: this.$t('page.home.title'),
                    exact: true,
                    to: { name: 'home' },
                },
                {
                    text: this.$t('page.amm.title'),
                    exact: true,
                    to: { name: 'amm' },
                },
                {
                    text: this.$t('general.new'),
                    disabled: true,
                },
            ],
            data: {
                canton: {},
            },
            rawData: {},
        };
    },
    methods: {
        saveObject() {
            if (JSON.stringify(this.data) === JSON.stringify(this.rawData)) {
                return;
            }
            this.$eventBus.dispatch(new StandBy(true));
            new AmmProxy().new(this.prepareFormData(this.data)).then(({ data }) => {
                this.$eventBus.dispatch(new StandBy(false));
                this.$eventBus.dispatch(new SuccessSnackbar(this.$t('amm.actions.created')));
                this.$router.push({ name: 'amm_detail', params: { id: data.data.id } });
            }).catch(({ data }) => {
                this.processErrors(data);
                this.$eventBus.dispatch(new StandBy(false));
            });
        }
    },
};
</script>
