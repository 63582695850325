<template>
    <div />
</template>

<script>

export default {
    name: 'Home',
    created () {
        if (this.$auth.check()) {
            this.$router.push({ name: 'participants_all', params: { courseType: 'all' } });
        } else {
            this.$router.push({ name: 'login' });
        }
    },
};
</script>
