<template>
    <v-row no-gutters>
        <v-col
            lg="6"
            offset-lg="3"
            md="12"
        >
            <v-progress-linear
                v-if="loading"
                indeterminate
                color="primary"
            />
            <v-row v-if="!loading">
                <v-col cols="12">
                    <v-btn
                        color="secondary--dark"
                        dark
                        rounded
                        depressed
                        class="text-none float-right mb-2"
                        @click="printPdf('final_report')"
                    >
                        <span class="d-md-none d-lg-flex">
                            Schlussbericht erzeugen
                        </span>
                    </v-btn>
                    <v-btn
                        color="secondary--dark"
                        dark
                        rounded
                        depressed
                        class="text-none float-right mb-2 mr-2"
                        @click="printPdf('learn_contract')"
                    >
                        <span class="d-md-none d-lg-flex">
                            Lernkontrakt erzeugen
                        </span>
                    </v-btn>
                    <v-select
                        :items="languages"
                        v-model="language"
                        class="text-none float-right mb-2 mr-2"
                        style="max-width: 25%; padding-top: 0"
                    />
                    <div class="headline mb-3 font-weight-black">
                        1. Standort
                    </div>
                    <v-card flat>
                        <v-card-text>
                            <v-col cols="12">
                                <v-textarea
                                    type="text"
                                    v-model="data.position"
                                    label="STANDORT"
                                    :error-messages="formError(formErrors, 'position')"
                                    :hint="$t('general.required')"
                                    persistent-hint
                                />
                            </v-col>
                            <v-col cols="12">
                                <v-textarea
                                    type="text"
                                    v-model="data.request"
                                    label="WUNSCH"
                                    :error-messages="formError(formErrors, 'request')"
                                    :hint="$t('general.required')"
                                    persistent-hint
                                />
                            </v-col>
                        </v-card-text>
                    </v-card>
                    <div class="headline mt-6 mb-3 font-weight-black">
                        2. Ziel
                    </div>
                    <v-card flat>
                        <v-card-text>
                            <v-col cols="12">
                                <v-textarea
                                    type="text"
                                    v-model="data.goal"
                                    label="ZIEL"
                                    :error-messages="formError(formErrors, 'goal')"
                                    :hint="$t('general.required')"
                                    persistent-hint
                                />
                            </v-col>
                            <v-col cols="12">
                                <table style="width: 100%">
                                    <thead>
                                        <tr>
                                            <th
                                                class="text-left"
                                                style="width:50%"
                                            >
                                                Welche Massnahmen sind zur Erreichung des Zieles geplant?
                                            </th>
                                            <th class="text-left" />
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <template v-for="question in categories[0].learning_contract_questions">
                                            <tr :key="`answer-${question.id}`">
                                                <td v-html="question.question" />
                                                <td class="checkboxTD">
                                                    <v-checkbox
                                                        v-for="answer in question.learning_contract_choice_answers"
                                                        :key="answer.id"
                                                        :value="answer.answer"
                                                        :label="answer.answer"
                                                        v-model="answers[ question.id ]"
                                                        @click="answerQuestion(question.id, answer)"
                                                        :disabled="isAnswerDisabled(question.id, answer)"
                                                        class="col-2"
                                                    />
                                                </td>
                                            </tr>
                                            <tr :key="`remark-${question.id}`">
                                                <td colspan="2">
                                                    <v-text-field
                                                        :value="remarkToQuestion(question.id)"
                                                        @change="answerRemarkToQuestion(question.id, $event)"
                                                        label="Bemerkung"
                                                    />
                                                </td>
                                            </tr>
                                        </template>
                                    </tbody>
                                </table>
                            </v-col>
                        </v-card-text>
                    </v-card>
                    <div class="headline mb-3 mt-6 font-weight-black">
                        3. Selbsteinschätzung
                    </div>
                    <v-card flat>
                        <v-card-text>
                            <v-col cols="12">
                                <table style="width: 100%">
                                    <thead>
                                        <tr>
                                            <th
                                                class="text-left"
                                                style="width:50%"
                                            >
                                                SELBSTEINSCHÄTZUNG: SKILLS
                                            </th>
                                            <th class="text-left" />
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <template v-for="question in categories[1].learning_contract_questions">
                                            <tr
                                                v-if="!question.question.endsWith('#__end__') && question.id in answers && answers[ question.id ] !== undefined && answers[ question.id ] && 'start' in answers[ question.id ]"
                                                :key="`answer-${question.id}`"
                                            >
                                                <td v-html="question.question" />
                                                <td class="pt-2">
                                                    <v-slider
                                                        v-model="answers[ question.id ].start.answer"
                                                        label="Kursstart"
                                                        color="grey"
                                                        :tick-labels="['0', '1', '2', '3', '4']"
                                                        :max="4"
                                                        step="1"
                                                        ticks="always"
                                                        tick-size="4"
                                                        @click="answerStartEndQuestion(answers[ question.id ].start, 'start')"
                                                    />
                                                    <v-slider
                                                        v-model="answers[ question.id ].end.answer"
                                                        label="Kursende"
                                                        :tick-labels="['0', '1', '2', '3', '4']"
                                                        :max="4"
                                                        step="1"
                                                        ticks="always"
                                                        tick-size="4"
                                                        @click="answerStartEndQuestion(answers[ question.id ].end, 'end')"
                                                    />
                                                </td>
                                            </tr>
                                            <tr
                                                v-if="!question.question.endsWith('#__end__') && question.id in answers && answers[ question.id ] !== undefined && answers[ question.id ] && 'start' in answers[ question.id ]"
                                                :key="`divider-${question.id}`"
                                            >
                                                <td colspan="2">
                                                    <v-divider />
                                                </td>
                                            </tr>
                                        </template>
                                    </tbody>
                                </table>
                            </v-col>
                        </v-card-text>
                    </v-card>
                    <div class="headline mb-3 mt-6 font-weight-black">
                        4. Schlussgespräch
                    </div>
                    <v-card flat>
                        <v-card-text>
                            <v-col cols="12">
                                <ckeditor
                                    :config="editorConfig"
                                    :editor="editor"
                                    v-model="data.final_talk"
                                />
                            </v-col>
                        </v-card-text>
                    </v-card>
                    <v-btn
                        color="secondary--dark"
                        dark
                        rounded
                        depressed
                        class="text-none float-right mt-3"
                        @click="printPdf('final_report')"
                    >
                        <span class="d-md-none d-lg-flex">
                            Schlussbericht erzeugen
                        </span>
                    </v-btn>
                    <v-btn
                        color="secondary--dark"
                        dark
                        rounded
                        depressed
                        class="text-none float-right mt-3 mr-2"
                        @click="printPdf('learn_contract')"
                    >
                        <span class="d-md-none d-lg-flex">
                            Lernkontrakt erzeugen
                        </span>
                    </v-btn>
                    <v-select
                        :items="languages"
                        v-model="language"
                        class="text-none float-right mb-2 mr-2"
                        style="max-width: 25%; padding-top: 5px"
                    />
                </v-col>
            </v-row>
        </v-col>
        <UnsavedForm
            :unsaved-form="unsavedForm"
            :errors="formErrors"
            :on-save="saveFunction"
            :on-cancel="cancelObjectChanges"
        />
    </v-row>
</template>
<style lang="scss">
.checkboxTD .v-input--checkbox:first-child {
  background-color: #e7bddd;
}

.checkboxTD .v-input--checkbox {
  width: 30%;
  display: inline-table;
  margin: 0px;
  padding: 15px 0px 0px 10px;
}
</style>

<script>

import UnsavedForm from '@/components/common/UnsavedForm';
import LearningContractProxy from '@/proxies/LearningContractProxy';
import LearningContractMixin from '@/mixins/learningContract';
import FileDownloadMixin from '@/mixins/file-download';
import { SuccessSnackbar, StandBy } from '@/app-events.js';
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
    name: 'LearningContract',
    components: {
        UnsavedForm,
    },
    mixins: [
        LearningContractMixin,
        FileDownloadMixin,
    ],
    props: {
        participant: {
            type: Object,
            default: () => {
            },
        },
        addProp: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            loading: false,
            unsavedForm: false,
            data: {},
            categories: [],
            answers: {},
            checkAnswers: {},
            remarks: {},
            checkedOnce: [],
            questionIdCheck: [],
            documentType: 'learn_contract',
            language: 'de',
            ex4: [ 'red', 'orange', 'primary', 'secondary', 'success', 'info', 'warning', 'error', 'red darken-3', 'indigo darken-3', 'orange darken-3' ],
            editor: ClassicEditor,
            editorConfig: {
                toolbar: {
                    items: [
                        'heading',
                        '|',
                        'bold',
                        'italic',
                        'link',
                        'bulletedList',
                        'numberedList',
                        'indent',
                        'outdent',
                        'undo',
                        'redo',
                    ],
                },
            },
            languages: [
                {
                    value: 'de',
                    text: 'Deutsch',
                },
                {
                    value: 'en',
                    text: 'Englisch',
                },
            ]
        }
    },
    created() {
        this.fetchData();
    },
    methods: {
        fetchData() {
            this.loading = true;
            new LearningContractProxy().get(this.participant.id).then(({ data }) => {
                this.data = JSON.parse(JSON.stringify(data.data));
                this.rawData = JSON.parse(JSON.stringify(data.data));
                new LearningContractProxy().getQuestions().then(({ data }) => {
                    this.categories = data.data.categories;
                    this.loading = false;
                    this.prepareAnswers();
                }).catch(({ data }) => {
                    this.displayError(data);
                });
            }).catch(({ data }) => {
                this.displayError(data);
            });
        },

        prepareAnswers() {
            for (let i = 0; i < this.categories.length; i++) {
                const category = this.categories[ i ];
                for (let j = 0; j < category.learning_contract_questions.length; j++) {
                    const question = category.learning_contract_questions[ j ];
                    const answer = this.getAnswerToQuestion(question.id);
                    const endAnswer = this.getEndAnswerToQuestion(question.question);
                    if (endAnswer) {
                        this.answers[ question.id ] = {
                            start: answer,
                            end: endAnswer,
                        };
                    } else {

                        if (answer.answer) {
                            this.answers[ question.id ] = answer.answer.split(',');
                        } else {
                            this.answers[ question.id ] = [];
                        }
                    }
                }
            }
        },
        saveFunction() {
            this.$eventBus.dispatch(new StandBy(true));
            if (this.data.id) {
                new LearningContractProxy().update(this.participant.id, true, null, this.prepareFormData(this.data)).then(({ data }) => {
                    this.$eventBus.dispatch(new StandBy(false));
                    this.$eventBus.dispatch(new SuccessSnackbar(this.$t('note.actions.saved')));
                    this.unsavedForm = false;
                    this.resetErrors();
                }).catch(({ data }) => {
                    this.processErrors(data);
                    this.$eventBus.dispatch(new StandBy(false));
                });
            } else {
                new LearningContractProxy().new(this.prepareFormData(this.data)).then(({ data }) => {
                    this.$eventBus.dispatch(new StandBy(false));
                    this.$eventBus.dispatch(new SuccessSnackbar(this.$t('note.actions.saved')));
                    this.resetErrors();
                }).catch(({ data }) => {
                    this.processErrors(data);
                    this.$eventBus.dispatch(new StandBy(false));
                });
            }
        },
        cancelObjectChanges() {
            this.resetObject();
        },
        isAnswerDisabled(questionId, answer) {
            if (answer.answer === 'geplant') {
                return false;
            }
            for (let i = 0; i < this.data.learning_contract_participant_answers.length; i++) {
                const participantAnswer = this.data.learning_contract_participant_answers[ i ];
                if (participantAnswer.learning_contract_question.id === questionId) {
                    if (!this.answers[ questionId ].includes('geplant')) {
                        return true;
                    }
                }
            }
        },
        answerQuestion(questionId, answer) {
            this.checkedOnce[ questionId ] = true;
            this.questionIdCheck.push(questionId);
            for (let i = 0; i < this.data.learning_contract_participant_answers.length; i++) {
                const participantAnswer = this.data.learning_contract_participant_answers[ i ];
                if (participantAnswer.learning_contract_question.id === questionId) {
                    if (answer.answer === 'erreicht' && this.answers[ questionId ].includes('nicht erreicht')) {
                        const index = this.answers[ questionId ].indexOf('nicht erreicht');
                        if (index > -1) {
                            this.answers[ questionId ].splice(index, 1);
                        }
                    } else if (answer.answer === 'nicht erreicht' && this.answers[ questionId ].includes('erreicht')) {
                        const index = this.answers[ questionId ].indexOf('erreicht');
                        if (index > -1) {
                            this.answers[ questionId ].splice(index, 1);
                        }
                    }
                    if (answer.answer === 'geplant' && (this.answers[ questionId ].includes('nicht erreicht') || this.answers[ questionId ].includes('erreicht')) && !this.answers[ questionId ].includes('geplant')) {
                        const index = this.answers[ questionId ].indexOf('erreicht');
                        if (index > -1) {
                            this.answers[ questionId ].splice(index, 1);
                        }
                        const index2 = this.answers[ questionId ].indexOf('nicht erreicht');
                        if (index2 > -1) {
                            this.answers[ questionId ].splice(index, 1);
                        }
                    }
                    if (null !== participantAnswer.answer) {
                        if (this.answers[ questionId ] === null) {
                            participantAnswer.answer = '';
                        } else {
                            participantAnswer.answer = this.answers[ questionId ].join(',');
                        }
                        break;
                    } else {
                        participantAnswer.answer = answer.answer.toString();
                        break;
                    }
                }
            }
        },
        formErrorCheckbox(questionID) {
            if (this.checkedOnce[ questionID ]) {
                if (this.answers[ questionID ] && this.answers[ questionID ].length > 0) {
                    return '';
                } else {
                    if (1 === questionID) {
                        return 'Bitte auswählen';
                    }

                    return '';
                }
            }
        },
        answerStartEndQuestion(answer, which) {
            const questionId = answer.learning_contract_question.id;
            for (let i = 0; i < this.data.learning_contract_participant_answers.length; i++) {
                const participantAnswer = this.data.learning_contract_participant_answers[ i ];
                if (participantAnswer.learning_contract_question.id === questionId) {
                    participantAnswer.answer = answer.answer.toString();
                    break;
                }
            }
        },
        getAnswerToQuestion(questionId) {
            for (let i = 0; i < this.data.learning_contract_participant_answers.length; i++) {
                const participantAnswer = this.data.learning_contract_participant_answers[ i ];
                if (participantAnswer.learning_contract_question.id === questionId) {
                    return this.data.learning_contract_participant_answers[ i ];
                }
            }
            return null;
        },
        getEndAnswerToQuestion(questionName) {
            const endQuestion = `${questionName}#__end__`;
            for (let i = 0; i < this.data.learning_contract_participant_answers.length; i++) {
                const participantAnswer = this.data.learning_contract_participant_answers[ i ];
                if (participantAnswer.learning_contract_question.question === endQuestion) {
                    return this.data.learning_contract_participant_answers[ i ];
                }
            }
            return null;
        },
        printPdf(docType) {
            this.$eventBus.dispatch(new StandBy(true));
            new LearningContractProxy().print(this.participant.id, docType, this.language).then(({ data }) => {
                this.openPdf(data);
            }).catch(({ data }) => {
            }).finally(() => {
                this.$eventBus.dispatch(new StandBy(false));
            });
        },
        remarkToQuestion(questionId) {
            for (let i = 0; i < this.data.learning_contract_participant_answers.length; i++) {
                const participantAnswer = this.data.learning_contract_participant_answers[ i ];
                if (participantAnswer.learning_contract_question.id === questionId) {
                    return this.data.learning_contract_participant_answers[ i ].remark;
                }
            }
            return null;
        },
        answerRemarkToQuestion(questionId, event) {
            for (let i = 0; i < this.data.learning_contract_participant_answers.length; i++) {
                const participantAnswer = this.data.learning_contract_participant_answers[ i ];
                if (participantAnswer.learning_contract_question.id === questionId) {
                    this.data.learning_contract_participant_answers[ i ].remark = event;
                    break;
                }
            }
        }
    },
    watch: {
        rawData: {
            handler(_, oldValue) {
                this.unsavedForm = false;
                const participantAnswerText = this.data;
                const originalAnswersText = this.rawData;
                if ((participantAnswerText.position !== originalAnswersText.position) || (participantAnswerText.request !== originalAnswersText.request) || (participantAnswerText.goal !== originalAnswersText.goal) || (participantAnswerText.final_talk !== originalAnswersText.final_talk)) {
                    this.unsavedForm = true;
                    return;
                }
                for (let i = 0; i < this.data.learning_contract_participant_answers.length; i++) {
                    const participantAnswer = this.data.learning_contract_participant_answers[ i ];
                    const originalAnswer = this.rawData.learning_contract_participant_answers[ i ];
                    participantAnswer.answer = participantAnswer.answer.toString();
                    if ((participantAnswer.answer !== originalAnswer.answer) || (participantAnswer.remark !== originalAnswer.remark)) {
                        this.unsavedForm = true;
                        break;
                    } else {
                        this.unsavedForm = false;
                    }
                }
            },
            deep: true
        },
        data: {
            handler(_, oldValue) {
                this.unsavedForm = false;
                const participantAnswerText = this.data;
                const originalAnswersText = this.rawData;
                if ((participantAnswerText.position !== originalAnswersText.position) || (participantAnswerText.request !== originalAnswersText.request) || (participantAnswerText.goal !== originalAnswersText.goal) || (participantAnswerText.final_talk !== originalAnswersText.final_talk)) {
                    this.unsavedForm = true;
                    return;
                }
                for (let i = 0; i < this.data.learning_contract_participant_answers.length; i++) {
                    const participantAnswer = this.data.learning_contract_participant_answers[ i ];
                    const originalAnswer = this.rawData.learning_contract_participant_answers[ i ];
                    if (null === participantAnswer.answer) {
                        this.unsavedForm = false;
                    } else {
                        participantAnswer.answer = participantAnswer.answer.toString();
                        if ((participantAnswer.answer !== originalAnswer.answer) || (participantAnswer.remark !== originalAnswer.remark)) {
                            this.unsavedForm = true;
                            break;
                        } else {
                            this.unsavedForm = false;
                        }
                    }

                }
            },
            deep: true
        },
        addProp(val) {

            this.printPdf(this.documentType);
        },
    }
};
</script>
