<template>
    <div>
        <div class="display-2">
            {{ $t('page.auth.password_reset.title') }}
        </div>
        <div class="subtitle-1 my-4">
            Für die Coaches der bewerbeagentur
        </div>
        <v-card
            flat
            class="pa-12"
        >
            <v-card-text>
                <v-alert
                    v-if="authError !== null"
                    :value="true"
                    type="error"
                >
                    {{ $t(authError) }}
                </v-alert>
                <v-form
                    @submit.prevent="submit"
                    v-if="!sent"
                >
                    <v-text-field
                        id="password"
                        v-model="password"
                        prepend-icon="mdi-lock"
                        name="password"
                        required
                        :label="$t('page.auth.password_reset.new_password')"
                        type="password"
                        :error-messages="formError(formErrors, 'password.children.first')"
                    />
                    <v-text-field
                        id="password_confirmation"
                        v-model="password_confirmation"
                        prepend-icon="mdi-lock"
                        name="password_confirmation"
                        required
                        :label="$t('page.auth.password_reset.new_password_confirmation')"
                        type="password"
                        :error-messages="formError(formErrors, 'password.children.second')"
                    />
                    <v-btn
                        style="display: none;"
                        type="submit"
                    >
                        Login
                    </v-btn>
                </v-form>
                <div
                    v-else
                    v-text="$t('page.auth.password_reset.success')"
                />
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn
                    v-if="sent"
                    text
                    small
                    :to="{ name: 'login' }"
                >
                    {{ $t('general.back') }}
                </v-btn>
                <v-btn
                    v-else
                    color="secondary--dark"
                    class="px-4"
                    dark
                    rounded
                    depressed
                    type=""
                    @click="submit"
                >
                    {{ $t('page.auth.password_reset.button') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
import AuthProxy from '@/proxies/AuthProxy';

export default {
    name: 'AuthPasswordReset',
    data() {
        return {
            password: '',
            password_confirmation: '',
            sent: false,
            authError: null,
        };
    },
    methods: {
        submit() {
            new AuthProxy().resetPassword(this.$route.params.token, {
                password_reset: {
                    password: {
                        first: this.password,
                        second: this.password_confirmation,
                    }
                },
            }).then(({ data }) => {
                this.sent = true;
                this.resetErrors();
            }).catch(({ data }) => {
                this.processErrors(data);
            });
        },
    },
};
</script>
