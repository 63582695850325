<template>
    <v-card flat>
        <v-card-text>
            <v-row>
                <v-col cols="12">
                    <v-text-field
                        type="text"
                        v-model="user.coach.firstname"
                        :label="$t('user.firstname')"
                        required
                        :error-messages="formError(errors, 'coach.children.firstname')"
                        :hint="$t('general.required')"
                        persistent-hint
                    />
                </v-col>
                <v-col cols="12">
                    <v-text-field
                        type="text"
                        v-model="user.coach.lastname"
                        :label="$t('user.lastname')"
                        required
                        :error-messages="formError(errors, 'coach.children.lastname')"
                        :hint="$t('general.required')"
                        persistent-hint
                    />
                </v-col>
                <v-col cols="12">
                    <v-text-field
                        type="text"
                        v-model="user.coach.phone"
                        :label="$t('coach.phone')"
                        :error-messages="formError(errors, 'coach.children.phone')"
                    />
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>

export default {
    name: 'CoachInformation',
    props: {
        user: {
            type: Object,
            default: () => ({
                coach: {
                    firstname: '',
                    lastname: '',
                    phone: '',
                }
            }),
        },
        errors: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            roles: [
                {
                    value: 'ROLE_COACH',
                    text: this.$t('user.role.coach'),
                },
                {
                    value: 'ROLE_ADMIN',
                    text: this.$t('user.role.admin'),
                },
                // {
                //     value: 'ROLE_PARTICIPANT',
                //     text: this.$t('user.role.participant'),
                // },
            ]
        }
    },
};
</script>
