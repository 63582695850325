<template>
    <v-dialog
        v-model="dialog"
        persistent
        max-width="800"
    >
        <v-card>
            <v-card-title
                class="headline"
            >
                {{ title ? title : 'E-Mail senden' }}
            </v-card-title>
            <v-card-text>
                <v-col
                    v-if="to"
                    cols="12"
                >
                    <v-text-field
                        type="text"
                        label="An"
                        v-model="data.to"
                    />
                </v-col>
                <v-col
                    cols="12"
                >
                    <v-text-field
                        type="text"
                        label="Betreff"
                        v-model="data.subject"
                        hint="Der Teilnehmer ist im cc!"
                        :persistent-hint="true"
                    />
                </v-col>
                <v-col
                    cols="12"
                >
                    <ckeditor
                        :config="editorConfig"
                        :editor="editor"
                        v-model="data.content"
                    />
                </v-col>
                <v-col
                    v-if="attachments"
                    cols="12"
                >
                    <v-file-input
                        v-model="data.attachments"
                        color="primary"
                        counter
                        label="Email Anhang"
                        multiple
                        :placeholder="$t('page.participant_upload.select')"
                        prepend-icon=""
                        prepend-inner-icon="mdi-paperclip"
                        outlined
                        :show-size="1000"
                        hint="Das ausgewählte Dokument wird automatisch beigefügt!"
                        :persistent-hint="true"
                    >
                        <template #selection="{ index, text }">
                            <v-chip
                                v-if="index < 2"
                                color="primary"
                                dark
                                label
                                small
                            >
                                {{ text }}
                            </v-chip>

                            <span
                                v-else-if="index === 2"
                                class="overline grey--text text--darken-3 mx-2"
                            >
                                +{{ data.attachments.length - 2 }} {{ $t('general.files') }}
                            </span>
                        </template>
                    </v-file-input>
                </v-col>
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn
                    v-if="!loading"
                    text
                    @click="cancel()"
                >
                    {{ $t('general.cancel') }}
                </v-btn>
                <v-btn
                    color="red darken-1"
                    :loading="loading"
                    text
                    @click="confirm()"
                >
                    {{ $t('general.send') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
    name: 'EmailDialog',
    props: {
        dialog: {
            type: Boolean,
            default: false,
        },
        attachments: {
            type: Boolean,
            default: false,
        },
        to: {
            type: Boolean,
            default: false,
        },
        data: {
            type: Object,
            default: () => ({
                subject: '',
                content: '',
                to: '',
                attachments: [],
            }),
        },
        title: {
            type: String,
            default: '',
        },
        loading: {
            type: Boolean,
            default: false,
        },
        send: {
            type: Function,
            default: () => ({}),
        },
        cancel: {
            type: Function,
            default: () => ({}),
        },
        confirm: {
            type: Function,
            default: () => ({}),
        },
    },
    data() {
        return {
            editor: ClassicEditor,
            editorConfig: {
                toolbar: {
                    items: [
                        'heading',
                        '|',
                        'bold',
                        'italic',
                        'link',
                        'bulletedList',
                        'numberedList',
                        'indent',
                        'outdent',
                        'undo',
                        'redo',
                        'source',
                    ],
                },
            },
            titleDisplay: '',
            cancelTextDisplay: '',
        }
    },
};
</script>
