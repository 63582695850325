<template>
    <v-col
        cols="12"
        class="d-print-none"
    >
        <MobileNewButton
            v-if="newButton"
            :to="newTo"
            :label="newLabel"
        />
        <v-tooltip 
            v-if="fetch"
            bottom
        >
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    v-on="on"
                    v-bind="attrs"
                    small
                    icon
                    @click="fetchFunction()"
                >
                    <v-icon>mdi-sync</v-icon>
                </v-btn>
            </template>
            <span v-text="$t('general.refresh')" />
        </v-tooltip>
        <slot name="addons" />
        <slot name="extra" />
    </v-col>
</template>

<script>
import MobileNewButton from '@/components/common/button/MobileNewButton';

export default {
    name: 'ActionBar',
    components: {
        MobileNewButton,
    },
    props: {
        newButton: {
            type: Boolean,
            default: false,
        },
        newTo: {
            type: String,
            default: '',
        },
        newLabel: {
            type: String,
            default: '',
        },
        fetch: {
            type: Boolean,
            default: false,
        },
        fetchFunction: {
            type: Function,
            default: () => ({}),
        },
    },
};
</script>
