<template>
    <div>
        Login out...
    </div>
</template>

<script>
export default {
    name: 'AuthLogout',
    created() {
        this.$store.dispatch('auth/logout', {
            authManager: this.$auth,
            localstorageManager: this.$localStorage,
        });
    },
};
</script>
