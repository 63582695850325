<template>
    <div>
        <v-row
            dense
            v-for="(week, weekKey) in computedObjects"
            :key="weekKey"
            class="mb-6"
        >
            <v-col
                cols="10"
                offset-lg="1"
            >
                {{ week.default ? 'STANDARD WOCHE' : `Woche von ${formatDate(formatDateISO(week.monday))} -  ${formatDate(formatDateISO(week.friday))}` }}
            </v-col>
            <v-col 
                v-for="(day, dayKey) in week.days"
                :key="dayKey"
                lg="2"
                cols="12"
                :offset-lg="dayKey === '1' ? 1 : 0"
                class="d-flex"
            >
                <v-card
                    class="mx-auto flex-grow-1 pb-3"
                    outlined
                >
                    <v-list-item three-line>
                        <v-list-item-content>
                            <v-row
                                align="center"
                                justify="space-between"
                                class="mx-0"
                            >
                                <span
                                    class="text-body-1 font-weight-bold"
                                    v-text="`${$t(`general.day_of_week.${dayKey}`)}`"
                                />

                                <v-tooltip left>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            v-on="on"
                                            v-bind="attrs"
                                            :to="{ name: 'registration_time_slot_new', params: { date: `${week.default ? 1 : 0}.${formatDateISO(week.monday)}.${dayKey}` }}"
                                            icon
                                        >
                                            <v-icon>mdi-plus</v-icon>
                                        </v-btn>
                                    </template>
                                    <span v-text="$t('page.registration_time_slot.new')" />
                                </v-tooltip>
                            </v-row>
                            <hr class="my-3">
                            <div
                                v-for="(entry, entryKey) in day"
                                :key="entryKey"
                            >
                                <v-list-item-subtitle class="mt-3">
                                    {{ entry.start.split(' ')[1] }}
                                    <v-tooltip left>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon
                                                v-on="on"
                                                v-bind="attrs"
                                                small
                                                color="grey"
                                                class="float-right ml-1"
                                                @click="initiateDeletion(entry)"
                                            >
                                                mdi-delete
                                            </v-icon>
                                        </template>
                                        <span v-text="$t('general.delete')" />
                                    </v-tooltip>
                                    <v-tooltip left>
                                        <template v-slot:activator="{ on, attrs }">
                                            <router-link
                                                class="registration-time-slot-link float-right"
                                                :to="{ name: 'registration_time_slot_detail', params: { id: entry.id }}"
                                            >
                                                <v-icon
                                                    small
                                                    color="grey"
                                                    v-on="on"
                                                    v-bind="attrs"
                                                >
                                                    mdi-pencil
                                                </v-icon>
                                            </router-link>
                                        </template>
                                        <span v-text="$t('general.edit')" />
                                    </v-tooltip>
                                </v-list-item-subtitle>
                                <v-list-item-subtitle
                                    class="font-weight-black"
                                >
                                    <router-link
                                        class="registration-time-slot-link"
                                        :to="{ name: 'registration_time_slot_detail', params: { id: entry.id }}"
                                    >
                                        <p :style="entry.used ? 'text-decoration: line-through;' : ''">
                                            {{ entry.course }}
                                        </p>
                                    </router-link>
                                </v-list-item-subtitle>
                                <v-list-item-subtitle v-text="entry.professor" />
                            </div>
                        </v-list-item-content>
                    </v-list-item>
                </v-card>
            </v-col>
        </v-row>
        <ConfirmationDialog
            :confirm="deleteObject"
            :cancel="() => (deleteDialog = false)"
            :loading="deleting"
            :dialog="deleteDialog"
        />
    </div>
</template>

<style scoped>
.registration-time-slot-link {
    color: rgba(0, 0, 0, 0.6);
    text-decoration: none;
}
</style>
<script>

import ConfirmationDialog from '@/components/common/ConfirmationDialog';
import RegistrationTimeSlotProxy from '@/proxies/RegistrationTimeSlotProxy';
import DatesMixin from '@/mixins/dates';
import { ObjectDeleted, StandBy } from '@/app-events.js';

Date.prototype.getWeekNumber = function(){
    var d = new Date(Date.UTC(this.getFullYear(), this.getMonth(), this.getDate()));
    var dayNum = d.getUTCDay() || 7;
    d.setUTCDate(d.getUTCDate() + 4 - dayNum);
    var yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
    return Math.ceil((((d - yearStart) / 86400000) + 1)/7)
};

export default {
    name: 'RegistrationTimeSlot',
    components: {
        ConfirmationDialog,
    },
    mixins: [ DatesMixin ],
    props: {
        search: {
            type: String,
            default: '',
        },
        selection: {
            type: Boolean,
            default: false,
        },
        objects: {
            type: Array,
            default: () => ([]),
        },
    },
    data() {
        return {
            deleteDialog: false,
            deleting: false,
            objectToDelete: null,
        }
    },
    computed: {
        computedObjects() {
            let weeks = {};
            let index = 0;
            let lastWeek = null;
            this.objects.forEach(entry => {
                entry.startDate = new Date(entry.start);
                const dayOfWeek = entry.startDate.getDay();
                const weekNumber = entry.startDate.getWeekNumber();
            
                if (lastWeek !== null && lastWeek !== weekNumber) {
                    index++;
                }

                const monday = this.getMonday(entry.startDate);
                const friday = new Date(monday.getTime());
                friday.setDate(monday.getDate() + 4);

                if (!Object.prototype.hasOwnProperty.call(weeks, index)) {
                    weeks[ index ] = {
                        default: entry.default,
                        monday: monday,
                        friday: friday,
                        days: {
                            1: [],
                            2: [],
                            3: [],
                            4: [],
                            5: [],
                        },
                    };
                }
                weeks[ index ].days[ dayOfWeek ].push(entry);

                
                //if (entry.default) {
                //    this.defaultWeek[ dayOfWeek ].push(entry);
                //} else {
                //    
                //}

                //weeks[ entry.week ][ entry.day ].push(entry);

                lastWeek = weekNumber;
            });
            return weeks;
        }
    },
    methods: {
        initiateDeletion(item) {
            this.objectToDelete = item;
            this.deleteDialog = true;
        },
        deleteObject() {
            this.deleting = true;
            new RegistrationTimeSlotProxy().delete(this.objectToDelete.id).then(({ data }) => {
                this.$eventBus.dispatch(new ObjectDeleted({
                    object: this.objectToDelete,
                }));
            }).catch(({ data }) => {
                this.displayError(data);
            }).finally(() => {
                this.deleteDialog = false;
                this.objectToDelete = null;
                this.deleting = false;
            });
        },
        getMonday(d) {
            const day = d.getDay();
            const diff = d.getDate() - day + (day == 0 ? -6:1); // adjust when day is sunday
            return new Date(d.setDate(diff));
        }
    },
};
</script>
