<template>
    <div>
        <v-row
            dense
            v-for="(weekValue, weekKey) in objects"
            :key="weekKey"
            class="mb-6"
        >
            <v-col
                cols="10"
                offset-lg="1"
            >
                {{ $t('calendar.week') }} {{ weekValue.week_no }} – {{ weekValue['name'] }}
                <v-btn
                    plain
                    class="mx-1 px-1"
                    @click="startEditObject(weekValue)"
                >
                    <v-icon>
                        mdi-pencil
                    </v-icon>
                </v-btn>
                <v-btn
                    plain
                    class="mx-1 px-1"
                    @click="initiateWeekDeletion(weekValue)"
                >
                    <v-icon>
                        mdi-delete
                    </v-icon>
                </v-btn>
            </v-col>
            <v-col
                v-for="(dayValue,dayKey) in daysArr"
                :key="dayKey"
                lg="2"
                cols="12"
                :offset-lg="dayValue === 1 ? 1 : 0"
                class="d-flex"
            >
                <v-card
                    class="mx-auto flex-grow-1 pb-3"
                    outlined
                >
                    <v-list-item three-line>
                        <v-list-item-content>
                            <v-row
                                align="center"
                                justify="space-between"
                                class="mx-0"
                            >
                                <span
                                    class="text-body-1 font-weight-bold"
                                    v-text="`${$t(`general.day_of_week.${dayValue}`)}`"
                                />

                                <v-tooltip left>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            v-on="on"
                                            v-bind="attrs"
                                            :to="{ name: 'calendar_new', params: { weekDay: `${weekValue.id}-${dayValue}` }}"
                                            icon
                                        >
                                            <v-icon>mdi-plus</v-icon>
                                        </v-btn>
                                    </template>
                                    <span v-text="$t('page.calendar.new')" />
                                </v-tooltip>
                            </v-row>
                            <hr class="my-3">
                            <div v-if="(weekValue.calendar_static_entries).length >0">
                                <div
                                    v-for="(entry,ckey) in computedWeekDay[weekValue.week_no][dayValue]"
                                    :key="ckey"
                                >
                                    <v-list-item-subtitle class="mt-3">
                                        {{ entry.start }} - {{ entry.end }}
                                        <v-tooltip left>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon
                                                    v-on="on"
                                                    v-bind="attrs"
                                                    small
                                                    color="grey"
                                                    class="float-right ml-1"
                                                    @click="initiateDeletion(entry)"
                                                >
                                                    mdi-delete
                                                </v-icon>
                                            </template>
                                            <span v-text="$t('general.delete')" />
                                        </v-tooltip>
                                        <v-tooltip left>
                                            <template v-slot:activator="{ on, attrs }">
                                                <router-link
                                                    class="calendar-link float-right"
                                                    :to="{ name: 'calendar_detail', params: { id: entry.id }}"
                                                >
                                                    <v-icon
                                                        small
                                                        color="grey"
                                                        v-on="on"
                                                        v-bind="attrs"
                                                    >
                                                        mdi-pencil
                                                    </v-icon>
                                                </router-link>
                                            </template>
                                            <span v-text="$t('general.edit')" />
                                        </v-tooltip>
                                    </v-list-item-subtitle>
                                    <v-list-item-subtitle
                                        class="font-weight-black"
                                    >
                                        <router-link
                                            class="calendar-link"
                                            :to="{ name: 'calendar_detail', params: { id: entry.id }}"
                                        >
                                            {{ entry.course }}
                                        </router-link>
                                    </v-list-item-subtitle>
                                    <v-list-item-subtitle v-text="entry.professor" />
                                </div>
                            </div>
                        </v-list-item-content>
                    </v-list-item>
                </v-card>
            </v-col>
        </v-row>

        <ConfirmationDialog
            :confirm="deleteObject"
            :cancel="() => (deleteDialog = false)"
            :loading="deleting"
            :dialog="deleteDialog"
        />
        <v-dialog
            v-model="editDialog"
            persistent
            max-width="800px"
        >
            <v-card>
                <v-card-title>
                    {{ weekname }}
                </v-card-title>
                <v-card-subtitle v-text="`weekname`" />
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col
                                cols="12"
                            >
                                <v-text-field
                                    type="text"
                                    :label="nameLabel"
                                    v-model="weekname"
                                    :rules="rules"
                                    :error-messages="formError(formErrors, 'course_name')"
                                    :hint="$t('general.required')"
                                />
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn
                        color="default"
                        text
                        @click="editDialog = false"
                    >
                        {{ $t('general.cancel') }}
                    </v-btn>
                    <v-btn
                        color="primary darken-1"
                        text
                        @click="updateWeekObject(week)"
                    >
                        {{ $t('general.save') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<style scoped>
.calendar-link {
    color: rgba(0, 0, 0, 0.6);
    text-decoration: none;
}
</style>
<script>

import ConfirmationDialog from '@/components/common/ConfirmationDialog';
import CalendarProxy from '@/proxies/CalendarProxy';
import { ObjectDeleted, StandBy } from '@/app-events.js';
import WeekProxy from "../../proxies/WeekProxy";
import WeekMixin from '@/mixins/week';

export default {
    name: 'Calendar',
    mixins: [ WeekMixin ],
    components: {
        ConfirmationDialog,
    },
    props: {
        search: {
            type: String,
            default: '',
        },
        selection: {
            type: Boolean,
            default: false,
        },
        objects: {
            type: Array,
            default: () => ([]),
        },
    },
    data() {
        return {
            rules: [
                value => !!value || 'Pflichtfeld.',
            ],
            deleteDialog: false,
            deleting: false,
            objectToDelete: null,
            weekToDelete: null,
            weekname:'',
            week:'',
            nameLabel:'Week Name',
            editDialog: false,
            daysArr: [ 1,2,3,4,5 ],
        }
    },
    computed: {
        computedWeekDay() {
            let weekDayArr = [];
            this.objects.forEach(weeksArr => {
                let weekData = weeksArr[ 'calendar_static_entries' ];

                for (const value1 of weekData) {
                    if ( !weekDayArr.hasOwnProperty(weeksArr[ 'week_no' ] ) )
                    {
                        weekDayArr[ weeksArr[ 'week_no' ] ] = [];
                    }
                    if ( ! weekDayArr[ weeksArr[ 'week_no' ] ].hasOwnProperty(value1[ 'day' ]) )
                    {
                        weekDayArr[ weeksArr[ 'week_no' ] ][ value1[ 'day' ] ] = [];
                    }
                    weekDayArr[ weeksArr[ 'week_no' ] ][ value1[ 'day' ] ].push(value1);
                }
            });
            return weekDayArr;
        },
    },
    methods: {
        initiateDeletion(item) {
            this.objectToDelete = item;
            this.deleteDialog = true;
        },
        initiateWeekDeletion(item) {
            this.weekToDelete = item;
            this.deleteDialog = true;
        },
        startEditObject(week) {
            this.editDialog = true;
            this.week = week;
            this.weekname = week.name;
        },
        deleteObject() {

            this.deleting = true;
            if (null !== this.weekToDelete)
            {
                new WeekProxy().delete(this.weekToDelete.id).then(({ data }) => {
                    this.$eventBus.dispatch(new ObjectDeleted({
                        object: this.weekToDelete,
                    }));
                }).catch(({ data }) => {
                    this.displayError(data);
                }).finally(() => {
                    this.deleteDialog = false;
                    this.weekToDelete = null;
                    this.deleting = false;
                });
            }else
            {
                new CalendarProxy().delete(this.objectToDelete.id).then(({ data }) => {
                    this.$eventBus.dispatch(new ObjectDeleted({
                        object: this.objectToDelete,
                    }));
                }).catch(({ data }) => {
                    this.displayError(data);
                }).finally(() => {
                    this.deleteDialog = false;
                    this.objectToDelete = null;
                    this.deleting = false;
                });
            }
        },
        updateWeekObject(object) {
            object.name = this.weekname;
            if (object.name !== '')
            {
                new WeekProxy().update(object.id, this.prepareFormData(object)).then(({ data }) => {
                    this.editDialog = false
                }).catch(({ data }) => {
                    this.processErrors(data);
                    this.$eventBus.dispatch(new StandBy(false));
                });
            }
        },
    },
};
</script>
