<template>
    <v-select
        background-color="secondary"
        color="accent"
        rounded
        dark
        clearable
        :full-width="false"
        :items="items"
        :label="label"
        value="value"
        @change="$emit('input', $event)"
    />
</template>

<script>

export default {
    name: 'Select',
    props: {
        items: {
            type: Array,
            default: () => ([]),
        },
        label: {
            type: String,
            default: '',
        },
        value: {
            type: String,
            default: '',
        },
    },
};
</script>
