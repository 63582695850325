import Proxy from './Proxy';

class StatisticsProxy extends Proxy {
    constructor(parameters = {}) {
        super('/admin/statistics', parameters);
    }

    exitStatus(start, end) {
        return this.submit('get', `/exit-status/${start}/${end}`, null, null, {}, 'blob');
    }

    dropoutRate(start, end) {
        return this.submit('get', `/dropout-rate/${start}/${end}`, null, null, {}, 'blob');
    }

    participations(start, end) {
        return this.submit('get', `/participation/${start}/${end}`, null, null, {}, 'blob');
    }

    waitlist(start, end) {
        return this.submit('get', `/waitlist/${start}/${end}`, null, null, {}, 'blob');
    }

    integration(start, end) {
        return this.submit('get', `/integration/${start}/${end}`, null, null, {}, 'blob');
    }

    workload(start, end) {
        return this.submit('get', `/workload/${start}/${end}`, null, null, {}, 'blob');
    }

    month(year) {
        return this.submit('get', `/month/${year}`, null, null, {}, 'blob');
    }

    canton(year) {
        return this.submit('get', `/canton/${year}`, null, null, {}, 'blob');
    }

    firstInterview(year) {
        return this.submit('get', `/first-interview/${year}`, null, null, {}, 'blob');
    }
}

export default StatisticsProxy;
