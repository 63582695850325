<template>
    <v-flex
        md12
        class="px-12 pb-12"
    >
        <PageHeader
            :breadcrumbs="breadcrumbs"
            :title="$t('page.general_setting.title')"
        />
        <v-row>
            <ActionBar
                new-button
                new-to="general_setting_new"
                :new-label="$t('page.general_setting.new')"
                fetch
                :fetch-function="fetchData"
            />
            <v-col cols="12">
                <v-progress-linear
                    v-if="loading"
                    indeterminate
                    color="primary"
                />
                <v-skeleton-loader
                    v-if="loading"
                    class="mx-auto"
                    type="table-tbody"
                />
                <GeneralSettingList
                    v-if="!loading"
                    :objects="objects"
                    :search="textSearch"
                />
            </v-col>
        </v-row>
    </v-flex>
</template>

<script>

import ActionBar from '@/components/common/ActionBar';
import PageHeader from '@/components/common/PageHeader';
import GeneralSettingList from '@/components/generalSetting/GeneralSettingList';
import GeneralSettingProxy from '@/proxies/GeneralSettingProxy';

import { SuccessSnackbar } from '@/app-events.js';

export default {
    name: 'GeneralSettingIndex',
    components: {
        ActionBar,
        PageHeader,
        GeneralSettingList,
    },
    data() {
        return {
            breadcrumbs: [
                {
                    text: this.$t('page.home.title'),
                    exact: true,
                    to: { name: 'home' },
                },
                {
                    text: this.$t('page.general_setting.title'),
                    disabled: true,
                },
            ],
            textSearch: '',
            objects: [],
            loading: true,
        };
    },
    created () {
        this.fetchData();
    },
    methods: {
        fetchData() {
            this.loading = true;
            new GeneralSettingProxy().all().then(({ data }) => {
                this.objects = data.data;
                this.loading = false;
            }).catch(({ data }) => {
                this.displayError(data);
            });
        },
        searchInputListener(value) {
            this.textSearch = value;
        },
    },
};
</script>
