<template>
    <v-flex
        md12
        class="px-12 pb-12"
    >
        <PageHeader
            :breadcrumbs="breadcrumbs"
            :title="$t('page.participant.title')"
            button
            :button-text="$t('page.participant.new')"
            button-to="participant_new"
            search
            :search-label="$t('page.participant.search')"
            @search-input="searchInputListener"
        />
        <v-row>
            <v-col
                cols="1"
                v-if="false"
            >
                <v-btn
                    @click="showAdvancedSearched = !showAdvancedSearched"
                    text
                >
                    <v-icon v-if="!showAdvancedSearched">
                        mdi-chevron-down
                    </v-icon>
                    <v-icon v-else>
                        mdi-chevron-up
                    </v-icon>
                    Advanced Search
                </v-btn>
            </v-col>
            <v-slide-y-transition>
                <AdvancedSearch
                    v-show="showAdvancedSearched"
                    v-model="advancedSearched"
                />
            </v-slide-y-transition>
            <v-col cols="12">
                <TabMenu
                    v-model="menu"
                    :menus="menus"
                />
            </v-col>
            <ActionBar
                new-button
                new-to="participant_new"
                :new-label="$t('page.participant.new')"
                fetch
                :fetch-function="fetchData"
            >
                <template #addons>
                    <v-menu
                        offset-y
                        open-on-hover
                    >
                        <template #activator="{ on, attrs }">
                            <v-btn
                                class="ml-3"
                                small
                                rounded
                                depressed
                                v-bind="attrs"
                                v-on="on"
                            >
                                <v-icon class="mr-2">
                                    mdi-database-export
                                </v-icon>
                                {{ $t('general.export') }}
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item @click="exportList('active')">
                                <v-list-item-action>
                                    <v-icon>mdi-file-excel</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title v-text="$t('page.participant.export.active_participant')" />
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="exportList('red_group')">
                                <v-list-item-action>
                                    <v-icon>mdi-file-excel</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title v-text="$t('page.participant.export.red_group')" />
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="exportList('blue_group')">
                                <v-list-item-action>
                                    <v-icon>mdi-file-excel</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title v-text="$t('page.participant.export.blue_group')" />
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="exportList('missing_information')">
                                <v-list-item-action>
                                    <v-icon>mdi-file-excel</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title v-text="$t('page.participant.export.missing_information')" />
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                    <v-menu
                        v-if="selectedRows.length > 0"
                        offset-y
                    >
                        <template #activator="{ on, attrs }">
                            <v-btn
                                class="ml-3"
                                small
                                rounded
                                depressed
                                v-bind="attrs"
                                v-on="on"
                            >
                                {{ selectedRows.length }} {{ $t('general.selected') }}
                                <v-icon>mdi-chevron-down</v-icon>
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item @click="openCertificateDialog('open')">
                                <v-list-item-action>
                                    <v-icon>mdi-certificate</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title v-text="$t('participant.downloads.amm_certificates')" />
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="openCertificateDialog('send')">
                                <v-list-item-action>
                                    <v-icon>mdi-certificate</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title v-text="$t('participant.downloads.amm_certificates_send')" />
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item
                                v-if="false"
                                @click="emailContract('send')"
                            >
                                <v-list-item-action>
                                    <v-icon>mdi-certificate</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title v-text="$t('participant.downloads.amm_learn_contract_send')" />
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="generateCourseIdentityDocument('open')">
                                <v-list-item-action>
                                    <v-icon>mdi-certificate</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title v-text="$t('participant.downloads.course_identity')" />
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="generateCourseIdentityDocument('send')">
                                <v-list-item-action>
                                    <v-icon>mdi-certificate</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title v-text="$t('participant.downloads.amm_course_identity_send')" />
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="generateMultipleFinalReports('send')">
                                <v-list-item-action>
                                    <v-icon>mdi-bullseye-arrow</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title v-text="$t('participant.downloads.final_report')" />
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item v-if="false">
                                <v-list-item-action>
                                    <v-icon>mdi-pencil-box-multiple</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title v-text="$t('general.bulk_edit')" />
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item v-if="false">
                                <v-list-item-action>
                                    <v-icon>mdi-delete</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title v-text="$t('general.delete')" />
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                    <v-tooltip
                        v-if="selectedRows.length > 0"
                        top
                    >
                        <template #activator="{ on }">
                            <v-btn
                                icon
                                :href="`mailto:?bcc=${selectedParticipantsEmails()}`"
                                v-on="on"
                                class="ml-3"
                            >
                                <v-icon>
                                    mdi-email-multiple-outline
                                </v-icon>
                            </v-btn>
                        </template>
                        <span>E-Mail an Teilnehmer</span>
                    </v-tooltip>
                </template>
            </ActionBar>
            <v-col cols="12">
                <v-progress-linear
                    v-if="loading"
                    indeterminate
                    color="primary"
                />
                <v-skeleton-loader
                    v-if="loading"
                    class="mx-auto"
                    type="table-tbody"
                />
                <ParticipantsList
                    :key="menu"
                    v-if="menu !== 'search' && !loading"
                    :objects="filteredObjects"
                    :search="textSearch"
                    :menu="menu"
                    :course-type="courseType"
                    :all="menu === 'ALL'"
                    selection
                    @selection-changed="selectionChangedListener"
                />
            </v-col>
        </v-row>
        <v-dialog
            v-model="certificateDialog"
            persistent
            max-width="350px"
        >
            <v-card>
                <v-card-title>
                    {{ $t('participant.downloads.amm_certificates') }}
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col
                                cols="12"
                            >
                                <v-select
                                    :items="languages"
                                    v-model="language"
                                    class="text-none mb-2 mr-2"
                                />
                                <v-date-picker
                                    locale="de-ch"
                                    v-model="certificateMonth"
                                    type="month"
                                />
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn
                        color="default"
                        text
                        @click="certificateDialog = false"
                    >
                        {{ $t('general.cancel') }}
                    </v-btn>
                    <v-btn
                        color="primary darken-1"
                        text
                        @click="downloadCertificates()"
                    >
                        {{ $t('general.export') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-flex>
</template>

<script>

import ActionBar from '@/components/common/ActionBar';
import PageHeader from '@/components/common/PageHeader';
import TabMenu from '@/components/common/TabMenu';
import AdvancedSearch from '@/components/participants/AdvancedSearch/Search';
import ParticipantsList from '@/components/participants/ParticipantsList';
import ParticipantProxy from '@/proxies/ParticipantProxy';
// import ParticipantUploadProxy from '@/proxies/ParticipantUploadProxy';
import FileDownloadMixin from '@/mixins/file-download';
import { SuccessSnackbar, ObjectDeleted, StandBy } from '@/app-events.js';

export default {
    name: 'ParticipantIndex',
    mixins: [ FileDownloadMixin ],
    components: {
        ActionBar,
        PageHeader,
        TabMenu,
        AdvancedSearch,
        ParticipantsList,
    },
    data() {
        return {
            showAdvancedSearched: false,
            menu: '',
            courseType: null,
            advancedSearched: {
                age: '',
                gender: '',
                jobCategory: '',
                group: '',
            },
            textSearch: '',
            objects: [],
            filteredObjects: [],
            selectedRows: [],
            loading: true,
            status: '',
            certificateAction: null,
            certificateDialog: false,
            certificateMonth: new Date().toISOString().substr(0, 7),
            language: 'de',
            languages: [
                {
                    value: 'de',
                    text: 'Deutsch',
                },
                {
                    value: 'en',
                    text: 'Englisch',
                },
            ]
        };;
        this.certificateDialog = false;
    },
    computed: {
        breadcrumbs() {
            return [
                {
                    text: this.$t('page.home.title'),
                    exact: true,
                    to: { name: 'home' },
                },
                {
                    text: `${this.$t('page.participant.title')} - ${this.$t(`page.participant.menu.${this.menu}`)}`,
                    disabled: true,
                },
            ];
        },
        menus() {
            return [
                {
                    id: 'ALL',
                    label: this.$t('page.participant.menu.ALL'),
                    callback: this.status !== 'ALL' ? () => this.goToMenu('ALL') : false
                },
                {
                    id: 'FI',
                    label: this.$t('page.participant.menu.FI'),
                    callback: this.status !== '' ? () => this.goToMenu('FI') : false
                },
                {
                    id: 'IC',
                    label: this.$t('page.participant.menu.IC'),
                    callback: this.status !== '' ? () => this.goToMenu('IC') : false
                },
                {
                    id: 'CB',
                    label: this.$t('page.participant.menu.CB'),
                    callback: this.status !== '' ? () => this.goToMenu('CB') : false
                },
                {
                    id: 'ER',
                    label: this.$t('page.participant.menu.ER'),
                    callback: this.status !== '' ? () => this.goToMenu('ER') : false
                },
                {
                    id: 'A',
                    label: this.$t('page.participant.menu.A'),
                    callback: this.status !== '' ? () => this.goToMenu('A') : false
                },
                {
                    id: 'WL',
                    label: this.$t('page.participant.menu.WL'),
                    callback: this.status !== '' ? () => this.goToMenu('WL') : false
                },
                // {
                //     id: 'ARC',
                //     label: this.$t('page.participant.menu.ARC'),
                //     callback: this.status !== 'ARC' ? () => this.goToMenu('ARC') : false
                // },
                {
                    id: 'ARC',
                    label: this.$t('page.participant.menu.ARC.ARC'),
                    menus: [
                        {
                            id: 'ARC.NO',
                            label: this.$t('page.participant.menu.ARC.NO'),
                            callback: this.status !== '' ? () => this.goToMenu('ARC.NO') : false
                        },
                        {
                            id: 'ARC.CCP',
                            label: this.$t('page.participant.menu.ARC.CCP'),
                            callback: this.status !== 'ARC.CCP' ? () => this.goToMenu('ARC.CCP') : false
                        },
                        {
                            id: 'ARC.CCC',
                            label: this.$t('page.participant.menu.ARC.CCC'),
                            callback: this.status !== 'ARC.CCC' ? () => this.goToMenu('ARC.CCC') : false
                        },
                        {
                            id: 'ARC.ARC',
                            label: this.$t('page.participant.menu.ARC.OTHER'),
                            callback: this.status !== 'ARC.ARC' ? () => this.goToMenu('ARC.ARC') : false
                        },
                        {
                            id: 'ARC.NO-COURSE',
                            label: this.$t('page.participant.menu.ARC.NO-COURSE'),
                            callback: this.status !== 'ARC.NO-COURSE' ? () => this.goToMenu('ARC.NO-COURSE') : false
                        },
                    ],
                    //callback: this.status !== 'ARC' ? () => this.goToMenu('ARC') : false
                },
            ];
        },
    },
    created () {
        this.courseType = this.$route.params.courseType;
        this.$eventBus.listen(ObjectDeleted, (event) => {
            this.$eventBus.dispatch(new SuccessSnackbar(this.$t('participant.actions.deleted')));
            for (let i = 0; i < this.objects.length; i++) {
                const participant = this.objects[ i ];
                if (participant.id === event.object.id) {
                    this.objects.splice(i, 1);
                    break;
                }
            }
        });
        this.initialize(this.$route.name);
    },
    methods: {
        selectedParticipantsEmails(){
            const emails = [];
            for (const index in this.selectedRows) {
                const item = this.selectedRows[ index ];
                if (item.email) {
                    emails.push(item.email);
                }
            }
            return emails.join(',');
        },
        initialize(routeName) {
            this.status = '';
            if (routeName === 'participants_archive') {
                this.status = 'ARC.ARC';
                this.menu = 'ARC.ARC';
            } else if (routeName === 'participants_course_completed') {
                this.status = 'ARC.CCP';
                this.menu = 'ARC.CCP';
            } else if (routeName === 'participants_course_canceled') {
                this.status = 'ARC.CCC';
                this.menu = 'ARC.CCC';
            } else if (routeName === 'participants_course_no_course') {
                this.status = 'ARC.NO-COURSE';
                this.menu = 'ARC.NO-COURSE';
            } else if (routeName === 'participants_all') {
                this.status = 'ALL';
                this.menu = 'ALL';
            } else if (this.$session.exists('participant_list_menu')) {
                this.menu = this.$session.get('participant_list_menu');
                if (this.menu === 'ARC.ARC' || this.menu === 'ARC.CCP' || this.menu === 'ARC.CCC' || this.menu === 'ALL' || this.menu === 'NO-COURSE') {
                    this.goToMenu(this.menu)
                }
            } else {
                this.menu = 'A';
            }
            this.fetchData();
        },
        fetchData() {
            this.loading = true;
            this.selectedRows = [];
            new ParticipantProxy().all({
                status: this.status.replace('ARC.', ''),
                courseType: this.courseType === 'all' ? '' : this.courseType,
            }).then(({ data }) => {
                this.objects = data.data;
                this.loading = false;
            }).catch(({ data }) => {
                this.displayError(data);
            });
        },
        exportList(exportType) {
            this.$eventBus.dispatch(new StandBy(true));
            new ParticipantProxy().export(exportType).then(({ data }) => {
                this.browserDownload(data, this.$t(`page.participant.export_filename.${exportType}`))
            }).catch(({ data }) => {
                this.displayError(data);
            }).finally(() => {
                this.$eventBus.dispatch(new StandBy(false));
            });
        },
        searchInputListener(value) {
            this.textSearch = value;
        },
        courseTypeMatch(courseType) {
            return this.courseType === 'all' || courseType === this.courseType || courseType === 'ol' && this.courseType == 'ol_standard';
        },
        updateObjects() {
            this.filteredObjects = [];
            const menu = this.menu.replace('ARC.', '');

            for (let i = 0, len = this.objects.length; i < len; i += 1) {
                const participant = this.objects[ i ];
                if ('ALL' === menu && this.courseType === 'all') {
                    this.filteredObjects.push(participant);
                    continue;
                }
                if ('NO-COURSE' === menu) {
                    this.filteredObjects.push(participant);
                    continue;
                }
                let courseType = null;
                if (participant.course && typeof participant.course.id !== 'undefined') {
                    courseType = participant.course.type.toLowerCase();
                }
                let waitingCourseType = null;
                if (participant.waiting_course && typeof participant.waiting_course.id !== 'undefined') {
                    waitingCourseType = participant.waiting_course.type.toLowerCase();
                }
                if ('ALL' === menu) {
                    if (participant.waiting_list && waitingCourseType == this.courseType) {
                        participant.status = 'WL';
                    }
                    this.filteredObjects.push(participant);
                    continue;
                }
                if (!this.courseTypeMatch(courseType) && ! this.courseTypeMatch(waitingCourseType) && this.courseType !== 'all') {
                    continue;
                }
                if (participant.waiting_list && menu === 'WL' && waitingCourseType == this.courseType) {
                    this.filteredObjects.push(participant);
                    continue;
                }
                else if (participant.waiting_list && menu === 'WL' && this.courseType == 'all') {
                    this.filteredObjects.push(participant);
                    continue;
                }
                if (participant.status === 'WL') {
                    if (menu === 'A' && this.courseTypeMatch(courseType)) {
                        this.filteredObjects.push(participant);
                    } else if (menu === 'WL' && this.courseTypeMatch(waitingCourseType)) {
                        this.filteredObjects.push(participant);
                    }
                    continue;
                }
                if (participant.status === menu && this.courseTypeMatch(courseType)) {
                    this.filteredObjects.push(participant);
                    continue;
                }
                //if (participant.status === 'WL') {
                //    if (menu === 'A' && this.courseTypeMatch(courseType) || (courseType === 'ol' && this.courseType == 'ol_standard')) {
                //        this.filteredObjects.push(participant);
                //    } else if((menu === 'WL' || 'ALL' === menu)) {
                //        if (participant.waiting_course && typeof participant.waiting_course.id !== 'undefined') {
                //            //waitingCourseType = participant.waiting_course.type.toLowerCase();
                //            if (this.courseTypeMatch(waitingCourseType) || (waitingCourseType === 'ol' && this.courseType == 'ol_standard')) {
                //                this.filteredObjects.push(participant);
                //            }
                //        }
                //        if (participant.course && typeof participant.course.id !== 'undefined') {
                //            if (this.courseTypeMatch(courseType) || (courseType === 'ol' && this.courseType == 'ol_standard')) {
                //                this.filteredObjects.push(participant);
                //            }
                //        }
                //    }
                //} else if (participant.status === menu || 'ALL' === menu) {
                //    if (this.courseTypeMatch(courseType) || (courseType === 'ol' && this.courseType == 'ol_standard')) {
                //        this.filteredObjects.push(participant);
                //    }
                //}
            }
            for (let i = this.filteredObjects.length - 1, zero = 0; i >= zero; i -= 1) {
                const participant = this.filteredObjects[ i ];
                if (this.advancedSearched.group && participant.group !== this.advancedSearched.group) {
                    this.filteredObjects.splice(i, 1);
                }
            }
        },
        openCertificateDialog(action) {
            this.certificateAction = action;
            this.certificateDialog = true;
        },
        /*emailContract(action) {
            this.$eventBus.dispatch(new StandBy(true));

            new ParticipantUploadProxy().emailLearnContract(item.id, item, documentType).then(({ data }) => {
                this.$eventBus.dispatch(new SuccessSnackbar(this.$t('learn_goal_control.actions.sent')));
            }).catch(({ data }) => {
                this.displayError(data);
            }).finally(() => {
                this.$eventBus.dispatch(new StandBy(false));
            });

        },*/
        generateCourseIdentityDocument(action) {
            this.$eventBus.dispatch(new StandBy(true));
            const ids = [];
            for (const index in this.selectedRows) {
                const item = this.selectedRows[ index ];
                ids.push(item.id);
            }

            new ParticipantProxy().generateCourseIdentityDocuments(ids, action).then(({ data }) => {
                this.openPdf(data);
            }).catch(({ data }) => {
                this.displayError(data);
            }).finally(() => {
                this.$eventBus.dispatch(new StandBy(false));
            });
        },
        generateMultipleFinalReports() {
            this.$eventBus.dispatch(new StandBy(true));
            const ids = [];
            for (const index in this.selectedRows) {
                const item = this.selectedRows[ index ];
                ids.push(item.id);
            }
            new ParticipantProxy().generateMultipleFinalReports(ids).then(({ data }) => {
                this.browserDownload(data, 'archive.zip');
                this.$eventBus.dispatch(new SuccessSnackbar(this.$t('participant.downloads.generated.goal')));
            }).catch(({ data }) => {
                this.displayError(data);
            }).finally(() => {
                this.$eventBus.dispatch(new StandBy(false));
            });
        },
        downloadCertificates() {
            this.$eventBus.dispatch(new StandBy(true));
            const ids = [];
            for (const index in this.selectedRows) {
                const item = this.selectedRows[ index ];
                ids.push(item.id);
            }
            if (this.certificateAction === 'open') {
                new ParticipantProxy().downloadCertificate(ids, this.certificateMonth, 0, this.language).then(({ data }) => {
                    this.openPdf(data);
                    this.certificateDialog = false;
                    this.certificateAction = null;
                }).catch(({ data }) => {
                    this.displayError(data);
                }).finally(() => {
                    this.$eventBus.dispatch(new StandBy(false));
                });
            } else {
                new ParticipantProxy().sendCertificate(ids, this.certificateMonth, 0, this.language).then(({ data }) => {
                    this.$eventBus.dispatch(new SuccessSnackbar(this.$t('participant.actions.certificate_sent')));
                    this.certificateDialog = false;
                    this.certificateAction = null;
                }).catch(({ data }) => {
                    this.displayError(data);
                }).finally(() => {
                    this.$eventBus.dispatch(new StandBy(false));
                });
            }
        },
        selectionChangedListener(value) {
            this.selectedRows = value;
        },
        goToMenu(menu) {
            this.$session.set('participant_list_menu', menu);
            let routeName = '';
            if (menu === 'ARC.ARC') {
                routeName = 'participants_archive';
            } else if (menu === 'ARC.CCP') {
                routeName = 'participants_course_completed';
            } else if (menu === 'ARC.CCC') {
                routeName = 'participants_course_canceled';
            } else if (menu === 'ARC.NO-COURSE') {
                routeName = 'participants_course_no_course';
            } else if (menu === 'ALL') {
                routeName = 'participants_all';
            } else {
                routeName = 'participants';
            }
            this.$router.push({ name: routeName });
            this.initialize(routeName);
        },
    },
    watch: {
        menu: function (val) {
            this.$session.set('participant_list_menu', val);
            this.updateObjects();
        },
        '$route.params.courseType': function (val) {
            this.courseType = this.$route.params.courseType;
            this.fetchData();
            this.updateObjects();
        },
        advancedSearched: {
            handler(val) {
                this.updateObjects();
            },
            deep: true
        },
        objects: {
            handler(val) {
                this.updateObjects();
            },
            deep: true
        }
    },
};
</script>
