import CantonProxy from '@/proxies/CantonProxy';
import {
    LOAD, RESET_STATE, LOAD_ERROR,
} from './mutation-types';

const load = async ({ commit }) => {
    try {
        new CantonProxy().all().then(({ data }) => {
            commit(LOAD, {
                cantons: data.data,
            });
        }).catch(() => {
            commit(LOAD_ERROR);
        });
    } catch (e) {
        commit(RESET_STATE);
    }
};

export default {
    load,
};
