import DefaultLayout from '@/layouts/Default';
import MailSentIndex from '@/views/MailSent/Index';
import MailSentDetail from '@/views/MailSent/Detail';

export default {
    path: '/mailsent',
    component: DefaultLayout,
    children: [
        {
            path: '',
            name: 'mailsent',
            component: MailSentIndex,
            meta: {
                auth: true,
            },
        },
        {
            path: ':id',
            name: 'mail_sent_detail',
            component: MailSentDetail,
            meta: {
                auth: true,
            },
        },
    ],
};
