<template>
    <v-flex
        md12
        class="px-12 pb-12"
    >
        <PageHeader
            :breadcrumbs="breadcrumbs"
            :title="pageTitle"
            back
            :back-to="courseLinkId ? '' : 'participants'"
            :button="hasHeaderButton()"
            :button-route="headerButtonRoute()"
            :button-callback="headerButtonCallback()"
            :button-text="headerButtonText()"
            :button-icon="headerButtonIcon()"
        />
        <v-row>
            <v-col cols="12">
                <TabMenu
                    :menus="menus"
                    v-model="activeMenu"
                />
            </v-col>
            <v-col cols="12">
                <v-alert
                    v-for="(error, errorKey) in generalFormErrors"
                    :key="errorKey"
                    type="error"
                    v-text="error"
                />
                <ParticipantForm
                    v-if="activeMenu == 'profile'"
                    :data="data"
                    :raw-data="rawData"
                    :errors="formErrors"
                    :loading="loading"
                    :save-function="saveObject"
                    :discard-function="resetObject"
                />
                <LearnGoalControl
                    v-if="activeMenu == 'learn_goal_control'"
                    :participant="data"
                    :add-prop="addLearnGoalControl"
                />
                <Feedback
                    v-if="activeMenu == 'feedback'"
                    :participant="data"
                    :add-prop="addFeedback"
                />
                <Note
                    v-if="activeMenu == 'note'"
                    :participant="data"
                    :add-prop="addNote"
                />
                <PresenceList
                    v-if="activeMenu == 'presence'"
                    :participant="data"
                />
                <LearningContract
                    v-if="activeMenu == 'learning_contract'"
                    :participant="data"
                    :add-prop="printLearningContract"
                />
                <Correspondence
                    v-if="activeMenu == 'correspondence'"
                    :participant="data"
                    :add-prop="addCorrespondence"
                />
                <ParticipantUploads
                    v-if="activeMenu == 'uploads'"
                    key="uploads"
                    type="R"
                    :participant="data"
                />
                <ParticipantUploads
                    v-if="activeMenu == 'final_uploads'"
                    key="final_uploads"
                    type="F"
                    :participant="data"
                />
            </v-col>
        </v-row>
    </v-flex>
</template>

<script>

import PageHeader from '@/components/common/PageHeader';
import ParticipantForm from '@/components/participants/Form/ParticipantForm';
import LearnGoalControl from '@/components/participants/LearnGoalControl';
import Feedback from '@/components/participants/Feedback';
import Note from '@/components/participants/Note';
import Correspondence from '@/components/participants/Correspondence';
import PresenceList from '@/components/participants/PresenceList';
import LearningContract from '@/components/participants/LearningContract';
import ParticipantUploads from '@/components/participants/ParticipantUploads';
import TabMenu from '@/components/common/TabMenu';
import ParticipantProxy from '@/proxies/ParticipantProxy';
import CourseProxy from '@/proxies/CourseProxy';
import ParticipantMixin from '@/mixins/participant';
import DatesMixin from '@/mixins/dates';
import { SuccessSnackbar, StandBy } from '@/app-events.js';

export default {
    name: 'ParticipantDetail',
    mixins: [
        ParticipantMixin,
        DatesMixin,
    ],
    components: {
        ParticipantForm,
        LearnGoalControl,
        TabMenu,
        PageHeader,
        Feedback,
        Note,
        Correspondence,
        PresenceList,
        LearningContract,
        ParticipantUploads,
    },
    data() {
        return {
            activeMenu: 'profile',
            loading: false,
            data: {
                firstname: '',
                lastname: '',
            },
            rawData: {
                firstname: '',
                lastname: '',
            },
            addLearnGoalControl: 0,
            addNote: 0,
            printLearningContract: 0,
            addCorrespondence: 0,
            addFeedback: 0,
            courseLinkId: null,
            courseData: null,
        };
    },
    computed: {
        menus() {
            let hideLearningContract = true;
            if (typeof this.rawData.course !== 'undefined' && this.rawData.course !== null && typeof this.rawData.course.type !== 'undefined' && (this.rawData.course.type === 'OL' || this.rawData.course.type === 'APPLICATION_AGENCY')) {
                hideLearningContract = false;
            }

            return [
                {
                    id: 'profile',
                    label: this.$t('page.participant_new.menu.profile'),
                },
                // {
                //   id: 'learn_goal_control',
                //   label: this.$t('page.participant_new.menu.learn_goal_control'),
                // },
                {
                    id: 'note',
                    label: this.$t('page.participant_new.menu.note'),
                },
                {
                    id: 'feedback',
                    label: this.$t('page.participant_new.menu.feedback'),
                },
                {
                    id: 'presence',
                    label: this.$t('page.participant_new.menu.presence'),
                },
                {
                    id: 'learning_contract',
                    label: this.$t('page.participant_new.menu.learning_contract'),
                    hidden: hideLearningContract,
                },
                // {
                //     id: 'outputs',
                //     label: this.$t('page.participant_new.menu.outputs'),
                // },
                {
                    id: 'correspondence',
                    label: this.$t('page.participant_new.menu.correspondence'),
                },
                {
                    id: 'uploads',
                    label: this.$t('page.participant_new.menu.uploads'),
                },
                {
                    id: 'final_uploads',
                    label: this.$t('page.participant_new.menu.final_uploads'),
                },
            ];
        },
        pageTitle() {
            try {
                return `${this.rawData.firstname} ${this.rawData.lastname}`;
            } catch (error) {
                return '';
            }
        },
        coursePageTitle() {
            let title = '';
            if (!this.courseData) {
                return '';
            }
            if (this.courseData.name) {
                title = `${this.courseData.name} | `;
            }
            if (this.courseData.start_date) {
                try {
                    return `${title}${this.formatDate(this.courseData.start_date)} - ${this.formatDate(this.courseData.end_date)}`;
                } catch (error) {
                    console.log(error);
                    return title;
                }
            } else {
                return title;
            }
        },
        breadcrumbs() {
            if (!this.courseLinkId) {
                return [
                    {
                        text: this.$t('page.home.title'),
                        exact: true,
                        to: { name: 'home' },
                    },
                    {
                        text: this.$t('page.participant.title'),
                        exact: true,
                        to: { name: 'participants' },
                    },
                    {
                        text: this.pageTitle,
                        disabled: true,
                    },
                ];
            }
            return [
                {
                    text: this.$t('page.home.title'),
                    exact: true,
                    to: { name: 'home' },
                },
                {
                    text: this.$t('page.course.title'),
                    exact: true,
                    to: { name: 'course' },
                },
                {
                    text: this.coursePageTitle,
                    exact: true,
                    to: { name: 'course_detail', params: { id: this.courseLinkId } },
                },
                {
                    text: this.pageTitle,
                    disabled: true,
                },
            ];
        },
    },
    created() {
        if (typeof this.$route.params.menu !== 'undefined') {
            this.activeMenu = this.$route.params.menu;
        }
        this.fetchData();
        if (this.$route.query.course) {
            this.courseLinkId = this.$route.query.course;
            this.fetchCourseData();
        }
    },
    methods: {
        fetchData() {
            this.loading = true;
            new ParticipantProxy().find(this.$route.params.id).then(({ data }) => {
                this.data = this.prepareData(data.data);
                this.rawData = JSON.parse(JSON.stringify(this.data));
                this.loading = false;
            }).catch(({ data }) => {
                this.displayError(data);
            });
        },
        fetchCourseData() {
            new CourseProxy().find(this.courseLinkId).then(({ data }) => {
                this.courseData = data.data;
            }).catch(({ data }) => {
                this.displayError(data);
            });
        },
        resetObject() {
            this.data = JSON.parse(JSON.stringify(this.rawData));
            this.resetErrors();
        },
        saveObject() {
            this.$eventBus.dispatch(new StandBy(true));
            new ParticipantProxy().update(this.$route.params.id, this.prepareFormData(this.data)).then(({ data }) => {
                this.$eventBus.dispatch(new StandBy(false));
                this.rawData = JSON.parse(JSON.stringify(this.data));
                this.$eventBus.dispatch(new SuccessSnackbar(this.$t('participant.actions.saved')));
                this.resetErrors();
            }).catch(({ data }) => {
                this.processErrors(data);
                this.$eventBus.dispatch(new StandBy(false));
            });
        },
        hasHeaderButton(menu) {
            if (this.activeMenu === 'learn_goal_control') {
                return true;
            }
            if (this.activeMenu === 'note') {
                return true;
            }
            if (this.activeMenu === 'correspondence') {
                return true;
            }
            if (this.activeMenu === 'feedback') {
                return true;
            }
            if (this.activeMenu === 'learning_contract') {
                return false;
            }
            return false;
        },
        headerButtonText() {
            if (this.activeMenu === 'learn_goal_control') {
                return this.$t('page.learn_goal_control.new');
            }
            if (this.activeMenu === 'note') {
                return this.$t('page.note.new');
            }
            if (this.activeMenu === 'correspondence') {
                return this.$t('page.correspondence.new');
            }
            if (this.activeMenu === 'feedback') {
                return this.$t('page.feedback.new');
            }
            if (this.activeMenu === 'learning_contract') {
                return 'Lernkontrakt erzeugen';
            }
            return '';
        },
        headerButtonIcon() {
            if (this.activeMenu === 'learn_goal_control') {
                return 'plus';
            }
            if (this.activeMenu === 'note') {
                return 'plus';
            }
            if (this.activeMenu === 'correspondence') {
                return 'plus';
            }
            if (this.activeMenu === 'feedback') {
                return 'plus';
            }
            if (this.activeMenu === 'learning_contract') {
                return 'pdf-box';
            }
            return '';
        },
        headerButtonRoute() {
            return {};
        },
        headerButtonCallback() {
            if (this.activeMenu === 'learn_goal_control') {
                return () => this.addLearnGoalControl++;
            }
            if (this.activeMenu === 'note') {
                return () => this.addNote++;
            }
            if (this.activeMenu === 'correspondence') {
                return () => this.addCorrespondence++;
            }
            if (this.activeMenu === 'feedback') {
                return () => this.addFeedback++;
            }
            if (this.activeMenu === 'learning_contract') {
                return () => this.printLearningContract++;
            }
            return () => ({});
        },
    },
};
</script>
