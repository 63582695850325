import Proxy from './Proxy';

class UserProxy extends Proxy {
    constructor(parameters = {}) {
        super('/admin/user', parameters);
    }

    all() {
        return this.submit('get', '/');
    }

    deactivate(id) {
        return this.submit('post', `/${id}/deactivate`);
    }

    activate(id) {
        return this.submit('post', `/${id}/activate`);
    }
}

export default UserProxy;
