import { mapGetters } from 'vuex';
import { preferences } from '@/store/modules/preferences';

export default {
    data() {
        return {
            defaultFooterProps: {
                'items-per-page-options': [ 10, 25, 50, 100, -1 ]
            },
        };
    },
    computed: {
        ...mapGetters({
            preferences: 'preferences/all',
        }),
        currentPage: {
            get() {
                const section = this.$route.matched[ 0 ].path;;
                const currentPage = this.preferences.currentPage;
                if (currentPage.section !== section) {
                    this.$store.dispatch('preferences/set', {
                        field: 'currentPage',
                        value: {
                            page: 1,
                            section,
                        },
                    });
                    return 1;
                }
                return currentPage.page;
            },
            set(page) {
                const section = this.$route.matched[ 0 ].path;;
                this.$store.dispatch('preferences/set', {
                    field: 'currentPage',
                    value: {
                        page,
                        section,
                    },
                });
            }
        },
    },
    methods: {
        getPrefenceValue(field, defaultValue) {
            if (typeof this.preferences[ field ] === 'undefined') {
                this.$store.dispatch('preferences/set', {
                    field,
                    value: defaultValue,
                });
                return defaultValue;
            }
            return this.preferences[ field ];
        },
        setPrefenceValue(field, value) {
            this.$store.dispatch('preferences/set', {
                field,
                value,
            });
        },
    },
};
