import DefaultLayout from '@/layouts/Default';
import RavIndex from '@/views/Rav/Index';
import RavDetail from '@/views/Rav/Detail';
import RavDetailConsultant from '@/views/RavConsultant/Detail';
import RavNew from '@/views/Rav/New';

export default {
    path: '/rav',
    component: DefaultLayout,
    children: [
        {
            path: '',
            name: 'rav',
            component: RavIndex,
            meta: {
                auth: true,
            },
        },
        {
            path: 'new',
            name: 'rav_new',
            component: RavNew,
            meta: {
                auth: true,
            },
        },
        {
            path: ':ravId/consultant/:id',
            name: 'rav_detail_consultant',
            component: RavDetailConsultant,
            meta: {
                auth: true,
            },
        },
        {
            path: ':id',
            name: 'rav_detail',
            component: RavDetail,
            meta: {
                auth: true,
            },
        },
    ],
};
