<template>
    <div>
        <v-card flat>
            <v-data-table
                class="pa-3"
                :headers="headers"
                :items="objects"
                :search="search"
                :custom-filter="filterMailSent"
                :footer-props="defaultFooterProps"
                :items-per-page="getPrefenceValue('mailsent.itemsPerPage', 25)"
                @update:items-per-page="val => setPrefenceValue('mailsent.itemsPerPage', val)"
                :page="currentPage"
                @update:page="page => currentPage = page"
            >
                <template v-slot:item.email_type="{ item }">
                    {{ $t(`mailsent.types.${item.email_type}`) }}
                </template>
                <template v-slot:item.language="{ item }">
                    {{ 'en' === item.language ? 'Englisch' : 'Deutsch' }}
                </template>
                <template v-slot:item.email_type="{ item }">
                    <v-btn
                        link
                        text
                        :to="{ name: 'mail_sent_detail', params: { id: item.id }}"
                    >
                        {{ $t(`mailsent.types.${item.email_type}`) }}
                    </v-btn>
                </template>
                <template v-slot:item.created="{ item }">
                    {{ formatDate(item.created) }}
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>

<script>
import DatesMixin from '@/mixins/dates';

export default {
    name: 'MailSentList',
    mixins: [ DatesMixin ],
    props: {
        search: {
            type: String,
            default: '',
        },
        objects: {
            type: Array,
            default: () => ([]),
        },
    },
    data() {
        return {
            headers: [
                {
                    text: this.$t('mailsent.email_type'),
                    value: 'email_type',
                    align: 'start',
                },
                {
                    text: this.$t('mailsent.language'),
                    value: 'language',
                },
                {
                    text: this.$t('mailsent.subject'),
                    value: 'subject',
                },
                {
                    text: this.$t('mailsent.participant'),
                    value: 'participant.fullname',
                },
                {
                    text: this.$t('mailsent.email'),
                    value: 'email',
                },
                {
                    text: this.$t('mailsent.created'),
                    value: 'created',
                },
            ],
        }
    },
    methods: {
        filterMailSent(value, search, item) {
            if (search === '') {
                return true;
            }
            if (!value) {
                return false;
            }
            if (item.email_type === value)
            {
                value = this.$t(`mailsent.types.${item.email_type}`);
            }
            else if (item.created === value)
            {
                return this.formatDate(value).includes(search);
            }
            /* if (typeof value.email_type !== 'undefined') {
                return this.formatDate(value.start_date).includes(search) || value.name.toString().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(search.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""));
            }*/
            return value.toString().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(search.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""));
        },
    },
};
</script>
