import {
    SET_PREFERENCES,
    INITIALIZE,
    RESET_STATE,
} from './mutation-types';

export default {
    [ SET_PREFERENCES ]: (state, payload) => {
        state.preferences[ payload.field ] = payload.value;
    },
    [ INITIALIZE ]: (state, payload) => {
        state.preferences.currentPage = {
            page: 1,
            section: '',
        };
    },
    [ RESET_STATE ]: (state) => {
        state.preferences = {
            currentPage: {
                page: 1,
                section: '',
            },
        };
    },
};
