import Proxy from './Proxy';

class CoachProxy extends Proxy {
    constructor(parameters = {}) {
        super('/coach', parameters);
    }

    all({ active = false } = {}) {
        if (active) {
            return this.submit('get', '/?active=1');
        }
        return this.submit('get', '/');
    }
}

export default CoachProxy;
