<template>
    <v-card flat>
        <v-card-text>
            <v-row>
                <v-col cols="12">
                    <v-select
                        :items="jobCategories"
                        v-model="data.job_category"
                        :label="$t('participant.job_category')"
                        :error-messages="formError(errors, 'jobCategory')"
                        :hint="$t('general.required')"
                        persistent-hint
                    />
                </v-col>
                <v-expand-transition>
                    <v-col
                        v-if="data.job_category === 'OE'"
                        cols="12"
                    >
                        <v-text-field
                            type="text"
                            v-model="data.job_category_other"
                            :label="$t('participant.job_category_other')"
                            :error-messages="formError(errors, 'jobCategoryOther')"
                        />
                    </v-col>
                </v-expand-transition>
                <v-col cols="12">
                    <v-select
                        :items="jobCategoriesExtended"
                        v-model="data.job_category_extended"
                        :label="$t('participant.job_category_extended')"
                        :error-messages="formError(errors, 'jobCategoryExtended')"
                        clearable
                        @click:clear="$nextTick(() => data.job_category_extended = null)"
                    />
                </v-col>
                <v-expand-transition>
                    <v-col
                        v-if="data.job_category_extended === 'O'"
                        cols="12"
                    >
                        <v-text-field
                            type="text"
                            v-model="data.job_category_extended_other"
                            :label="$t('participant.job_category_extended_other')"
                            :error-messages="formError(errors, 'jobCategoryExtendedOther')"
                        />
                    </v-col>
                </v-expand-transition>
                <v-col cols="12">
                    <v-select
                        :items="jobFunctions"
                        v-model="data.job_function"
                        :label="$t('participant.job_function')"
                        :error-messages="formError(errors, 'jobFunction')"
                        clearable
                        @click:clear="$nextTick(() => data.job_function = null)"
                    />
                </v-col>
                <v-col cols="12">
                    <v-select
                        :items="professionalAwards"
                        v-model="data.professional_awards"
                        :label="$t('participant.professional_awards')"
                        :error-messages="formError(errors, 'professionalAwards')"
                        multiple
                        chips
                        clearable
                        @click:clear="$nextTick(() => data.professional_awards = null)"
                    />
                </v-col>
                <v-col cols="12">
                    <v-text-field
                        type="text"
                        v-model="data.job_title"
                        :label="$t('participant.job_title')"
                        :error-messages="formError(errors, 'jobTitle')"
                    />
                </v-col>
                <v-col cols="12">
                    <v-text-field
                        type="text"
                        v-model="data.workload"
                        :label="$t('participant.workload')"
                        :error-messages="formError(errors, 'workload')"
                    />
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>

import FormDataMixin from '@/mixins/form-data';

export default {
    name: 'JobInformation',
    mixins: [ FormDataMixin ],
    props: {
        data: {
            type: Object,
            default: () => ({
                insurance_fund: {},
                rav_location: {},
                rav_consultant: {},
            }),
        },
        errors: {
            type: Object,
            default: () => ({}),
        },
    },
};
</script>
