import { ErrorSnackbar } from '@/app-events.js';

export default {
    data() {
        return {
            formErrors: {},
            generalFormErrors: {},
        }
    },
    methods: {
        resetErrors() {
            this.formErrors = {};
            this.generalFormErrors = {};
        },
        processErrors(data) {
            this.formErrors = this.getFormErrors(data);
            this.generalFormErrors = this.getGeneralFormErrors(data);
        },
        formError(errors, field) {
            if (Object.keys(errors).length === 0) {
                return null;
            }
            let fieldValue = {};
            try {
                fieldValue = field.split('.').reduce((o,i)=>o[ i ], errors);

                if (typeof fieldValue.errors === 'undefined') {
                    return null;
                }
                if (fieldValue.errors.length === 0) {
                    return null;
                }
                
                return fieldValue.errors;
            } catch (error) {
                return null;
            }
        },
        getFormErrors(responseData) {
            if (typeof responseData.errors !== 'undefined') {
                this.$eventBus.dispatch(new ErrorSnackbar(`${responseData.errors.title}. ${responseData.errors.detail}`));
                return {};
            }
            if (typeof responseData.data === 'undefined' || typeof responseData.data.children === 'undefined') {
                this.$eventBus.dispatch(new ErrorSnackbar('Technical Error. Contact an administrator'));
                return {};
            }
            return responseData.data.children;
        },
        getGeneralFormErrors(responseData) {
            if (typeof responseData.errors !== 'undefined') {
                this.$eventBus.dispatch(new ErrorSnackbar(`${responseData.errors.title}. ${responseData.errors.detail}`));
                return {};
            }
            if (typeof responseData.data === 'undefined') {
                this.genericErrorSnackbar();
                return {};
            }
            return responseData.data.errors;
        }
    },
};
