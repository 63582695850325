<template>
    <div>
        <div class="display-2">
            Anmeldung Erstgespräch
        </div>
        <div class="subtitle-1 my-4" />
        <v-card
            flat
            class="pa-12"
        >
            <v-card-text>
                <p class="text-h6">
                    Vielen Dank für Ihr Interesse am Kurs. Sie erhalten in Kürze eine Bestätigungsmail.
                </p>
                <p>
                    Wir kontrollieren Ihre Angaben und werden uns so schnell wie möglich mit Ihnen in Verbindung setzen.<br>
                    <br>
                    Falls Sie sich für einen weiteren Kurs anmelden möchten, wählen Sie unten einen zusätzlichen Kurs aus.<br>
                    <br>
                    Freundliche Grüsse<br>
                    Ihr bewerbeagentur Team<br>
                </p>
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn
                    v-if="formType"
                    color="secondary--dark"
                    class="px-4"
                    :dark="!disabled"
                    rounded
                    :disabled="disabled"
                    :loading="loading"
                    depressed
                    type=""
                    @click="submit"
                >
                    {{ $t('page.first_interview.button') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>

export default {
    name: 'FirstInterviewThankYou',
};
</script>
