import Proxy from './Proxy';

class OfficeLaborProxy extends Proxy {
    constructor(parameters = {}) {
        super('/office-labor', parameters);
    }

    all() {
        return this.submit('get', '/');
    }
}

export default OfficeLaborProxy;
