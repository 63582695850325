<template>
    <v-flex
        md12
        class="px-12 pb-12"
    >
        <PageHeader
            :breadcrumbs="breadcrumbs"
            :title="pageTitle"
            back
            back-to="admin_users"
        />
        <v-row>
            <v-col cols="12">
                <v-alert
                    v-for="(error, errorKey) in generalFormErrors"
                    :key="errorKey"
                    type="error"
                    v-text="error"
                />
                <UserForm
                    :user="user"
                    :raw-data="rawData"
                    :errors="formErrors"
                    :loading="loading"
                    :save-function="saveObject"
                    :discard-function="resetObject"
                />
            </v-col>
        </v-row>
    </v-flex>
</template>

<script>

import PageHeader from '@/components/common/PageHeader';
import UserForm from '@/components/users/Form/UserForm';
import UserProxy from '@/proxies/UserProxy';
import { SuccessSnackbar, StandBy } from '@/app-events.js';

export default {
    name: 'UserDetail',
    components: {
        PageHeader,
        UserForm,
    },
    created() {
        this.fetchData();
    },
    data() {
        return {
            breadcrumbs: [
                {
                    text: this.$t('page.home.title'),
                    exact: true,
                    to: { name: 'home' },
                },
                {
                    text: this.$t('page.admin.title'),
                    disabled: true,
                    href: '/admin',
                },
                {
                    text: this.$t('page.user.title'),
                    exact: true,
                    to: { name: 'admin_users' },
                },
                {
                    text: 'Profil',
                    disabled: true,
                },
            ],
            loading: false,
            user: {},
            rawData: {},
        };
    },
    computed: {
        pageTitle() {
            try {
                return `${this.user.coach.firstname} ${this.user.coach.lastname}`;    
            } catch (error) {
                return '';
            }
        }
    },
    methods: {
        fetchData() {
            this.loading = true;
            new UserProxy().find(this.$route.params.id).then(({ data }) => {
                this.user = data.data;
                this.rawData = JSON.parse(JSON.stringify(this.user));
                this.loading = false;
            }).catch(({ data }) => {
                this.displayError(data);
            });
            
        },
        resetObject() {
            this.user = JSON.parse(JSON.stringify(this.rawData));
            this.resetErrors();
        },
        saveObject() {
            this.$eventBus.dispatch(new StandBy(true));
            const formData = {
                user: {
                    email: this.user.email,
                    roles: this.user.roles,
                    coach: {
                        firstname: this.user.coach.firstname,
                        lastname: this.user.coach.lastname,
                        phone: this.user.coach.phone,
                    },
                },
            };
            new UserProxy().update(this.$route.params.id, formData).then(({ data }) => {
                this.$eventBus.dispatch(new StandBy(false));
                this.rawData = JSON.parse(JSON.stringify(this.user));
                this.$eventBus.dispatch(new SuccessSnackbar(this.$t('user.actions.saved')));
                this.resetErrors();
            }).catch(({ data }) => {
                this.processErrors(data);
                this.$eventBus.dispatch(new StandBy(false));
            });
        },
    },
};
</script>
