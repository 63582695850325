<template>
    <div>
        <v-flex
            md12
            rounded
            class="white px-12 pb-12 pt-12 mt-6"
        >
            <v-row
                align="center"
                justify="space-around"
            >
                <v-col
                    cols="12"
                    sm="4"
                    md="2"
                >
                    <DatePicker
                        v-model="filters.startDate"
                        :label="$t('course.start_date')"
                    />
                </v-col>
                <v-col
                    cols="12"
                    sm="4"
                    md="2"
                >
                    <DatePicker
                        v-model="filters.endDate"
                        :label="$t('course.end_date')"
                    />
                </v-col>
                <v-col
                    cols="12"
                    sm="4"
                    md="2"
                >
                    <v-autocomplete
                        v-model="filters.courseType"
                        :items="courseTypes"
                        :label="$t('course.type')"
                    />
                </v-col>
                <v-col
                    cols="12"
                    sm="4"
                    md="2"
                >
                    <v-autocomplete
                        v-model="filters.language"
                        :items="courseLanguages"
                        :label="$t('course.language')"
                    />
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    md="2"
                    lg="1"
                >
                    <v-checkbox
                        v-model="filters.fullyBooked"
                        :label="$t('course.fully_booked')"
                        class="text-wrap"
                    />
                </v-col>
                <v-col
                    cols="12"
                    sm="2"
                    md="1"
                >
                    <v-tooltip top>
                        <template #activator="{ on, attrs }">
                            <v-btn
                                v-bind="attrs"
                                v-on="on"
                                icon
                                rounded
                                @click="clearFilters"
                            >
                                <v-icon>mdi-filter-off</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('course.actions.clear_filters') }}</span>
                    </v-tooltip>
                </v-col>
            </v-row>
        </v-flex>
    </div>
</template>
<script>
import DatePicker from "../../components/common/DatePicker.vue";
export default {
    name: 'CourseFilter',
    components: { DatePicker },
    props: {
        courseTypes: {
            type: Array,
            required: true,
        },
        courseLanguages: {
            type: Array,
            required: true,
        },
        filters: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            courseTypeFilter: '',
            courseLanguageFilter: '',
            startDateFilter: '',
            endDateFilter: '',
            fullyBookedFilter: false,
        };
    },

    methods: {
        updateFilters() {
            this.$emit('filter-change', {
                courseType: this.courseTypeFilter,
                language: this.courseLanguageFilter,
                startDate: this.startDateFilter,
                endDate: this.endDateFilter,
                fullyBooked: this.fullyBookedFilter,
            });
        },
        clearFilters() {
            this.courseTypeFilter = '';
            this.courseLanguageFilter = '';
            this.startDateFilter = '';
            this.endDateFilter = '';
            this.fullyBookedFilter = false;
            this.updateFilters();
        },
    },

    mounted() {
        console.log('mounted with filters', this.filters);
    },
};
</script>
