import Proxy from './Proxy';

class FeedbackProxy extends Proxy {
    constructor(parameters = {}) {
        super('/feedback', parameters);
    }

    all({ participantId = null } = {}) {
        if (participantId) {
            return this.submit('get', `/?participant=${participantId}`);    
        }
        return this.submit('get', '/');
    }

    send(id, formData) {
        return this.submit('post', `/${id}/send`, formData);
    }

    email(id, formData) {
        return this.submit('get', `/${id}/email`, formData);
    }
}

export default FeedbackProxy;
