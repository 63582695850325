<template>
    <v-row>
        <v-col cols="3">
            <v-list flat>
                <v-list-item-group multiple>
                    <v-list-item-group multiple>
                        <v-list-item :key="0" :value="0">
                            <v-list-item-content>
                                <v-list-item-title class="text-h6 active">
                                    Kopieren und in den Textbereich einfügen
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-divider />
                        <v-virtual-scroll
                            :items="data.custom_fields"
                            :item-height="65"
                            height="610"
                        >
                            <template v-slot="{ item, i }">
                                <v-list-item :key="i" :value="item">
                                    <v-list-item-content>
                                        <v-list-item-title v-text="item" id="fswf" />
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <v-icon @click="copyCustomField(item)">mdi-content-copy</v-icon>
                                    </v-list-item-action>
                                </v-list-item>
                            </template>
                        </v-virtual-scroll>
                    </v-list-item-group>
                </v-list-item-group>
            </v-list>
        </v-col>
        <v-col cols="8">
            <v-list class="pa-5">
                <v-row>
                    <v-col cols="12">
                        <v-text-field
                            type="text"
                            v-model="data.subject"
                            :label="$t('email_master_template.subject')"
                            :error-messages="formError(errors, 'subject')"
                        />
                    </v-col>
                    <v-col cols="12">
                        <ckeditor :config="editorConfig" :editor="editor" v-model="data.body" />
                        <v-textarea
                            v-if="false"
                            type="text"
                            outlined
                            auto-grow
                            v-model="data.body"
                            :label="$t('note.content')"
                            :error-messages="formError(formErrors, 'body')"
                        />
                    </v-col>
                </v-row>
            </v-list>
        </v-col>
    </v-row>
</template>
<script>

import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
    name: 'EmailMasterTemplateInformation',
    props: {
        data: {
            type: Object,
            default: () => ({
                name: '',
            }),
        },
        errors: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            insertText: '%insertChar%',
            editor: ClassicEditor,
            editorConfig: {
                toolbar: {
                    items: [
                        'heading',
                        '|',
                        'bold',
                        'italic',
                        'link',
                        'bulletedList',
                        'numberedList',
                        'indent',
                        'outdent',
                        'undo',
                        'redo',
                    ],
                },
            },
        }
    },
    methods: {
        copyCustomField (textToBeCopied) {
            const elem = document.createElement('textarea');
            elem.value = textToBeCopied;
            document.body.appendChild(elem);
            elem.select();
            document.execCommand('copy');
            document.body.removeChild(elem);
        },
    },
};
</script>
