<template>
    <v-flex
        md12
        class="px-12 pb-12"
    >
        <PageHeader
            :breadcrumbs="breadcrumbs"
            :title="$t('page.insurance_fund_new.title')"
            back
        />
        <v-row>
            <v-col cols="12">
                <InsuranceFundForm
                    :data="data"
                    :raw-data="rawData"
                    :errors="formErrors"
                    :save-function="saveObject"
                    :discard-function="resetObject"
                />
            </v-col>
        </v-row>
    </v-flex>
</template>

<script>

import PageHeader from '@/components/common/PageHeader';
import InsuranceFundForm from '@/components/insuranceFund/Form/InsuranceFundForm';
import InsuranceFundProxy from '@/proxies/InsuranceFundProxy';
import InsuranceFundMixin from '@/mixins/insuranceFund';
import { StandBy, SuccessSnackbar } from '@/app-events.js';

export default {
    name: 'InsuranceFundNew',
    mixins: [ InsuranceFundMixin ],
    components: {
        PageHeader,
        InsuranceFundForm,
    },
    data() {
        return {
            breadcrumbs: [
                {
                    text: this.$t('page.home.title'),
                    exact: true,
                    to: { name: 'home' },
                },
                {
                    text: this.$t('page.insurance_fund.title'),
                    exact: true,
                    to: { name: 'insurance_fund' },
                },
                {
                    text: this.$t('general.new'),
                    disabled: true,
                },
            ],
            data: {},
            rawData: {},
        };
    },
    methods: {
        saveObject() {
            if (JSON.stringify(this.data) === JSON.stringify(this.rawData)) {
                return;
            }
            this.$eventBus.dispatch(new StandBy(true));
            new InsuranceFundProxy().new(this.prepareFormData(this.data)).then(({ data }) => {
                this.$eventBus.dispatch(new StandBy(false));
                this.$eventBus.dispatch(new SuccessSnackbar(this.$t('insurance_fund.actions.created')));
                this.$router.push({ name: 'insurance_fund_detail', params: { id: data.data.id } });
            }).catch(({ data }) => {
                this.processErrors(data);
                this.$eventBus.dispatch(new StandBy(false));
            });
        }
    },
};
</script>
