import HomeRoute from './home';
import AuthRoute from './auth';
import ParticipantRoute from './participant';
import RegistrationRoute from './registration';
import RavRoute from './rav';
import RavConsultantRoute from './ravConsultant';
import OfficeLaborRoute from './officeLabor';
import InsuranceFundRoute from './insuranceFund';
import AmmRoute from './amm';
import LearnGoalControlRoute from './learnGoalControl';
import PresenceListRoute from './presenceList';
import CalendarRoute from './calendar';
import RegistrationTimeSlotRoute from './registrationTimeSlot';
import FirstInterviewRoute from './firstInterview';
import FirstInterviewEnRoute from './firstInterviewEn';
import CourseRoute from './course';
import AdminUserRoute from './admin/user';
import AdminStatisticsRoute from './admin/statistics';
import AdminGeneralSettingRoute from './admin/generalSetting';
import AdminMailSentRoute from './admin/mailsent';
import AdminWeekRoute from './admin/week';
import CourseCalendarRoute from './courseCalendar';
import CourseSurveyRoute from './courseSurvey';
import CourseSurveyEnRoute from './courseSurveyEn';
import EmailMasterTemplateRoute from './admin/emailMasterTemplate';
import SecurityRoute from './admin/security';
import CatchAllRoutes from './catchall';

const routes = [
    HomeRoute,
    AuthRoute,
    ParticipantRoute,
    RegistrationRoute,
    RavRoute,
    RavConsultantRoute,
    OfficeLaborRoute,
    InsuranceFundRoute,
    AmmRoute,
    LearnGoalControlRoute,
    PresenceListRoute,
    CalendarRoute,
    RegistrationTimeSlotRoute,
    FirstInterviewRoute,
    FirstInterviewEnRoute,
    CourseRoute,
    AdminUserRoute,
    AdminStatisticsRoute,
    AdminGeneralSettingRoute,
    AdminMailSentRoute,
    AdminWeekRoute,
    CourseCalendarRoute,
    CourseSurveyRoute,
    CourseSurveyEnRoute,
    EmailMasterTemplateRoute,
    SecurityRoute,
    CatchAllRoutes,
];

export default routes;
