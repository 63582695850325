<template>
    <div>
        <v-card flat>
            <v-data-table
                class="pa-3"
                :headers="headers"
                :items="computedObjects"
                :search="search"
                :show-select="selection"
                v-model="selectedRows"
                :footer-props="defaultFooterProps"
                :items-per-page="getPrefenceValue('presence_list.itemsPerPage', 25)"
                @update:items-per-page="val => setPrefenceValue('presence_list.itemsPerPage', val)"
                :page="currentPage"
                @update:page="page => currentPage = page"
                :group-by="participantId ? 'week' : undefined"
            >
                <template v-slot:group.header="{items, isOpen, toggle}">
                    <th colspan="4">
                        <v-icon
                            @click="toggle"
                        >
                            {{ isOpen ? 'mdi-chevron-down' : 'mdi-chevron-up' }}
                        </v-icon>
                        {{ $t('general.week') }} {{ items[0].week }}
                    </th>
                </template>
                <template v-slot:item.participant.fullname="{ item }">
                    <div v-if="typeof item.participant !== 'undefined'">
                        {{ item.participant.fullname }}
                        <span
                            v-if="item.participant.workload"
                            class="text--secondary"
                        >
                            [{{ item.participant.workload }}%]
                        </span>
                    </div>
                </template>
                <template v-slot:item.date="{ item }">
                    {{ formatDate(item.date) }} | {{ dayOfWeek(item.date) }}
                </template>
                <template v-slot:item.morning_presence="{ item }">
                    <v-chip pill>
                        <v-avatar
                            left
                            :color="presenceColor(item.morning_presence)"
                        >
                            {{ item.morning_presence }}
                        </v-avatar>
                        {{ $t(`presence_list.presence.${item.morning_presence}`) }}
                    </v-chip>
                    <span
                        v-if="item.morning_remark"
                        class="pl-3 text--secondary"
                        v-text="`(${item.morning_remark})`"
                    />
                </template>
                <template v-slot:item.afternoon_presence="{ item }">
                    <v-chip pill>
                        <v-avatar
                            left
                            :color="presenceColor(item.afternoon_presence)"
                        >
                            {{ item.afternoon_presence }}
                        </v-avatar>
                        {{ $t(`presence_list.presence.${item.afternoon_presence}`) }}
                    </v-chip>
                    <span
                        v-if="item.afternoon_remark"
                        class="pl-3 text--secondary"
                        v-text="`(${item.afternoon_remark})`"
                    />
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-btn
                        icon
                        class="mx-2"
                        @click="startEditObject(item)"
                    >
                        <v-icon>
                            mdi-pencil
                        </v-icon>
                    </v-btn>
                </template>
            </v-data-table>
        </v-card>
        <v-dialog
            v-model="editDialog"
            persistent
            max-width="800px"
        >
            <v-card>
                <v-card-title>
                    {{ editItem.name }}
                </v-card-title>
                <v-card-subtitle v-text="`${$t('presence_list.absence_for')} ${formatDate(editItem.date)} | ${dayOfWeek(editItem.date)}`" />
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col
                                cols="6"
                            >
                                <v-autocomplete
                                    :items="presences"
                                    :label="$t('presence_list.morning_presence')"
                                    v-model="editItem.morning_presence"
                                    :error-messages="formError(formErrors, 'morningPresence')"
                                />
                            </v-col>
                            <v-col
                                cols="6"
                            >
                                <v-autocomplete
                                    :items="presences"
                                    :label="$t('presence_list.afternoon_presence')"
                                    v-model="editItem.afternoon_presence"
                                    :error-messages="formError(formErrors, 'afternoonPresence')"
                                />
                            </v-col>
                            <v-col
                                cols="6"
                            >
                                <v-text-field
                                    type="text"
                                    :label="$t('presence_list.remark')"
                                    v-model="editItem.morning_remark"
                                    :error-messages="formError(formErrors, 'morningRemark')"
                                />
                            </v-col>
                            <v-col
                                cols="6"
                            >
                                <v-text-field
                                    type="text"
                                    :label="$t('presence_list.remark')"
                                    v-model="editItem.afternoon_remark"
                                    :error-messages="formError(formErrors, 'afternoonRemark')"
                                />
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn
                        color="default"
                        text
                        @click="editDialog = false"
                    >
                        {{ $t('general.cancel') }}
                    </v-btn>
                    <v-btn
                        color="primary darken-1"
                        text
                        @click="saveObject(editItem)"
                    >
                        {{ $t('general.save') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>

import PresenceListProxy from '@/proxies/PresenceListProxy';
import FormDataMixin from '@/mixins/form-data';
import DatesMixin from '@/mixins/dates';
import PresenceListMixin from '@/mixins/presenceList';
import { StandBy, BulkEditPresence, RefreshList } from '@/app-events.js';

export default {
    name: 'PresenceListList',
    mixins: [
        FormDataMixin,
        DatesMixin,
        PresenceListMixin,
    ],
    props: {
        search: {
            type: String,
            default: '',
        },
        selection: {
            type: Boolean,
            default: false,
        },
        objects: {
            type: Array,
            default: () => ([]),
        },
        participantId: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            selectedRows: [],
            editDialogs: [],
            editDialog: false,
            headers: [
                {
                    value: 'week',
                    align: ' d-none',
                },
                { 
                    text: this.$t('presence_list.participant'),
                    value: 'participant.fullname',
                    align: this.participantId ? ' d-none' : 'start',
                },
                { 
                    text: this.$t('presence_list.date'),
                    value: 'date',
                    align: this.participantId ? 'start' : ' d-none',
                },
                { 
                    text: this.$t('presence_list.morning_presence'),
                    value: 'morning_presence' 
                },
                { 
                    text: this.$t('presence_list.afternoon_presence'),
                    value: 'afternoon_presence' 
                },
                { 
                    text: 'Aktionen',
                    value: 'actions',
                    sortable: false ,
                    align: 'end',
                },
            ],
            editItem: {},
        }
    },
    mounted() {
        this.$eventBus.listen(BulkEditPresence, (event) => {
            const firstPresence = event.presences[ 0 ];
            this.editItem = {
                name: this.$t('general.bulk_edit'),
                date: firstPresence.date,
                morning_presence: firstPresence.morning_presence,
                morning_remark: firstPresence.morning_remark,
                afternoon_presence: firstPresence.afternoon_presence,
                afternoon_remark: firstPresence.afternoon_remark,
                bulk: event.presences,
            };
            this.editDialog = true;
        });
    },
    computed: {
        computedObjects() {
            if (this.participantId === '') {
                return this.objects;
            }
            const objects = JSON.parse(JSON.stringify(this.objects));
            let week = 1;
            let currentWeek = 0;
            let currentCourseWeek = 0;
            for (let i = 0; i < objects.length; i++) {
                const object = objects[ i ];
                const objectDate = new Date(object.date);
                const objectWeek = this.getWeekNumber(objectDate);
                if (currentWeek !== objectWeek) {
                    currentWeek = objectWeek;
                    currentCourseWeek++;
                }
                objects[ i ].week = currentCourseWeek;
            }
            return objects;
        }
    },
    methods: {
        getWeekNumber(d) {
            d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
            d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay()||7));
            var yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
            var weekNo = Math.ceil(( ( (d - yearStart) / 86400000) + 1)/7);
            return weekNo;
        },
        presenceColor(presence) {
            if (presence === 'X') {
                return 'success--light';
            }
            if (presence === 'HO') {
                return 'primary--light';
            }
            return 'error--light';
        },
        startEditObject(object) {
            this.editDialog = true;
            this.editItem = JSON.parse(JSON.stringify(object));
        },
        saveSingleObject(object) {
            new PresenceListProxy().update(object.id, this.prepareFormData(object)).then(({ data }) => {
                this.$eventBus.dispatch(new StandBy(false));
                this.editDialog = false
                this.resetErrors();
                for (let i = 0; i < this.objects.length; i++) {
                    if (this.objects[ i ].id === object.id) {
                        this.objects[ i ].morning_presence = object.morning_presence;
                        this.objects[ i ].morning_remark = object.morning_remark;
                        this.objects[ i ].afternoon_presence = object.afternoon_presence;
                        this.objects[ i ].afternoon_remark = object.afternoon_remark;
                    }
                }
            }).catch(({ data }) => {
                this.processErrors(data);
                this.$eventBus.dispatch(new StandBy(false));
            });
        },
        saveBulkObjects(object) {
            const ids = []
            for (let i = 0; i < object.bulk.length; i++) {
                const presence = object.bulk[ i ];
                ids.push(presence.id);
            }
            new PresenceListProxy().massUpdate(ids.join(','), this.prepareFormData(object)).then(({ data }) => {
                this.$eventBus.dispatch(new StandBy(false));
                this.editDialog = false
                this.resetErrors();
                this.$eventBus.dispatch(new RefreshList());
            }).catch(({ data }) => {
                this.processErrors(data);
                this.$eventBus.dispatch(new StandBy(false));
            });
        },
        saveObject(object) {
            this.$eventBus.dispatch(new StandBy(true));
            if (typeof object.bulk === 'undefined') {
                this.saveSingleObject(object);
            } else {
                this.saveBulkObjects(object);
            }
        },
    },
    watch: {
        selectedRows(val) {
            this.$emit('selection-changed', val);
        }
    },
};
</script>
