<template>
    <v-flex
        md12
        class="px-12 pb-12"
    >
        <PageHeader
            :breadcrumbs="breadcrumbs"
            :title="$t('page.registration_time_slot.title')"
            button
            :button-text="$t('page.registration_time_slot.new')"
            button-to="registration_time_slot_new"
            search
            :search-label="$t('page.registration_time_slot.search')"
            @search-input="searchInputListener"
        />
        <v-row>
            <ActionBar
                new-button
                new-to="registration_time_slot_new"
                :new-label="$t('page.registration_time_slot.new')"
                fetch
                :fetch-function="fetchData"
            >
                <template v-slot:addons>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                v-on="on"
                                v-bind="attrs"
                                small
                                icon
                                @click="generateDialog = true"
                                class="ml-3"
                            >
                                <v-icon>mdi-content-duplicate</v-icon>
                            </v-btn>
                        </template>
                        <span v-text="$t('page.registration_time_slot.generate')" />
                    </v-tooltip>
                </template>
            </ActionBar>
            <v-col cols="12">
                <v-row dense>
                    <v-col
                        cols="10"
                        offset-lg="1"
                    >
                        <v-progress-linear
                            v-if="loading"
                            indeterminate
                            color="primary"
                        />
                    </v-col>
                    <v-col
                        cols="2"
                        offset-lg="1"
                    >
                        <v-skeleton-loader
                            v-if="loading"
                            class="mx-auto"
                            type="article"
                        />
                    </v-col>
                    <v-col cols="2">
                        <v-skeleton-loader
                            v-if="loading"
                            class="mx-auto"
                            type="article"
                        />
                    </v-col>
                    <v-col cols="2">
                        <v-skeleton-loader
                            v-if="loading"
                            class="mx-auto"
                            type="article"
                        />
                    </v-col>
                    <v-col cols="2">
                        <v-skeleton-loader
                            v-if="loading"
                            class="mx-auto"
                            type="article"
                        />
                    </v-col>
                    <v-col cols="2">
                        <v-skeleton-loader
                            v-if="loading"
                            class="mx-auto"
                            type="article"
                        />
                    </v-col>
                </v-row>
                <RegistrationTimeSlotList
                    v-if="!loading"
                    :objects="filteredObjects()"
                    :search="textSearch"
                    @selection-changed="selectionChangedListener"
                />
            </v-col>
        </v-row>
        
        <v-dialog
            v-model="generateDialog"
            persistent
            max-width="400px"
        >
            <v-card>
                <v-card-title>
                    {{ $t('page.registration_time_slot.generate') }}
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col
                                cols="12"
                            >
                                <DatePicker
                                    :allowed-dates="allowedDates"
                                    v-model="generateDate"
                                    :label="$t('page.registration_time_slot.until')"
                                />
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn
                        color="default"
                        text
                        @click="generateDialog = false"
                    >
                        {{ $t('general.cancel') }}
                    </v-btn>
                    <v-btn
                        color="primary darken-1"
                        text
                        @click="generateRegistrationTimeSlot()"
                    >
                        {{ $t('general.generate') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-flex>
</template>

<script>

import ActionBar from '@/components/common/ActionBar';
import PageHeader from '@/components/common/PageHeader';
import RegistrationTimeSlotList from '@/components/registrationTimeSlot/RegistrationTimeSlotList';
import RegistrationTimeSlotProxy from '@/proxies/RegistrationTimeSlotProxy';
import DatesMixin from '@/mixins/dates';
import FileDownloadMixin from '@/mixins/file-download';
import DatePicker from '@/components/common/DatePicker';
import { SuccessSnackbar, ObjectDeleted, StandBy } from '@/app-events.js';

export default {
    name: 'RegistrationTimeSlotIndex',
    mixins: [
        DatesMixin,
        FileDownloadMixin,
    ],
    components: {
        //MobileNewButton,
        ActionBar,
        PageHeader,
        RegistrationTimeSlotList,
        DatePicker
    },
    data() {
        return {
            breadcrumbs: [
                {
                    text: this.$t('page.home.title'),
                    exact: true,
                    to: { name: 'home' },
                },
                {
                    text: this.$t('page.registration_time_slot.title'),
                    disabled: true,
                },
            ],
            textSearch: '',
            objects: [],
            selectedRows: [],
            loading: true,
            generateDialog: false,
            generateDate: this.formatDateISO(this.beginingOfWeek(new Date())),
        };
    },
    created () {
        this.fetchData();
        this.$eventBus.listen(ObjectDeleted, (event) => {
            this.$eventBus.dispatch(new SuccessSnackbar(this.$t('registration_time_slot.actions.deleted')));
            for (let i = 0; i < this.objects.length; i++) {
                const registrationTimeSlot = this.objects[ i ];
                if (registrationTimeSlot.id === event.object.id) {
                    this.objects.splice(i, 1);
                    break;
                }
            }
        });
    },
    methods: {
        allowedDates(date) {
            const day = this.$moment(date).day();
            return day !== 0 && day !== 6
        },
        filteredObjects() {
            if (!this.textSearch) {
                return this.objects;
            }
            const lowerCaseSearch = this.textSearch.toLowerCase();
            const filteredObjects = [];
            for (let i = 0; i < this.objects.length; i++) {
                const registrationTimeSlot = this.objects[ i ];
                if (registrationTimeSlot.course.toLowerCase().includes(lowerCaseSearch) || (registrationTimeSlot.professor && registrationTimeSlot.professor.toLowerCase().includes(lowerCaseSearch))) {
                    filteredObjects.push(registrationTimeSlot);
                }
            }
            return filteredObjects;
        },
        generateRegistrationTimeSlot() {
            this.$eventBus.dispatch(new StandBy(true));
            new RegistrationTimeSlotProxy().generate(this.generateDate).then(({ data }) => {
                this.loading = false;
                this.generateDialog = false;
                this.fetchData();
            }).catch(({ data }) => {
                this.displayError(data);
            }).finally(() => {
                this.$eventBus.dispatch(new StandBy(false));
            });
        },
        fetchData() {
            this.loading = true;
            this.selectedRows = [];
            new RegistrationTimeSlotProxy().all().then(({ data }) => {
                this.objects = data.data;
                this.loading = false;
            }).catch(({ data }) => {
                this.displayError(data);
            });
        },
        searchInputListener(value) {
            this.textSearch = value;
        },
        selectionChangedListener(value) {
            this.selectedRows = value;
        },
    },
};
</script>
