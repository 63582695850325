import Proxy from './Proxy';

class RegistrationTimeSlotProxy extends Proxy {
    constructor(parameters = {}) {
        super('/registration-time-slot', parameters);
    }

    all() {
        return this.submit('get', '/');
    }

    generate(date) {
        return this.submit('post', `/generate/${date}`, null, null, {}, 'blob');
    }
}

export default RegistrationTimeSlotProxy;
