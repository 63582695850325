<template>
    <v-flex
        md12
        class="px-12 pb-12"
    >
        <PageHeader
            :breadcrumbs="breadcrumbs"
            :title="pageTitle"
            back
            back-to="insurance_fund"
        />
        <v-row>
            <v-col cols="12">
                <v-alert
                    v-for="(error, errorKey) in generalFormErrors"
                    :key="errorKey"
                    type="error"
                    v-text="error"
                />
                <InsuranceFundForm
                    :data="data"
                    :raw-data="rawData"
                    :errors="formErrors"
                    :loading="loading"
                    :save-function="saveObject"
                    :discard-function="resetObject"
                />
            </v-col>
        </v-row>
    </v-flex>
</template>

<script>

import PageHeader from '@/components/common/PageHeader';
import InsuranceFundForm from '@/components/insuranceFund/Form/InsuranceFundForm';
import InsuranceFundProxy from '@/proxies/InsuranceFundProxy';
import InsuranceFundMixin from '@/mixins/insuranceFund';
import { SuccessSnackbar, StandBy } from '@/app-events.js';

export default {
    name: 'InsuranceFundDetail',
    mixins: [ InsuranceFundMixin ],
    components: {
        PageHeader,
        InsuranceFundForm,
    },
    created() {
        this.fetchData();
    },
    data() {
        return {
            loading: false,
            data: {},
            rawData: {},
        };
    },
    computed: {
        pageTitle() {
            let title = '';
            if (typeof this.rawData.name !== 'undefined' && this.rawData.name !== null) {
                title = `${this.rawData.name} `;
            }
            if (typeof this.rawData.number !== 'undefined' && this.rawData.number !== null) {
                title = `${title}${this.rawData.number}`;
            }
            return title;
        },
        breadcrumbs() {
            return [
                {
                    text: this.$t('page.home.title'),
                    exact: true,
                    to: { name: 'home' },
                },
                {
                    text: this.$t('page.insurance_fund.title'),
                    exact: true,
                    to: { name: 'insurance_fund' },
                },
                {
                    text: this.pageTitle,
                    disabled: true,
                },
            ];
        },
    },
    methods: {
        fetchData() {
            this.loading = true;
            new InsuranceFundProxy().find(this.$route.params.id).then(({ data }) => {
                this.data = this.prepareData(data.data);
                this.rawData = JSON.parse(JSON.stringify(this.data));
                this.loading = false;
            }).catch(({ data }) => {
                this.displayError(data);
            });
            
        },
        saveObject() {
            this.$eventBus.dispatch(new StandBy(true));
            new InsuranceFundProxy().update(this.$route.params.id, this.prepareFormData(this.data)).then(({ data }) => {
                this.$eventBus.dispatch(new StandBy(false));
                this.rawData = JSON.parse(JSON.stringify(this.data));
                this.$eventBus.dispatch(new SuccessSnackbar(this.$t('insurance_fund.actions.saved')));
                this.resetErrors();
            }).catch(({ data }) => {
                this.processErrors(data);
                this.$eventBus.dispatch(new StandBy(false));
            });
        },
    },
};
</script>
