import DefaultLayout from '@/layouts/Default';
import OfficeLaborIndex from '@/views/OfficeLabor/Index';
import OfficeLaborDetail from '@/views/OfficeLabor/Detail';
import OfficeLaborNew from '@/views/OfficeLabor/New';

export default {
    path: '/awa',
    component: DefaultLayout,
    children: [
        {
            path: '',
            name: 'office_labor',
            component: OfficeLaborIndex,
            meta: {
                auth: true,
            },
        },
        {
            path: 'new',
            name: 'office_labor_new',
            component: OfficeLaborNew,
            meta: {
                auth: true,
            },
        },
        {
            path: ':id',
            name: 'office_labor_detail',
            component: OfficeLaborDetail,
            meta: {
                auth: true,
            },
        },
    ],
};
