var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-flex',{staticClass:"px-12 pb-12",attrs:{"md12":""}},[_c('PageHeader',{attrs:{"breadcrumbs":_vm.breadcrumbs,"title":_vm.$t('page.presence_list.title')}}),_c('v-row',[_c('ActionBar',{attrs:{"fetch":"","fetch-function":_vm.fetchData},scopedSlots:_vm._u([{key:"addons",fn:function(){return [(_vm.objects.length > 0)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-3",attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.print()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-printer")])],1)]}}],null,false,1020408071)},[_c('span',{domProps:{"textContent":_vm._s(_vm.$t('general.print'))}})]):_vm._e(),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-chip',{staticClass:"float-right text-none",attrs:{"color":"secondary--dark","dark":""}},[_c('v-avatar',{attrs:{"left":""}},[_c('v-icon',{on:{"click":function($event){!_vm.loading ? _vm.previousDate() : false}}},[_vm._v(" mdi-chevron-left ")])],1),_c('v-btn',_vm._g({staticClass:"mx-6",attrs:{"text":"","min-width":"190px"}},on),[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-calendar ")]),_vm._v(" "+_vm._s(_vm.currentDateDisplay)+" | "+_vm._s(_vm.dayOfWeek(_vm.currentDate, true))+" ")],1),_c('v-avatar',{attrs:{"right":""}},[_c('v-icon',{on:{"click":function($event){!_vm.loading ? _vm.nextDate() : false}}},[_vm._v(" mdi-chevron-right ")])],1)],1)]}}]),model:{value:(_vm.datePicker),callback:function ($$v) {_vm.datePicker=$$v},expression:"datePicker"}},[_c('v-date-picker',{attrs:{"locale":"de-ch","first-day-of-week":1,"allowed-dates":_vm.allowedDates},on:{"input":function($event){_vm.datePicker = false}},model:{value:(_vm.currentDatePicker),callback:function ($$v) {_vm.currentDatePicker=$$v},expression:"currentDatePicker"}})],1),(_vm.selectedRows.length > 0)?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-3",attrs:{"small":"","rounded":"","depressed":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.selectedRows.length)+" "+_vm._s(_vm.$t('general.selected'))+" "),_c('v-icon',[_vm._v("mdi-chevron-down")])],1)]}}],null,false,1904422200)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.bulkEdit()}}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-pencil-box-multiple")])],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(_vm.$t('general.bulk_edit'))}})],1)],1)],1)],1):_vm._e()]},proxy:true}])}),_c('v-col',{attrs:{"cols":"12"}},[(_vm.loading)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e(),(_vm.loading)?_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"type":"table-tbody"}}):_vm._e(),(!_vm.loading)?_c('PresenceListList',{attrs:{"objects":_vm.objects,"selection":""},on:{"selection-changed":_vm.selectionChangedListener}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }