<template>
    <v-app id="inspire">
        <navigation-drawer
            v-model="drawer"
        />
        <v-app-bar
            color="primary"
            clipped-left
            app
            flat
            class="d-print-none"
        >
            <v-app-bar-nav-icon
                @click.stop="drawer = !drawer"
            />
            <v-toolbar-title>ba Database</v-toolbar-title>
            <v-spacer />
        </v-app-bar>
        <v-main>
            <!-- Snackbar -->
            <v-snackbar
                v-model="snackbar.enabled"
                :color="snackbar.color"
                :timeout="4000"
            >
                {{ snackbar.text }}
            </v-snackbar>
            <!-- StandBy -->
            <v-dialog
                v-model="standby"
                persistent
                width="300"
            >
                <v-card
                    color="primary"
                    dark
                >
                    <v-card-text>
                        {{ $t('general.stand_by') }}
                        <v-progress-linear
                            indeterminate
                            color="white"
                            class="mb-0"
                        />
                    </v-card-text>
                </v-card>
            </v-dialog>
            <router-view />
        </v-main>
    </v-app>
</template>

<script>
import NavigationDrawer from '@/components/navigation/NavigationDrawer';
import { Snackbar, SuccessSnackbar, ErrorSnackbar, DefaultSnackbar, StandBy } from '@/app-events.js';

export default {
    name: 'DefaultLayout',
    components: {
        NavigationDrawer,
    },
    data: () => ({
        drawer: true,
        standby: false,
        snackbar: {
            enabled: false,
            color: '',
            text: '',
        }
    }),
    created() {
        if (this.$auth.check()) {
            this.$store.dispatch('preferences/initialize');
            this.$store.dispatch('canton/load');   
        }
        this.$eventBus.listen(Snackbar, event => {
            this.snackbar = event.snackbar;
        });
        this.$eventBus.listen(SuccessSnackbar, event => {
            this.snackbar = {
                enabled: true,
                color: 'success',
                text: event.text,
            };
        });
        this.$eventBus.listen(ErrorSnackbar, event => {
            this.snackbar = {
                enabled: true,
                color: 'error',
                text: event.text,
            };
        });
        this.$eventBus.listen(DefaultSnackbar, event => {
            this.snackbar = {
                enabled: true,
                color: '',
                text: event.text,
            };
        });
        this.$eventBus.listen(StandBy, event => {
            this.standby = event.standby;
        });
    },
};
</script>
