export default {
    methods: {
        resetObject() {
            this.data = JSON.parse(JSON.stringify(this.rawData));
            this.resetErrors();
        },
        prepareData(data) {
            const startParts = data.start.split(' ');
            data.hour = startParts[ 1 ];
            data.startDay = startParts[ 0 ];
            return data;
        },
        prepareFormData(data) {
            return {
                registration_time_slot: {
                    course: data.course,
                    professor: data.professor,
                    start: `${data.startDay} ${data.hour}`,
                    default: data.default,
                },
            };
        },
    },
};
