import Proxy from './Proxy';

class InsuranceFundProxy extends Proxy {
    constructor(parameters = {}) {
        super('/insurance-fund', parameters);
    }

    all() {
        return this.submit('get', '/');
    }
}

export default InsuranceFundProxy;
