import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import colors from 'vuetify/lib/util/colors';
import de from 'vuetify/es5/locale/de';

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'mdi',
    },
    theme: {
        dark: false,
        themes: {
            light: {
                primary: colors.amber.darken1,
                "primary--light": colors.amber.lighten3,
                "primary--dark": "#c68400",
                secondary: colors.blueGrey.darken2,
                "secondary--light": colors.blueGrey.lighten1,
                "secondary--dark": colors.blueGrey.darken4,
                accent: "#102027",
                "success--light": colors.green.lighten2,
                "error--light": colors.red.lighten2,
            },
            dark: {
                primary: colors.pink.darken1,
                "primary--light": colors.amber.lighten3,
                "primary--dark": "#c68400",
                secondary: colors.blueGrey.darken2,
                "secondary--light": colors.blueGrey.lighten1,
                "secondary--dark": colors.blueGrey.darken4,
                accent: "#102027",
            },
        },
    },
    lang: {
        locales: { de },
        current: 'de',
    },
});
