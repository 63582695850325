import Proxy from './Proxy';

class EmailMasterTemplateProxy extends Proxy {
    constructor(parameters = {}) {
        super('/email-master-template', parameters);
    }
    find(type, language) {
        return this.submit('get', `/${type}/${language}`);
    }
    update(type, language, formData) {
        return this.submit('put', `/${type}/${language}`, formData);
    }
}

export default EmailMasterTemplateProxy;
