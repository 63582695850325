import DefaultLayout from '@/layouts/Default';
import InsuranceFundIndex from '@/views/InsuranceFund/Index';
import InsuranceFundDetail from '@/views/InsuranceFund/Detail';
import InsuranceFundNew from '@/views/InsuranceFund/New';

export default {
    path: '/kassen',
    component: DefaultLayout,
    children: [
        {
            path: '',
            name: 'insurance_fund',
            component: InsuranceFundIndex,
            meta: {
                auth: true,
            },
        },
        {
            path: 'new',
            name: 'insurance_fund_new',
            component: InsuranceFundNew,
            meta: {
                auth: true,
            },
        },
        {
            path: ':id',
            name: 'insurance_fund_detail',
            component: InsuranceFundDetail,
            meta: {
                auth: true,
            },
        },
    ],
};
