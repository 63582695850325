import DefaultLayout from '@/layouts/Default';
import Index from '@/views/Admin/User/Index';
import Detail from '@/views/Admin/User/Detail';
import New from '@/views/Admin/User/New';

export default {
    path: '/admin/users',
    component: DefaultLayout,
    children: [
        {
            path: '',
            name: 'admin_users',
            component: Index,
            meta: {
                auth: {
                    roles: 'ROLE_ADMIN',
                },
            },
        },
        {
            path: 'new',
            name: 'admin_user_new',
            component: New,
            meta: {
                auth: {
                    roles: 'ROLE_ADMIN',
                },
            },
        },
        {
            path: ':id',
            name: 'admin_user_detail',
            component: Detail,
            meta: {
                auth: {
                    roles: 'ROLE_ADMIN',
                },
            },
        },
    ],
};
