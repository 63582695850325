<template>
    <v-card flat>
        <v-card-text>
            <v-row>
                <v-col cols="12">
                    <v-text-field
                        type="text"
                        v-model="data.course"
                        :label="$t('registration_time_slot.course')"
                        :error-messages="formError(errors, 'course')"
                        :hint="$t('general.required')"
                        persistent-hint
                    />
                </v-col>
                <v-col cols="12">
                    <v-text-field
                        type="text"
                        v-model="data.professor"
                        :label="$t('registration_time_slot.professor')"
                        :error-messages="formError(errors, 'professor')"
                    />
                </v-col>
                <v-col
                    v-if="!data.id && data.selectDate"
                    cols="12"
                >
                    <DatePicker
                        v-model="data.startDay"
                        :label="$t('registration_time_slot.start_day')"
                    />
                </v-col>
                <v-col cols="12">
                    <v-select
                        :items="hours"
                        v-model="data.hour"
                        :label="$t('registration_time_slot.start')"
                        :error-messages="formError(errors, 'start')"
                        :hint="$t('general.required')"
                        persistent-hint
                    />
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>

import DatePicker from '@/components/common/DatePicker';

export default {
    name: 'OfficeInformation',
    components: {
        DatePicker
    },
    props: {
        data: {
            type: Object,
            default: () => ({
                name: '',
                roles: [],
            }),
        },
        errors: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            hours: [
                {
                    value: '08:00',
                    text: '08:00',
                },
                {
                    value: '08:15',
                    text: '08:15',
                },
                {
                    value: '08:30',
                    text: '08:30',
                },
                {
                    value: '08:45',
                    text: '08:45',
                },
                {
                    value: '09:00',
                    text: '09:00',
                },
                {
                    value: '09:15',
                    text: '09:15',
                },
                {
                    value: '09:30',
                    text: '09:30',
                },
                {
                    value: '09:45',
                    text: '09:45',
                },
                {
                    value: '10:00',
                    text: '10:00',
                },
                {
                    value: '10:15',
                    text: '10:15',
                },
                {
                    value: '10:30',
                    text: '10:30',
                },
                {
                    value: '10:45',
                    text: '10:45',
                },
                {
                    value: '11:00',
                    text: '11:00',
                },
                {
                    value: '11:15',
                    text: '11:15',
                },
                {
                    value: '11:30',
                    text: '11:30',
                },
                {
                    value: '11:45',
                    text: '11:45',
                },
                {
                    value: '12:00',
                    text: '12:00',
                },
                {
                    value: '12:15',
                    text: '12:15',
                },
                {
                    value: '12:30',
                    text: '12:30',
                },
                {
                    value: '12:45',
                    text: '12:45',
                },
                {
                    value: '13:00',
                    text: '13:00',
                },
                {
                    value: '13:15',
                    text: '13:15',
                },
                {
                    value: '13:30',
                    text: '13:30',
                },
                {
                    value: '13:45',
                    text: '13:45',
                },
                {
                    value: '14:00',
                    text: '14:00',
                },
                {
                    value: '14:15',
                    text: '14:15',
                },
                {
                    value: '14:30',
                    text: '14:30',
                },
                {
                    value: '14:45',
                    text: '14:45',
                },
                {
                    value: '15:00',
                    text: '15:00',
                },
                {
                    value: '15:15',
                    text: '15:15',
                },
                {
                    value: '15:30',
                    text: '15:30',
                },
                {
                    value: '15:45',
                    text: '15:45',
                },
                {
                    value: '16:00',
                    text: '16:00',
                },
                {
                    value: '16:15',
                    text: '16:15',
                },
                {
                    value: '16:30',
                    text: '16:30',
                },
                {
                    value: '16:45',
                    text: '16:45',
                },
                {
                    value: '17:00',
                    text: '17:00',
                },
                {
                    value: '17:15',
                    text: '17:15',
                },
                {
                    value: '17:30',
                    text: '17:30',
                },
                {
                    value: '17:45',
                    text: '17:45',
                },
                {
                    value: '18:00',
                    text: '18:00',
                },
                {
                    value: '18:15',
                    text: '18:15',
                },
                {
                    value: '18:30',
                    text: '18:30',
                },
                {
                    value: '18:45',
                    text: '18:45',
                },
                {
                    value: '19:00',
                    text: '19:00',
                },
            ],
        }
    },
};
</script>
