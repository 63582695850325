import {
    LOAD,
    RESET_STATE,
    LOAD_ERROR,
} from './mutation-types';

export default {
    [ LOAD ]: (state, payload) => {
        state.cantons = payload.cantons;
    },
    [ LOAD_ERROR ]: (state) => {
        state.cantons = [];
    },
    [ RESET_STATE ]: (state) => {
        state.cantons = [];
    },
};
