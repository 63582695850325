<template>
    <v-flex
        md12
        class="px-12 pb-12"
    >
        <PageHeader
            :breadcrumbs="breadcrumbs"
            :title="$t('page.office_labor_new.title')"
            back
        />
        <v-row>
            <v-col cols="12">
                <OfficeLaborForm
                    :data="data"
                    :raw-data="rawData"
                    :errors="formErrors"
                    :save-function="saveObject"
                    :discard-function="resetObject"
                />
            </v-col>
        </v-row>
    </v-flex>
</template>

<script>

import PageHeader from '@/components/common/PageHeader';
import OfficeLaborForm from '@/components/officeLabor/Form/OfficeLaborForm';
import OfficeLaborProxy from '@/proxies/OfficeLaborProxy';
import { StandBy, SuccessSnackbar } from '@/app-events.js';

export default {
    name: 'OfficeLaborNew',
    components: {
        PageHeader,
        OfficeLaborForm,
    },
    data() {
        return {
            breadcrumbs: [
                {
                    text: this.$t('page.home.title'),
                    exact: true,
                    to: { name: 'home' },
                },
                {
                    text: this.$t('page.office_labor.title'),
                    exact: true,
                    to: { name: 'office_labor' },
                },
                {
                    text: this.$t('general.new'),
                    disabled: true,
                },
            ],
            data: {
                canton: {},
            },
            rawData: {
                canton: {},
            },
        };
    },
    methods: {
        resetObject() {
            this.data = JSON.parse(JSON.stringify(this.rawData));
            this.resetErrors();
        },
        saveObject() {
            if (JSON.stringify(this.data) === JSON.stringify(this.rawData)) {
                return;
            }
            this.$eventBus.dispatch(new StandBy(true));
            const formData = {
                office_labor: {
                    officeName: this.data.office_name,
                    address: this.data.address,
                    zip: this.data.zip,
                    city: this.data.city,
                    officePhone: this.data.office_phone,
                    fax: this.data.fax,
                    memo: this.data.memo,
                    firstname: this.data.firstname,
                    lastname: this.data.lastname,
                    phone: this.data.phone,
                    email: this.data.email,
                    canton: this.data.canton.code,
                },
            };
            new OfficeLaborProxy().new(formData).then(({ data }) => {
                this.$eventBus.dispatch(new StandBy(false));
                this.$eventBus.dispatch(new SuccessSnackbar(this.$t('office_labor.actions.created')));
                this.$router.push({ name: 'office_labor_detail', params: { id: data.data.id } });
            }).catch(({ data }) => {
                this.processErrors(data);
                this.$eventBus.dispatch(new StandBy(false));
            });
        }
    },
};
</script>
