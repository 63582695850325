<template>
    <v-card flat>
        <v-card-text>
            <v-row>
                <v-col cols="12">
                    <v-select
                        v-model="data.salutation"
                        :items="salutations"
                        :label="$t('participant.salutation')"
                        :error-messages="formError(errors, 'salutation')"
                        :hint="$t('general.required')"
                        persistent-hint
                    />
                </v-col>
                <v-col cols="12">
                    <v-text-field
                        type="text"
                        v-model="data.firstname"
                        :label="$t('participant.firstname')"
                        :error-messages="formError(errors, 'firstname')"
                        :hint="$t('general.required')"
                        persistent-hint
                    />
                </v-col>
                <v-col cols="12">
                    <v-text-field
                        type="text"
                        v-model="data.lastname"
                        :label="$t('participant.lastname')"
                        :error-messages="formError(errors, 'lastname')"
                        :hint="$t('general.required')"
                        persistent-hint
                    />
                </v-col>
                <v-col cols="12">
                    <v-textarea
                        type="text"
                        v-model="data.address"
                        :label="$t('participant.address')"
                        :error-messages="formError(errors, 'address')"
                        :hint="isSimpleForm(data) ? '' : $t('general.required')"
                        persistent-hint
                    />
                </v-col>
                <v-col md="6">
                    <v-text-field
                        type="text"
                        v-model="data.zip"
                        :label="$t('participant.zip')"
                        :error-messages="formError(errors, 'zip')"
                        :hint="$t('general.required')"
                        persistent-hint
                    />
                </v-col>
                <v-col md="6">
                    <v-text-field
                        type="text"
                        v-model="data.city"
                        :label="$t('participant.city')"
                        :error-messages="formError(errors, 'city')"
                        :hint="$t('general.required')"
                        persistent-hint
                    />
                </v-col>
                <v-col cols="12">
                    <v-select
                        v-model="data.canton.code"
                        :items="computedCantons"
                        :label="$t('participant.canton')"
                        :error-messages="formError(errors, 'canton')"
                        clearable
                        @click:clear="$nextTick(() => data.canton = null)"
                        :hint="$t('general.required')"
                        persistent-hint
                    />
                </v-col>
                <v-col cols="12">
                    <v-text-field
                        type="text"
                        v-model="data.phone"
                        :label="$t('participant.phone')"
                        :error-messages="formError(errors, 'phone')"
                    />
                </v-col>
                <v-col cols="12">
                    <v-text-field
                        type="text"
                        v-model="data.mobile"
                        :label="$t('participant.mobile')"
                        :error-messages="formError(errors, 'mobile')"
                    />
                </v-col>
                <v-col cols="12">
                    <v-text-field
                        type="email"
                        v-model="data.email"
                        :label="$t('participant.email')"
                        :error-messages="formError(errors, 'email')"
                        :hint="$t('general.required')"
                        persistent-hint
                    />
                </v-col>
                <v-col cols="12">
                    <BirthdatePicker
                        v-model="data.birthdate"
                        :label="$t('participant.birthdate')"
                        :errors="errors"
                        error-key="birthdate"
                        :hint="$t('general.required')"
                        persistent-hint
                    />
                </v-col>
                <v-col cols="12">
                    <v-select
                        v-model="data.civil_status"
                        :items="civilStatuses"
                        :label="$t('participant.civil_status')"
                        :error-messages="formError(errors, 'civilStatus')"
                        :hint="isSimpleForm(data) ? '' : $t('general.required')"
                        persistent-hint
                    />
                </v-col>
                <v-col cols="12">
                    <v-autocomplete
                        v-model="data.nationality"
                        :items="nationalities"
                        :label="$t('participant.nationality')"
                        :error-messages="formError(errors, 'nationality')"
                        :hint="$t('general.required')"
                        persistent-hint
                    />
                </v-col>
                <v-col cols="12">
                    <v-text-field
                        type="text"
                        v-model="data.plain_ahv_number"
                        :label="$t('participant.plain_ahv_number')"
                        :error-messages="formError(errors, 'plainAhvNumber')"
                        :hint="isSimpleForm(data) ? '' : $t('general.required')"
                        persistent-hint
                    />
                </v-col>
                <v-col cols="12">
                    <v-select
                        v-model="data.german_knowledge"
                        :items="germanKnowledges"
                        :label="$t('participant.german_knowledge')"
                        :error-messages="formError(errors, 'germanKnowledge')"
                        clearable
                        @click:clear="$nextTick(() => data.german_knowledge = null)"
                        :hint="$t('general.required')"
                        persistent-hint
                    />
                </v-col>
                <v-col cols="12">
                    <v-text-field
                        type="text"
                        v-model="data.xing"
                        :label="$t('participant.xing')"
                        :error-messages="formError(errors, 'xing')"
                    />
                </v-col>
                <v-col cols="12">
                    <v-text-field
                        type="text"
                        v-model="data.linkedin"
                        :label="$t('participant.linkedin')"
                        :error-messages="formError(errors, 'linkedin')"
                    />
                </v-col>
                <v-col cols="12">
                    <v-text-field
                        type="text"
                        v-model="data.website"
                        :label="$t('participant.website')"
                        :error-messages="formError(errors, 'website')"
                    />
                </v-col>
                <v-col cols="12">
                    <v-textarea
                        type="text"
                        v-model="data.note"
                        :label="$t('participant.note')"
                        :error-messages="formError(errors, 'note')"
                    />
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>

import FormDataMixin from '@/mixins/form-data';
import BirthdatePicker from '@/components/common/BirthdatePicker';
import ParticipantMixin from '@/mixins/participant';
import { mapGetters } from 'vuex';

export default {
    name: 'PersonalInformation',
    mixins: [
        FormDataMixin,
        ParticipantMixin,
    ],
    components: {
        BirthdatePicker,
    },
    props: {
        data: {
            type: Object,
            default: () => ({
                canton: {},
            }),
        },
        errors: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        ...mapGetters({
            cantons: 'canton/cantons',
        }),
        computedCantons() {
            const allCantons = [];
            for (let i = 0; i < this.cantons.length; i++) {
                const canton = this.cantons[ i ];
                allCantons.push({
                    value: canton.code,
                    text: `${canton.code} ${canton.name}`,
                })
            }
            return allCantons;
        },
    },
};
</script>
