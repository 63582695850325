<template>
    <div>
        <div class="display-2 mt-12">
            Anmeldung Kurs(e)
        </div>
        <div
            v-if="!thankyou"
            class="subtitle-1 my-4"
        >
            Bei Interesse an einem unserer Angebote füllen Sie bitte folgendes Formular aus.<br>
            Wir werden im Anschluss mit Ihnen Kontakt aufnehmen.
        </div>
        <v-card
            flat
            class="pa-12"
        >
            <v-card-text>
                <div v-if="thankyou">
                    <p class="text-h6">
                        Vielen Dank für Ihr Interesse am Kurs. Sie erhalten in Kürze eine Bestätigungsmail.
                    </p>
                    <p>
                        Wir kontrollieren Ihre Angaben und werden uns so schnell wie möglich mit Ihnen in Verbindung setzen.<br>
                        <br>
                        Falls Sie sich für einen weiteren Kurs anmelden möchten, wählen Sie unten einen zusätzlichen Kurs aus.<br>
                        <br>
                        Freundliche Grüsse<br>
                        Ihr bewerbeagentur Team<br>
                    </p>
                </div>
                <v-alert
                    v-for="(error, errorKey) in generalFormErrors"
                    :key="errorKey"
                    type="error"
                    v-text="error"
                />
                <v-form @submit.prevent="submit">
                    <p
                        class="headline mb-8"
                        v-text="$t('page.first_interview.course_information')"
                    />
                    <v-row>
                        <v-col cols="12">
                            <v-select
                                v-model="data.course"
                                :items="computedCourses"
                                :label="$t('first_interview.course')"
                                :error-messages="formError(formErrors, 'course')"
                                :hint="$t('general.required')"
                                persistent-hint
                                @change="courseSelected"
                            />
                            <p>
                                <br>Falls Sie an mehreren Kursen Interesse haben, können Sie einen weiteren Kurs auswählen, nachdem Sie
                                Ihre erste Anmeldung für einen Kurs abgeschickt haben.
                            </p>
                        </v-col>
                    </v-row>
                    <div v-if="formType">
                        <div
                            class="mb-8"
                            v-if="computedRegistrationTimeSlots.length > 0"
                        >
                            <p
                                class="headline mb-3"
                                v-text="$t('page.first_interview.time_slot')"
                            />
                            <v-checkbox
                                v-model="data.hadFirstInterviewViaPhone"
                                :label="$t('first_interview.already_done')"
                            />
                            <v-select
                                v-model="data.registration_time_slot"
                                :items="computedRegistrationTimeSlots"
                                :label="$t('first_interview.registration_time_slot')"
                                :hint="$t('general.required')"
                                :disabled="data.hadFirstInterviewViaPhone === true"
                                persistent-hint
                            />
                        </div>

                        <p
                            class="headline mb-3"
                            v-text="$t('page.first_interview.personal_information')"
                        />

                        <StandardForm
                            v-if="formType === 'standard'"
                            :data="data"
                            :form-errors-prop="formErrors"
                            :insurance-funds="computedInsuranceFunds"
                            :cantons="computedCantons"
                            :registration-time-slots="computedRegistrationTimeSlots"
                            :rav-locations="computedRavLocations"
                            :amms="computedAmms"
                        />
                        <SimpleForm
                            v-if="formType === 'simple'"
                            :data="data"
                            :form-errors-prop="formErrors"
                            :insurance-funds="computedInsuranceFunds"
                            :registration-time-slots="computedRegistrationTimeSlots"
                            :cantons="computedCantons"
                            :rav-locations="computedRavLocations"
                            :amms="computedAmms"
                        />
                        <EasyForm
                            v-if="formType === 'easy'"
                            :data="data"
                            :form-errors-prop="formErrors"
                            :insurance-funds="computedInsuranceFunds"
                            :registration-time-slots="computedRegistrationTimeSlots"
                            :cantons="computedCantons"
                            :rav-locations="computedRavLocations"
                            :amms="computedAmms"
                        />

                        <div
                            class="file-upload"
                            v-if="formType !== 'easy'"
                        >
                            <p
                                class="headline mb-3"
                                v-text="$t('page.first_interview.documents')"
                            />
                            <p
                                class="text--secondary mb-3"
                            >
                                Für die Anmeldung brauchen wir von Ihnen einen aktuellen Lebenslauf im PDF Format.
                            </p>
                            <v-row>
                                <v-col cols="12">
                                    <v-file-input
                                        v-model="file"
                                        color="primary"
                                        :label="$t('page.first_interview.upload')"
                                        :placeholder="$t('page.first_interview.select')"
                                        :hint="formType === 'standard' ? $t('general.required') : ''"
                                        persistent-hint
                                        prepend-inner-icon="mdi-paperclip"
                                        prepend-icon=""
                                        outlined
                                        :disabled="formType === 'simple' && provideFileLater"
                                        :show-size="1000"
                                        :error-messages="formError(formErrors, 'upload')"
                                        @change="fileProvided"
                                    />
                                    <v-checkbox
                                        class="mt-n6"
                                        v-if="formType === 'simple'"
                                        v-model="provideFileLater"
                                        label="Lebenslauf wird nachgereicht"
                                        :disabled="!enableFileCheckbox"
                                    />
                                </v-col>
                            </v-row>
                        </div>
                        <v-row>
                            <v-col cols="12">
                                <VueRecaptcha
                                    sitekey="6LcBRO0dAAAAAPpRb0d8_EACxVB-rEN05JZxcPku"
                                    :load-recaptcha-script="true"
                                    @verify="disabled = false"
                                    @expired="disabled = true"
                                />
                            </v-col>
                        </v-row>
                        <p
                            v-if="hasError"
                            class="error--text mb-3"
                        >
                            Ein Fehler ist aufgetreten, bitte prüfen Sie Ihre Eingabe in den obigen Feldern.
                        </p>
                    </div>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn
                    v-if="formType"
                    color="secondary--dark"
                    class="px-4"
                    :dark="!disabled && (formType !== 'simple' || !enableFileCheckbox || provideFileLater)"
                    rounded
                    :disabled="disabled || (formType === 'simple' && enableFileCheckbox && !provideFileLater)"
                    :loading="loading"
                    depressed
                    type=""
                    @click="submit"
                >
                    {{ $t('page.first_interview.button') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>

import VueRecaptcha from 'vue-recaptcha';
import FormDataMixin from '@/mixins/form-data';
import DatesMixin from '@/mixins/dates';
import FirstInterviewMixin from '@/mixins/firstInterview';
import { SuccessSnackbar, StandBy } from '@/app-events.js';
import FirstInterviewProxy from '@/proxies/FirstInterviewProxy';
import InsuranceFundProxy from '@/proxies/InsuranceFundProxy';
import RavProxy from '@/proxies/RavProxy';
import AmmProxy from '@/proxies/AmmProxy';
import SimpleForm from '@/views/FirstInterview/Form/SimpleForm';
import EasyForm from '@/views/FirstInterview/Form/EasyForm';
import StandardForm from '@/views/FirstInterview/Form/StandardForm';
import { mapGetters } from 'vuex';

export default {
    name: 'FirstInterviewIndex',
    mixins: [
        FormDataMixin,
        FirstInterviewMixin,
        DatesMixin,
    ],
    components: {
        VueRecaptcha,
        SimpleForm,
        EasyForm,
        StandardForm,
    },
    data() {
        return {
            loading: false,
            disabled: true,
            fileOk: false,
            provideFileLater: false,
            enableFileCheckbox: true,
            hasError: false,
            thankyou: false,
            formType: null,
            file: undefined,
            courses: [],
            registrationTimeSlots: [],
            hadFirstInterviewViaPhone: false,
            data: {
                canton: {},
                insurance_fund: {},
                rav_location: {},
                course: {},
                /*rav: 0,*/
                amm: {},
                /*salutation: null,
firstname: null,
lastname: null,
address: null,
zip: null,
city: null,
phone: null,
mobile: null,
email: null,
birthdate: null,
civil_status: null,
nationality: null,
plain_ahv_number: null,
german_knowledge: null,
xing: null,
linkedin: null,
website: null,
daily_allowances: null,*/
            },
            insuranceFunds: [],
            ravLocations: [],
            amms: [],
        };
    },
    created() {
        this.$store.dispatch('canton/load');
        new InsuranceFundProxy().all().then(({ data }) => {
            this.insuranceFunds = data.data;
        }).catch(({ data }) => {
            this.displayError(data);
        });
        new RavProxy().all().then(({ data }) => {
            this.ravLocations = data.data;
        }).catch(({ data }) => {
            this.displayError(data);
        });
        new AmmProxy().all().then(({ data }) => {
            this.amms = data.data;
        }).catch(({ data }) => {
            this.displayError(data);
        });
        new FirstInterviewProxy().courses('de').then(({ data }) => {
            this.courses = data.data;
        }).catch(({ data }) => {
            this.displayError(data);
        });
    },
    computed: {
        ...mapGetters({
            cantons: 'canton/cantons',
        }),
        computedAmms() {
            const allAmms = [];
            for (let i = 0; i < this.amms.length; i++) {
                const amm = this.amms[ i ];
                allAmms.push({
                    value: amm.id,
                    text: `${amm.name} (${amm.city})`,
                });
            }
            allAmms.push({
                value: 'other',
                text: this.$t('participant.other'),
            });
            return allAmms;
        },
        computedCantons() {
            const allCantons = [];
            for (let i = 0; i < this.cantons.length; i++) {
                const canton = this.cantons[ i ];
                allCantons.push({
                    value: canton.code,
                    text: `${canton.code} ${canton.name}`,
                })
            }
            return allCantons;
        },
        computedRegistrationTimeSlots() {
            const allRegistrationTimeSlots = [];
            if (this.data.course.type === 'OL' || this.data.course.type === 'APPLICATION_AGENCY') {
                for (let i = 0; i < this.registrationTimeSlots.length; i++) {
                    const registrationTimeSlot = this.registrationTimeSlots[ i ];
                    const startParts = registrationTimeSlot.start.split(' ');
                    allRegistrationTimeSlots.push({
                        value: registrationTimeSlot.id,
                        text: `${this.formatDate(startParts[ 0 ])} - ${startParts[ 1 ]}`,
                    })
                }
                allRegistrationTimeSlots.push({
                    value: '',
                    text: 'Kein passendes Zeitfenster',
                });
            }

            return allRegistrationTimeSlots;
        },
        computedCourses() {
            const allCourses = [];
            for (let i = 0; i < this.courses.length; i++) {
                const course = this.courses[ i ];
                allCourses.push({
                    value: course,
                    text: `${course.name} (${this.formatDate(course.start_date)} - ${this.formatDate(course.end_date)})`,
                })
            }
            return allCourses;
        },
        computedInsuranceFunds() {
            const allInsuranceFunds = [];
            for (let i = 0; i < this.insuranceFunds.length; i++) {
                const insuranceFund = this.insuranceFunds[ i ];
                if (insuranceFund.name || insuranceFund.number) {
                    allInsuranceFunds.push({
                        value: insuranceFund.id,
                        text: `${insuranceFund.number} ${insuranceFund.name}`,
                    });
                }
            }
            allInsuranceFunds.push({
                value: 'other',
                text: this.$t('participant.other'),
            });
            return allInsuranceFunds;
        },
        computedRavLocations() {
            const allRavLocations = [];
            for (let i = 0; i < this.ravLocations.length; i++) {
                const ravLocation = this.ravLocations[ i ];
                allRavLocations.push({
                    value: ravLocation.id,
                    text: `${ravLocation.city} ${ravLocation.name}`,
                });
            }
            allRavLocations.push({
                value: 'other',
                text: this.$t('participant.other'),
            });
            return allRavLocations;
        },
    },
    methods: {
        fileProvided() {
            this.enableFileCheckbox = typeof this.file === 'undefined';
            this.provideFileLater = false;
        },
        submitButtonDisabled() {
            if (this.disabled) {

            }
            return false;
        },
        courseSelected() {
            this.registrationTimeSlots = [];
            if (typeof this.data.course === 'undefined') {
                this.formType = null;
                return;
            }
            // only "3-Wochen-Kurs" and "9-Wochen-Kurs" have standard form
            if (this.data.course.type === 'OL' || this.data.course.type === 'APPLICATION_AGENCY') {
                this.formType = 'standard';
                this.fetchTimeSlots();
            // } else if (this.data.course.type === 'EASY') {
            //     this.formType = 'easy';
            // } else {
            //     this.formType = 'simple';
            // }
            } else {
                this.formType = 'easy';
            }
        },
        fetchTimeSlots() {
            new FirstInterviewProxy().registrationTimeSlotsForCourse(this.data.course.id).then(({ data }) => {
                this.registrationTimeSlots = data.data;
            }).catch(({ data }) => {
                this.displayError(data);
            });
        },
        submit() {
            this.loading = true;
            this.formErrors = {};
            this.$eventBus.dispatch(new StandBy(true));
            let formData = new FormData();
            let formName = 'first_interview';
            if (this.formType === 'simple') {
                formName = 'simple_first_interview';
            } else if (this.formType === 'easy') {
                formName = 'easy_first_interview';
            }
            if (typeof this.file !== 'undefined') {
                formData.append(`${formName}[upload]`, this.file, this.file.name);
            } else if (this.formType !== 'easy') {
                formData.append(`${formName}[upload]`, null);
            }
            const preparedData = this.prepareFormData(this.data);
            for (var key in preparedData) {
                if (preparedData.hasOwnProperty(key)) {
                    if (typeof preparedData[ key ] !== 'undefined') {
                        formData.append(`${formName}[${key}]`, preparedData[ key ]);
                    }
                }
            }
            if (!formData.has(`${formName}[firstname]`)) {
                formData.append(`${formName}[firstname]`, '');
            }
            new FirstInterviewProxy().new(formData, this.formType).then(({ data }) => {
                this.hasError = false;
                this.loading = false;
                this.$eventBus.dispatch(new StandBy(false));
                //this.$router.push({ name: 'first_interview_thank_you' });
                this.data.course = {};
                this.data.registration_time_slot = '';
                this.formErrors = {};
                this.generalFormErrors = {};
                this.formType = null;
                this.thankyou = true;
                window.scrollTo(0, 0);
            }).catch(({ data }) => {
                this.loading = false;
                this.hasError = true;
                this.processErrors(data);
                this.$eventBus.dispatch(new StandBy(false));
            });
        },
    },
};
</script>
