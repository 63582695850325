<template>
    <v-app id="login">
        <v-main>
            <v-container
                fluid
                fill-height
            >
                <v-layout
                    align-center
                    justify-center
                >
                    <v-flex
                        xs12
                        sm12
                        md8
                        lg6
                        xl4
                    >
                        <router-view />
                    </v-flex>
                </v-layout>
            </v-container>
        </v-main>
    </v-app>
</template>

<script>
export default {
    name: 'LoginLayout',
};
</script>

<style lang="scss">
  #login {
    height: 50%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    z-index: 0;
    background-color: #ffb300 !important;
  }
  body {
      background-color: #eceff1 !important;
  }
</style>
