<template>
    <v-flex
        md12
        class="px-12 pb-12"
    >
        <PageHeader
            :breadcrumbs="breadcrumbs"
            :title="pageTitle"
            back
            back-to="registration_time_slot"
        />
        <v-row>
            <v-col cols="12">
                <v-alert
                    v-for="(error, errorKey) in generalFormErrors"
                    :key="errorKey"
                    type="error"
                    v-text="error"
                />
                <RegistrationTimeSlotForm
                    :data="data"
                    :raw-data="rawData"
                    :errors="formErrors"
                    :loading="loading"
                    :save-function="saveObject"
                    :discard-function="resetObject"
                />
            </v-col>
        </v-row>
    </v-flex>
</template>

<script>

import PageHeader from '@/components/common/PageHeader';
import RegistrationTimeSlotForm from '@/components/registrationTimeSlot/Form/RegistrationTimeSlotForm';
import RegistrationTimeSlotProxy from '@/proxies/RegistrationTimeSlotProxy';
import RegistrationTimeSlotMixin from '@/mixins/registrationTimeSlot';
import DatesMixin from '@/mixins/dates';
import { SuccessSnackbar, StandBy } from '@/app-events.js';

export default {
    name: 'RegistrationTimeSlotDetail',
    mixins: [
        RegistrationTimeSlotMixin,
        DatesMixin
    ],
    components: {
        PageHeader,
        RegistrationTimeSlotForm,
    },
    created() {
        this.fetchData();
    },
    data() {
        return {
            loading: false,
            data: {},
            rawData: {},
        };
    },
    computed: {
        pageTitle() {
            if (Object.keys(this.rawData).length === 0) {
                return '';
            }
            const startParts = this.rawData.start.split(' ');
            const date = this.formatDate(startParts[ 0 ]);
            return `${this.rawData.course} | ${date} - ${startParts[ 1 ]}`;
        },
        breadcrumbs() {
            return [
                {
                    text: this.$t('page.home.title'),
                    exact: true,
                    to: { name: 'home' },
                },
                {
                    text: this.$t('page.registration_time_slot.title'),
                    exact: true,
                    to: { name: 'registration_time_slot' },
                },
                {
                    text: this.pageTitle,
                    disabled: true,
                },
            ];
        },
    },
    methods: {
        fetchData() {
            this.loading = true;
            new RegistrationTimeSlotProxy().find(this.$route.params.id).then(({ data }) => {
                this.data = this.prepareData(data.data);
                this.rawData = JSON.parse(JSON.stringify(this.data));
                this.loading = false;
            }).catch(({ data }) => {
                this.displayError(data);
            });
            
        },
        saveObject() {
            this.$eventBus.dispatch(new StandBy(true));
            new RegistrationTimeSlotProxy().update(this.$route.params.id, this.prepareFormData(this.data)).then(({ data }) => {
                this.$eventBus.dispatch(new StandBy(false));
                this.rawData = JSON.parse(JSON.stringify(this.data));
                this.$eventBus.dispatch(new SuccessSnackbar(this.$t('registration_time_slot.actions.saved')));
                this.resetErrors();
            }).catch(({ data }) => {
                this.processErrors(data);
                this.$eventBus.dispatch(new StandBy(false));
            });
        },
    },
};
</script>
