<template>
    <v-flex
        md12
        class="px-12 pb-12"
    >
        <PageHeader
            :breadcrumbs="breadcrumbs"
            :title="$t('page.rav_consultant_new.title')"
            back
        />
        <v-row>
            <v-col cols="12">
                <RavConsultantForm
                    :rav-consultant="ravConsultant"
                    :raw-data="rawData"
                    :errors="formErrors"
                    :save-function="saveObject"
                    :discard-function="resetObject"
                />
            </v-col>
        </v-row>
    </v-flex>
</template>

<script>

import PageHeader from '@/components/common/PageHeader';
import RavConsultantForm from '@/components/ravConsultants/Form/RavConsultantForm';
import RavConsultantProxy from '@/proxies/RavConsultantProxy';
import { StandBy, SuccessSnackbar } from '@/app-events.js';

export default {
    name: 'RavNew',
    components: {
        PageHeader,
        RavConsultantForm,
    },
    data() {
        return {
            breadcrumbs: [
                {
                    text: this.$t('page.home.title'),
                    exact: true,
                    to: { name: 'home' },
                },
                {
                    text: this.$t('page.rav_consultant.title'),
                    exact: true,
                    to: { name: 'rav_consultant' },
                },
                {
                    text: this.$t('general.new'),
                    disabled: true,
                },
            ],
            ravConsultant: {
                rav_location: {},
            },
            rawData: {
                rav_location: {},
            },
        };
    },
    created() {
        if (typeof this.$route.params.ravId !== 'undefined') {
            this.ravConsultant = {
                rav_location: {
                    id: this.$route.params.ravId,
                },
            };
            this.rawData = {
                rav_location: {
                    id: this.$route.params.ravId,
                },
            };
        }
    },
    methods: {
        resetObject() {
            this.ravConsultant = JSON.parse(JSON.stringify(this.rawData));
            this.resetErrors();
        },
        saveObject() {
            if (JSON.stringify(this.ravConsultant) === JSON.stringify(this.rawData)) {
                return;
            }
            this.$eventBus.dispatch(new StandBy(true));
            const formData = {
                rav_consultant: {
                    title: this.ravConsultant.title,
                    firstname: this.ravConsultant.firstname,
                    lastname: this.ravConsultant.lastname,
                    phone: this.ravConsultant.phone,
                    fax: this.ravConsultant.fax,
                    email: this.ravConsultant.email,
                    memo: this.ravConsultant.memo,
                    active: this.ravConsultant.active,
                    ravLocation: this.ravConsultant.rav_location.id,
                },
            };
            new RavConsultantProxy().new(formData).then(({ data }) => {
                this.$eventBus.dispatch(new StandBy(false));
                this.$eventBus.dispatch(new SuccessSnackbar(this.$t('rav_consultant.actions.created')));
                this.$router.push({ name: 'rav_consultant_detail', params: { id: data.data.id } });
            }).catch(({ data }) => {
                this.processErrors(data);
                this.$eventBus.dispatch(new StandBy(false));
            });
        }
    },
};
</script>
