<template>
    <v-card flat>
        <v-card-text>
            <v-row>
                <v-col cols="12">
                    <v-select
                        v-model="ravConsultant.title"
                        :items="availableTitles"
                        :label="$t('rav_consultant.title')"
                        :error-messages="formError(errors, 'title')"
                        :hint="$t('general.required')"
                        persistent-hint
                    />
                </v-col>
                <v-col cols="12">
                    <v-text-field
                        type="text"
                        v-model="ravConsultant.firstname"
                        :label="$t('rav_consultant.firstname')"
                        :error-messages="formError(errors, 'firstname')"
                        :hint="$t('general.required')"
                        persistent-hint
                    />
                </v-col>
                <v-col cols="12">
                    <v-text-field
                        type="text"
                        v-model="ravConsultant.lastname"
                        :label="$t('rav_consultant.lastname')"
                        :error-messages="formError(errors, 'lastname')"
                        :hint="$t('general.required')"
                        persistent-hint
                    />
                </v-col>
                <v-col cols="12">
                    <v-text-field
                        type="text"
                        v-model="ravConsultant.phone"
                        :label="$t('rav_consultant.phone')"
                        :error-messages="formError(errors, 'phone')"
                    />
                </v-col>
                <v-col cols="12">
                    <v-text-field
                        type="text"
                        v-model="ravConsultant.fax"
                        :label="$t('rav_consultant.fax')"
                        :error-messages="formError(errors, 'fax')"
                    />
                </v-col>
                <v-col cols="12">
                    <v-text-field
                        type="text"
                        v-model="ravConsultant.email"
                        :label="$t('rav_consultant.email')"
                        :error-messages="formError(errors, 'email')"
                    />
                </v-col>
                <v-col cols="12">
                    <v-textarea
                        type="text"
                        v-model="ravConsultant.memo"
                        :label="$t('rav_consultant.memo')"
                        :error-messages="formError(errors, 'memo')"
                    />
                </v-col>
                <v-col cols="12">
                    <v-select
                        v-model="ravConsultant.rav_location.id"
                        :items="ravLocations"
                        :label="$t('rav.name')"
                        :error-messages="formError(errors, 'ravLocation')"
                        :hint="$t('general.required')"
                        persistent-hint
                    />
                </v-col>
                <v-col cols="12">
                    <v-checkbox
                        v-model="ravConsultant.active"
                        :label="$t('rav_consultant.active')"
                    />
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>

import RavProxy from '@/proxies/RavProxy';

export default {
    name: 'RavConsultantInformation',
    props: {
        ravConsultant: {
            type: Object,
            default: () => ({
                name: '',
                roles: [],
            }),
        },
        errors: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            ravLocations: [],
            availableTitles: [
                {
                    value: 'man',
                    text: 'Herr',
                },
                {
                    value: 'woman',
                    text: 'Frau',
                },
            ],
        }
    },
    created() {
        new RavProxy().all().then(({ data }) => {
            for (let i = 0; i < data.data.length; i++) {
                const ravLocation = data.data[ i ];
                this.ravLocations.push({
                    value: ravLocation.id,
                    text: ravLocation.name,
                })
            }
            this.loading = false;
        }).catch(({ data }) => {
            this.displayError(data);
        });
    },
};
</script>
