import DefaultLayout from '@/layouts/Default';
import CourseCalendar from '@/views/Course/Detail';
import CalendarOverview from '@/views/CalendarOverview/Index';

export default {
    path: '/course-calendar',
    component: DefaultLayout,
    children: [
        {
            path: '',
            name: 'course_calendar',
            component: CourseCalendar,
            meta: {
                auth: true,
            },
        },
        {
            path: 'overview',
            name: 'calendar_overview',
            component: CalendarOverview,
            meta: {
                auth: true,
            },
        },
    ],
};
