<template>
    <v-flex
        md12
        class="px-12 pb-12"
    >
        <PageHeader
            :breadcrumbs="breadcrumbs"
            :title="$t('page.registrations.title')"
            search
            :search-label="$t('page.registrations.search')"
            @search-input="searchInputListener"
        />
        <v-row>
            <v-col cols="12">
                <TabMenu
                    v-model="menu"
                    :menus="menus"
                />
            </v-col>
            <ActionBar
                fetch
                :fetch-function="fetchData"
            />
            <v-col cols="12">
                <v-progress-linear
                    v-if="loading"
                    indeterminate
                    color="primary"
                />
                <v-skeleton-loader
                    v-if="loading"
                    class="mx-auto"
                    type="table-tbody"
                />
                <ParticipantsList
                    v-if="!loading"
                    :objects="objects"
                    :search="textSearch"
                />
            </v-col>
        </v-row>
    </v-flex>
</template>

<script>

import ActionBar from '@/components/common/ActionBar';
import PageHeader from '@/components/common/PageHeader';
import ParticipantsList from '@/components/registrations/ParticipantsList';
import ParticipantProxy from '@/proxies/ParticipantProxy';
import TabMenu from '@/components/common/TabMenu';
import FileDownloadMixin from '@/mixins/file-download';
import { SuccessSnackbar, ObjectDeleted, StandBy } from '@/app-events.js';

export default {
    name: 'ParticipantIndex',
    mixins: [ FileDownloadMixin ],
    components: {
        ActionBar,
        PageHeader,
        ParticipantsList,
        TabMenu,
    },
    data() {
        return {
            textSearch: '',
            objects: [],
            selectedRows: [],
            loading: true,
            menu: 'open',
        };;
    },
    computed: {
        breadcrumbs() {
            return [
                {
                    text: this.$t('page.home.title'),
                    exact: true,
                    to: { name: 'home' },
                },
                {
                    text: this.$t('page.registrations.title'),
                    disabled: true,
                },
            ];
        },
        menus() {
            return [
                {
                    id: 'open',
                    label: this.$t('page.registrations.menu.open'),
                    callback: () => this.goToMenu('registrations')
                },
                {
                    id: 'closed',
                    label: this.$t('page.registrations.menu.closed'),
                    callback: () => this.goToMenu('registrations_closed')
                },
            ];
        },
    },
    created () {
        if (this.$route.name === 'registrations_closed') {
            this.menu = 'closed'
        } else {
            this.menu = 'open';
        }
        this.$eventBus.listen(ObjectDeleted, (event) => {
            this.$eventBus.dispatch(new SuccessSnackbar(this.$t('participant.actions.deleted')));
            for (let i = 0; i < this.objects.length; i++) {
                const participant = this.objects[ i ];
                if (participant.id === event.object.id) {
                    this.objects.splice(i, 1);
                    break;
                }
            }
        });
        this.initialize();
    },
    methods: {
        initialize() {
            this.fetchData();
        },
        fetchData() {
            this.loading = true;
            this.selectedRows = [];
            let status = 'RE';
            if (this.$route.name === 'registrations_closed') {
                status = 'RE-registrationClosed';
            }
            new ParticipantProxy().all({
                status,
            }).then(({ data }) => {
                this.objects = data.data;
                this.loading = false;
            }).catch(({ data }) => {
                this.displayError(data);
            });
        },
        searchInputListener(value) {
            this.textSearch = value;
        },
        goToMenu(route) {
            this.$router.push({ name: route });
            this.initialize();
        },
    },
};
</script>
