import DefaultLayout from '@/layouts/Default';
import Index from '@/views/Admin/Statistics/Index';

export default {
    path: '/admin/statistics',
    component: DefaultLayout,
    children: [
        {
            path: '',
            name: 'admin_statistics',
            component: Index,
            meta: {
                auth: {
                    roles: 'ROLE_ADMIN',
                },
            },
        },
    ],
};
