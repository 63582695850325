<template>
    <v-row no-gutters>
        <v-col
            lg="6"
            offset-lg="3"
            md="12"
        >
            <v-progress-linear
                v-if="loading"
                indeterminate
                color="primary"
            />

            <v-row>
                <v-col cols="12">
                    <v-skeleton-loader
                        v-if="loading"
                        class="mx-auto"
                        type="card"
                    />
                    <div
                        v-if="!loading"
                        class="headline mb-3"
                        v-text="$t('page.user_new.connection_information')"
                    />
                    <UserInformation
                        v-if="!loading"
                        :user="user"
                        :errors="errors"
                    />
                </v-col>
                <v-col
                    v-if="isCoach()"
                    cols="12"
                >
                    <v-skeleton-loader
                        v-if="loading"
                        class="mx-auto"
                        type="card"
                    />
                    <div
                        v-if="!loading"
                        class="headline mb-3"
                        v-text="$t('page.user_new.coach_information')"
                    />
                    <CoachInformation
                        v-if="!loading"
                        :user="user"
                        :errors="errors"
                    />
                </v-col>
            </v-row>
            <UnsavedForm
                :unsaved-form="unsavedForm"
                :errors="errors"
                :on-save="saveFunction"
                :on-cancel="cancelObjectChanges"
            />
        </v-col>
    </v-row>
</template>

<script>
import UnsavedForm from '@/components/common/UnsavedForm';
import UserInformation from '@/components/users/Form/UserInformation';
import CoachInformation from '@/components/users/Form/CoachInformation';
//import ParticipantInformation from '@/components/users/Form/ParticipantInformation';
import { DefaultSnackbar } from '@/app-events.js';

export default {
    name: 'UserForm',
    components: {
        UnsavedForm,
        UserInformation,
        CoachInformation,
    },
    props: {
        rawData: {
            type: Object,
            default: () => ({}),
        },
        user: {
            type: Object,
            default: () => ({}),
        },
        errors: {
            type: Object,
            default: () => ({}),
        },
        loading: {
            type: Boolean,
            default: false,
        },
        saveFunction: {
            type: Function,
            default: () => ({}),
        },
        discardFunction: {
            type: Function,
            default: () => ({}),
        },
    },
    data() {
        return {
            unsavedForm: false,
        }
    },
    methods: {
        cancelObjectChanges() {
            this.$eventBus.dispatch(new DefaultSnackbar(this.$t('general.changes_discarded')));
            this.discardFunction();
        },
        isCoach() {
            if (typeof this.user.roles === 'undefined') {
                return false;
            }

            return this.user.roles.includes('ROLE_COACH') || this.user.roles.includes('ROLE_ADMIN');
        }
    },
    watch: {
        user: {
            handler(_, oldValue) {
                if (JSON.stringify(this.user) === JSON.stringify(this.rawData)) {
                    this.unsavedForm = false;
                } else {
                    this.unsavedForm = true;
                }
            },
            deep: true
        },
        rawData: {
            handler(_, oldValue) {
                if (JSON.stringify(this.user) === JSON.stringify(this.rawData)) {
                    this.unsavedForm = false;
                } else {
                    this.unsavedForm = true;
                }
            },
            deep: true
        },
    },
};
</script>
