import {
    SET_PREFERENCES, INITIALIZE,
} from './mutation-types';

const set = async ({ commit }, payload) => {
    commit(SET_PREFERENCES, {
        field: payload.field,
        value: payload.value,
    });
};

const initialize = async ({ commit }, payload) => {
    try {
        commit(INITIALIZE);
    } catch (e) {
        throw new Error(e);
    }
};

export default {
    set,
    initialize,
};
