import { SuccessSnackbar } from '@/app-events.js';

export default {
    methods: {
        copyText (str) {
            this.$copyText(str).then(() => {
                this.$eventBus.dispatch(new SuccessSnackbar(this.$t('general.copied')));
            }, e => {
                console.log(e);
            });
        }
    },
};
