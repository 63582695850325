<template>
    <div :class="`${divClass} d-print-none`">
        <span
            v-for="(menu, menuKey) in activeMenus"
            :key="menuKey"
        >
            <v-btn
                v-if="!menu.menus"
                text
                class="mx-2 text-none"
                :color="value !== menu.id ? 'grey' : ''"
                @click="trigger(menu)"
            >
                {{ menu.label }}
            </v-btn>
            <v-menu
                v-else
                offset-y
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        text
                        class="mx-2 text-none"
                        :color="value.split('.')[0] !== menu.id ? 'grey' : ''"
                        v-bind="attrs"
                        v-on="on"
                    >
                        {{ menu.label }}
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item-group color="secondary">
                        <v-list-item
                            :class="value === submenu.id ? 'v-item--active v-list-item--active' : ''"
                            v-for="(submenu, submenuKey) in menu.menus"
                            :key="submenuKey"
                            @click="trigger(submenu)"
                        >
                            <v-list-item-content>
                                <v-list-item-title v-text="submenu.label" />
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-menu>
        </span>
        <v-divider />
    </div>
</template>

<script>

export default {
    name: 'TabMenu',
    props: {
        value: {
            type: String,
            default: '',
        },
        menus: {
            type: Array,
            default: () => ([]),
        },
        divClass: {
            type: String,
            default: 'mt-6 mb-6',
        },
    },
    computed: {
        activeMenus() {
            const menus = [];
            for (let i = 0; i < this.menus.length; i++) {
                const menu = this.menus[ i ];
                if (typeof menu.hidden !== 'undefined' && menu.hidden) {
                    continue;
                }
                menus.push(menu);
            }

            return menus;
        },
    },
    methods: {
        trigger(val) {
            if (val.hasOwnProperty('callback') && val.callback !== false) {
                val.callback();
            }
            if (val.hasOwnProperty('to') && val.to !== '') {
                this.$router.push(val.to);
            }
            this.$emit('input', val.id);
        },
    },
};
</script>
