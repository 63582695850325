import Vue from 'vue';
import Router from 'vue-router';
import routes from '../routes';

Vue.use(Router);

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach((to, from, next) => {
    // This goes through the matched routes from last to first,
    // finding the closest route with a title.
    // eg. if we have /some/deep/nested/route and /some, /deep,
    // and /nested have titles, nested's will be chosen.
    const nearestWithTitle = to.matched.slice().reverse().find((r) => r.meta && r.meta.title);

    // If a route with a title was found, set the document (page) title to that value.
    if (nearestWithTitle) {
        document.title = `${nearestWithTitle.meta.title} | ba-database`;
    }

    next();
});

Vue.router = router;

export default router;
