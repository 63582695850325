import Proxy from './Proxy';

class LearnGoalControlProxy extends Proxy {
    constructor(parameters = {}) {
        super('/learn-goal-control', parameters);
    }

    all({ participantId = null } = {}) {
        if (participantId) {
            return this.submit('get', `/?participant=${participantId}`);    
        }
        return this.submit('get', '/');
    }

    sendToRav(id, formData) {
        return this.submit('post', `/${id}/send-rav`, formData);
    }

    sendToParticipant(id, formData) {
        return this.submit('post', `/${id}/send-participant`, formData);
    }

    emailToRav(id, formData) {
        return this.submit('get', `/${id}/email-rav`, formData);
    }

    emailToParticipant(id, formData) {
        return this.submit('get', `/${id}/email-participant`, formData);
    }
}

export default LearnGoalControlProxy;
