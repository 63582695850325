export class SaveParticipant {
    constructor(count) {
        this.count = count;
    }
    static get id() { return 'SaveParticipant'; }
}
export class DiscardParticipant {
    constructor() {}
    static get id() { return 'DiscardParticipant'; }
}
export class ObjectDeleted {
    constructor({ object = null, options = {} }) {
        this.object = object;
        this.options = options;
    }
    static get id() { return 'ObjectDeleted'; }
}
export class RefreshList {
    constructor() {}
    static get id() { return 'RefreshList'; }
}
export class Snackbar {
    constructor(snackbar) {
        this.snackbar = snackbar;
    }
    static get id() { return 'Snackbar'; }
}
export class BulkEditPresence {
    constructor(presences) {
        this.presences = presences;
    }
    static get id() { return 'BulkEditPresence'; }
}
export class SuccessSnackbar {
    constructor(text) {
        this.text = text;
    }
    static get id() { return 'SuccessSnackbar'; }
}
export class DefaultSnackbar {
    constructor(text) {
        this.text = text;
    }
    static get id() { return 'DefaultSnackbar'; }
}
export class ErrorSnackbar {
    constructor(text) {
        this.text = text;
    }
    static get id() { return 'ErrorSnackbar'; }
}
export class StandBy {
    constructor(standby) {
        this.standby = standby;
    }
    static get id() { return 'StandBy'; }
}