import LoginLayout from '@/layouts/Login';
import Index from '@/views/FirstInterviewEn/Index';

export default {
    path: '/registration',
    component: LoginLayout,
    children: [
        {
            path: '',
            name: 'first_interview',
            component: Index,
            meta: {
                auth: false,
            },
        },
    ],
};
