<template>
    <v-flex
        md12
        class="px-12 pb-12"
    >
        <PageHeader
            :breadcrumbs="breadcrumbs"
            :title="pageTitle"
            back
            back-to="rav_consultant"
        />
        <v-row>
            <v-col cols="12">
                <TabMenu
                    :menus="menus"
                    v-model="activeMenu"
                />
            </v-col>
            <v-col cols="12">
                <v-alert
                    v-for="(error, errorKey) in generalFormErrors"
                    :key="errorKey"
                    type="error"
                    v-text="error"
                />
                <RavConsultantForm
                    v-if="activeMenu == 'profile'"
                    :rav-consultant="ravConsultant"
                    :raw-data="rawData"
                    :errors="formErrors"
                    :loading="loading"
                    :save-function="saveObject"
                    :discard-function="resetObject"
                />
                <Participants
                    v-if="activeMenu == 'participants'"
                    :rav-consultant="ravConsultant"
                />
            </v-col>
        </v-row>
    </v-flex>
</template>

<script>

import PageHeader from '@/components/common/PageHeader';
import RavConsultantForm from '@/components/ravConsultants/Form/RavConsultantForm';
import Participants from '@/components/ravConsultants/Participants';
import TabMenu from '@/components/common/TabMenu';
import RavConsultantProxy from '@/proxies/RavConsultantProxy';
import { SuccessSnackbar, StandBy } from '@/app-events.js';

export default {
    name: 'RavConsultantDetail',
    components: {
        PageHeader,
        RavConsultantForm,
        Participants,
        TabMenu,
    },
    created() {
        this.fetchData();
    },
    data() {
        return {
            activeMenu: 'profile',
            menus: [
                {
                    id: 'profile',
                    label: 'Profil',
                },
                {
                    id: 'participants',
                    label: this.$t('rav_consultant.participants'),
                },
            ],
            loading: false,
            ravConsultant: {
                firstname: '',
                lastname: '',
            },
            rawData: {
                firstname: '',
                lastname: '',
            },
        };
    },
    computed: {
        pageTitle() {
            return `${this.rawData.firstname} ${this.rawData.lastname}`;
        },
        breadcrumbs() {
            return [
                {
                    text: this.$t('page.home.title'),
                    exact: true,
                    to: { name: 'home' },
                },
                {
                    text: this.$t('page.rav_consultant.title'),
                    exact: true,
                    to: { name: 'rav_consultant' },
                },
                {
                    text: this.pageTitle,
                    disabled: true,
                },
            ];
        },
    },
    methods: {
        fetchData() {
            this.loading = true;
            new RavConsultantProxy().find(this.$route.params.id).then(({ data }) => {
                let ravConsultant = data.data;
                if (ravConsultant.rav_location === null) {
                    ravConsultant.rav_location = {};
                }
                this.ravConsultant = ravConsultant;
                this.rawData = JSON.parse(JSON.stringify(this.ravConsultant));
                this.loading = false;
            }).catch(({ data }) => {
                this.displayError(data);
            });
            
        },
        resetObject() {
            this.ravConsultant = JSON.parse(JSON.stringify(this.rawData));
            this.resetErrors();
        },
        saveObject() {
            this.$eventBus.dispatch(new StandBy(true));
            const formData = {
                rav_consultant: {
                    title: this.ravConsultant.title,
                    firstname: this.ravConsultant.firstname,
                    lastname: this.ravConsultant.lastname,
                    phone: this.ravConsultant.phone,
                    fax: this.ravConsultant.fax,
                    email: this.ravConsultant.email,
                    memo: this.ravConsultant.memo,
                    active: this.ravConsultant.active,
                    ravLocation: this.ravConsultant.rav_location.id,
                },
            };
            new RavConsultantProxy().update(this.$route.params.id, formData).then(({ data }) => {
                this.$eventBus.dispatch(new StandBy(false));
                this.rawData = JSON.parse(JSON.stringify(this.ravConsultant));
                this.$eventBus.dispatch(new SuccessSnackbar(this.$t('rav.actions.saved')));
                this.resetErrors();
            }).catch(({ data }) => {
                this.processErrors(data);
                this.$eventBus.dispatch(new StandBy(false));
            });
        },
    },
};
</script>
