<template>
    <div>
        <v-card flat>
            <v-data-table
                class="pa-3"
                :headers="headers"
                :items="users"
                :search="search"
                :show-select="selection"
                v-model="selectedRows"
                :footer-props="defaultFooterProps"
                :items-per-page="getPrefenceValue('admin_user.itemsPerPage', 25)"
                @update:items-per-page="val => setPrefenceValue('admin_user.itemsPerPage', val)"
                :page="currentPage"
                @update:page="page => currentPage = page"
            >
                <template v-slot:item.email="{ item }">
                    <v-btn
                        link
                        text
                        :to="{ name: 'admin_user_detail', params: { id: item.id }}"
                    >
                        {{ item.email }}
                    </v-btn>
                </template>
                <template v-slot:item.roles="{ item }">
                    {{ getUserRole(item) }}
                </template>
                <template v-slot:item.enabled="{ item }">
                    <v-tooltip
                        v-if="item.enabled"
                        right
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                color="green"
                                v-bind="attrs"
                                v-on="on"
                            >
                                mdi-account-check
                            </v-icon>
                        </template>
                        <span v-text="$t('user.enabled')" />
                    </v-tooltip>
                    <v-tooltip
                        v-else
                        right
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                color="red"
                                v-bind="attrs"
                                v-on="on"
                            >
                                mdi-account-cancel
                            </v-icon>
                        </template>
                        <span v-text="$t('user.disabled')" />
                    </v-tooltip>
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                v-bind="attrs"
                                v-on="on"
                            >
                                mdi-dots-horizontal
                            </v-icon>
                        </template>
                        <v-list>
                            <v-list-item
                                :disabled="$auth.user().id === item.id"
                                @click="initiateDeletion(item)"
                            >
                                <v-list-item-action>
                                    <v-icon>mdi-delete</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title v-text="$t('general.delete')" />
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item
                                v-if="item.enabled"
                                :disabled="$auth.user().id === item.id"
                                @click="deactivateUser(item)"
                            >
                                <v-list-item-action>
                                    <v-icon>mdi-account-cancel</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title v-text="$t('user.actions.deactivate')" />
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item
                                v-if="!item.enabled"
                                :disabled="$auth.user().id === item.id"
                                @click="activateUser(item)"
                            >
                                <v-list-item-action>
                                    <v-icon>mdi-account-check</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title v-text="$t('user.actions.activate')" />
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </template>
            </v-data-table>
        </v-card>
        <ConfirmationDialog
            :confirm="deleteObject"
            :cancel="() => (deleteDialog = false)"
            :loading="deleting"
            :dialog="deleteDialog"
        />
    </div>
</template>

<script>

import ConfirmationDialog from '@/components/common/ConfirmationDialog';
import UserProxy from '@/proxies/UserProxy';
import { ObjectDeleted, StandBy, SuccessSnackbar } from '@/app-events.js';

export default {
    name: 'UsersList',
    components: {
        ConfirmationDialog,
    },
    props: {
        search: {
            type: String,
            default: '',
        },
        selection: {
            type: Boolean,
            default: false,
        },
        users: {
            type: Array,
            default: () => ([]),
        },
    },
    data() {
        return {
            deleteDialog: false,
            deleting: false,
            objectToDelete: null,
            selectedRows: [],
            headers: [
                { 
                    text: this.$t('user.email'),
                    value: 'email',
                    align: 'start',
                },
                {
                    text: this.$t('user.firstname'),
                    value: 'person.firstname',
                },
                {
                    text: this.$t('user.lastname'),
                    value: 'person.lastname',
                },
                {
                    text: this.$t('user.roles'),
                    value: 'roles',
                },
                {
                    text: this.$t('user.status'),
                    value: 'enabled',
                },
                { 
                    text: 'Aktionen',
                    value: 'actions',
                    sortable: false ,
                    align: 'center',
                },
            ],
        }
    },
    methods: {
        getUserRole(user) {
            const roles = [];
            if (user.roles.length === 0) {
                roles.push(this.$t('user.role.participant'));
            }
            if (user.roles.includes('ROLE_ADMIN')) {
                roles.push(this.$t('user.role.admin'));
            }
            if (user.roles.includes('ROLE_COACH')) {
                roles.push(this.$t('user.role.coach'));
            }
            return roles.join(', ');
        },
        initiateDeletion(item) {
            this.objectToDelete = item;
            this.deleteDialog = true;
        },
        deleteObject() {
            this.deleting = true;
            new UserProxy().delete(this.objectToDelete.id).then(({ data }) => {
                this.$eventBus.dispatch(new ObjectDeleted({
                    object: this.objectToDelete,
                }));
            }).catch(({ data }) => {
                this.displayError(data);
            }).finally(() => {
                this.deleteDialog = false;
                this.objectToDelete = null;
                this.deleting = false;
            });
        },
        deactivateUser(user) {
            this.$eventBus.dispatch(new StandBy(true));
            new UserProxy().deactivate(user.id).then(({ data }) => {
                user.enabled = false;
                this.$eventBus.dispatch(new SuccessSnackbar(this.$t('user.disabled')));
            }).catch(({ data }) => {
                this.genericErrorSnackbar();
            }).finally(() => {
                this.$eventBus.dispatch(new StandBy(false));
            });
        },
        activateUser(user) {
            this.$eventBus.dispatch(new StandBy(true));
            new UserProxy().activate(user.id).then(({ data }) => {
                user.enabled = true;
                this.$eventBus.dispatch(new SuccessSnackbar(this.$t('user.enabled')));
            }).catch(({ data }) => {
                this.genericErrorSnackbar();
            }).finally(() => {
                this.$eventBus.dispatch(new StandBy(false));
            });
        }
    },
    watch: {
        selectedRows(val) {
            this.$emit('selection-changed', val);
        }
    },
};
</script>
