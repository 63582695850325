import DefaultLayout from '@/layouts/Default';
import PresenceList from '@/views/PresenceList';

export default {
    path: '/praesenz',
    component: DefaultLayout,
    children: [
        {
            path: '',
            name: 'presence_list',
            component: PresenceList,
            meta: {
                auth: true,
            },
        },
    ],
};
