<template>
    <v-row>
        <v-col cols="12">
            <v-list class="pa-5">
                <v-row>
                    <v-col cols="12">
                        <v-text-field
                            type="text"
                            readonly
                            v-model="data.subject"
                            :label="$t('mailsent.subject')"
                            :error-messages="formError(errors, 'subject')"
                        />
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                            type="text"
                            readonly
                            v-model="data.email"
                            :label="$t('mailsent.email')"
                            :error-messages="formError(errors, 'email')"
                        />
                    </v-col>
                    <v-col
                        cols="12"
                    >
                        <div
                            v-html="data.body"
                            class="pa-4 grey lighten-4 rounded-sm"
                        />
                    </v-col>
                </v-row>
            </v-list>
        </v-col>
    </v-row>
</template>
<script>
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
    name: 'OfficeInformation',
    props: {
        data: {
            type: Object,
            default: () => ({
                name: '',
            }),
        },
        errors: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            value: 'Vuetify is a Vue UI Library with beautifully handcrafted Material Components. No design skills required — everything you need to create amazing applications is at your fingertips.',
            insertText: '%insertChar%',
            editor: ClassicEditor,
            editorConfig: {
                toolbar: {
                    items: [
                        'heading',
                        '|',
                        'bold',
                        'italic',
                        'link',
                        'bulletedList',
                        'numberedList',
                        'indent',
                        'outdent',
                        'undo',
                        'redo',
                    ],
                },
            },
        }
    },
    methods: {
        copyCustomField (textToBeCopied) {
            const elem = document.createElement('textarea');
            elem.value = textToBeCopied;
            document.body.appendChild(elem);
            elem.select();
            document.execCommand('copy');
            document.body.removeChild(elem);
        },
    },
};
</script>
