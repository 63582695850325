export default {
    methods: {
        browserDownload(data, filename) {
            let fileURL = window.URL.createObjectURL(new Blob([ data ]));
            let fileLink = document.createElement('a');
            fileLink.href = fileURL;
            fileLink.setAttribute('download', filename);
            document.body.appendChild(fileLink);
            fileLink.click();
            setTimeout(function() {
                document.body.removeChild(fileLink);
            }, 200);
        },
        openPdf(data) {
            let fileURL = window.URL.createObjectURL(new Blob([ data ], { type: "application/pdf" }));
            let fileLink = document.createElement('a');
            fileLink.href = fileURL;
            fileLink.target = '_blank';
            document.body.appendChild(fileLink);
            fileLink.click();
            setTimeout(function() {
                document.body.removeChild(fileLink);
            }, 200);
        },
    },
};
