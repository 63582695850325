import DefaultLayout from '@/layouts/Default';
import LearnGoalControlIndex from '@/views/LearnGoalControl/Index';
import LearnGoalControlDetail from '@/views/LearnGoalControl/Detail';
import LearnGoalControlNew from '@/views/LearnGoalControl/New';

export default {
    path: '/lzk',
    component: DefaultLayout,
    children: [
        {
            path: '',
            name: 'learn_goal_control',
            component: LearnGoalControlIndex,
            meta: {
                auth: true,
            },
        },
        {
            path: 'new',
            name: 'learn_goal_control_new',
            component: LearnGoalControlNew,
            meta: {
                auth: true,
            },
        },
        {
            path: ':id',
            name: 'learn_goal_control_detail',
            component: LearnGoalControlDetail,
            meta: {
                auth: true,
            },
        },
    ],
};
