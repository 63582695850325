<template>
    <v-card flat>
        <v-card-text>
            <v-row>
                <v-col cols="12">
                    <v-radio-group
                        v-model="data.rav"
                        row
                    >
                        <v-radio
                            :label="$t('participant.rav')"
                            :value="1"
                        />
                        <v-radio
                            :label="$t('participant.not_rav')"
                            :value="0"
                        />
                        <v-radio
                            :label="$t('participant.rav_amm')"
                            :value="2"
                        />
                    </v-radio-group>
                </v-col>
                <v-col cols="12">
                    <v-text-field
                        type="text"
                        v-model="data.person_number"
                        :label="$t('participant.person_number')"
                        :error-messages="formError(errors, 'personNumber')"
                    />
                </v-col>
                <v-col
                    v-if="data.rav === 1"
                    cols="12"
                    md="6"
                >
                    <v-autocomplete
                        v-model="data.rav_location.id"
                        :items="computedRavLocations"
                        :label="$t('participant.rav_location')"
                        :error-messages="formError(errors, 'ravLocation')"
                        clearable
                        @click:clear="$nextTick(() => data.rav_location = {})"
                        @change="ravLocationChange"
                    />
                </v-col>
                <v-col
                    v-if="data.rav === 1"
                    cols="12"
                    md="6"
                >
                    <v-autocomplete
                        v-if="data.rav_location.id !== 'other'"
                        v-model="data.rav_consultant.id"
                        :items="computedRavConsultants"
                        :label="$t('participant.rav_consultant')"
                        :error-messages="formError(errors, 'ravConsultant')"
                        clearable
                        @click:clear="$nextTick(() => data.rav_consultant = {})"
                        :loading="ravConsultantsLoading"
                    />
                    <v-text-field
                        v-if="data.rav_location.id === 'other' || data.rav_consultant_email_freetext"
                        type="text"
                        v-model="data.rav_consultant_email_freetext"
                        :label="$t('participant.rav_consultant_email_freetext')"
                        :error-messages="formError(errors, 'ravConsultantEmailFreetext')"
                    />
                </v-col>
                <v-col
                    cols="12"
                    md="12"
                    v-if="data.rav === 1 && data.rav_location.id === 'other'"
                >
                    <v-text-field
                        type="text"
                        v-model="data.rav_location_freetext"
                        :label="$t('participant.rav_location_freetext')"
                        :error-messages="formError(errors, 'ravLocationFreetext')"
                    />
                </v-col>
                <v-col
                    v-if="data.rav === 1"
                    cols="12"
                >
                    <v-autocomplete
                        v-model="data.insurance_fund.id"
                        :items="computedInsuranceFunds"
                        :label="$t('participant.insurance_fund')"
                        :error-messages="formError(errors, 'insuranceFund')"
                        clearable
                        @click:clear="$nextTick(() => data.insurance_fund = {})"
                    />
                </v-col>
                <v-expand-transition>
                    <v-col
                        cols="12"
                        v-if="data.rav === 1 && data.insurance_fund.id === 'other'"
                    >
                        <v-text-field
                            type="text"
                            v-model="data.insurance_fund_freetext"
                            :label="$t('participant.insurance_fund_freetext')"
                            :error-messages="formError(errors, 'insuranceFundFreetext')"
                        />
                    </v-col>
                </v-expand-transition>
                <v-col
                    v-if="data.rav === 1"
                    cols="12"
                >
                    <v-text-field
                        type="text"
                        v-model="data.daily_allowances"
                        :label="$t('participant.daily_allowances')"
                        :error-messages="formError(errors, 'dailyAllowances')"
                        :hint="isSimpleForm(data) ? '' : $t('general.required')"
                        persistent-hint
                    />
                </v-col>
                <v-col
                    v-if="data.rav === 1"
                    cols="12"
                >
                    <v-text-field
                        type="number"
                        v-model="data.daily_allowances_value"
                        :label="$t('participant.daily_allowances_value')"
                        :error-messages="formError(errors, 'dailyAllowancesValue')"
                        :hint="isSimpleForm(data) ? '' : $t('general.required')"
                        persistent-hint
                    />
                </v-col>
                <v-col
                    v-if="data.rav === 2"
                    cols="12"
                >
                    <v-autocomplete
                        v-model="data.amm.id"
                        :items="computedAmms"
                        :label="$t('participant.amm')"
                        :error-messages="formError(errors, 'amm')"
                        clearable
                        @click:clear="$nextTick(() => data.amm = {})"
                    />
                </v-col>
                <v-col
                    cols="12"
                    md="12"
                    v-if="data.rav === 2 && data.amm.id === 'other'"
                >
                    <v-text-field
                        type="text"
                        v-model="data.amm_freetext"
                        :label="$t('participant.amm_freetext')"
                        :error-messages="formError(errors, 'ammFreetext')"
                    />
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>

import InsuranceFundProxy from '@/proxies/InsuranceFundProxy';
import RavProxy from '@/proxies/RavProxy';
import RavConsultantProxy from '@/proxies/RavConsultantProxy';
import AmmProxy from '@/proxies/AmmProxy';
import FormDataMixin from '@/mixins/form-data';
import ParticipantMixin from '@/mixins/participant';

export default {
    name: 'RAVALVInformation',
    mixins: [ FormDataMixin, ParticipantMixin ],
    props: {
        data: {
            type: Object,
            default: () => ({
                insurance_fund: {},
                rav_location: {},
                rav_consultant: {},
                amm: {},
            }),
        },
        errors: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            insuranceFunds: [],
            ravLocations: [],
            amms: [],
            ravConsultants: [],
            ravConsultantsLoading: false,
        }
    },
    created() {
        new InsuranceFundProxy().all().then(({ data }) => {
            this.insuranceFunds = data.data;
        }).catch(({ data }) => {
            this.displayError(data);
        });
        new RavProxy().all().then(({ data }) => {
            this.ravLocations = data.data;
        }).catch(({ data }) => {
            this.displayError(data);
        });
        new AmmProxy().all().then(({ data }) => {
            this.amms = data.data;
        }).catch(({ data }) => {
            this.displayError(data);
        });
        if (typeof this.data.rav_consultant.id !== 'undefined') {
            new RavConsultantProxy().all({
                active: true,
                ravId: this.data.rav_location.id,
            }).then(({ data }) => {
                this.ravConsultants = data.data;
            }).catch(({ data }) => {
                this.displayError(data);
            });
        }
    },
    computed: {
        computedAmms() {
            const allAmms = [];
            for (let i = 0; i < this.amms.length; i++) {
                const amm = this.amms[ i ];
                allAmms.push({
                    value: amm.id,
                    text: `${amm.name} (${amm.city})`,
                });
            }
            allAmms.push({
                value: 'other',
                text: this.$t('participant.other'),
            });
            return allAmms;
        },
        computedInsuranceFunds() {
            const allInsuranceFunds = [];
            for (let i = 0; i < this.insuranceFunds.length; i++) {
                const insuranceFund = this.insuranceFunds[ i ];
                allInsuranceFunds.push({
                    value: insuranceFund.id,
                    text: `${insuranceFund.number} ${insuranceFund.name}`,
                });
            }
            allInsuranceFunds.push({
                value: 'other',
                text: this.$t('participant.other'),
            });
            return allInsuranceFunds;
        },
        computedRavLocations() {
            const allRavLocations = [];
            for (let i = 0; i < this.ravLocations.length; i++) {
                const ravLocation = this.ravLocations[ i ];
                allRavLocations.push({
                    value: ravLocation.id,
                    text: `${ravLocation.city} ${ravLocation.name}`,
                });
            }
            allRavLocations.push({
                value: 'other',
                text: this.$t('participant.other'),
            });
            return allRavLocations;
        },
        computedRavConsultants() {
            const allRavConsultants = [];
            for (let i = 0; i < this.ravConsultants.length; i++) {
                const ravConsultant = this.ravConsultants[ i ];
                allRavConsultants.push({
                    value: ravConsultant.id,
                    text: `${ravConsultant.fullname}`,
                });
            }
            return allRavConsultants;
        },
    },
    methods: {
        ravLocationChange(ravLocationId) {
            this.ravConsultantsLoading = true;
            this.data.rav_consultant = {};
            new RavConsultantProxy().all({
                active: true,
                ravId: ravLocationId,
            }).then(({ data }) => {
                this.ravConsultants = data.data;
                this.ravConsultantsLoading = false;
            }).catch(({ data }) => {
                this.displayError(data);
            });
        }
    },
};
</script>
