import Proxy from './Proxy';

class RavProxy extends Proxy {
    constructor(parameters = {}) {
        super('/rav-location', parameters);
    }

    all() {
        return this.submit('get', '/');
    }

    consultantEmails(id) {
        return this.submit('get', `/${id}/consultant-emails`);
    }
}

export default RavProxy;
