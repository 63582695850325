<template>
    <v-flex
        md12
        class="px-12 pb-12"
    >
        <PageHeader
            :breadcrumbs="breadcrumbs"
            :title="$t('page.participant_new.title')"
            back
        />
        <v-row>
            <v-col cols="12">
                <ParticipantForm
                    :data="data"
                    :raw-data="rawData"
                    :errors="formErrors"
                    :save-function="saveObject"
                    :discard-function="resetObject"
                />
            </v-col>
        </v-row>
    </v-flex>
</template>

<script>

import PageHeader from '@/components/common/PageHeader';
import ParticipantForm from '@/components/participants/Form/ParticipantForm';
import ParticipantProxy from '@/proxies/ParticipantProxy';
import ParticipantMixin from '@/mixins/participant';
import { StandBy, SuccessSnackbar } from '@/app-events.js';

export default {
    name: 'ParticipantNew',
    mixins: [ ParticipantMixin ],
    components: {
        PageHeader,
        ParticipantForm,
    },
    data() {
        return {
            breadcrumbs: [
                {
                    text: this.$t('page.home.title'),
                    exact: true,
                    to: { name: 'home' },
                },
                {
                    text: this.$t('page.participant.title'),
                    exact: true,
                    to: { name: 'participants' },
                },
                {
                    text: this.$t('general.new'),
                    disabled: true,
                },
            ],
            data: {
                canton: {},
                course: {},
                coach: {},
                default_presence: {},
                insurance_fund: {},
                rav_location: {},
                rav_consultant: {},
                registration_time_slot: {},
                rav: 0,
                amm: {},
            },
            rawData: {},
        };
    },
    methods: {
        resetObject() {
            this.data = JSON.parse(JSON.stringify(this.rawData));
            this.resetErrors();
        },
        saveObject() {
            if (JSON.stringify(this.data) === JSON.stringify(this.rawData)) {
                return;
            }
            this.$eventBus.dispatch(new StandBy(true));
            new ParticipantProxy().new(this.prepareFormData(this.data)).then(({ data }) => {
                this.$eventBus.dispatch(new StandBy(false));
                this.$eventBus.dispatch(new SuccessSnackbar(this.$t('participant.actions.created')));
                this.$router.push({ name: 'participant_detail', params: { id: data.data.id } });
            }).catch(({ data }) => {
                this.processErrors(data);
                this.$eventBus.dispatch(new StandBy(false));
            });
        }
    },
};
</script>
