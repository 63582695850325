import DefaultLayout from '@/layouts/Default';
import AmmIndex from '@/views/Amm/Index';
import AmmDetail from '@/views/Amm/Detail';
import AmmNew from '@/views/Amm/New';

export default {
    path: '/amm',
    component: DefaultLayout,
    children: [
        {
            path: '',
            name: 'amm',
            component: AmmIndex,
            meta: {
                auth: true,
            },
        },
        {
            path: 'new',
            name: 'amm_new',
            component: AmmNew,
            meta: {
                auth: true,
            },
        },
        {
            path: ':id',
            name: 'amm_detail',
            component: AmmDetail,
            meta: {
                auth: true,
            },
        },
    ],
};
